export default {
  GRID_LOAD_NUM: 100,
  API_DEBOUNCED_MS: 300,
  SEARCH_DEBOUNCED_MS: 100,
  FORM_DEBOUNCED_MS: 900,
  AUTOCOMPLETE_LIMIT: 10,
  DOM_WAIT_MS: 25, // https://github.com/vuejs/vuex/issues/1023
  DEFAULT_QUERY_PROPS: ['season'],
  DEFAULT_ASSET_FILTER: {
  },
  UNAUTHENTICATED_PATHS: ['/set-password', '/logged-out', '/confirm-email', '/not-approved', '/ad-register', '/file']
}
