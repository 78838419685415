import store from '@/store/store'
import feConstants from '@/store/constants/config/fe_constants'
export const getFileTypeIcon = (fileName) => {
  const fileExt = fileName.split('.').pop()
  switch (fileExt.toLowerCase()) {
    case 'pdf':
      return require(`@/assets/fileIcons/file-pdf.svg`)
    case 'docx':
      return require(`@/assets/fileIcons/file-doc.svg`)
    case 'zip':
      return require(`@/assets/fileIcons/file-zip.svg`)
    case 'jpeg':
    case 'gif':
    case 'jpg':
    case 'png':
      return require(`@/assets/fileIcons/file-image.svg`)
    case 'psd':
      return require(`@/assets/fileIcons/file-psd.svg`)
    case 'xlsx':
      return require(`@/assets/fileIcons/file-spreadsheet.svg`)
    case 'pptx':
      return require(`@/assets/fileIcons/file-presentation.svg`)
    case 'mp4':
      return require(`@/assets/fileIcons/file-video.svg`)
    default:
      return require(`@/assets/fileIcons/file-generic.svg`)
  }
}

export const getFileTypeText = (fileName) => {
  return fileName.split('.').pop()
}

export const resizeImage = (file, height, width) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()
    const canvas = document.createElement('canvas')

    reader.onload = (e) => {
      img.src = e.target.result
    }

    img.onload = () => {
      let origHeight = img.height
      let origWidth = img.width

      let ratio = 1
      if ((height && !width) || (!height && width)) {
        ratio = (height ? height / origHeight : width / origWidth).toFixed(2)
      }

      let adjustedHeight = height || origHeight * ratio
      let adjustedWidth = width || origWidth * ratio

      canvas.height = adjustedHeight
      canvas.width = adjustedWidth

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, adjustedWidth, adjustedHeight)
      canvas.toBlob(blob => resolve(new File([blob], 'name')), 'image/jpeg')
    }

    reader.readAsDataURL(file)
  })
}

export const s3Href = (path, bucket = feConstants.ITS__S3__BUCKET_TYPE__PUBLIC) => {
  if (path && path.includes('http')) {
    return path
  }

  const s3 = store.state.options.data.aws.s3
  const bucketName = s3[`${bucket}Bucket`]
  const domain = s3.domain
  const s3path = `https://${bucketName || bucket}.${domain}`

  if (!path) {
    path = ''
  }

  path = path[0] === '/' ? path.substr(1) : path

  if (!path) {
    return require('@/assets/images/thumb-image-coming-soon.jpg')
  } else {
    return `${s3path}/${path}`
  }
}
