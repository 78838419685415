import axios from 'axios'
import api from '@/api'

// import ApiValidator from '@/classes/ApiValidator'

import {
  VUEX_API_OPTIONS_FETCH,
  VUEX_API_OPTIONS_INTERNAL_ASSORTMENT_FETCH,

  VUEX_API_OPTIONS_FETCH_SUCCESS,
  VUEX_API_OPTIONS_FETCH_FAILURE,

  VUEX_API_OPTIONS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

// const apiValidator = new ApiValidator('options')

const state = {
  optionsRequest: null,
  optionsCancelRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_OPTIONS_FETCH]: async ({ commit, dispatch }, payload) => {
    // if (!payload.libraryType) return null

    // const validatedPayload = apiValidator.validate(payload.data)

    // Set `optionsRequest` to PENDING
    commit(VUEX_API_OPTIONS_FETCH)

    // Cancel previous requests
    if (state.optionsCancelRequest) state.optionsCancelRequest.cancel()
    await commit(VUEX_API_OPTIONS_SET_CANCEL_TOKEN, 'optionsCancelRequest')

    // TODO: Using `options/data/` because it will pass, but return value
    // not needed.
    const orgType = payload.data.query.orgType
    const isAssortmentLibrary = payload.libraryType === 'Assortment'
    let route = `${payload.libraryType}${orgType && isAssortmentLibrary ? '/' + orgType : ''}`

    return api.post(`/options/data/${route}`, payload.data).then(response => {
      commit(VUEX_API_OPTIONS_FETCH_SUCCESS)

      return response.data
    }).catch(err => {
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Dynamic options fetch failed!`
          }
        })
      }
    })
  },

  [VUEX_API_OPTIONS_INTERNAL_ASSORTMENT_FETCH]: async ({ rootState, commit, dispatch }, payload) => {
    // if (!payload.libraryType) return null

    // const validatedPayload = apiValidator.validate(payload.data)

    // Set `optionsRequest` to PENDING
    commit(VUEX_API_OPTIONS_FETCH)

    // Cancel previous requests
    if (state.optionsCancelRequest) state.optionsCancelRequest.cancel()
    await commit(VUEX_API_OPTIONS_SET_CANCEL_TOKEN, 'optionsCancelRequest')

    // TODO: Using `options/data/` because it will pass, but return value
    // not needed.

    return api.post(`/internalAssortments/products/options`, payload.data).then(response => {
      commit(VUEX_API_OPTIONS_FETCH_SUCCESS)

      return response.data
    }).catch(err => {
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Dynamic options fetch failed!`
          }
        })
      }
    })
  }
}

const mutations = {
  [VUEX_API_OPTIONS_FETCH]: state => {
    state.optionsRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_OPTIONS_FETCH_SUCCESS]: state => {
    state.optionsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_OPTIONS_FETCH_FAILURE]: state => {
    state.optionsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUEX_API_OPTIONS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
