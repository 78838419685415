import productsAvailabilityModule from '@/components/_core/FilterBar/_config/modules/productsAvailabilityModule.js'

export default {
  modules: [
    {
      label: 'Style / Color',
      filterGroups: [
        {
          hint: 'i.e. 220001 BKGR, 54358, BBK',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter IDs separated by commas'
              },
              key: 'id'
            }
          ]
        }
      ]
    },
    {
      label: 'Keyword',
      allowMultiple: false,
      filterGroups: [
        {
          label: 'Name',
          hint: 'Name is combination of Outsole and Upper',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter Name'
              },
              key: 'name',
              multiQuery: {
                type: 'multiselect',
                ifAllSelectedUseKey: 'name',
                options: [
                  {
                    label: 'Outsole',
                    key: 'outsole',
                    value: true
                  },
                  {
                    label: 'Upper',
                    key: 'upper',
                    value: true
                  }
                ]
              }
            }
          ]
        },
        {
          label: 'Description',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter Description'
              },
              key: 'description'
            }
          ]
        }
      ]
    },
    {
      label: 'Key Initiative Season',
      allowMultiple: false,
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'keyInitiative',
              placeholder: 'Key Initiative',
              // data: 'properties.Product.properties.keyInitiative.options'
              data: {
                getter: 'seasonSetsFlattened',
                filters: {
                  productType: 'Apparel'
                }
              }
            }
          ]
        }
      ]
    },
    {
      label: 'Division',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Division Name or ID',
                filterable: true,
                multiselect: true
              },
              key: 'division',
              chipLabelProp: 'value',
              data: 'properties.Product.properties.division.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Gender',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'gender',
              data: 'properties.Product.properties.gender.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Color / Material',
      filterGroups: [
        {
          layout: {
            type: 'relational'
          },
          filters: [
            {
              group: 'Primary',
              component: {
                type: 'select',
                placeholder: 'Color',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.color1',
              data: 'properties.Product.properties.components.properties.color1.options'
            },
            {
              group: 'Primary',
              component: {
                type: 'select',
                placeholder: 'Material',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.material1',
              data: 'properties.Product.properties.components.properties.material1.options'
            },
            {
              group: 'Secondary',
              component: {
                type: 'select',
                placeholder: 'Color',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.color2',
              data: 'properties.Product.properties.components.properties.color2.options'
            },
            {
              group: 'Secondary',
              component: {
                type: 'select',
                placeholder: 'Material',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.material2',
              data: 'properties.Product.properties.components.properties.material2.options'
            },
            {
              group: 'Tertiary',
              component: {
                type: 'select',
                placeholder: 'Color',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.color3',
              data: 'properties.Product.properties.components.properties.color3.options'
            },
            {
              group: 'Tertiary',
              component: {
                type: 'select',
                placeholder: 'Material',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.material3',
              data: 'properties.Product.properties.components.properties.material3.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Features',
      filterGroups: [
        {
          // hint: 'Enter Feature name',
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Feature',
                filterable: true,
                multiselect: true
              },
              key: 'features',
              data: 'properties.Product.properties.features.optionsApparel'
            }
          ]
        }
      ]
    },
    {
      label: 'Categories',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Type',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.apparelType',
              data: 'properties.Product.properties.categories.properties.apparelType.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Subtype',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.apparelSubType',
              data: 'properties.Product.properties.categories.properties.apparelSubType.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Season',
      filterGroups: [
        {
          hint: 'Enter Season code',
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Season Code',
                filterable: true
              },
              key: 'seasonCodes',
              data: 'properties.Product.properties.seasonCodes.options',
              chipLabelProp: 'value'
            }
          ]
        }
      ]
    },
    productsAvailabilityModule('Apparel'),
    {
      label: 'Price',
      allowMultiple: false,
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'price-span',
                fields: [
                  {
                    label: 'From',
                    key: '$gte'
                  },
                  {
                    label: 'To',
                    key: '$lte'
                  }
                ]
              },
              dependencies: {
                product: [{ location: 'locations.code' }]
              },
              payloadType: 'object',
              multiQuery: {
                type: 'singleselect',
                options: [
                  {
                    label: 'Cost',
                    key: 'locations.wholesalePrice',
                    value: true
                  },
                  {
                    label: 'Sugg. Retail',
                    key: 'locations.suggestedRetail',
                    value: false
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ]
}
