import {
  VUEX_ANNOUNCEMENTS_FETCH,
  VUEX_ANNOUNCEMENTS_FETCH_SUCCESS,

  VUEX_ANNOUNCEMENTS_DELETE,
  VUEX_ANNOUNCEMENTS_DELETE_SUCCESS,

  VUEX_ANNOUNCEMENTS_UPDATE,
  VUEX_ANNOUNCEMENTS_UPDATE_SUCCESS
} from '@/store/constants/models/announcements'

import {
  VUEX_API_ANNOUNCEMENT_FETCH,
  VUEX_API_ANNOUNCEMENT_UPDATE,
  VUEX_API_ANNOUNCEMENT_DELETE
} from '@/store/constants/api'

const state = {
  announcement: {},
  announcements: []
}

const getters = {}

const actions = {
  [VUEX_ANNOUNCEMENTS_FETCH]: ({ dispatch, commit }) => {
    dispatch(VUEX_API_ANNOUNCEMENT_FETCH).then(response => {
      if (response?.data) commit(VUEX_ANNOUNCEMENTS_FETCH_SUCCESS, response.data)
    }).catch(err => {
      console.error(err)
      return err
    })
  },

  [VUEX_ANNOUNCEMENTS_DELETE]: ({ dispatch, commit }, payload) => {
    dispatch(VUEX_API_ANNOUNCEMENT_DELETE, payload).then(() => {
      commit(VUEX_ANNOUNCEMENTS_DELETE_SUCCESS, payload._id)
    }).catch(err => {
      console.error(err)
      return err
    })
  },

  [VUEX_ANNOUNCEMENTS_UPDATE]: ({ dispatch, commit }, payload) => {
    dispatch(VUEX_API_ANNOUNCEMENT_UPDATE, payload).then(() => {
      commit(VUEX_ANNOUNCEMENTS_UPDATE_SUCCESS, payload)
    }).catch(err => {
      console.error(err)
      return err
    })
  }
}

const mutations = {
  [VUEX_ANNOUNCEMENTS_FETCH]: state => {
    state.announcements = []
  },

  [VUEX_ANNOUNCEMENTS_FETCH_SUCCESS]: (state, data) => {
    state.announcements = data.reverse()
  },

  [VUEX_ANNOUNCEMENTS_UPDATE_SUCCESS]: (state, data) => {
    const index = state.announcements.findIndex(a => a._id === data._id)

    const updated = {
      ...state.announcements[index],
      ...data
    }

    if (updated.published) {
      state.announcements.splice(index, 1, updated)
    } else {
      state.announcements = state.announcements.filter(chart => chart._id !== updated._id)
    }
  },

  [VUEX_ANNOUNCEMENTS_DELETE_SUCCESS]: (state, data) => {
    state.announcements = state.announcements.filter(chart => chart._id !== data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
