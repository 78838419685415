import PQueue from 'p-queue'
import _clone from 'lodash.clonedeep'

import { fetchSignedURLs, completeUploadRequest } from './http'
import { UploadModel } from './models'

import Axios from 'axios'

const ERROR_BASE = 'MultipartUploader:'
const ERROR_CONFIG_VALIDATION = 'Config Error ->'
const DEFAULT_CONFIG_AWS = { forceOverwrite: true, chunkSize: 10 * 1024 * 1024 }

export default class MultipartUploader {
  constructor ({ awsConfig = {}, concurrencyLimit = 4 }) {
    // Constants
    this.AWS_CONFIG = Object.assign(DEFAULT_CONFIG_AWS, awsConfig)
    this.FILE_CHUNK_SIZE = this.AWS_CONFIG.chunkSize
    this.CONCURRENCY_SIZE = concurrencyLimit

    // Axios Setup
    // eslint-disable-next-line
    this.axios = new Axios.create()
    delete this.axios.defaults.headers.put['Content-Type']

    // PQueue Setup
    this.queue = new PQueue({ concurrency: this.CONCURRENCY_SIZE })

    // Upload Data Setup
    this.uploadData = null

    this.completeResponse = null

    // Event Listeners Setup
    this.listeners = {}

    // Validate Config
    MultipartUploader._validateConfig(this)
  }

  // ************************************************************************************
  // Public Methods
  // ************************************************************************************
  upload (file) {
    const config = _clone(this.AWS_CONFIG)

    new Promise(async (resolve, reject) => {
      const urlData = await fetchSignedURLs(config)
      if (urlData.message === 'Success') resolve(urlData)
      else reject(new Error())
    }).then((signedURLData) => {
      this.uploadData = new UploadModel({ ...config, file, signedURLData })
      this.uploadData.progress.total = file.size

      this.uploadData.parts.forEach((part, idx) => {
        const start = idx * this.FILE_CHUNK_SIZE
        const end = (idx + 1) * this.FILE_CHUNK_SIZE
        const blob = idx < this.uploadData.parts.length ? file.slice(start, end) : file.slice(start)

        this.queue.add(() => {
          return this.axios
            .put(part.url, blob, {
              onUploadProgress: (progressEvent) => {
                MultipartUploader._updateProgressHandler(this.uploadData, part, progressEvent)
                MultipartUploader._triggerEvent(
                  this.listeners,
                  'uploadProgress',
                  this.uploadData.progress
                )
              }
            })
            .then((response) => {
              this.uploadData.parts[idx].etag = JSON.parse(response.headers.etag)
              this.updateCompleteHandler(
                this,
                this.uploadData,
                part,
                ITS__FILE__PROCESSING__STATUS__SUCCESS
              )
            })
            .catch(() => {
              this.updateCompleteHandler(
                this,
                this.uploadData,
                part,
                ITS__FILE__PROCESSING__STATUS__FAILED
              )
            })
            .finally(() => {
              // MultipartUploader._triggerEvent(this.listeners, 'uploadComplete', this.uploadData.progress.status)
            })
        })
      })
    })

    return this
  }

  // Events
  onUploadProgress (cb) {
    this.listeners['uploadProgress'] = cb
    return this
  }

  onUploadComplete (cb) {
    this.listeners['uploadComplete'] = cb
    return this
  }

  // ************************************************************************************
  // Private Methods
  // ************************************************************************************
  static _validateConfig (context) {
    if (!context.AWS_CONFIG.bucket) {
      MultipartUploader._throwError(`${ERROR_CONFIG_VALIDATION} "awsConfig.bucket" is missing`)
    }
    if (!context.AWS_CONFIG.key) {
      MultipartUploader._throwError(`${ERROR_CONFIG_VALIDATION} "awsConfig.key" is missing`)
    }
    if (!context.AWS_CONFIG.fileSize) {
      MultipartUploader._throwError(`${ERROR_CONFIG_VALIDATION} "awsConfig.fileSize" is missing`)
    }
    if (!context.AWS_CONFIG.chunkSize) {
      MultipartUploader._throwError(`${ERROR_CONFIG_VALIDATION} "awsConfig.chunkSize" is missing`)
    }
  }

  static _updateProgressHandler (uploadData, part, progressEvent) {
    uploadData.parts[part.index].progress = {
      loaded: progressEvent.loaded,
      total: progressEvent.total,
      status: 'In Progress'
    }

    const totalProgress = uploadData.progress.total
    const loadedProgress = uploadData.parts
      .map((item) => item.progress.loaded)
      .reduce((acc, curr) => (acc += curr))

    uploadData.progress.loaded = loadedProgress
    uploadData.progress.loadPercent = (loadedProgress / totalProgress) * 100
  }

  async updateCompleteHandler (context, uploadData, part, status) {
    uploadData.parts[part.index].progress.status = status

    const partStatusArr = uploadData.parts.map((item) => item.progress.status)
    const allPartsComplete = !partStatusArr.includes('In Progress')

    if (allPartsComplete) {
      const status = partStatusArr.every((status) => status === 'Success') ? 'Success' : 'Error'

      const { bucket, key, progress, parts, uploadId } = uploadData
      // progress.status = status // send complete response

      if (status === 'Success') {
        try {
          context.completeResponse = await completeUploadRequest({
            bucket,
            key,
            uploadId,
            etags: parts.map((part) => part.etag)
          })
          progress.status = status
          MultipartUploader._triggerEvent(context.listeners, 'uploadComplete', context.completeResponse.data.message)
        } catch {
          progress.status = 'Error'
          MultipartUploader._triggerEvent(context.listeners, 'uploadComplete', progress.status)
        }
      }
    }
  }

  static _triggerEvent (listeners, eventName, data) {
    const cb = listeners[eventName]
    if (cb) cb(data)
  }

  static _throwError (msg) {
    console.error(`${ERROR_BASE} ${msg}`)
  }
}
