import _merge from 'lodash.merge'
import _set from 'lodash.set'
import _get from 'lodash.get'
// import _unset from 'lodash.unset'
import _cloneDeep from 'lodash.clonedeep'
import dotize from 'dotize'

import { getUserPOPColors } from '@/helpers/pop_colors'

import {
  // VUEX_ASSORTMENT_SHOWROOM_DEFAULTS_SET,

  // VUEX_ASSORTMENT_SHOWROOM_QUERY_SET,
  // VUEX_ASSORTMENT_SHOWROOM_QUERY_CLEAR,

  VUEX_ASSORTMENT_SHOWROOM_OPTIONS_SET,
  // VUEX_ASSORTMENT_SHOWROOM_OPTIONS_CLEAR,
  // VUEX_ASSORTMENT_SHOWROOM_OPTIONS_UNSET,

  VUEX_ASSORTMENT_SHOWROOM_UPDATE_STATE_FROM_QUERY,

  VUEX_ASSORTMENT_SHOWROOM_UI_SET
} from '@/store/constants/models/assortments/assortmentShowroom'

const state = {
  options: {
    search: {
      _options: {
        streaming: true
      },
      _uiOptions: {
        sort: {
          'locations.addDate': -1
        },
        includeAssortmentsDataQuery: {
          productType: null,
          orgType: null,
          method: null,
          _id: null
        },
        frontendOptions: {
          controlBarTab: null
        }
      }
    },
    browse: {
      _options: {
        streaming: false
      },
      _uiOptions: {
        assortmentsQuery: {
          productType: null,
          orgType: null,
          method: null,
          _id: null
        },
        sort: {
          sortOrder: 1,
          assortmentIndex: 1
        },
        frontendOptions: {
          controlBarTab: null
        }
      }
    },
    details: {}
  },
  ui: {
    startScreenCompleted: false
  }
}

const getters = {
  getAssortmentShowroomOptions: (state, getters, rootState) => obj => {
    if (!obj) return false

    let data = _cloneDeep(state.options[obj])

    const assortment = rootState.assortments.assortment

    if (data._uiOptions.includeAssortmentsDataQuery) {
      data._uiOptions.includeAssortmentsDataQuery.method = assortment.method
      data._uiOptions.includeAssortmentsDataQuery.orgType = assortment.orgType
      data._uiOptions.includeAssortmentsDataQuery._id = assortment._id
      data._uiOptions.includeAssortmentsDataQuery.productType = assortment.productType
    }

    // Set `styleLevel` to false only for POP and add `popColors`
    if (assortment.productType === ITS__PRODUCT_TYPE__POP) {
      data._options.popColors = getUserPOPColors(assortment.locationId, assortment.status)
    }

    return data
  },

  getAssortmentShowroomProductDetailQuery: (state, getters, rootState) => (style, view) => {
    let assortment = rootState.assortments.assortment
    let details = { ...state.options.details }
    let popColors = {}
    let assortmentQueryData = {}

    const assortmentQueryName = rootState.controlBar.currentTab === 'search'
      ? 'includeAssortmentsDataQuery'
      : 'assortmentsQuery'

    assortmentQueryData = {
      [assortmentQueryName]: {
        method: ITS__ASSORTMENTS__METHOD_TYPE__STATIC,
        orgType: ITS__ASSORTMENTS__ORG_TYPE__REGULAR,
        productType: assortment.productType,
        _id: assortment._id
      }
    }

    // assortment.locationId
    switch (assortment.productType) {
      case ITS__PRODUCT_TYPE__FOOTWEAR:
        break
      case ITS__PRODUCT_TYPE__POP:
        popColors = {
          popColors: getUserPOPColors(assortment.locationId, assortment.status)
        }
        delete details.project
        break
      case ITS__PRODUCT_TYPE__APPAREL:
        delete details.project
        break
    }

    let query = {
      style,
      'locations.code': assortment.locationId,
      'productType': assortment.productType,
      _uiOptions: {
        ...assortmentQueryData
      },
      _options: {
        ...details,
        ...popColors
      }
    }

    if (assortment.uiSettings?.digitalShowroomSettings?.assortmentStatusOnly) {
      query['locations.lineStatus'] = assortment.status
    }

    return query
  }
}

const actions = {
  /* [VUEX_ASSORTMENT_SHOWROOM_OPTIONS_UNSET]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_SHOWROOM_OPTIONS_UNSET, payload)
  }, */

  /* [VUEX_ASSORTMENT_SHOWROOM_DEFAULTS_SET]: ({ rootState, commit }) => {
    const defaults = rootState.properties.data.InternalAssortments.defaults

    let data = {
      _uiOptions: {
        assortmentsQuery: {
          state: default.state,
          season: defaults.currentSeason,
          year: defaults.currentYear,
          region: defaults.defaultRegion
        }
      }
    }

    commit(VUEX_ASSORTMENT_SHOWROOM_DEFAULTS_SET, data)
  }, */

  /* [VUEX_ASSORTMENT_SHOWROOM_QUERY_SET]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_SHOWROOM_QUERY_SET, payload)
  }, */

  /* [VUEX_ASSORTMENT_SHOWROOM_QUERY_CLEAR]: ({ commit }) => {
    commit(VUEX_ASSORTMENT_SHOWROOM_QUERY_CLEAR)
  }, */

  [VUEX_ASSORTMENT_SHOWROOM_OPTIONS_SET]: async ({ commit }, payload) => {
    const data = _merge(payload, {
      // additional static props
    })

    await commit(VUEX_ASSORTMENT_SHOWROOM_OPTIONS_SET, data)
  },

  /* [VUEX_ASSORTMENT_SHOWROOM_OPTIONS_CLEAR]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_SHOWROOM_OPTIONS_CLEAR, payload)
  }, */

  [VUEX_ASSORTMENT_SHOWROOM_UPDATE_STATE_FROM_QUERY]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_SHOWROOM_UPDATE_STATE_FROM_QUERY, payload)
  },

  [VUEX_ASSORTMENT_SHOWROOM_UI_SET]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_SHOWROOM_UI_SET, payload)
  }
}

const mutations = {
  /* [VUEX_ASSORTMENT_SHOWROOM_DEFAULTS_SET]: (state, data) => {
    if (payload.query) Object.assign(state.query, payload.query)
    if (payload.options) Object.assign(state.options, payload.options)
  }, */

  /* [VUEX_ASSORTMENT_SHOWROOM_QUERY_SET]: (state, data) => {
    if (payload.clearCurrentQuery) state.query = {}
    Object.assign(state.query, _merge(state.query, payload))
  }, */

  /* [VUEX_ASSORTMENT_SHOWROOM_QUERY_CLEAR]: state => {
    state.query = {}
  }, */

  [VUEX_ASSORTMENT_SHOWROOM_OPTIONS_SET]: (state, data) => {
    Object.keys(data).forEach(key => {
      _merge(state.options[key], data[key])
    })
  },

  /* [VUEX_ASSORTMENT_SHOWROOM_OPTIONS_UNSET]: (state, data) => {
    _unset(state.options, payload)
  }, */

  /* [VUEX_ASSORTMENT_SHOWROOM_OPTIONS_CLEAR]: (state, data) => {
    if (!payload || Object.keys(payload).length === 0) state.options = {}
  }, */

  [VUEX_ASSORTMENT_SHOWROOM_UI_SET]: (state, data) => {
    _merge(state.ui, data)
  },

  [VUEX_ASSORTMENT_SHOWROOM_UPDATE_STATE_FROM_QUERY]: (state, data) => {
    // Convert dot notation strings to objects
    let dotized = dotize.convert(data)

    const searchType = _get(dotized, 'query._uiOptions.frontendOptions.controlBarTab')
    if (!searchType) return

    // Bail if not properties exist in query
    if (!Object.keys(dotized).length) return

    // Loop over props to add to state.options
    Object.keys(dotized).forEach(async key => {
      // Define value to be stored later
      let value

      // Modify prop value
      switch (typeof dotized[key]) {
        // If string - string or number (0 or 1 for project)
        case 'string' :
          // If 1 or 0 convert into int else decode string for spaces and special chars
          value = (dotized[key] === '1' || dotized[key] === '0')
            ? parseInt(dotized[key])
            : decodeURIComponent(dotized[key])
          break
        // Otherwise store as is (for bools)
        default :
          value = dotized[key]
      }

      // Check is top level oh state.options has prop to be assigned
      if (state.options[searchType].hasOwnProperty(key)) {
        // If prop exists - add
        _set(state.options[searchType], key, value)
      } else {
        // Loop through state.options and look for mix of nested objects and
        // dot notated props. This is used to set specific props that have dot notation
        // so we don't convert them into nested object, ie 'query._options.project.locations.lineStatus'

        // Split dot notated keys into array
        const keyArr = key.split('.')

        // Loop through array to find where dot notated props begin
        /*
        * This is the major chunk of functionality. This algo splits dot notation strings and trys to match
        * the object with state. If it matches, save the data to state -- if not, it splits the string
        * at the next dot (.) and trys again until the prop is found.
        *
        * Example: [_options]['project.locations.lineStatus'] (NOT FOUND) --> [_options.project]['locations.lineStatus'] (FOUND)
        *
        * It keeps moving until the prop is found
        */
        let idx = 1
        while (idx < keyArr.length) {
          // Left side props (ie, _options.project)
          let keyLeft = [...keyArr].slice(0, idx).join('.')
          // Right side props (ie, locations.lineStatus)
          let keyRight = [...keyArr].splice(idx, keyArr.length - 1).join('.')

          // Is array item without nested dot notation
          if (/(\[[0-9]]?)/.test(keyRight) && keyRight.split('.').length === 1) {
            // Get key without array brackets
            const arrKeyLeft = keyRight.split(/(\[[0-9]]?)/)[0]
            // Get array index - int inside brackets
            const arrKeyRight = keyRight.split(/(\[[0-9]]?)/)[1].match(/([0-9])/)[1]

            // Assign keyRight as new formatted array key
            keyRight = `${arrKeyLeft}.${arrKeyRight}`
          }

          // Get base object with dynamic key
          let baseObj = _get(state.options[searchType], keyLeft)

          if (!baseObj) return

          // Validate if object exist as dot notated prop
          let valid = !!baseObj.hasOwnProperty(keyRight)

          // If exists OR key includes 'assortmentsQuery` - add
          // 'assortmentsQuery is initialize empty so theres nothing to validate (can we validate with null values????)
          if (valid /* || keyLeft.includes('assortmentsQuery') */) {
            // Set in state.options
            _set(baseObj, keyRight, value)
            // Return to abort loop
            return
          }

          // Index to progressing position of baseObj
          idx++
        }
      }
    })

    state.options[searchType] = JSON.parse(JSON.stringify(state.options[searchType], (key, value) => {
      return (value === null ? undefined : value)
    }))
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
