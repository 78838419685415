import {
  VUEX_PHOTOS_FETCH,
  VUEX_PHOTOS_FETCH_SUCCESS,
  VUEX_PHOTOS_FETCH_FAILURE,

  VUEX_PHOTOS_STATE_UPDATE,

  VUEX_PHOTOS_CLEAR
} from '@/store/constants/models/photos'

import {
  VUEX_API_PHOTOS_FETCH_STREAMING
} from '@/store/constants/api'

let tempPhotos = []

const state = {
  totalCount: 0,
  photos: []
}

const getters = {}

const actions = {
  [VUEX_PHOTOS_FETCH]: ({ commit, dispatch }, payload) => {
    commit(VUEX_PHOTOS_FETCH)

    dispatch(VUEX_API_PHOTOS_FETCH_STREAMING, {
      query: payload,
      callback: data => {
        commit(VUEX_PHOTOS_STATE_UPDATE, data)
      }
    }).catch(() => {
      // clears photos state on failure/cancel
      commit(VUEX_PHOTOS_FETCH)
    })
  },

  [VUEX_PHOTOS_CLEAR]: ({ commit }) => {
    commit(VUEX_PHOTOS_CLEAR)
  }
}

const mutations = {
  [VUEX_PHOTOS_FETCH]: state => {
    state.photos = []
  },

  [VUEX_PHOTOS_FETCH_SUCCESS]: (state, data) => {
    //
  },

  [VUEX_PHOTOS_FETCH_FAILURE]: (state, data) => {
    //
  },

  [VUEX_PHOTOS_STATE_UPDATE]: (state, data) => {
    Object.keys(data).forEach(key => {
      switch (key) {
        case 'totalCount' :
          state.totalCount = data[key]
          // Clear tempPhotos if totalCount is updated
          tempPhotos = []
          break

        case 'items' :
          tempPhotos.push(...data[key])

          if (tempPhotos.length === 100) {
            state.photos = [...tempPhotos]
          }

          if (tempPhotos.length > 100 && tempPhotos.length % 500 === 0) {
            state.photos = [...tempPhotos]
          }

          if (tempPhotos.length === state.totalCount) {
            state.photos = [...tempPhotos]
            tempPhotos = []
          }

          break
      }
    })
  },

  [VUEX_PHOTOS_CLEAR]: state => {
    state.photos = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
