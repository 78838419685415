import {
  replaceSpecialCharaters as REGEX_SPECIAL_CHARATERS,
  replaceSpecialCharatersAndWhiteSpace as REGEX_SPECIAL_CHARATERS_AND_WHITE_SPACE,
  replaceMultipleWhiteSpace as REGEX_MULTIPLE_WHITE_SPACE,
  isNotEmpty as REGEX_IS_NOT_EMPTY
  // isValidServerDate as REGEX_IS_VALID_SERVER_DATE
} from './expressions'

const replaceSpecialCharaters = (str, replaceWith = ' ') => {
  return str.replace(REGEX_SPECIAL_CHARATERS, replaceWith)
}

const replaceSpecialCharatersAndWhiteSpace = (str, replaceWith = ' ') => {
  return str.replace(REGEX_SPECIAL_CHARATERS_AND_WHITE_SPACE, replaceWith)
}

const replaceMultipleWhiteSpace = (str, replaceWith = ' ') => {
  return str.replace(REGEX_MULTIPLE_WHITE_SPACE, replaceWith)
}

const isNotEmpty = str => {
  return REGEX_IS_NOT_EMPTY.test(str)
}

/* const isValidServerDate = str => {
  return REGEX_IS_VALID_SERVER_DATE.test(str)
} */

export default {
  replaceSpecialCharaters,
  replaceSpecialCharatersAndWhiteSpace,
  replaceMultipleWhiteSpace,
  isNotEmpty
  // isValidServerDate
}
