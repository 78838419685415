export const VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FETCH = 'VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FETCH'
export const VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_SUCCESS = 'VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_SUCCESS'
export const VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FAILURE = 'VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FAILURE'
export const VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_CLEAR = 'VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_CLEAR'

export const VUEX_ASSORTMENTS_INTERNALS_DEFAULTS_SET = 'VUEX_ASSORTMENTS_INTERNALS_DEFAULTS_SET'

export const VUEX_ASSORTMENTS_INTERNALS_QUERY_SET = 'VUEX_ASSORTMENTS_INTERNALS_QUERY_SET'
export const VUEX_ASSORTMENTS_INTERNALS_QUERY_CLEAR = 'VUEX_ASSORTMENTS_INTERNALS_QUERY_CLEAR'

export const VUEX_ASSORTMENTS_INTERNALS_OPTIONS_CLEAR = 'VUEX_ASSORTMENTS_INTERNALS_OPTIONS_CLEAR'
export const VUEX_ASSORTMENTS_INTERNALS_QUERY_UNSET = 'VUEX_ASSORTMENTS_INTERNALS_QUERY_UNSET'

export const VUEX_ASSORTMENTS_INTERNALS_UPDATE_STATE_FROM_QUERY = 'VUEX_ASSORTMENTS_INTERNALS_UPDATE_STATE_FROM_QUERY'

export const VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_DIVISIONS_SET = 'VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_DIVISIONS_SET'
export const VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_CONTENT_SET = 'VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_CONTENT_SET'
