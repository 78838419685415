import {
  VUEX_SUBHEADER_SHOW,
  VUEX_SUBHEADER_HIDE,
  VUEX_SUBHEADER_TOGGLE_OPEN_STATE,
  VUEX_SUBHEADER_SET_SIZE,
  VUEX_SUBHEADER_SET_CLOSE_BTN_VISIBLE,
  VUEX_SUBHEADER_UPDATE_CONTENT,
  VUEX_SUBHEADER_UPDATE_PROPS
} from '@/store/constants/ui/subheader'

const state = {
  openState: false,
  content: null,
  size: null,
  props: {},
  closeButton: {
    visible: false
  }
}

const getters = {}

const actions = {
  [VUEX_SUBHEADER_SHOW]: ({ commit }, payload) => {
    commit(VUEX_SUBHEADER_SHOW, payload)
  },

  [VUEX_SUBHEADER_HIDE]: ({ commit }, payload) => {
    commit(VUEX_SUBHEADER_HIDE, payload)
  },

  [VUEX_SUBHEADER_TOGGLE_OPEN_STATE]: ({ commit }) => {
    commit(VUEX_SUBHEADER_TOGGLE_OPEN_STATE)
  },

  [VUEX_SUBHEADER_SET_SIZE]: ({ commit }, payload) => {
    if (!payload) return
    commit(VUEX_SUBHEADER_SET_SIZE, payload)
  },

  [VUEX_SUBHEADER_SET_CLOSE_BTN_VISIBLE]: ({ commit }, payload) => {
    commit(VUEX_SUBHEADER_SET_CLOSE_BTN_VISIBLE, payload)
  },

  [VUEX_SUBHEADER_UPDATE_CONTENT]: ({ commit }, payload) => {
    commit(VUEX_SUBHEADER_UPDATE_CONTENT, payload)
  },

  [VUEX_SUBHEADER_UPDATE_PROPS]: ({ commit }, payload) => {
    commit(VUEX_SUBHEADER_UPDATE_PROPS, payload)
  }
}

const mutations = {
  [VUEX_SUBHEADER_SHOW]: (state, data) => {
    state.content = data.content ? data.content : null
    state.size = parseInt(data.size) || data.size ? parseInt(data.size) : parseInt(ITS__UI__SUBHEADER__SIZE__2)
    state.openState = true
    state.props = data.props ? data.props : {}
    state.closeButton.visible = data.closeButton?.visible || false
  },

  [VUEX_SUBHEADER_HIDE]: (state, data = {}) => {
    state.openState = false
    state.size = 0
    state.closeButton.visible = data.closeButton?.visible || false
    if (!data.preserveContent) state.content = data.content
  },

  [VUEX_SUBHEADER_TOGGLE_OPEN_STATE]: state => {
    state.openState = !state.openState
  },

  [VUEX_SUBHEADER_SET_SIZE]: (state, data) => {
    state.size = parseInt(data)
  },

  [VUEX_SUBHEADER_SET_CLOSE_BTN_VISIBLE]: (state, data) => {
    state.closeButton.visible = data
  },

  [VUEX_SUBHEADER_UPDATE_CONTENT]: (state, data) => {
    state.content = data
  },

  [VUEX_SUBHEADER_UPDATE_PROPS]: (state, data) => {
    state.props = Object.assign(state.props, data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
