import Oboe from 'oboe'

import queryCtrl from '@/utils/query'

import {
  VUEX_API_PHOTOS_FETCH,
  VUEX_API_PHOTOS_FETCH_STREAMING,
  VUEX_API_PHOTOS_FETCH_SUCCESS,
  VUEX_API_PHOTOS_FETCH_FAILED,
  VUEX_API_PHOTOS_ABORT
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

let oboeInstance
const BUFFER_SIZE = 20
const ACTIVE_BUFFER_SIZE = 20

const state = {
  photosRequest: null,
  photosCancelRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_PHOTOS_FETCH_STREAMING]: async ({ state, dispatch, commit }, payload) => {
    try {
      commit(VUEX_API_PHOTOS_FETCH_SUCCESS)
      oboeInstance.abort()
    } catch (error) { /*  */ }

    if (!Object.keys(payload.query).length) return

    let itemCount = 0
    let itemRows = 0
    let totalItems = 0
    let buffer = []

    let queryStr = queryCtrl.qsStringify(payload.query, { arrayFormat: 'indices' })

    await commit(VUEX_API_PHOTOS_FETCH)

    Oboe({
      method: 'GET',
      url: process.env.VUE_APP_API_BASE_URL + '/photos/data?' + queryStr,
      withCredentials: true
    }).on('start', function () {
      oboeInstance = this
    }).node('totalCount', function (totalCount) {
      totalItems = totalCount
      payload.callback({ totalCount: totalCount })
    }).node('row', function (row) {
      if (state.photosRequest !== ITS__API__REQUEST_TYPE__PENDING) {
        this.abort()
        return
      }

      const item = Object.freeze(row)
      itemCount++

      if (itemRows > ACTIVE_BUFFER_SIZE) {
        buffer.push(item)

        if (buffer.length === BUFFER_SIZE || itemCount === itemRows) {
          payload.callback({ items: buffer })
          buffer = []
        }
      } else {
        payload.callback({ items: [item] })
      }

      return Oboe.drop
    }).on('done', function () {
      if (itemCount === totalItems) {
        commit(VUEX_API_PHOTOS_FETCH_SUCCESS)
      }
    }).on('fail', function (error) {
      commit(VUEX_API_PHOTOS_FETCH_FAILED)

      if (error?.jsonBody?.message) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          timeout: -1,
          data: {
            type: 'error',
            message: `Cannot return products: ${error.jsonBody.message}`
          }
        })
      }
    })
  },

  [VUEX_API_PHOTOS_ABORT]: () => {
    if (oboeInstance) oboeInstance.abort()
  }
}

const mutations = {
  [VUEX_API_PHOTOS_FETCH]: state => {
    state.photosRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_PHOTOS_FETCH_SUCCESS]: state => {
    state.photosRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_PHOTOS_FETCH_FAILED]: state => {
    state.photosRequest = ITS__API__REQUEST_TYPE__FAILED
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
