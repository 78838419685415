import { loadView, loadComponent } from '@/helpers/asyncLoaders'
export default {
  path: '/prototypes',
  name: 'prototypes',
  component: loadView('app/App.vue'),
  children: [
    {
      path: 'dragdrop',
      name: 'prototypes--dragdrop',
      component: loadComponent('_prototypes/DragDrop.vue')
    },
    {
      path: 'previewer',
      name: 'prototypes--previewer',
      component: loadComponent('_prototypes/Previewer_Prototype.vue')
    },
    {
      path: 'category-select',
      name: 'prototypes--category-select',
      component: loadComponent('_prototypes/Category_Select_Prototype.vue')
    },
    {
      path: 'product-detail',
      name: 'prototypes--product-detail',
      component: loadComponent('_prototypes/Product_Detail_Prototype.vue')
    }
  ],
  meta: {
    menu: false,
    label: 'Prototypes',
    modules: {
      toolbar: {
        title: 'Prototypes',
        extension: {
          navigation: []
        }
      }
    }
  }
}
