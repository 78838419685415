import store from '@/store/store'
import Debug from 'logdown'
import shared from 'skch_its_be_fe_shared'
import longWaitBlocker from '@/helpers/longWaitBlocker'
import properties from '@/store/modules/config/properties'
import {
  VUEX_PRODUCTS_FETCH,
  VUEX_PRODUCTS_FETCH_SUCCESS,
  VUEX_PRODUCTS_FETCH_FAILURE,
  VUEX_PRODUCTS_CLEAR,

  VUEX_PRODUCT_STYLE_TEARSHEET,
  VUEX_PRODUCT_STYLE_TEARSHEET_SUCCESS,
  VUEX_PRODUCT_STYLE_TEARSHEET_FAILURE
} from '@/store/constants/models/products'

import {
  VUEX_API_PRODUCTS_REQUEST_FETCH,
  VUEX_API_PRODUCT_TEARSHEET
} from '@/store/constants/api'
import router from '@/router'
const pricesheet = shared.pricesheet
const d = new Debug('its:store:modules:products')

const state = {
  products: [],
  totalCount: 0,
  styleTearsheet: null
}

const getters = {}

const actions = {
  [VUEX_PRODUCTS_FETCH]: ({ commit, dispatch }, payload) => {
    commit(VUEX_PRODUCTS_FETCH)
    dispatch(VUEX_API_PRODUCTS_REQUEST_FETCH, payload.data).then(response => {
      longWaitBlocker.hide()
      commit(VUEX_PRODUCTS_FETCH_SUCCESS, {
        data: response.data[0],
        modelType: payload.modelType,
        action: payload.action ? payload.action : 'replace',
        prepareData: payload.prepareData || 'prepare'
      })
    }).catch(err => {
      longWaitBlocker.hide()
      commit(VUEX_PRODUCTS_FETCH_FAILURE, err)
    })
  },

  [VUEX_PRODUCTS_CLEAR]: ({ commit }) => {
    commit(VUEX_PRODUCTS_CLEAR)
  },

  [VUEX_PRODUCT_STYLE_TEARSHEET]: ({ commit, dispatch }, payload) => {
    dispatch(VUEX_API_PRODUCT_TEARSHEET, payload).then(response => {
      commit(VUEX_PRODUCT_STYLE_TEARSHEET_SUCCESS, {
        data: response.data,
        type: ITS__FILE__DOWNLOAD_TYPE__PDF,
        filename: `${payload.style}-Tearsheet.pdf`
      })
    }).catch(err => {
      commit(VUEX_PRODUCT_STYLE_TEARSHEET_FAILURE, err)
    })
  }
}

const mutations = {
  [VUEX_PRODUCTS_FETCH]: (state) => {
    // state.products = [] // do not clear items for each request | destroys pagination
  },

  [VUEX_PRODUCTS_FETCH_SUCCESS]: (state, data) => {
    if (data.data && data.data.totalCount) state.totalCount = data.data.totalCount

    let productsLookup
    switch (data.modelType) {
      case 'assortment' :
        productsLookup = store.state.assortments.assortmentProductsLookup
        break
      case 'order' :
        productsLookup = store.state.orders.orderProductsLookup
        break
      default :
        productsLookup = null
        break
    }

    if (data.data && data.data.data) {
      state.products = data.data.data.map(item => {
        let colors = []
        if (item.colors) {
          colors = item.colors.map(c => {
            return {
              ...c,
              active: !!productsLookup[`${item.style}-${c.color}`] || false,
              disabled: false
            }
          })
        }
        switch (data.prepareData) {
          case 'prepare':
            // pass through middle ware
            let options = {}
            if (router?.currentRoute?.value.meta?.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
              options.isInternalAssortment = true
              options.assortment = store.state.assortments.assortment
              options.properties = properties
            }
            item = pricesheet.prepAssortmentDetailDataFromBackend(item, -1, options)
            break
          default:
            break
        }

        return {
          ...item,
          selected: colors.some(c => c.active),
          colors: colors
        }
      })
    }
  },
  [VUEX_PRODUCTS_FETCH_FAILURE]: (state, data) => {
    d.log('VUEX_PRODUCTS_FETCH_FAILURE', data)
  },

  [VUEX_PRODUCT_STYLE_TEARSHEET_SUCCESS]: (state, data) => {
    state.styleTearsheet = data
  },

  [VUEX_PRODUCT_STYLE_TEARSHEET_FAILURE]: (_, data) => {
    d.log('VUEX_PRODUCT_STYLE_TEARSHEET_FAILURE', data)
  },

  [VUEX_PRODUCTS_CLEAR]: (state) => {
    state.products = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
