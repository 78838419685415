// a utility library to format queries for us
// needs matching parser on server side

export default function (payload) {
  if (!payload.query && !payload.select && !payload.cursor) return ''
  let qs = '?'
  if (payload.query) qs += `query=${JSON.stringify(payload.query)}`
  if (payload.select) qs += `${payload.query ? '&' : ''}select=${JSON.stringify(payload.select)}`
  if (payload.cursor) qs += `${payload.query || payload.cursor ? '&' : ''}cursor=${JSON.stringify(payload.cursor)}`
  return qs
}
