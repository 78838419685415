import axios from 'axios'
import api from '@/api'

import ApiValidator from '@/classes/ApiValidator'

// Get all collections      : GET  : /collections/ProductCollection
// Get a  collection by ID  : GET  : /collections/ProductCollection/:collectionId
// Create a user collection : POST : /collections/ProductCollection
// Lookup a user collection : POST : /collections/ProductCollection/query
// Update a user collection : POST : /collections/ProductCollection/batch/:collectionId

import {
  VUEX_API_COLLECTIONS_REQUEST,
  VUEX_API_COLLECTION_REQUEST,
  VUEX_API_COLLECTIONS_REQUEST_SUCCESS,
  VUEX_API_COLLECTIONS_REQUEST_FAILURE,

  VUEX_API_COLLECTION_LOOKUP,
  VUEX_API_COLLECTION_CREATE,
  VUEX_API_COLLECTION_UPDATE,

  VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  collectionsRequest: null,
  collectionsCancelRequest: null
}

const getters = {}

const actions = {
  // Fetch all Collections
  [VUEX_API_COLLECTIONS_REQUEST]: async ({ commit, dispatch }, payload) => {
    if (!payload || !payload.collectionType) { /* Throw error | return false */ }
    if (!payload.params) payload.params = {}

    commit(VUEX_API_COLLECTIONS_REQUEST)

    if (state.collectionsCancelRequest) state.collectionsCancelRequest.cancel()
    await commit(VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN, 'collectionsCancelRequest')

    // payload = collection ID
    return api.get(`/collections/${payload.collectionType}`, {
      params: payload.params,
      cancelToken: state.collectionsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/collections' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Fetch a user Collection by ID
  [VUEX_API_COLLECTION_REQUEST]: async ({ commit, dispatch }, payload) => {
    if (!payload || !payload.collectionType || !payload.id) { /* Throw error | return false */ }
    if (!payload.params) payload.params = {}

    commit(VUEX_API_COLLECTIONS_REQUEST)

    if (state.collectionsCancelRequest) state.collectionsCancelRequest.cancel()
    await commit(VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN, 'collectionsCancelRequest')

    // payload = collection ID
    return api.get(`/collections/${payload.collectionType}`, {
      params: payload.params,
      cancelToken: state.collectionsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/collections' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Look up a user Collection
  [VUEX_API_COLLECTION_LOOKUP]: async ({ commit, dispatch }, payload) => {
    if (!payload || !payload.collectionType || !payload.data) { /* Throw error | return false */ }
    if (!payload.params) payload.params = {}

    const apiValidator = new ApiValidator('collectionLookup')
    const validatedPayload = apiValidator.validate(payload.data)

    commit(VUEX_API_COLLECTIONS_REQUEST)

    if (state.collectionsCancelRequest) state.collectionsCancelRequest.cancel()
    await commit(VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN, 'collectionsCancelRequest')

    // payload = collection ID
    return api.post(`/collections/${payload.collectionType}/query`, validatedPayload, {
      params: payload.params,
      cancelToken: state.collectionsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/collections' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Create a user Collection
  [VUEX_API_COLLECTION_CREATE]: async ({ commit, dispatch }, payload) => {
    if (!payload || !payload.collectionType || !payload.data) { /* Throw error | return false */ }
    if (!payload.params) payload.params = {}

    commit(VUEX_API_COLLECTIONS_REQUEST)

    if (state.collectionsCancelRequest) state.collectionsCancelRequest.cancel()
    await commit(VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN, 'collectionsCancelRequest')

    // payload = collection ID
    return api.post(`/collections/${payload.collectionType}`, payload.data, {
      params: payload.params,
      cancelToken: state.collectionsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/collections' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Update a user Collection by ID
  [VUEX_API_COLLECTION_UPDATE]: async ({ commit, dispatch }, payload) => {
    if (!payload || !payload.collectionType || !payload.data) { /* Throw error | return false */ }
    if (!payload.params) payload.params = {}

    const apiValidator = new ApiValidator('collectionUpdate')
    const validatedPayload = apiValidator.validate(payload.data)

    commit(VUEX_API_COLLECTIONS_REQUEST)

    if (state.collectionsCancelRequest) state.collectionsCancelRequest.cancel()
    await commit(VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN, 'collectionsCancelRequest')

    // payload = collection ID
    return api.post(`/collections/${payload.collectionType}/batch/${payload.collectionId}`, validatedPayload, {
      params: payload.params,
      cancelToken: state.collectionsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_COLLECTIONS_REQUEST_SUCCESS)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/collections' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  }
}

const mutations = {
  // Fetch all Collections
  [VUEX_API_COLLECTIONS_REQUEST]: state => {
    state.collectionsRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_COLLECTIONS_REQUEST_SUCCESS]: state => {
    state.collectionsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_COLLECTIONS_REQUEST_FAILURE]: state => {
    state.collectionsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
