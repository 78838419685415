// import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/lib/styles/main.sass'
import colors from 'vuetify/lib/util/colors'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'
import { aliases, md } from 'vuetify/iconsets/md'

// import { VDataTable } from 'vuetify/labs/VDataTable'
// import { VDatePicker } from 'vuetify/labs/VDatePicker'

export default createVuetify({
  components: {
    ...components
    // VDatePicker
    // VDataTable // TODO: Pre-prod feature!!!
  },
  directives,

  icons: {
    defaultSet: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    aliases,
    sets: {
      md
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: colors.blue.darken3,
          secondary: '#1B283B',
          secondaryVariant: '#2A3951',
          secondaryVariantLight: '#445C7A',
          accent: '#0B141F',
          error: colors.red.darken4,
          red: colors.red.darken4,
          success: colors.green.darken4,
          green: colors.green.darken4,
          grey: colors.grey.base,
          greyDark2: colors.grey.darken2,
          greyDark3: colors.grey.darken3,
          greyDark4: colors.grey.darken4,
          slate: '#b7c7de'
        }
      },
      dark: {
        colors: {
          primary: colors.blue.darken3,
          secondary: '#1B283B',
          secondaryVariant: '#2A3951',
          secondaryVariantLight: '#445C7A',
          accent: '#0B141F',
          error: colors.red.darken4,
          red: colors.red.darken4,
          success: colors.green.darken4,
          green: colors.green.darken4,
          grey: colors.grey.base,
          greyDark2: colors.grey.darken2,
          greyDark3: colors.grey.darken3,
          greyDark4: colors.grey.darken4,
          slate: '#b7c7de'
        }
      }
    }
  }

  // theme: {
  //   defaultTheme: 'itsTheme_Light',
  //   themes: {
  //     itsTheme_Light: {
  //       colors: {
  //         // light: {
  //           primary: colors.blue.darken3,
  //           secondary: '#1B283B',
  //           secondaryVariant: '#2A3951',
  //           secondaryVariantLight: '#445C7A',
  //           accent: '#0B141F',
  //           error: colors.red.darken4,
  //           red: colors.red.darken4,
  //           success: colors.green.darken4,
  //           green: colors.green.darken4,
  //           grey: colors.grey.base,
  //           greyDark2: colors.grey.darken2,
  //           greyDark3: colors.grey.darken3,
  //           greyDark4: colors.grey.darken4,
  //           slate: '#b7c7de'
  //         // },
  //         /* dark: {
  //           primary: colors.blue.darken3,
  //           secondary: '#1B283B',
  //           secondaryVariant: '#2A3951',
  //           secondaryVariantLight: '#445C7A',
  //           accent: '#0B141F',
  //           error: colors.red.darken4,
  //           success: colors.green.darken4,
  //           grey: colors.grey,
  //           greyDark2: colors.grey.darken2,
  //           greyDark3: colors.grey.darken3,
  //           greyDark4: colors.grey.darken4,
  //           slate: '#b7c7de'
  //         } */
  //       }
  //     }
  //   }
  // }
})
