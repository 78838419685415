import {
  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS,
  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS_LANDING,
  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES,
  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES_LANDING,

  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_MANAGE,

  VUEX_ROUTING_ENTER_LIBRARIES_PENDING,
  VUEX_ROUTING_ENTER_LIBRARIES_PUBLISHED,
  VUEX_ROUTING_ENTER_LIBRARIES_UNPUBLISHED,
  VUEX_ROUTING_ENTER_LIBRARIES_DELETED,
  VUEX_ROUTING_ENTER_LIBRARIES_UPLOAD

} from '@/store/constants/routing/routingGlobalRetail'

import {
  VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET
} from '@/store/constants/ui/filterBar/filterBar_ItemsDisplay'

import {
  VUEX_ASSETS_CLEAR
} from '@/store/constants/models/assets'

import {
  VUEX_LIBRARIES_SET_QUERY_PARAMS
} from '@/store/constants/models/libraries'

import {
  VUEX_SUBHEADER_SHOW,
  VUEX_SUBHEADER_HIDE,
  VUEX_SUBHEADER_SET_SIZE
} from '@/store/constants/ui/subheader'

import assetsAssetsConfig from '@/components/_core/FilterBar/_config/dataSets/repositories/assets'
import repositoriesAssetsConfig from '@/components/_core/FilterBar/_config/dataSets/repositories/resources'

// ------------------ Dynamic Options Config ----------------
import dynamicOptions from '@/components/_core/FilterBar/_config/dynamicOptions'

const actions = {
  /***************************/
  // Global Retail Routes
  /***************************/
  [VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS]: async ({ dispatch }) => {
    await dispatch(VUEX_ASSETS_CLEAR)

    await dispatch(VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET, '-modifiedDate')

    dispatch(VUEX_SUBHEADER_SHOW, {
      content: '_core/FilterBar/FilterBar.vue',
      props: {
        dataSet: assetsAssetsConfig,
        constructFromQuery: true,
        dynamicOptionsData: dynamicOptions.globalRetail.assets,
        actions: [
          'share'
        ]
      }
    })
  },

  [VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS_LANDING]: async ({ dispatch }) => {
    dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    dispatch(VUEX_SUBHEADER_HIDE, { content: null })
  },

  [VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES]: async ({ dispatch }) => {
    await dispatch(VUEX_ASSETS_CLEAR)

    await dispatch(VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET, '-modifiedDate')

    dispatch(VUEX_SUBHEADER_SHOW, {
      content: '_core/FilterBar/FilterBar.vue',
      props: {
        dataSet: repositoriesAssetsConfig,
        constructFromQuery: true,
        dynamicOptionsData: dynamicOptions.globalRetail.resources,
        actions: [
          'share'
        ]
      }
    })
  },

  [VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES_LANDING]: async ({ dispatch }) => {
    dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    dispatch(VUEX_SUBHEADER_HIDE, { content: null })
  },

  [VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_MANAGE]: ({ dispatch, commit }) => {
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },
  /***************************/
  /***************************/

  /***************************/
  // Repository Routes
  /***************************/
  [VUEX_ROUTING_ENTER_LIBRARIES_PENDING]: async ({ dispatch, commit }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Pending'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
    return true
  },

  [VUEX_ROUTING_ENTER_LIBRARIES_PUBLISHED]: async ({ dispatch, commit }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Published'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
    return true
  },

  [VUEX_ROUTING_ENTER_LIBRARIES_UNPUBLISHED]: async ({ dispatch, commit }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Unpublished'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
    return true
  },

  [VUEX_ROUTING_ENTER_LIBRARIES_DELETED]: async ({ dispatch, commit }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Deleted'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
    return true
  },

  [VUEX_ROUTING_ENTER_LIBRARIES_UPLOAD]: async ({ dispatch, commit }) => {
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  }
}

const mutations = {}

export default {
  actions,
  mutations
}
