import {
  VUEX_ASSETS_FETCH,
  VUEX_ASSETS_FETCH_SUCCESS,
  VUEX_ASSETS_FETCH_FAILURE,

  VUEX_ASSETS_STATE_UPDATE,

  VUEX_ASSETS_CLEAR
} from '@/store/constants/models/assets'

import {
  VUEX_API_ASSETS_FETCH
  // VUEX_API_ASSETS_FETCH_STREAMING
} from '@/store/constants/api'

const state = {
  totalCount: 0,
  assets: []
}

const getters = {}

const actions = {
  [VUEX_ASSETS_FETCH]: async ({ commit, dispatch }, payload) => {
    commit(VUEX_ASSETS_FETCH)

    await dispatch(VUEX_API_ASSETS_FETCH, payload).then(response => {
      commit(VUEX_ASSETS_FETCH_SUCCESS, response.data)
    }).catch(() => {
      commit(VUEX_ASSETS_FETCH_FAILURE)
    })
  },

  [VUEX_ASSETS_CLEAR]: ({ commit }) => {
    commit(VUEX_ASSETS_CLEAR)
  }
}

const mutations = {
  [VUEX_ASSETS_FETCH]: state => {
    state.assets = []
  },

  [VUEX_ASSETS_FETCH_SUCCESS]: (state, data) => {
    state.assets = data
  },

  [VUEX_ASSETS_FETCH_FAILURE]: (state, data) => {
    //
  },

  [VUEX_ASSETS_STATE_UPDATE]: (state, data) => {
    Object.keys(data).forEach(key => {
      switch (key) {
        case 'totalCount' :
          state.totalCount = data[key]
          break

        case 'items' :
          state.assets.push(...data[key])
          break
      }
    })
  },

  [VUEX_ASSETS_CLEAR]: state => {
    state.assets = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
