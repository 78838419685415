export const VUEX_ASSORTMENT_DOWNLOADS_ADD_TO_QUEUE = 'VUEX_ASSORTMENT_DOWNLOADS_ADD_TO_QUEUE'

export const VUEX_ASSORTMENT_DOWNLOADS_HASH_PUSH = 'VUEX_ASSORTMENT_DOWNLOADS_HASH_PUSH'
export const VUEX_ASSORTMENT_DOWNLOADS_HASH_POP = 'VUEX_ASSORTMENT_DOWNLOADS_HASH_POP'

export const VUEX_ASSORTMENT_DOWNLOADS_ENQUEUE = 'VUEX_ASSORTMENT_DOWNLOADS_ENQUEUE'

export const VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER = 'VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER'
export const VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_SUCCESS = 'VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_SUCCESS'
export const VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_FAILED = 'VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_FAILED'

export const VUEX_ASSORTMENT_DOWNLOADS_MOVE_TO_BUCKET = 'VUEX_ASSORTMENT_DOWNLOADS_MOVE_TO_BUCKET'

export const VUEX_ASSORTMENT_DOWNLOADS_CHANGE_STATUS = 'VUEX_ASSORTMENT_DOWNLOADS_CHANGE_STATUS'
