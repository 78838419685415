import {
  VUEX_PRODUCT_DETAIL_CLEAR_CACHE,
  VUEX_PRODUCT_DETAIL_CLOSE_ANGLE_SELECTOR,
  VUEX_PRODUCT_DETAIL_FETCH_DATA,
  VUEX_PRODUCT_DETAIL_OPEN_ANGLE_SELECTOR,
  VUEX_PRODUCT_DETAIL_SET_ORGANIZATIONAL_TYPE,
  VUEX_PRODUCT_DETAIL_SET_IMAGES_AVAILABLE,
  VUEX_PRODUCT_DETAIL_SET_IS_SIDEBAR_OPEN,
  VUEX_PRODUCT_DETAIL_SET_ITEM,
  VUEX_PRODUCT_DETAIL_SET_LOADING,
  VUEX_PRODUCT_DETAIL_SET_PANEL,
  VUEX_PRODUCT_DETAIL_SET_PRODUCTS,
  VUEX_PRODUCT_DETAIL_SET_SELECTED_ANGLE,
  VUEX_PRODUCT_DETAIL_SET_SELECTED_COLOR,
  VUEX_PRODUCT_DETAIL_SET_SHOWROOM_SETTINGS,
  VUEX_PRODUCT_DETAIL_SET_VIEW
} from '@/store/constants/ui/productDetail'

import {
  VUEX_API_PRODUCTS_REQUEST_FETCH,
  VUEX_API_ASSORTMENT_PRODUCTS_FETCH,
  VUEX_API_ASSORTMENT_INTERNAL_PRODUCTS_REQUEST_FETCH
  // VUEX_API_ASSORTMENT_INTERNAL_REQUEST_FETCH
} from '@/store/constants/api'

import {
  getDefaultDigitalShowroomSettings
} from '@/helpers/showroomSettings'

import shared from 'skch_its_be_fe_shared'

const imgSrc = shared.productImage.imgSrc

let CACHED_REQUESTS = {}

const ANGLE_SELECTOR_STYLE_CLOSED = {
  display: 'none'
}

const ANGLE_SELECTOR_STYLE_OPEN = {
  display: 'flex'
}

const DEFAULT_KI_SETTINGS = {
  originalCost: true,
  nrf: true,

  // used in ItemPreview_AssortmentProduct_ProductInfo
  division: true,
  features: true,
  sizeScale: true,
  boxCategory: true,
  dutyCode: true,
  sizeRange: true,

  // used in ItemPreview_AssortmentProduct_Availability
  availability: true,

  // used in ItemPreview_AssortmentProduct_Quantities
  colorSellThru: true,
  styleSellThru: true,
  percentage: true,
  quantity: true,

  // used in ItemPreview_AssortmentProduct_Marketing
  marketingDescription: true,

  // used in ItemPreview_AssortmentProduct_Prices
  cost: true,
  netCost: true,
  initialMarkup: true,
  suggestedRetail: true
}

const state = {
  angleSelectorStyle: ANGLE_SELECTOR_STYLE_CLOSED,
  orgType: null,
  images: [],
  isSidebarOpen: true,
  isShowingAngles: false,
  item: null,
  loading: false,
  panels: {
    // controls expanders to be open/closed
    availability: false,
    info: true,
    marketing: false,
    notes: false,
    quantities: false,
    prices: false
  },
  products: [],
  selectedAngleIndex: 0,
  selectedColor: null,
  selectedColorIndex: 0,
  showroomSettings: {},
  variant: null,
  view: 'single'
}

const getCurrentIndex = (currentState) => {
  return (item) => {
    if (item.style) {
      return item.style === currentState.item.style
    }
    return item.groupSlug === currentState.item.groupSlug
  }
}

const getters = {
  productDetail_hasPreviousAngle: state => {
    return state.selectedAngleIndex > 0
  },
  productDetail_hasNextAngle: state => {
    return state.selectedAngleIndex + 1 < state.images.length
  },

  productDetail_nextColor: ({ selectedColorIndex, item }) => {
    if (selectedColorIndex < item.colors.length - 1) {
      return item.colors[selectedColorIndex + 1]
    }

    return null
  },

  productDetail_nextStyle: state => {
    const currentIndex = state.products.findIndex(getCurrentIndex(state))
    const remaining = state.products.slice(currentIndex + 1).filter(p => !!p.style)

    if (remaining.length > 0) {
      return remaining[0]
    }

    return null
  },

  productDetail_hasNextStyle: state => {
    const currentIndex = state.products.findIndex(getCurrentIndex(state))
    return state.products.length > currentIndex + 1 && state.products[currentIndex + 1]
  },
  productDetail_hasPreviousStyle: state => {
    const currentIndex = state.products.findIndex(getCurrentIndex(state))
    return currentIndex > 0 && state.products[currentIndex - 1]
  }
}

const actions = {
  [VUEX_PRODUCT_DETAIL_FETCH_DATA]: async ({ rootGetters, rootState, dispatch, commit, state }, payload) => {
    let reqResponse
    let query
    let location

    commit(VUEX_PRODUCT_DETAIL_SET_LOADING, true)
    commit(VUEX_PRODUCT_DETAIL_SET_PRODUCTS,
      rootState.controlBar.currentTab === 'search'
        ? rootState.assortmentProducts.products
        : rootState.assortmentProducts.productsWithGroups)
    commit(VUEX_PRODUCT_DETAIL_SET_ORGANIZATIONAL_TYPE, payload.type)

    switch (payload.type) {
      case 'staticShowroomSearch':
        location = rootState.assortments.assortment.locationId
        query = rootGetters['getAssortmentShowroomProductDetailQuery'](payload.style)

        if (payload.style in CACHED_REQUESTS) {
          reqResponse = CACHED_REQUESTS[payload.style]
        } else {
          await dispatch(VUEX_API_PRODUCTS_REQUEST_FETCH, query).then(res => {
            reqResponse = res.data[0].data
            CACHED_REQUESTS[payload.style] = reqResponse
          })
        }
        break
      case 'staticShowroomBrowse':
        location = rootState.assortments.assortment.locationId
        query = rootGetters['getAssortmentShowroomProductDetailQuery'](payload.style)

        if (payload.style in CACHED_REQUESTS) {
          reqResponse = CACHED_REQUESTS[payload.style]
        } else {
          await dispatch(VUEX_API_ASSORTMENT_PRODUCTS_FETCH, query).then(res => {
            reqResponse = res.data[0].data
            CACHED_REQUESTS[payload.style] = reqResponse
          })
        }
        break
      case ITS__ASSORTMENTS__ORG_TYPE__INTERNAL:
        const colors = state.products.find(p => p.style === payload.style)?.colors
        location = rootState.assortmentsInternal.query['locations.code']
        query = rootGetters['getInternalAssortmentProductDetailQuery'](payload.style, colors)

        if (payload.style in CACHED_REQUESTS) {
          reqResponse = CACHED_REQUESTS[payload.style]
        } else {
          await dispatch(VUEX_API_ASSORTMENT_INTERNAL_PRODUCTS_REQUEST_FETCH, query).then(res => {
            reqResponse = res.data[0].data
            CACHED_REQUESTS[payload.style] = reqResponse
          })
        }
        break
      default :
        console.error('VUEX_PRODUCT_DETAIL_FETCH_DATA | payload.type not defined')
        return
    }

    const formatedProducts = formatData(reqResponse, payload.type, query)
    const product = formatedProducts.find(p => p.style === payload.style && p.colors?.find(c => c.color === payload.color))

    if (payload.type === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
      commit(VUEX_PRODUCT_DETAIL_SET_SHOWROOM_SETTINGS, DEFAULT_KI_SETTINGS)
    } else {
      const settings = rootState.assortments.assortment.uiSettings?.digitalShowroomSettings || {}
      const defaultSettings = getDefaultDigitalShowroomSettings(rootState.assortments.assortment.productType, rootState.assortments.assortment.status)
      commit(VUEX_PRODUCT_DETAIL_SET_SHOWROOM_SETTINGS, { ...defaultSettings, ...settings })
    }

    commit(VUEX_PRODUCT_DETAIL_SET_LOADING, false)
    commit(VUEX_PRODUCT_DETAIL_SET_ITEM, { ...product, location })
    commit(VUEX_PRODUCT_DETAIL_SET_SELECTED_COLOR, payload.color)
    commit(VUEX_PRODUCT_DETAIL_SET_SELECTED_ANGLE, 0)

    let queryNext
    const nextStyle = rootGetters.productDetail_nextStyle
    if (!nextStyle) {
      return
    }

    switch (payload.type) {
      case 'staticShowroomSearch':
        queryNext = rootGetters['getAssortmentShowroomProductDetailQuery'](nextStyle.style)

        if (!(nextStyle.style in CACHED_REQUESTS)) {
          dispatch(VUEX_API_PRODUCTS_REQUEST_FETCH, queryNext).then(res => {
            CACHED_REQUESTS[nextStyle.style] = res.data[0].data
          })
        }
        break
      case 'staticShowroomBrowse':
        queryNext = rootGetters['getAssortmentShowroomProductDetailQuery'](nextStyle.style)

        if (!(nextStyle.style in CACHED_REQUESTS)) {
          dispatch(VUEX_API_PRODUCTS_REQUEST_FETCH, queryNext).then(res => {
            CACHED_REQUESTS[nextStyle.style] = res.data[0].data
          })
        }
        break
      case ITS__ASSORTMENTS__ORG_TYPE__INTERNAL:
        const nextColors = state.products.find(p => p.style === nextStyle.style)?.colors
        queryNext = rootGetters['getInternalAssortmentProductDetailQuery'](nextStyle.style, nextColors)

        if (!(nextStyle.style in CACHED_REQUESTS)) {
          dispatch(VUEX_API_ASSORTMENT_INTERNAL_PRODUCTS_REQUEST_FETCH, queryNext).then(res => {
            CACHED_REQUESTS[nextStyle.style] = res.data[0].data
          })
        }
        break
      default :
        console.error('VUEX_PRODUCT_DETAIL_FETCH_DATA | payload.type not defined')
    }
  },
  [VUEX_PRODUCT_DETAIL_OPEN_ANGLE_SELECTOR]: ({ commit }, payload) => {
    setTimeout(() => {
      commit(VUEX_PRODUCT_DETAIL_OPEN_ANGLE_SELECTOR, payload)
    }, 0)
  },
  [VUEX_PRODUCT_DETAIL_CLOSE_ANGLE_SELECTOR]: ({ commit }) => {
    commit(VUEX_PRODUCT_DETAIL_CLOSE_ANGLE_SELECTOR)
  },
  [VUEX_PRODUCT_DETAIL_CLEAR_CACHE]: () => {
    CACHED_REQUESTS = {}
  }
}

const mutations = {
  [VUEX_PRODUCT_DETAIL_SET_ORGANIZATIONAL_TYPE]: (state, type) => {
    state.orgType = type
  },
  [VUEX_PRODUCT_DETAIL_SET_ITEM]: (state, item) => {
    state.item = item
    state.images = []
  },
  [VUEX_PRODUCT_DETAIL_SET_PANEL]: (state, data) => {
    state.panels[data.key] = data.value
  },
  [VUEX_PRODUCT_DETAIL_SET_LOADING]: (state, loading) => {
    state.loading = loading
  },
  [VUEX_PRODUCT_DETAIL_SET_SELECTED_COLOR]: (state, color) => {
    let colorIndex = state.item.colors.findIndex(c => c.color === color)
    state.selectedColorIndex = colorIndex < 0 ? 0 : colorIndex
    state.selectedColor = state.item.colors[state.selectedColorIndex]

    const images = getColorImages(state.item.style, state.selectedColor, state.item.productType, state.variant)

    if (state.selectedAngleIndex >= images.length) {
      state.selectedAngleIndex = images.length - 1
    }

    state.images = images
  },
  [VUEX_PRODUCT_DETAIL_SET_SELECTED_ANGLE]: (state, index) => {
    state.selectedAngleIndex = index
    state.angleSelectorStyle = ANGLE_SELECTOR_STYLE_CLOSED
  },
  [VUEX_PRODUCT_DETAIL_SET_IMAGES_AVAILABLE]: (state, images) => {
    state.images = images
  },
  [VUEX_PRODUCT_DETAIL_SET_VIEW]: (state, view) => {
    state.view = view
  },
  [VUEX_PRODUCT_DETAIL_SET_IS_SIDEBAR_OPEN]: (state, isOpen) => {
    state.isSidebarOpen = isOpen
  },
  [VUEX_PRODUCT_DETAIL_OPEN_ANGLE_SELECTOR]: (state, properties) => {
    state.angleSelectorStyle = {
      ...properties,
      ...ANGLE_SELECTOR_STYLE_OPEN
    }
  },
  [VUEX_PRODUCT_DETAIL_CLOSE_ANGLE_SELECTOR]: (state) => {
    state.angleSelectorStyle = ANGLE_SELECTOR_STYLE_CLOSED
  },
  [VUEX_PRODUCT_DETAIL_SET_SHOWROOM_SETTINGS]: (state, settings) => {
    if (settings) {
      state.showroomSettings = settings
      state.variant = state.showroomSettings.cadOnly ? 'cadOnly' : null
    }
  },
  [VUEX_PRODUCT_DETAIL_SET_PRODUCTS]: (state, products) => {
    if (products && products.length > 0) {
      state.products = products
    }
  }
}

const formatData = (data, type, query) => {
  if (type !== ITS__ASSORTMENTS__ORG_TYPE__INTERNAL || !data.length) {
    return data
  }

  data.forEach(product => {
    product.colors.forEach(color => {
      delete color.initialMarkup

      if (query['locations.code'] === 'US') {
        delete color.suggestedRetail
        delete color.locations.suggestedRetail
      }
    })
  })

  return data
}

const getColorImages = (style, colorObj, productType, variant) => {
  let images = [
    {
      gallery: imgSrc('xlarge', style, colorObj.color, 'A', productType, variant),
      thumbnail: imgSrc('small', style, colorObj.color, 'A', productType, variant)
    }
  ]

  if (colorObj.printImage) {
    images = [
      {
        gallery: colorObj.printImage,
        thumbnail: colorObj.thumbnail
      }
    ]
  }

  if (!images.length) {
    images = [
      {
        gallery: '/images/coming_soon.jpg',
        thumbnail: '/images/coming_soon.jpg'
      }
    ]
  }

  return images
}

export default {
  state,
  getters,
  actions,
  mutations
}
