
/*
Please keep this up to date when you add
any code that sets data to local storage

localStorage: {
  assortments: {
    documents: {
      documentOptions: { document settings go here },
      documentExportSettings: { document export settings go here }
    }
  },
  libraries: {
    librariesCollection: {
      internationalRetailCollection: [{ asset }],
      includeWorkingFiles: boolean
    }
  }
}
*/

import _get from 'lodash.get'
import _set from 'lodash.set'
import _unset from 'lodash.unset'
import _isEqual from 'lodash.isequal'

import storage from '@/localStorage'

import {
  VUEX_LOCALSTORAGE_INIT,

  VUEX_LOCALSTORAGE_SET_STATE,
  VUEX_LOCALSTORAGE_DATA_SET,
  VUEX_LOCALSTORAGE_DATA_REMOVE,
  VUEX_LOCALSTORAGE_REFRESH_STATE,
  VUEX_LOCALSTORAGE_CLEAR
} from '@/store/constants/localStorage'

const state = {
  storageName: 'skch_its',
  intialized: false,
  localStorage: {}
}

const getters = {
  localStorage_Get: state => obj => _get(state.localStorage, obj),

  localStorage_Compare: state => (path, val) => {
    // currently only compares object keys, but can be extended
    let obj = _get(state.localStorage, path)
    if (!obj) return

    let storageKeys = Object.keys(obj)
    let valKeys = Object.keys(val)

    return _isEqual(storageKeys, valKeys)
  }
}

const actions = {
  // Initialize localStorage
  [VUEX_LOCALSTORAGE_INIT]: ({ state, commit, dispatch }) => {
    if (!window.localStorage.hasOwnProperty(state.storageName)) storage.set(state.storageName, {})
    dispatch(VUEX_LOCALSTORAGE_SET_STATE, storage.get(state.storageName))
  },

  [VUEX_LOCALSTORAGE_SET_STATE]: ({ state, commit }, payload) => {
    commit(VUEX_LOCALSTORAGE_INIT, payload)
  },

  // Set data in localStorage -> update state
  [VUEX_LOCALSTORAGE_DATA_SET]: async ({ state, dispatch, commit }, payload) => {
    if (!payload.hasOwnProperty('path') || !payload.hasOwnProperty('value')) {
      console.error('LocalStorage -> Data Set | Missing path or value')
      return
    }
    // if (_isEmpty(window.localStorage.hasOwnProperty(state.storageName))) storage.set(state.storageName, state.localStorage)

    let data = _set(storage.get(state.storageName), payload.path, payload.value)
    await storage.set(state.storageName, data)

    dispatch(VUEX_LOCALSTORAGE_REFRESH_STATE)
  },

  // Remove properties from storage
  [VUEX_LOCALSTORAGE_DATA_REMOVE]: ({ dispatch, commit }, payload) => {
    let obj = Object.assign(state.localStorage, {})
    _unset(obj, payload)

    storage.set(state.storageName, obj)

    dispatch(VUEX_LOCALSTORAGE_REFRESH_STATE)
  },

  // Update state with localStorage
  [VUEX_LOCALSTORAGE_REFRESH_STATE]: ({ commit }) => {
    commit(VUEX_LOCALSTORAGE_REFRESH_STATE, storage.get(state.storageName))
  },

  // Clear entire store|localStorage:state.storageName
  [VUEX_LOCALSTORAGE_CLEAR]: ({ commit }) => {
    storage.set(state.storageName, {})
    commit(VUEX_LOCALSTORAGE_CLEAR)
  }
}

const mutations = {
  [VUEX_LOCALSTORAGE_INIT]: (state, data) => {
    state.localStorage = data
    state.intialized = true
  },

  [VUEX_LOCALSTORAGE_REFRESH_STATE]: (state, data) => {
    state.localStorage = data
  },

  [VUEX_LOCALSTORAGE_CLEAR]: state => {
    state.localStorage = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
