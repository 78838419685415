import {
  VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP,
  VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP_LANDING,

  VUEX_ROUTING_ENTER_SALES_MANAGE_PENDING,
  VUEX_ROUTING_ENTER_SALES_MANAGE_PUBLISHED,
  VUEX_ROUTING_ENTER_SALES_MANAGE_UNPUBLISHED,
  VUEX_ROUTING_ENTER_SALES_MANAGE_UPLOAD,
  VUEX_ROUTING_ENTER_SALES_MANAGE_DELETED
} from '@/store/constants/routing/routingSales'

import {
  VUEX_SUBHEADER_SHOW,
  VUEX_SUBHEADER_HIDE,
  VUEX_SUBHEADER_SET_SIZE
} from '@/store/constants/ui/subheader'

import {
  VUEX_CONTENT_CONTAINER_SET_STYLES
} from '@/store/constants/ui/contentContainer'

import {
  VUEX_LIBRARIES_SET_QUERY_PARAMS
} from '@/store/constants/models/libraries'

const actions = {
  /***************************/
  // Sales Routes
  /***************************/
  [VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP]: async ({ dispatch }) => {
    /* await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__SMALL
    }) */

    await dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    await dispatch(VUEX_SUBHEADER_HIDE, { content: null })
  },
  [VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP_LANDING]: async ({ dispatch }) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__NONE
    })

    await dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    await dispatch(VUEX_SUBHEADER_HIDE, { content: null })
  },

  /***************************/
  /***************************/

  /***************************/
  // Sales Manage Routes
  /***************************/
  [VUEX_ROUTING_ENTER_SALES_MANAGE_PENDING]: async ({ dispatch, commit }) => {
    /* await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__SMALL
    }) */

    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Pending'
      }
    })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_SALES_MANAGE_PUBLISHED]: async ({ dispatch, commit }) => {
    /* await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__SMALL
    }) */

    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Published'
      }
    })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_SALES_MANAGE_UNPUBLISHED]: async ({ dispatch, commit }) => {
    /* await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__SMALL
    }) */

    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Unpublished'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_SALES_MANAGE_DELETED]: async ({ dispatch, commit }) => {
    /* await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__SMALL
    }) */

    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Deleted'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_SALES_MANAGE_UPLOAD]: async ({ dispatch, commit }) => {
    /* await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__SMALL
    }) */

    /* await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Unpublished'
      }
    }) */
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  }
}

const mutations = {}

export default {
  actions,
  mutations
}
