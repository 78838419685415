import api from '@/api'

export const fetchSignedURLs = config => {
  return api.post(`/upload/aws/s3/create`, config)
    .then(response => response.data)
    .catch(error => error)
}

export const completeUploadRequest = data => {
  return api.post(`/upload/aws/s3/complete`, data)
    .then(response => response)
    .catch(error => error)
}
