import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP
} from '@/store/constants/routing/routingSales'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { salesMeta } from '@/router/routeMeta'

export default {
  path: '/sales/libraries',
  // name: 'sales--libraries',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      callback: () => { store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()) }
    }),
  children: [
    {
      path: '',
      name: 'sales--libraries--default',
      redirect: { name: 'sales--libraries--step' }
    },
    {
      path: 'STEP',
      name: 'sales--libraries--step',
      component: loadComponent('Sales/STEP/Sales_STEP.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET
      }
    }
  ],
  meta: salesMeta
}
