export const VUEX_REQUEST_OPTIONS_INIT = 'VUEX_REQUEST_OPTIONS_INIT'

export const requestOptionsData = {
  // ****************************************
  // Models: Key Initiatives
  // ****************************************
  'assortmentsInternal.query': {
    _options: {
      project: {
        eCommerce: 0,
        categories: 0,
        components: 0,
        nameTokens: 0,
        descriptionTokens: 0,
        boxCategory: 0,
        division: 0,
        gender: 0,
        nrf: 0,
        dutyCode: 0,
        updated: 0,
        description: 0,
        features: 0,
        createdDate: 0,
        updatedDate: 0,
        sizeScale: 0,
        colorDescription: 0,
        seasonCodes: 0,
        rand: 0,
        bought: 0,
        boughtDom: 0,
        boughtIntl: 0,
        outsoleTokens: 0,
        upperTokens: 0,
        channelData: 0,
        'locations.availability': 0,
        'locations.lastProdDate': 0,
        'availabilityDate': 0,
        'locations.lineStatus': 0
      },
      finalProject: {
        'sortOrder': 0,
        'assortment': 0,
        'colors.locations': 0,
        'colors.createdDate': 0,
        'colors.modifiedDate': 0,
        'colors._id': 0,
        'colors.carryoverDate': 0,
        'colors.assortmentIndex': 0
      },
      styleLevel: true,
      totalCount: true,
      assortmentsQueryPreserveNull: true,
      productDataFormat: {
        withPricing: true
      }
    }
  },
  'assortmentsInternal.internalAssortmentProductDetailsQuery': {
    _options: {
      project: {
        categories: 0,
        descriptionTokens: 0,
        components: 0,
        bought: 0,
        boughtDom: 0,
        boughtIntl: 0,
        outsoleTokens: 0,
        upperTokens: 0,
        channelData: 0,
        dutyCode: 0,
        'locations.lastProdDate': 0,
        'locations.lineStatus': 0,
        /* ITS2-690 Add availability to Intl/Domestic KI DS PDF and Excel
         * remove locations.availability and availabilityDate (locations.firstAvailDate) from project in
         * request for product data for KI detail state -
         * confirm availability displays by size in the sidebar (confirmed)
         */
        // 'locations.availability': 0,
        // 'availabilityDatee': 0,
        nrf: 0, // ITSFD-1470 Remove items from KI detail
        netCost: 0,
        initialMarkup: 0
      },
      styleLevel: true,
      includeEcomData: true,
      assortmentsQueryPreserveNull: true,
      productDataFormat: {
        withPricing: true
      }
    }
  },
  // ****************************************
  // Models: Assortments
  // ****************************************
  'assortments.internalAssortmentsLookupParams': {
    _options: {
      sort: {
        season: 1,
        year: 1,
        region: 1,
        sortOrder: 1,
        title: 1
      },
      project: {
        alerts: 1,
        uiSettings: 1,
        _id: 1,
        published: 1,
        title: 1,
        gender: 1,
        channel: 1,
        state: 1,
        region: 1,
        season: 1,
        year: 1,
        modifiedDate: 1,
        createdDate: 1,
        products: 1,
        lastEditedByUser: 1,
        productType: 1,
        carryoverDate: 1,
        sortOrder: 1,
        logo: 1
      }
    }
  },
  'assortmentShowroom.options.search': {
    _options: {
      styleLevel: { colorSort: { rand: 1 } },
      totalCount: true,
      productDataFormat: {
        withPricing: true
      },
      project: {
        outsole: 0,
        upper: 0,
        createDate: 0,
        displayFlags: 0,
        eCommerce: 0,
        categories: 0,
        components: 0,
        nameTokens: 0,
        descriptionTokens: 0,
        boxCategory: 0,
        division: 0,
        gender: 0,
        nrf: 0,
        dutyCode: 0,
        updated: 0,
        description: 0,
        features: 0,
        createdDate: 0,
        updatedDate: 0,
        sizeScale: 0,
        colorDescription: 0,
        seasonCodes: 0,
        rand: 0,
        bought: 0,
        boughtDom: 0,
        boughtIntl: 0,
        outsoleTokens: 0,
        upperTokens: 0,
        channelData: 0,
        locations: 0
      },
      finalProject: {
        'colors.regionBoughtStatus': 0,
        'colors.locations.availability': 0
      }
    }
  },
  'assortmentShowroom.options.browse': {
    _options: {
      assortmentsQueryPreserveNull: true,
      includeCustomContent: true,
      productDataFormat: {
        withPricing: true
      },
      // styleLevel: { colorSort: { rand: 1 } },
      styleLevel: true,
      totalCount: true,
      project: {
        createDate: 0,
        displayFlags: 0,
        eCommerce: 0,
        categories: 0,
        components: 0,
        nameTokens: 0,
        descriptionTokens: 0,
        boxCategory: 0,
        division: 0,
        gender: 0,
        nrf: 0,
        dutyCode: 0,
        updated: 0,
        description: 0,
        features: 0,
        createdDate: 0,
        updatedDate: 0,
        sizeScale: 0,
        colorDescription: 0,
        seasonCodes: 0,
        rand: 0,
        bought: 0,
        boughtDom: 0,
        boughtIntl: 0,
        outsoleTokens: 0,
        upperTokens: 0,
        channelData: 0,
        locations: 0
      },
      finalProject: {
        'colors.createdDate': 0,
        'colors.modifiedDate': 0,
        'colors.assortment': 0,
        'colors._id': 0,
        'colors.assortmentIndex': 0
      }
    }
  },
  'assortmentShowroom.options.details': {
    project: {
      categories: 0,
      descriptionTokens: 0,
      components: 0,
      bought: 0,
      boughtDom: 0,
      boughtIntl: 0,
      outsoleTokens: 0,
      upperTokens: 0,
      channelData: 0,
      'locations.lastProdDate': 0,
      'locations.lineStatus': 0
    },
    styleLevel: true,
    includeEcomData: true,
    assortmentsQueryPreserveNull: true,
    productDataFormat: {
      withPricing: true
    }
  },
  // ****************************************
  // Models: Orders
  // ****************************************
  'orders.query.data': {
    _options: {
      includeComments: true
    }
  },
  // ****************************************
  // UI: ControlBar
  // ****************************************
  'controlBar.productsQuery': {
    _options: {
      preserveNullAndEmptyArrays: true,
      sort: { style: 1, color: 1 },
      project: {
        eCommerce: 0,
        outsoleTokens: 0,
        upperTokens: 0,
        descriptionTokens: 0,
        categories: 0,
        components: 0,
        bought: 0,
        boughtDom: 0,
        boughtIntl: 0
      }
    }
  },
  'filterBarProductSearch.searchQueryBase': {
    _options: {
      'limit': 1000,
      'styleLevel': true,
      'totalCount': true,
      'preserveNullAndEmptyArrays': false,
      'sort': { 'style': 1 },
      project: {
        eCommerce: 0,
        categories: 0,
        components: 0,
        nameTokens: 0,
        descriptionTokens: 0,
        boxCategory: 0,
        division: 0,
        gender: 0,
        nrf: 0,
        dutyCode: 0,
        updated: 0,
        description: 0,
        features: 0,
        createdDate: 0,
        updatedDate: 0,
        sizeScale: 0,
        colorDescription: 0,
        seasonCodes: 0,
        productType: 0,
        // locations: 0,
        rand: 0,
        boughtDom: 0,
        boughtIntl: 0,
        outsoleTokens: 0,
        upperTokens: 0,
        outsole: 0,
        upper: 0,
        channelData: 0
      },
      finalProject: {
        // 'colors.locations': 0,
        'colors.bought': 0,
        'colors.regionBoughtStatus': 0,
        'colors.usLocation': 0
      }
    }
  }
}
