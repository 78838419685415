export const VUEX_S3FUM_ADD_FILES = 'VUEX_S3FUM_ADD_FILES'
export const VUEX_S3FUM_HASH_FILE_PUSH = 'VUEX_S3FUM_HASH_FILE_PUSH'
export const VUEX_S3FUM_HASH_FILE_POP = 'VUEX_S3FUM_HASH_FILE_POP'
export const VUEX_3SFUM_HASH_FILE_POP_COMPLETED_AND_FAILED = 'VUEX_3SFUM_HASH_FILE_POP_COMPLETED_AND_FAILED'

export const VUEX_S3FUM_FILE_MOVE_TO_BUCKET = 'VUEX_S3FUM_FILE_MOVE_TO_BUCKET'

export const VUEX_S3FUM_ENQUEUE_UPLOAD = 'VUEX_S3FUM_ENQUEUE_UPLOAD'
export const VUEX_S3FUM_ASSIGN_S3_KEY = 'VUEX_S3FUM_ASSIGN_S3_KEY'
export const VUEX_S3FUM_UPLOAD_PROGRESS = 'VUEX_S3FUM_UPLOAD_PROGRESS'
export const VUEX_S3FUM_CHANGE_STATUS = 'VUEX_S3FUM_CHANGE_STATUS'

export const VUEX_S3FUM_UPLOAD_RESULT_HANDLER = 'VUEX_S3FUM_UPLOAD_RESULT_HANDLER'
export const VUEX_S3FUM_UPLOAD_RESULT_HANDLER_SUCCESS = 'VUEX_S3FUM_UPLOAD_RESULT_HANDLER_SUCCESS'
export const VUEX_S3FUM_UPLOAD_RESULT_HANDLER_FAILED = 'VUEX_S3FUM_UPLOAD_RESULT_HANDLER_FAILED'
