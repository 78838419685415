export const VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD_INIT = 'VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD_INIT'
export const VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD = 'VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD'
export const VUEX_GROUPSORT_ASSORTMENT_BATCH_UPDATE = 'VUEX_GROUPSORT_ASSORTMENT_BATCH_UPDATE'
export const VUEX_GROUPSORT_ASSORTMENT_UPDATE = 'VUEX_GROUPSORT_ASSORTMENT_UPDATE'
export const VUEX_GROUPSORT_ASSORTMENT_CLEAR_BATCHED_REQUESTS = 'VUEX_GROUPSORT_ASSORTMENT_CLEAR_BATCHED_REQUESTS'

export const VUEX_GROUPSORT_ASSORTMENT_SAVE_LINKED_ASSORTMENT = 'VUEX_GROUPSORT_ASSORTMENT_SAVE_LINKED_ASSORTMENT'

export const VUEX_GROUPSORT_ASSORTMENT_UPDATE_PROPS = 'VUEX_GROUPSORT_ASSORTMENT_UPDATE_PROPS'
export const VUEX_GROUPSORT_ASSORTMENT_UPDATE_CUSTOM_CONTENT = 'VUEX_GROUPSORT_ASSORTMENT_UPDATE_CUSTOM_CONTENT'
export const VUEX_GROUPSORT_ASSORTMENTS_PRODUCTS_UPDATE_PROPS = 'VUEX_GROUPSORT_ASSORTMENTS_PRODUCTS_UPDATE_PROPS'

export const VUEX_GROUPSORT_SORTORDER_UPDATE = 'VUEX_GROUPSORT_SORTORDER_UPDATE'

export const VUEX_GROUPSORT_SET_GROUPBY = 'VUEX_GROUPSORT_SET_GROUPBY'
export const VUEX_GROUPSORT_SET_AUTOSORT = 'VUEX_GROUPSORT_SET_AUTOSORT'

export const VUEX_GROUPSORT_ITEMS_AUTOSORT = 'VUEX_GROUPSORT_ITEMS_AUTOSORT'
