import api from '@/api'
import axios from 'axios'

import {
  VUEX_API_CUSTOMERS_SEARCH_REQUEST,
  VUEX_API_CUSTOMERS_SEARCH_REQUEST_SUCCESS,
  VUEX_API_CUSTOMERS_SEARCH_REQUEST_FAILURE,
  VUEX_API_CUSTOMERS_SEARCH_REQUEST_CLEAR_LIST,
  VUEX_API_CUSTOMERS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

// import Debug from 'logdown'
// const d = new Debug('its:store:modules:api:aws')

const state = {
  customersRequest: null,
  customersList: [],
  customersCancelRequest: null
}

const getters = {}

const actions = {
  // company search
  [VUEX_API_CUSTOMERS_SEARCH_REQUEST]: async ({ commit, dispatch }, payload) => {
    if (!payload) return
    //
    commit(VUEX_API_CUSTOMERS_SEARCH_REQUEST)

    if (state.customersCancelRequest) state.customersCancelRequest.cancel()
    await commit(VUEX_API_CUSTOMERS_SET_CANCEL_TOKEN, 'customersCancelRequest')

    const val = encodeURIComponent(payload.val)
    const territory = encodeURIComponent(payload.territory)

    return api.get(`/customers/official/text?corporateOnly=true&labelValue=true&text=${val}&territory=${territory}`, {
      cancelToken: state.customersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_CUSTOMERS_SEARCH_REQUEST_SUCCESS, response.data)
      return response
    }).catch(err => {
      commit(VUEX_API_CUSTOMERS_SEARCH_REQUEST_FAILURE)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/collections' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_CUSTOMERS_SEARCH_REQUEST_CLEAR_LIST]: async ({ commit }) => {
    commit(VUEX_API_CUSTOMERS_SEARCH_REQUEST_CLEAR_LIST)
  }
}
const mutations = {
// Fetch all Collections
  [VUEX_API_CUSTOMERS_SEARCH_REQUEST]: state => {
    state.customersRequest = ITS__API__REQUEST_TYPE__PENDING
    state.customersList = []
  },

  [VUEX_API_CUSTOMERS_SEARCH_REQUEST_SUCCESS]: (state, data) => {
    state.customersRequest = ITS__API__REQUEST_TYPE__SUCCESS
    state.customersList = data
  },

  [VUEX_API_CUSTOMERS_SEARCH_REQUEST_FAILURE]: state => {
    state.customersRequest = ITS__API__REQUEST_TYPE__FAILED
    state.customersList = []
  },

  [VUEX_API_CUSTOMERS_SEARCH_REQUEST_CLEAR_LIST]: state => {
    state.customersList = []
  },

  // Set Cancelation token
  [VUEX_API_CUSTOMERS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
