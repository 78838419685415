import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_PRODUCT_DETAIL_CLEAR_CACHE
} from '@/store/constants/ui/productDetail'

import {
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_DOCUMENTS,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_PRODUCTS,

  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_SEASONS,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_LANDING,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_WHOLESALE,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_RETAIL,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DIVIDER,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_WHOLESALE,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_RETAIL
} from '@/store/constants/routing/routingProduct'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { productMeta } from '@/router/routeMeta'

export default {
  path: '/product/libraries',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true
    }),
  children: [
    {
      path: '',
      name: 'product--libraries--default',
      redirect: { name: 'product--libraries--documents' }
    },
    {
      path: 'documents',
      name: 'product--libraries--documents',
      component: loadComponent('Product/Documents/Product_Documents.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assets'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_DOCUMENTS, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT,
        queryRequest: true
      }
    },
    {
      path: 'products',
      name: 'product--libraries--products',
      component: loadComponent('Product/Products/Product_Products.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_PRODUCTS, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT,
        queryRequest: true
      }
    },
    // INTERNAL ASSORTMENTS
    // Seasons Page -----------------------------
    {
      path: 'internal/seasons-wholesale',
      name: 'product--libraries--assortments-internal--seasons--wholesale',
      component: loadComponent('Product/Assortments/Internal/Product__Assortments__Internal__Seasons.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_PRODUCT_DETAIL_CLEAR_CACHE),
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_SEASONS, to)
        ]).then(() => next())
      },
      meta: {
        internalChannelType: feConstants.ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE
      }
    },
    {
      path: 'internal/seasons-retail',
      name: 'product--libraries--assortments-internal--seasons--retail',
      component: loadComponent('Product/Assortments/Internal/Product__Assortments__Internal__Seasons.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_PRODUCT_DETAIL_CLEAR_CACHE),
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_SEASONS, to)
        ]).then(() => next())
      },
      meta: {
        internalChannelType: feConstants.ITS__ASSORTMENTS__CHANNEL_TYPE__RETAIL
      }
    },
    {
      path: 'internal/international-seasons',
      redirect: '/product/libraries/internal/seasons'
    },
    // Landing Page (ToC) -----------------------------
    {
      path: 'internal/landing-wholesale',
      name: 'product--libraries--assortments-internal--landing--wholesale',
      component: loadComponent('Product/Assortments/Internal/Product__Assortments__Internal__Landing.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_PRODUCT_DETAIL_CLEAR_CACHE),
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_LANDING, { to: to, from: from })
        ]).then(() => next())
      }
    },
    {
      path: 'internal/landing-retail',
      name: 'product--libraries--assortments-internal--landing--retail',
      component: loadComponent('Product/Assortments/Internal/Product__Assortments__Internal__Landing.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_PRODUCT_DETAIL_CLEAR_CACHE),
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_LANDING, { to: to, from: from })
        ]).then(() => next())
      }
    },
    // Internal International ------------------------------
    {
      path: 'internal/international-wholesale',
      name: 'product--libraries--assortments-internal--international-wholesale',
      component: loadComponent('Product/Assortments/Internal/Product__Assortments__Internal__Grid.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_WHOLESALE, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        appBar: false,
        collectionType: 'ProductCollection',
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL
      }
    },
    {
      path: 'internal/international-retail',
      name: 'product--libraries--assortments-internal--international-retail',
      component: loadComponent('Product/Assortments/Internal/Product__Assortments__Internal__Grid.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_RETAIL, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        appBar: false,
        collectionType: 'ProductCollection',
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL
      }
    },
    {
      path: 'internal/domestic-wholesale',
      name: 'product--libraries--assortments-internal--domestic-wholesale',
      component: loadComponent('Product/Assortments/Internal/Product__Assortments__Internal__Grid.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_WHOLESALE, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        appBar: false,
        collectionType: 'ProductCollection',
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL
      }
    },
    {
      path: 'internal/domestic-retail',
      name: 'product--libraries--assortments-internal--domestic-retail',
      component: loadComponent('Product/Assortments/Internal/Product__Assortments__Internal__Grid.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_RETAIL, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        appBar: false,
        collectionType: 'ProductCollection'
      }
    },
    // -------------------------------------------------------
    {
      path: 'internal/divider/:group_slug',
      name: 'product--libraries--assortments-internal--divider',
      component: loadComponent('Product/Assortments/Internal/Detail/Product__Assortments__Internal__GroupDivider.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DIVIDER, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        appBar: false,
        collectionType: 'ProductCollection',
        detailRoute: 'product--libraries--assortments-internal--detail',
        dividerRoute: 'product--libraries--assortments-internal--divider'
      }
    },
    {
      path: 'internal/detail/:style_color_id',
      name: 'product--libraries--assortments-internal--detail',
      component: loadComponent('Product/Assortments/Internal/Detail/Product__Assortments__Internal__Detail.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments'),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DIVIDER, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        appBar: false,
        collectionType: 'ProductCollection',
        detailRoute: 'product--libraries--assortments-internal--detail',
        dividerRoute: 'product--libraries--assortments-internal--divider'
      }
    }
  ],
  meta: productMeta
}
