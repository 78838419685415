<template>
  <v-combobox
    :clearable="clearable"
    :color="color"
    :disabled="disabled"
    :items="items"
    :label="label"
    :model-value="modelValue"
    :rules="rules"
    @update:modelValue="update"
  ></v-combobox>
</template>

<script>
export default {
  props: {
    appendIcon: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean
    },
    hideDetails: {
      type: Boolean
    },
    items: {
      type: Object
    },
    label: {
      type: String
    },
    modelValue: {
      type: String
    },
    placeholder: {
      type: String
    },
    prependIcon: {
      type: String
    },
    prependInnerIcon: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    rules: {
      type: Object
    },
    type: {
      type: String,
      default: 'text'
    },
    variant: {
      type: String,
      default: 'filled'
    }
  },

  computed: {
    value () {
      return this.modelValue
    }
  },

  emits: ['update:modelValue'],

  methods: {
    update (val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
