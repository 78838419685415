export const VUEX_SAMPLE_INVENTORY_FETCH = 'VUEX_SAMPLE_INVENTORY_FETCH'

export const VUEX_SAMPLE_INVENTORY_GRID_REKEY = 'VUEX_SAMPLE_INVENTORY_GRID_REKEY'

export const VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_START = 'VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_START'
export const VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH = 'VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH'

export const VUEX_SAMPLE_INVENTORY_SET_QUERY_PARAMS = 'VUEX_SAMPLE_INVENTORY_SET_QUERY_PARAMS'
export const VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE = 'VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE'
export const VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD = 'VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD'
export const VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD_COMPLETE = 'VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD_COMPLETE'
export const VUEX_SAMPLE_INVENTORY_GRID_ROWS_DELETE = 'VUEX_SAMPLE_INVENTORY_GRID_ROWS_DELETE'
export const VUEX_SAMPLE_INVENTORY_GRID_REQUEST_CLEAR = 'VUEX_SAMPLE_INVENTORY_GRID_REQUEST_CLEAR'
export const VUEX_SAMPLE_INVENTORY_PRODUCTS_UPDATE = 'VUEX_SAMPLE_INVENTORY_PRODUCTS_UPDATE'

export const VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FETCH = 'VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FETCH'
export const VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_SUCCESS = 'VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_SUCCESS'
export const VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FAILURE = 'VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FAILURE'

export const VUEX_SAMPLE_INVENTORY_TRACKING_FETCH = 'VUEX_SAMPLE_INVENTORY_TRACKING_FETCH'
export const VUEX_SAMPLE_INVENTORY_TRACKING_CREATE = 'VUEX_SAMPLE_INVENTORY_TRACKING_CREATE'

export const VUEX_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION = 'VUEX_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION'
