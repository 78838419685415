import store from '@/store/store'

// pass in an assortment level object, get back key initiative name: season/year/region
// optional param to ignoreRegion
const generateInternalAssortmentName = function (obj, ignoreRegion = false) {
  let name = ''

  if (obj.season) name += obj.season

  if (obj.year) {
    if (name !== '') {
      name += ' '
    }
    name += obj.year
  }

  if (!ignoreRegion) {
    if (obj.region) {
      if (name !== '') {
        name += ' '
      }
      name += obj.region
    } else if (obj.region) {
      if (name !== '') {
        name += ' '
      }
      name += obj.region
    }
  }

  let channel = obj?.channel
  if (channel) {
    if (name !== '') {
      name += ' '
    }
    name += channel
  }

  if (obj.productType) {
    if (name !== '') {
      name += ' '
    }
    name += obj.productType
  }

  return name || ''
}

const generateInternalAssortmentNameAlt = assortment => {
  const assortmentObj = {
    ...(assortment || store.state.assortmentsInternal.query._uiOptions.assortmentsQuery)
  }
  if (!assortmentObj) return

  // const toc = store.state.assortmentsInternal.tableOfContents
  const productType = store.state.assortmentsInternal.query.productType
  let channel = assortmentObj?.channel || ''

  if (!assortment) assortmentObj.productType = productType

  switch (channel) {
    case ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE:
      channel = 'Wholesale Key Initiative'
      break
    case ITS__ASSORTMENTS__CHANNEL_TYPE__RETAIL:
      channel = 'Retail Assortment'
      break

    default :
      console.error('/helpers/assortmentHelper.js | generateInternalAssortmentNameAlt | Cannot find assortment `channel`')
      break
  }

  return [
    assortmentObj.season || '',
    assortmentObj.year || '',
    assortmentObj.region || '',
    assortmentObj.productType || '',
    channel || ''
  ].join(' ')
}

const getStatusLabel = assortment => {
  const locations =
    store.state.properties.data.Product.properties.locations.properties.code.options
  const location = locations.find(loc => loc.code === assortment.locationId)
  if (location) {
    return `${location.name} (${assortment.status})`
  }
  return null
}

const generateAssortmentName = assortment => {
  if (!assortment.title) {
    return null
  }

  const title = assortment['title']
  if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
    const internalAssortmentName = generateInternalAssortmentNameAlt(assortment)
    const gender = assortment.gender

    return `${[gender, title].join(' ')} | ${internalAssortmentName}`
  } else {
    return `${title} | ${getStatusLabel(assortment)}`
  }
}

export {
  generateInternalAssortmentName,
  generateInternalAssortmentNameAlt,
  generateAssortmentName
}
