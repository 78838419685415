import _groupBy from 'lodash.groupby'
import _orderBy from 'lodash.orderby'

import {
  // Seasons
  VUEX_SEASONS_FETCH,
  VUEX_SEASONS_REFETCH,

  // Season
  VUEX_SEASON_FETCH,
  VUEX_SEASON_CREATE,
  VUEX_SEASON_UPDATE,
  VUEX_SEASON_DELETE,

  // Season Sets
  VUEX_SEASON_SET_UPDATE_STATE
} from '@/store/constants/models/seasons'

import {
  // Seasons
  VUEX_API_SEASONS_REQUEST_FETCH,

  // Season
  VUEX_API_SEASON_REQUEST_CREATE,
  VUEX_API_SEASON_REQUEST_FETCH,
  VUEX_API_SEASON_REQUEST_UPDATE,
  VUEX_API_SEASON_REQUEST_DELETE,

  // Season sets
  VUEX_API_SEASON_SET_UPDATE_STATE
} from '@/store/constants/api'

import {
  VUEX_GRID_UPDATE_START,
  VUEX_GRID_UPDATE_SUCCESS,
  VUEX_GRID_UPDATE_FAILURE
} from '@/store/constants/ui/grid'

import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'

import { s3Href } from '@/utils/file'

const state = {
  // List of seasons
  seasons: [],
  seasonsRefetchPayload: null,

  // Single season object
  season: {},

  // for ag grid
  agGridRowData: []
}

const getters = {
  seasonSetsFlattened: state => (mapped = false, filters = {}) => {
    let sets = state.seasons.flatMap(season => {
      return season.seasonSets.map(set => {
        const setItem = mapped ? { label: set.shortName, value: { ...season, ...set } } : { ...season, ...set }
        return setItem
      })
    })

    // Filter sets | key:value array
    const filterEntries = Object.entries(filters)
    if (filterEntries.length) {
      filterEntries.forEach(([key, value]) => {
        sets = sets.filter(set => set.value[key] === value)
      })
    }

    return sets
  },

  seasonSetsHierarchy: state => {
    const sets = state.seasons.flatMap(season => {
      return season.seasonSets.map(set => {
        const setItem = { ...season, ...set }
        delete setItem.seasonSets
        return setItem
      })
    })

    return Object.entries(_groupBy(sets, 'productType')).map(([productKey, productArr]) => {
      const yearGroups = Object.entries(_groupBy(productArr, 'year')).map(([yearKey, setsArr]) => ({ year: yearKey, sets: setsArr }))

      return {
        productType: productKey,
        yearGroups: _orderBy(yearGroups, ['year'], ['desc'])
      }
    })
  },

  seasonsPage: (state, getters, rootState) => {
    const seasons = []
    // const s3 = rootState.options.data.aws.s3

    for (const seasonData of state.seasons) {
      const title = `${seasonData.season} ${seasonData.year}`
      const season = {
        title,
        season: seasonData.season,
        year: seasonData.year,
        thumbnail: s3Href(seasonData.previewJpg),
        regions: []
      }

      const groupedSetsByRegion = _groupBy(seasonData.seasonSets, ss => ss.region)

      season.regions = Object.keys(groupedSetsByRegion).map(region => ({
        title: region,
        orgType: groupedSetsByRegion[region][0].orgType,
        channel: seasonData.channel,
        method: groupedSetsByRegion[region][0].method,
        productTypes: groupedSetsByRegion[region].map(s => ({ title: s.productType }))
      }))

      seasons.push(season)
    }

    return seasons
  },

  allSeasonSets: (state) => {
    return state.seasons.flatMap(s => s.seasonSets)
  }
}

const actions = {

  [VUEX_SEASONS_REFETCH]: async ({ dispatch, commit, state }, payload) => {
    await commit(VUEX_SEASONS_FETCH)
    dispatch(VUEX_SEASONS_FETCH, state.seasonsRefetchPayload)
  },

  [VUEX_SEASONS_FETCH]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_SEASONS_FETCH, []) // clear seasons
    commit(VUEX_SEASONS_REFETCH, payload)
    dispatch(VUEX_API_SEASONS_REQUEST_FETCH, payload).then(async response => {
      commit(VUEX_SEASONS_FETCH, response?.data)
    })
  },

  [VUEX_SEASON_FETCH]: ({ dispatch, commit }, payload) => {
    dispatch(VUEX_API_SEASON_REQUEST_FETCH, payload).then(async response => {
      commit(VUEX_SEASON_FETCH, response)
    })
  },

  [VUEX_SEASON_CREATE]: ({ dispatch, commit }, payload) => {
    dispatch(VUEX_API_SEASON_REQUEST_CREATE, payload).then(async response => {
      commit(VUEX_SEASON_CREATE, response?.data)
    })
  },

  [VUEX_SEASON_UPDATE]: async ({ dispatch, commit }, payload) => {
    // normally we batch this, but this is not batched so we need to loop one by one
    if (payload) {
      dispatch(VUEX_GRID_UPDATE_START)
      for (let i = 0; i < payload.length; i++) {
        const payloadObj = {
          id: payload[i].id,
          data: payload[i].properties
        }
        await dispatch(VUEX_API_SEASON_REQUEST_UPDATE, payloadObj).then(async response => {
          commit(VUEX_SEASON_UPDATE, response.data)
          dispatch(VUEX_GRID_UPDATE_SUCCESS)
        }).catch(() => {
          dispatch(VUEX_GRID_UPDATE_FAILURE)
        })
      } // for
    } // if
  },

  [VUEX_SEASON_DELETE]: async ({ dispatch }, payload) => {
    // normally we batch this, but this is not batched so we need to loop one by one
    if (payload) {
      dispatch(VUEX_GRID_UPDATE_START)
      for (let i = 0; i < payload.length; i++) {
        const payloadId = payload[i].id
        await dispatch(VUEX_API_SEASON_REQUEST_DELETE, payloadId).then(async response => {
          // commit(VUEX_SEASON_DELETE, response.data)
          dispatch(VUEX_GRID_UPDATE_SUCCESS)
        }).catch(() => {
          dispatch(VUEX_GRID_UPDATE_FAILURE)
        })
      } // for
    } // if
  },

  [VUEX_SEASON_SET_UPDATE_STATE]: ({ dispatch, commit }, payload) => {
    dispatch(VUEX_API_SEASON_SET_UPDATE_STATE, payload).then(async response => {
      commit(VUEX_SEASON_SET_UPDATE_STATE, response.data)
    })
  }
}

const mutations = {
  [VUEX_SEASONS_REFETCH]: (state, data) => {
    state.seasonsRefetchPayload = data
  },

  [VUEX_SEASONS_FETCH]: (state, data) => {
    if (data) {
      state.seasons = data
      state.agGridRowData = DataMiddleware.convertManageSeasonsToRowData(data)
    } else {
      state.seasons = []
      state.agGridRowData = []
    }
  },

  [VUEX_SEASON_FETCH]: (state, data) => {
    state.season = data
  },

  [VUEX_SEASON_CREATE]: (state, data) => {
    state.seasons = [
      ...state.seasons,
      data
    ]
  },

  [VUEX_SEASON_UPDATE]: (state, data) => {
    state.season = data
  },

  [VUEX_SEASON_SET_UPDATE_STATE]: (state, data) => {
    state.seasons = [
      data,
      ...state.seasons.filter(s => s._id !== data._id)
    ]
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
