import _clone from 'lodash.clonedeep'

import schemas from './schemas'
// import utils from './utils'

let errorTemplate = `ApiValidator:`

export default class ApiValidator {
  constructor (schema, config = {}) {
    // config properties
    this._debug = config.debug

    // schema properties
    this.schemaName = schema
    this.schema = schemas[schema]

    if (this._debug) ApiValidator.debug(null, 'initialized')

    // validate config
    ApiValidator.validateSchema(this)
  }

  // ************************************************************************************
  // Public Methods ---------------------------------
  // ************************************************************************************
  validate (payload, requestType = 'Default', refObject) {
    if (!ApiValidator[requestType]) ApiValidator.throwError("validate(requestType, payload) | Invalid 'requestType'")
    if (!payload) ApiValidator.throwError('validate(requestType, payload) | Missing payload')

    return ApiValidator[requestType](this, payload, _clone(refObject))
  }

  static ['Default'] (context, payload/* , refObject = {} */) {
    const validationData = context.schema.validate(payload, { stripUnknown: true })
    if (validationData?.error) ApiValidator.logError(validationData.error)

    if (this._debug) ApiValidator.debug({ payload, validationData })

    return validationData.value
  }
  // ************************************************************************************

  // ************************************************************************************
  // Utils
  static validateSchema (context) {
    if (!context.schema) console.error(`ApiValidator: Cannot find schema '${context.schema}' `)
    if (this._debug) ApiValidator.debug(null, `schema ${context.schema ? 'PASSED' : 'FAILED'}`)
  }

  static debug (payload, msg = null) {
    let debugObj = {
      ...payload,
      schemaName: this.schemaName
    }

    console.error(`${errorTemplate} ${msg} : ${debugObj}`)
  }

  static throwError (msg) {
    console.error(`${errorTemplate} ${msg}`)
  }

  static logError (msg) {
    console.error(`${errorTemplate} ${msg}`)
  }

  static getProductKey (product) {
    return `${product.style}-${product.color}`
  }
}
