import {
  VUEX_ALERT_DIALOG_SHOW,
  VUEX_ALERT_DIALOG_HIDE,
  VUEX_ALERT_DIALOG_USER_DISMISS
} from '@/store/constants/ui/alertDialog'

const state = {
  openState: false,
  content: null,
  userDismissed: false
}

const getters = {}

const actions = {
  [VUEX_ALERT_DIALOG_SHOW]: ({ commit }, payload) => {
    commit(VUEX_ALERT_DIALOG_SHOW, payload)
  },

  [VUEX_ALERT_DIALOG_HIDE]: ({ commit }, payload) => {
    commit(VUEX_ALERT_DIALOG_HIDE, payload)
  },

  [VUEX_ALERT_DIALOG_USER_DISMISS]: ({ commit }) => {
    commit(VUEX_ALERT_DIALOG_USER_DISMISS)
  }
}

const mutations = {
  [VUEX_ALERT_DIALOG_SHOW]: (state, data) => {
    state.openState = true
    state.userDismissed = false
    if (data.content) state.content = data.content
  },

  [VUEX_ALERT_DIALOG_HIDE]: (state, data) => {
    state.openState = false
    if (data?.userDismissed) state.userDismissed = data?.userDismissed
  },

  [VUEX_ALERT_DIALOG_USER_DISMISS]: state => {
    state.userDismissed = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
