// Advertising Libraries
export const VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS = 'VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS'
export const VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS_LANDING = 'VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS_LANDING'
export const VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS = 'VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS'
export const VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS_LANDING = 'VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS_LANDING'

// Advertising Libraries Manage
export const VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PENDING = 'VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PENDING'
export const VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PUBLISHED = 'VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PUBLISHED'
export const VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UNPUBLISHED = 'VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UNPUBLISHED'
export const VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UPLOAD = 'VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UPLOAD'
export const VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_DELETED = 'VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_DELETED'
