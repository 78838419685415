import cloneDeep from 'lodash/cloneDeep'
import diff from 'deep-diff'

import {
  VUEX_GLOBAL_ACTION_PROMPT_YES,
  VUEX_GLOBAL_ACTION_PROMPT_NO,
  VUEX_GLOBAL_ACTION_PROMPT_SHOW,
  VUEX_GLOBAL_ACTION_PROMPT_CLOSED,
  VUEX_GLOBAL_ACTION_PROMPT_SET_ACTION_OBJECT,
  VUEX_GLOBAL_ACTION_PROMPT_SET_STATE_CHANGE_WATCHERS, VUEX_GLOBAL_ACTION_PROMPT_CLOSEX
} from '@/store/constants/ui/globalActionPrompt'

var defaultActionObj = {
  title: 'You Have Unsaved Items',
  description: 'Are you sure you want to cancel?',
  labelYes: 'YES',
  labelNo: 'NO',
  dispatchAction: null,
  dispatchActionObject: null,
  dispatchActionNo: null,
  dispatchActionObjectNo: null
}
const state = {
  show: false,

  // pass in these items
  actionObj: cloneDeep(defaultActionObj),
  stateChangeWatchers: [],
  stateChangeWatchersInitial: []
}

const actions = {
  [VUEX_GLOBAL_ACTION_PROMPT_SET_STATE_CHANGE_WATCHERS]: ({ commit, state, dispatch, rootState }, payload) => {
    let obj = {
      data: payload.stateChangeWatchers,
      rootState: rootState
    }
    commit(VUEX_GLOBAL_ACTION_PROMPT_SET_STATE_CHANGE_WATCHERS, obj)
  },
  [VUEX_GLOBAL_ACTION_PROMPT_SHOW]: ({ commit, state, dispatch, rootState }, payload) => {
    commit(VUEX_GLOBAL_ACTION_PROMPT_SET_ACTION_OBJECT, payload.actionObj)
    var showIt = false
    if (payload.actionObj.checkChangedFields) {
      if (state.stateChangeWatchers.length > 0) {
        for (var i = 0; i < state.stateChangeWatchers.length; i++) {
          let s = state.stateChangeWatchers[i]

          if (s instanceof Array) {
            let rs1 = s[0]
            let rs2 = s[1]
            s = rootState[rs1][rs2]
          }

          let sInit = state.stateChangeWatchersInitial[i]
          let changes = diff(s, sInit)
          if (changes) {
            showIt = true
          }
        }
      }
    } else {
      // always show it if not checking for changed fields
      showIt = true
    }

    // if we don't show it, close the dispatch event
    if (showIt) {
      commit(VUEX_GLOBAL_ACTION_PROMPT_SHOW, showIt)
    } else {
      dispatch(VUEX_GLOBAL_ACTION_PROMPT_YES)
    }
  },
  [VUEX_GLOBAL_ACTION_PROMPT_YES]: ({ commit, state, dispatch }) => {
    if (state.actionObj.dispatchAction) {
      dispatch(state.actionObj.dispatchAction, state.actionObj.dispatchActionObject)
    }
    commit(VUEX_GLOBAL_ACTION_PROMPT_SHOW, false)
    setTimeout(function () {
      commit(VUEX_GLOBAL_ACTION_PROMPT_CLOSED)
    }, 100)
  },
  [VUEX_GLOBAL_ACTION_PROMPT_NO]: ({ commit, state, dispatch }) => {
    if (state.actionObj.dispatchActionNo) {
      dispatch(state.actionObj.dispatchActionNo, state.actionObj.dispatchActionObjectNo)
    }
    commit(VUEX_GLOBAL_ACTION_PROMPT_SHOW, false)
  },
  [VUEX_GLOBAL_ACTION_PROMPT_CLOSEX]: ({ commit, state, dispatch }) => {
    commit(VUEX_GLOBAL_ACTION_PROMPT_SHOW, false)
  }
}

const mutations = {
  [VUEX_GLOBAL_ACTION_PROMPT_SHOW]: (state, data) => {
    state.show = data
  },

  [VUEX_GLOBAL_ACTION_PROMPT_CLOSED]: (state, data) => {
    state.actionObj = cloneDeep(defaultActionObj)
  },

  [VUEX_GLOBAL_ACTION_PROMPT_SET_ACTION_OBJECT]: (state, data) => {
    if (data.title && data.title !== '') {
      state.actionObj.title = data.title
    } else {
      state.actionObj.title = defaultActionObj.title
    }
    if (data.description && data.description !== '') {
      state.actionObj.description = data.description
    } else {
      state.actionObj.description = defaultActionObj.description
    }
    if (data.labelYes && data.labelYes !== '') {
      state.actionObj.labelYes = data.labelYes
    } else {
      state.actionObj.labelYes = defaultActionObj.labelYes
    }
    if (data.labelNo && data.labelNo !== '') {
      state.actionObj.labelNo = data.labelNo
    } else {
      state.actionObj.labelNo = defaultActionObj.labelNo
    }
    if (data.dispatchAction && data.dispatchAction !== '') {
      state.actionObj.dispatchAction = data.dispatchAction
    } else {
      state.actionObj.dispatchAction = defaultActionObj.dispatchAction
    }
    if (data.dispatchActionObject && data.dispatchActionObject !== '') {
      state.actionObj.dispatchActionObject = data.dispatchActionObject
    } else {
      state.actionObj.dispatchActionObject = defaultActionObj.dispatchActionObject
    }
    if (data.dispatchActionNo && data.dispatchActionNo !== '') {
      state.actionObj.dispatchActionNo = data.dispatchActionNo
    } else {
      state.actionObj.dispatchActionNo = defaultActionObj.dispatchActionNo
    }
    if (data.dispatchActionObjectNo && data.dispatchActionObjectNo !== '') {
      state.actionObj.dispatchActionObjectNo = data.dispatchActionObjectNo
    } else {
      state.actionObj.dispatchActionObjectNo = defaultActionObj.dispatchActionObjectNo
    }
    state.actionObj.checkChangedFields = data.checkChangedFields
  },

  [VUEX_GLOBAL_ACTION_PROMPT_SET_STATE_CHANGE_WATCHERS]: (state, obj) => {
    state.stateChangeWatchersInitial = []
    state.stateChangeWatchers = []
    let rootState = obj.rootState
    let data = obj.data
    if (data && data !== '') {
      for (var i = 0; i < data.length; i++) {
        let s = data[i]
        if (s instanceof Array) {
          let rs1 = s[0]
          let rs2 = s[1]
          let newS = rootState[rs1][rs2]

          state.stateChangeWatchers.push(s)
          let sClone = cloneDeep(newS)
          state.stateChangeWatchersInitial.push(sClone)
        } else {
          state.stateChangeWatchers.push(s)
          let sClone = cloneDeep(s)
          state.stateChangeWatchersInitial.push(sClone)
        }
      }
    }
  }

}

export default {
  state,
  actions,
  mutations
}
