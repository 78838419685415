// import axios from 'axios'
import api from '@/api'

import {
  VUEX_API_MAIL_SEND,
  VUEX_API_MAIL_SEND_SUCCESS,
  VUEX_API_MAIL_SEND_FAILURE
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  sendRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_MAIL_SEND]: ({ commit, dispatch }, payload) => {
    return api.post('/mail/applink', payload).then(response => {
      commit(VUEX_API_MAIL_SEND_SUCCESS)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: 'Thank you. Your message has been sent.'
        }
      })
    }).catch(() => {
      commit(VUEX_API_MAIL_SEND_FAILURE)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: 'Error: Cannot send message'
        }
      })
    })
  }
}

const mutations = {
  [VUEX_API_MAIL_SEND]: state => {
    state.sendRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_MAIL_SEND_SUCCESS]: state => {
    state.sendRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_MAIL_SEND_FAILURE]: state => {
    state.sendRequest = ITS__API__REQUEST_TYPE__FAILED
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
