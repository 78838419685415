import _debounce from 'lodash.debounce'
import Debug from 'logdown'

import {
  VUEX_TOAST_SHOW,
  VUEX_TOAST_HIDE,

  VUEX_TOAST_SET_STATE,
  VUEX_TOAST_ADD_TO_QUEUE,
  VUEX_TOAST_PROCCESS,
  VUEX_TOAST_CYCLE_COMPLETE,
  VUEX_TOAST_CLEAR
} from '@/store/constants/ui/toast'

const d = new Debug('its:modules:toast:store')

const state = {
  toastQueue: [],
  hasToastPending: false,

  component: null,
  data: null,
  timeout: null,
  openState: false
}

const getters = {
  numberOfNotifications: state => {
    return state.toastQueue.length
  },

  hasToastPending: state => {
    return state.toastQueue.length > 0
  }
}

const actions = {
  [VUEX_TOAST_SHOW]: ({ commit }, payload) => {
    commit(VUEX_TOAST_SHOW, payload)
  },

  [VUEX_TOAST_HIDE]: ({ commit }) => {
    commit(VUEX_TOAST_HIDE)
  },

  [VUEX_TOAST_SET_STATE]: ({ commit, dispatch }, payload) => {
    commit(VUEX_TOAST_SET_STATE, payload)
    setTimeout(() => {
      dispatch(VUEX_TOAST_CYCLE_COMPLETE)
    }, 1000)
  },

  [VUEX_TOAST_ADD_TO_QUEUE]: _debounce(async ({ dispatch, commit }, payload) => {
    d.log('Added toast to queue', { payload })
    await commit(VUEX_TOAST_ADD_TO_QUEUE, payload)
    dispatch(VUEX_TOAST_PROCCESS)
  }, 250),

  [VUEX_TOAST_PROCCESS]: ({ state, getters, dispatch }) => {
    if (getters.hasToastPending && !state.openState) {
      // Kick-off Toast Queue
      dispatch(VUEX_TOAST_SHOW, state.toastQueue[0])
    }
  },

  [VUEX_TOAST_CYCLE_COMPLETE]: async ({ dispatch, commit }) => {
    await commit(VUEX_TOAST_CYCLE_COMPLETE)
    dispatch(VUEX_TOAST_PROCCESS)
  },

  [VUEX_TOAST_CLEAR]: async ({ dispatch, commit }) => {
    await commit(VUEX_TOAST_HIDE)
    setTimeout(async () => {
      await commit(VUEX_TOAST_CYCLE_COMPLETE)
      dispatch(VUEX_TOAST_PROCCESS)
    }, 500)
  }
}

const mutations = {
  [VUEX_TOAST_SHOW]: (state, data) => {
    if (data.component) state.component = data.component
    if (data.data) state.data = data.data

    if (data.hasOwnProperty('timeout')) {
      state.timeout = parseInt(data.timeout)
    } else {
      state.timeout = ITS__UI__TOAST__DELAY__DEFAULT
    }

    state.openState = true
  },

  [VUEX_TOAST_HIDE]: (state) => {
    state.openState = false
  },

  [VUEX_TOAST_SET_STATE]: (state, data) => {
    state.openState = data
  },

  [VUEX_TOAST_ADD_TO_QUEUE]: (state, data) => {
    state.toastQueue = [
      ...state.toastQueue,
      data
    ]
  },

  [VUEX_TOAST_CYCLE_COMPLETE]: state => {
    state.toastQueue.shift()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
