import feConstants from '@/store/constants/config/fe_constants'

export default {
  modules: {
    toolbar: {
      title: 'Product',
      extension: {
        navigation: [
          {
            title: 'Pending',
            name: 'product--libraries--documents--manage--pending'
          },
          {
            title: 'Published',
            name: 'product--libraries--documents--manage--published'
          },
          {
            title: 'Unpublished',
            name: 'product--libraries--documents--manage--unpublished'
          },
          {
            title: 'Upload Files',
            name: 'product--libraries--documents--manage--upload'
          }
        ]
      },
      subnav: [
        {
          title: 'Document Library',
          name: 'product--libraries--documents',
          subRoutes: [
            {
              title: 'View Documents',
              name: 'product--libraries--documents',
              accessRequires: [
                {
                  permission: feConstants.ITS__PERMISSION__PRODUCT__DOCUMENTS,
                  roles: [
                    feConstants.ITS__ROLE__USER,
                    feConstants.ITS__ROLE__EDITOR,
                    feConstants.ITS__ROLE__SENIOR_EDITOR
                  ]
                }
              ]
            },
            {
              title: 'Manage Documents',
              name: 'product--libraries--documents--manage--pending',
              accessRequires: [
                {
                  permission: feConstants.ITS__PERMISSION__PRODUCT__DOCUMENTS,
                  roles: [
                    feConstants.ITS__ROLE__EDITOR,
                    feConstants.ITS__ROLE__SENIOR_EDITOR
                  ]
                }
              ]
            }
          ],
          accessRequires: [
            { permission: feConstants.ITS__PERMISSION__PRODUCT__DOCUMENTS }
          ],
          match: [
            'product--libraries--documents',
            'product--libraries--documents--manage--pending',
            'product--libraries--documents--manage--published',
            'product--libraries--documents--manage--unpublished',
            'product--libraries--documents--manage--upload'
          ]
        },
        {
          title: 'Product Library',
          name: 'product--libraries--products',
          accessRequires: [
            { permission: feConstants.ITS__PERMISSION__PRODUCTS }
          ],
          match: [
            'product--libraries--products'
          ]
        },
        {
          title: 'Sample Library',
          name: 'product--libraries--sample-inventory--default',
          accessRequires: [
            { permission: feConstants.ITS__PERMISSION__SAMPLE_INVENTORY }
          ],
          match: [
            'product--libraries--sample-inventory--production',
            'product--libraries--sample-inventory--sales'
          ]
        },
        {
          title: 'Key Initiatives',
          name: 'product--libraries--assortments-internal--seasons--wholesale',
          accessRequires: [
            { permission: feConstants.ITS__PERMISSION__PRODUCT__INTERNAL_ASSORTMENTS }
          ],
          match: [
            'product--libraries--assortments-internal--seasons--wholesale',
            'product--libraries--assortments-internal--landing--wholesale',
            'product--libraries--assortments-internal--international-wholesale',
            'product--libraries--assortments-internal--domestic-wholesale'
          ]
        },
        {
          title: 'Assortment Manager',
          name: 'assortment-manager--quick-access',
          subRoutes: [
            {
              title: 'Manage Assortments',
              name: 'assortment-manager--quick-access',
              accessRequires: [
                {
                  permission: feConstants.ITS__PERMISSION__PRODUCT__ASSORTMENTS,
                  roles: [
                    feConstants.ITS__ROLE__MANAGER
                  ]
                }
              ]
            },
            {
              title: 'View Reporting',
              name: 'assortment-manager--reporting',
              accessRequires: [
                {
                  permission: feConstants.ITS__PERMISSION__PRODUCT__ASSORTMENTS,
                  roles: [
                    feConstants.ITS__ROLE__MANAGER
                  ]
                }
              ]
            }
          ],
          accessRequires: [
            { permission: feConstants.ITS__PERMISSION__PRODUCT__ASSORTMENTS }
          ],
          match: [
            'assortment-manager--quick-access',
            'assortment-manager--all-assortments',
            'assortment-manager--internal-wholesale',
            'assortment-manager--internal-retail',
            'assortment-manager--recent',
            'assortment-manager--starred',
            'assortment-manager--scheduled',
            'assortment-manager--archived',
            'assortment-details--manage',
            'assortment-details--sort',
            'assortment-details--export',
            'assortment-details--showroom-config',
            'assortment-internal-details',
            'assortment-internal-details--manage',
            'assortment-internal-details--sort',
            'assortment-internal-details--export',
            'assortment-manager--reporting'
          ]
        }
      ]
    }
  }
}
