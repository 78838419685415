import { loadComponent } from '@/helpers/asyncLoaders'
import store from '@/store/store'
import gscStore from '@/components/GlobalConference/Spring24/store'

import {
  VUEX_USER_HAS_CONFERENCE_ASIAN,
  VUEX_USER_HAS_CONFERENCE_DOMESTIC,
  VUEX_USER_HAS_CONFERENCE_INTERNATIONAL
} from '@/store/constants/user'

const hasPerms = () => {
  return store.getters[VUEX_USER_HAS_CONFERENCE_ASIAN] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_DOMESTIC] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_INTERNATIONAL]
}

store.registerModule('spring24', gscStore)

export const routes = {
  path: 'spring24',
  name: 'spring24--main',
  meta: {
    conferenceClass: 'spring24'
  },
  component: loadComponent('GlobalConference/Spring24/Main.vue'),
  redirect: { name: 'spring24--categories' },
  children: [
    {
      path: 'welcome',
      name: 'spring24--welcome',
      component: loadComponent('GlobalConference/Spring24/Pages/Welcome.vue')
    },
    {
      path: '',
      name: 'spring24--dashboard',
      component: loadComponent('GlobalConference/Spring24/Pages/Dashboard.vue'),
      beforeEnter: (to, from, next) => {
        if (hasPerms()) {
          next()
        } else {
          next('/')
        }
      },
      children: [
        {
          path: '',
          name: 'spring24--dashboard-default',
          redirect: { name: 'spring24--categories' }
        },
        {
          path: 'categories',
          name: 'spring24--categories',
          component: loadComponent('GlobalConference/Spring24/Pages/Categories.vue'),
          meta: {
            eventCategory: 'spring24',
            title: 'Product Categories',
            navigation: [
              'Womens',
              'Mens',
              'Kids',
              'Unisex'
            ]
          }
        },
        {
          path: 'ads',
          name: 'spring24--ads',
          component: loadComponent('GlobalConference/Spring24/Pages/Ads.vue'),
          meta: {
            eventCategory: 'spring24',
            title: 'Advertising',
            fixedNav: true,
            navigation: [
              'Womens',
              'Mens',
              'Kids',
              'Unisex',
              'Work',
              'BTS',
              'International',
              'Radio'
            ]
          }
        },
        {
          path: 'sessions',
          name: 'spring24--sessions',
          component: loadComponent('GlobalConference/Spring24/Pages/Sessions.vue'),
          meta: {
            eventCategory: 'spring24',
            title: 'General Sessions'
          }
        },
        {
          path: 'marketing',
          name: 'spring24--marketing',
          component: loadComponent('GlobalConference/Spring24/Pages/Marketing.vue'),
          meta: {
            eventCategory: 'spring24',
            title: 'Marketing'
          }
        }
      ]
    }
  ]
}
