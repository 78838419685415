import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'
// import { loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_ASSORTMENT_REPORTING,
  VUEX_ROUTING_ENTER_ASSORTMENT_SEASONS_MANAGER,

  VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_INTERNAL,

  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_GROUPSORT,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_EXPORT,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM_CONFIG,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_CREATE_ORDER,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DIVIDER,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM,

  VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS,
  VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_GROUPSORT,
  VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_EXPORT
} from '@/store/constants/routing/routingProduct'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import {
  VUEX_SUBHEADER_SHOW
} from '@/store/constants/ui/subheader'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { productMeta } from '@/router/routeMeta'

export default {
  path: '/product/assortments',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      callback: async () => {
        await store.dispatch(VUEX_OPTIONS_FETCH, 'assortments').then(() => next())
      }
    }),
  children: [
    // DEFAULT <-------------------------------------------------------
    {
      path: '',
      name: 'assortment-manager--default',
      redirect: { name: 'assortment-manager--quick-access' }
    },
    // REPORTING <-----------------------------------------------------
    {
      path: 'reporting',
      name: 'assortment-manager--reporting',
      component: loadComponent('Reporting/Reporting_AssortmentManager.vue'),
      beforeEnter: (_to, _from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_REPORTING, _to).then(() => next())
      },
      meta: {
        internalChannelType: feConstants.ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE
      }
    },
    // SEASONS MANAGER <-----------------------------------------------
    {
      path: 'seasons/manage',
      name: 'assortment-manager--manage-seasons',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_SeasonsManager.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_SEASONS_MANAGER, to).then(() => next())
      },
      meta: {
        internalChannelType: feConstants.ITS__ASSORTMENTS__CHANNEL_TYPE__ALL,
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__SEASONSMANAGER
      }
    },
    // INTERNAL/REGULAR ASSORTMENTS MANAGER <--------------------------
    {
      path: 'internal/wholesale',
      name: 'assortment-manager--internal-wholesale',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_AssortmentsInternal_Wholesale.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_INTERNAL, to).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL,
        internalChannelType: feConstants.ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE
      }
    },
    {
      path: 'internal/retail',
      name: 'assortment-manager--internal-retail',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_AssortmentsInternal_Retail.vue'),
      beforeEnter: (_to, _from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_INTERNAL, _to).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL,
        internalChannelType: feConstants.ITS__ASSORTMENTS__CHANNEL_TYPE__RETAIL
      }
    },
    {
      path: 'quick-access',
      name: 'assortment-manager--quick-access',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_QuickAccess.vue'),
      beforeEnter: (_to, _from, next) => {
        store.dispatch(VUEX_SUBHEADER_SHOW, {
          size: ITS__UI__SUBHEADER__SIZE__1,
          content: '_core/AppBar/AppBar_Extension_Nav.vue'
        }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR
      }
    },
    {
      path: 'all',
      name: 'assortment-manager--all-assortments',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_Assortments.vue'),
      beforeEnter: (_to, _from, next) => {
        store.dispatch(VUEX_SUBHEADER_SHOW, {
          size: ITS__UI__SUBHEADER__SIZE__1,
          content: '_core/AppBar/AppBar_Extension_Nav.vue'
        }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR
      }
    },
    {
      path: 'recent',
      name: 'assortment-manager--recent',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_Recent.vue'),
      beforeEnter: (_to, _from, next) => {
        store.dispatch(VUEX_SUBHEADER_SHOW, {
          size: ITS__UI__SUBHEADER__SIZE__1,
          content: '_core/AppBar/AppBar_Extension_Nav.vue'
        }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR
      }
    },
    {
      path: 'starred',
      name: 'assortment-manager--starred',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_Starred.vue'),
      beforeEnter: (_to, _from, next) => {
        store.dispatch(VUEX_SUBHEADER_SHOW, {
          size: ITS__UI__SUBHEADER__SIZE__1,
          content: '_core/AppBar/AppBar_Extension_Nav.vue'
        }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR
      }
    },
    {
      path: 'scheduled',
      name: 'assortment-manager--scheduled',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_Scheduled.vue'),
      beforeEnter: (_to, _from, next) => {
        store.dispatch(VUEX_SUBHEADER_SHOW, {
          size: ITS__UI__SUBHEADER__SIZE__1,
          content: '_core/AppBar/AppBar_Extension_Nav.vue'
        }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR
      }
    },
    {
      path: 'archived',
      name: 'assortment-manager--archived',
      component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_Archived.vue'),
      beforeEnter: (_to, _from, next) => {
        store.dispatch(VUEX_SUBHEADER_SHOW, {
          size: ITS__UI__SUBHEADER__SIZE__1,
          content: '_core/AppBar/AppBar_Extension_Nav.vue'
        }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR
      }
    },
    // SINGLE INTERNAL ASSORTMENT MANAGE <-----------------------------
    {
      path: 'internal/:assortment_id',
      name: 'assortment-internal-details',
      component: loadComponent('AssortmentManager/AssortmentManager_Assortment.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS, { to, from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR
      },
      children: [
        {
          path: '',
          name: 'assortment-internal-details--default',
          redirect: { name: 'assortment-internal-details--manage' }
        },
        {
          path: 'manage',
          name: 'assortment-internal-details--manage',
          component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_AssortmentDetails_Internal.vue'),
          /* beforeEnter: (to, from, next) => {
            store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_MANAGE, { to, from }).then(() => next())
          }, */
          meta: {
            manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL,
            collectionType: 'ProductCollection',
            controlBarContent: 'manage',
            queryRequest: false
          }
        },
        {
          path: 'sort',
          name: 'assortment-internal-details--sort',
          component: loadComponent('AssortmentManager/GroupSort/AssortmentManager_GroupSort.vue'),
          beforeEnter: (to, from, next) => {
            store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_GROUPSORT, { to, from }).then(() => next())
          },
          meta: {
            manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL,
            controlBarContent: 'sort'
          }
        },
        {
          path: 'export',
          name: 'assortment-internal-details--export',
          component: loadComponent('AssortmentManager/Export/AssortmentManager_Export.vue'),
          beforeEnter: (to, from, next) => {
            store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_EXPORT, { to, from }).then(() => next())
          },
          meta: {
            manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL,
            controlBarContent: 'export'
          }
        }
      ]
    },
    // SINGLE REGULAR ASSORTMENT MANAGE <-------------------------------
    {
      path: 'assortment/:assortment_id',
      name: 'assortment-details',
      component: loadComponent('AssortmentManager/AssortmentManager_Assortment.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS, { to, from }).then(() => next())
      },
      children: [
        {
          path: '',
          name: 'assortment-details--default',
          redirect: { name: 'assortment-details--manage' }
        },
        {
          path: 'manage',
          name: 'assortment-details--manage',
          component: loadComponent('AssortmentManager/Manage/Grids/AssortmentManager_AssortmentDetails.vue'),
          meta: {
            manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR,
            collectionType: 'ProductCollection',
            controlBarContent: 'manage'
          }
        },
        {
          path: 'sort',
          name: 'assortment-details--sort',
          component: loadComponent('AssortmentManager/GroupSort/AssortmentManager_GroupSort.vue'),
          beforeEnter: (to, from, next) => {
            store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_GROUPSORT, { to, from }).then(() => next())
          },
          meta: {
            manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR,
            collectionType: 'ProductCollection',
            controlBarContent: 'sort'
          }
        },
        {
          path: 'export',
          name: 'assortment-details--export',
          component: loadComponent('AssortmentManager/Export/AssortmentManager_Export.vue'),
          beforeEnter: (to, from, next) => {
            store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_EXPORT, { to, from }).then(() => next())
          },
          meta: {
            manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR,
            collectionType: 'ProductCollection',
            controlBarContent: 'export'
          }
        },
        {
          path: 'showroom-config',
          name: 'assortment-details--showroom-config',
          component: loadComponent('AssortmentManager/ShowroomConfig/AssortmentManager_ShowroomConfig.vue'),
          beforeEnter: (to, from, next) => {
            store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM_CONFIG, { to, from }).then(() => next())
          },
          meta: {
            manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR,
            collectionType: 'ProductCollection',
            controlBarContent: 'showroom'
          }
        },
        {
          path: 'create-order',
          name: 'assortment-details--create-order',
          component: loadComponent('AssortmentManager/CreateOrder/AssortmentManager_CreateOrder.vue'),
          beforeEnter: async (to, from, next) => {
            await store.dispatch(VUEX_OPTIONS_FETCH, 'orders')
            await store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_CREATE_ORDER, { to, from })
            next()
          },
          meta: {
            manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR,
            collectionType: 'ProductCollection',
            controlBarContent: 'createOrder'
          }
        }
      ]
    },
    // REGULAR ASSORTMENT SHOWROOM <-------------------------------------
    {
      path: 'assortment/:assortment_id/showroom',
      name: 'assortment-showroom',
      component: loadComponent('AssortmentManager/Showroom/AssortmentManager_Showroom.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM, { to, from })
        ]).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR,
        collectionType: 'ProductCollection',
        controlBarContent: 'showroom'
      }
    },
    {
      path: 'assortment/:assortment_id/showroom/divider/:group_slug',
      name: 'assortment-showroom--divider',
      component: loadComponent('AssortmentManager/Detail/AssortmentManager_GroupDivider.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DIVIDER, { to, from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR,
        collectionType: 'ProductCollection',
        controlBarContent: 'showroom',
        detailRoute: 'assortment-showroom--detail',
        dividerRoute: 'assortment-showroom--divider'
      }
    },
    {
      path: 'assortment/:assortment_id/showroom/detail/:style_color_id',
      name: 'assortment-showroom--detail',
      component: loadComponent('AssortmentManager/Detail/AssortmentManager_Detail.vue'),
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR,
        collectionType: 'ProductCollection',
        controlBarContent: 'showroom',
        detailRoute: 'assortment-showroom--detail',
        dividerRoute: 'assortment-showroom--divider'
      }
    }
  ],
  meta: productMeta
}
