import {
  VUEX_UI_EXPAND_GLOBAL_UPLOADER,
  VUEX_UI_HIDE_GLOBAL_UPLOADER,
  VUEX_UI_MINIMIZE_GLOBAL_UPLOADER,
  VUEX_UI_COLLAPSE_GLOBAL_UPLOADER
} from '@/store/constants/ui/globalUploader'

const state = {
  displayState: 'hidden' // expanded, mini, collapsed, hidden
}

const actions = {
  [VUEX_UI_EXPAND_GLOBAL_UPLOADER]: ({ commit }, delay) => {
    setTimeout(() => {
      commit(VUEX_UI_EXPAND_GLOBAL_UPLOADER)
    }, delay || 0)
  },

  [VUEX_UI_HIDE_GLOBAL_UPLOADER]: ({ commit }, delay) => {
    setTimeout(() => {
      commit(VUEX_UI_HIDE_GLOBAL_UPLOADER)
    }, delay || 0)
  },

  [VUEX_UI_MINIMIZE_GLOBAL_UPLOADER]: ({ commit }, delay) => {
    setTimeout(() => {
      commit(VUEX_UI_MINIMIZE_GLOBAL_UPLOADER)
    }, delay || 0)
  },

  [VUEX_UI_COLLAPSE_GLOBAL_UPLOADER]: ({ commit }, delay) => {
    setTimeout(() => {
      commit(VUEX_UI_COLLAPSE_GLOBAL_UPLOADER)
    }, delay || 0)
  }
}

const mutations = {
  [VUEX_UI_EXPAND_GLOBAL_UPLOADER]: (state) => {
    state.displayState = 'expanded'
  },

  [VUEX_UI_HIDE_GLOBAL_UPLOADER]: (state) => {
    state.displayState = 'hidden'
  },

  [VUEX_UI_MINIMIZE_GLOBAL_UPLOADER]: (state) => {
    state.displayState = 'mini'
  },

  [VUEX_UI_COLLAPSE_GLOBAL_UPLOADER]: (state) => {
    state.displayState = 'collapsed'
  }
}

export default {
  getters: {},
  state,
  actions,
  mutations
}
