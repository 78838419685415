import _debounce from 'lodash.debounce'
import _clone from 'lodash.clonedeep'

import {
  VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT,
  VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT_REQUEST,
  VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT_CLEAR,

  VUEX_ASSORTMENT_PRODUCTS_UPDATE_STYLE_SORT
} from '@/store/constants/models/assortments/assortmentGroupSort'

import {
  VUEX_API_ASSORTMENT_PRODUCTS_UPDATE
} from '@/store/constants/api'

const state = {
  groupSortUpdateProducts: {
    // updateProducts
  }
}

const getters = {}

const actions = {
  [VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT, payload)
    dispatch(VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT_REQUEST)
  },

  [VUEX_ASSORTMENT_PRODUCTS_UPDATE_STYLE_SORT]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENT_PRODUCTS_UPDATE_STYLE_SORT, payload)
    dispatch(VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT_REQUEST)
  },

  [VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT_REQUEST]: _debounce(async ({ rootState, state, dispatch, commit }) => {
    const productsToUpdate = _clone(state.groupSortUpdateProducts)
    commit(VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT_CLEAR)

    dispatch(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE, {
      id: rootState.assortments.assortment._id,
      data: [productsToUpdate]
    }).catch(err => {
      console.error('VUEX_API_ASSORTMENT_PRODUCTS_UPDATE | err', err)
      return err
    })
  }, 500)
}

const mutations = {
  [VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT]: (state, data) => {
    const products = _clone(data.products)
    if (!state.groupSortUpdateProducts.updateProducts) state.groupSortUpdateProducts.updateProducts = []

    products.forEach(product => {
      state.groupSortUpdateProducts.updateProducts.push({
        style: product.style,
        color: product.color,
        properties: data.props
      })
    })
  },

  [VUEX_ASSORTMENT_PRODUCTS_UPDATE_STYLE_SORT]: (state, data) => {
    state.groupSortUpdateProducts = data
  },

  [VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT_CLEAR]: state => {
    state.groupSortUpdateProducts = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
