import router from '@/router'

import {
  VUEX_ROUTING_ROUTE
} from '@/store/constants/routing'

import {
  VUEX_SAMPLE_INVENTORY_GRID_REKEY
} from '@/store/constants/models/samples'

import {
  // Product Libraries
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_DOCUMENTS,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_PRODUCTS,

  //
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_SEASONS,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_LANDING,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_WHOLESALE,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_WHOLESALE,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_RETAIL,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_RETAIL,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DIVIDER,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DETAIL,

  // Product Libraries Manage
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PENDING,
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PUBLISHED,
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UNPUBLISHED,
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UPLOAD,
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_DELETED,

  // Sample Orders
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_PRODUCTION,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_SALES,

  // Reporting - Assortment and User
  VUEX_ROUTING_ENTER_ASSORTMENT_REPORTING,

  // Seasons Management
  VUEX_ROUTING_ENTER_ASSORTMENT_SEASONS_MANAGER,

  // Assortment Manager - REGULAR
  VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER,
  VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_ALL,
  VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_ARCHIVED,
  VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_STARRED,
  VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_RECENT,
  VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_QUICKACCESS,

  // Showroom - REGULAR
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_MANAGE,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_GROUPSORT,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_EXPORT,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM_CONFIG,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_CREATE_ORDER,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DETAIL,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DIVIDER,
  VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM,

  // Assortment Manager - INTERNAL
  VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_INTERNAL,
  VUEX_ROUTING_LOADED_ASSORTMENT_MANAGER_INTERNAL,

  // Showroom - INTERNAL
  VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS,
  VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_MANAGE,
  VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_GROUPSORT,
  VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_EXPORT
} from '@/store/constants/routing/routingProduct'

import {
  VUEX_APPBAR_HIDE
} from '@/store/constants/ui/appBar'

import {
  VUEX_CONTENT_CONTAINER_SET_STYLES
} from '@/store/constants/ui/contentContainer'

import {
  VUEX_ASSORTMENTS_FETCH,
  VUEX_ASSORTMENT_FETCH
} from '@/store/constants/models/assortments'

import {
  VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD
} from '@/store//constants/ui/groupSort'

import {
  // VUEX_ASSORTMENTS_INTERNALS_DEFAULTS_SET,
  VUEX_ASSORTMENTS_INTERNALS_QUERY_CLEAR,
  VUEX_ASSORTMENTS_INTERNALS_OPTIONS_CLEAR,
  VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FETCH
} from '@/store/constants/models/assortments/assortmentsInternal'

import {
  VUEX_SEASONS_FETCH
} from '@/store/constants/models/seasons'

import {
  VUEX_LIBRARIES_SET_QUERY_PARAMS
} from '@/store/constants/models/libraries'

import {
  VUEX_CONTROLBAR_CLEAR_ALL_STYLE_DATA
} from '@/store/constants/ui/controlBar'

import {
  VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET
} from '@/store/constants/ui/filterBar/filterBar_ItemsDisplay'

import {
  VUEX_ASSETS_CLEAR
} from '@/store/constants/models/assets'

import {
  VUEX_SUBHEADER_SHOW,
  VUEX_SUBHEADER_HIDE,
  VUEX_SUBHEADER_SET_SIZE
} from '@/store/constants/ui/subheader'

import {
  VUEX_PRODUCT_DETAIL_FETCH_DATA,
  VUEX_PRODUCT_DETAIL_SET_ITEM,
  VUEX_PRODUCT_DETAIL_SET_PRODUCTS
} from '@/store/constants/ui/productDetail'

import productFootwearConfig from '@/components/_core/FilterBar/_config/dataSets/product/products/footwear'
import productDocumentsConfig from '@/components/_core/FilterBar/_config/dataSets/product/documents/documents'
import productProductsSelectors from '@/components/_core/FilterBar/_config/selectors/productProducts'

// ------------------ Dynamic Options Config ----------------
import dynamicOptions from '@/components/_core/FilterBar/_config/dynamicOptions'
import { VUEX_CATEGORY_BROWSER_UPDATE_ACTIONS } from '../../constants/ui/categoryBrowser'

function internalAssortmentRouteData (routerQuery, rootState) {
  if (!routerQuery || !rootState) return {}

  const internalAssortmentsQuery = rootState.assortmentsInternal.query

  const locationsCode = internalAssortmentsQuery['locations.code'] || routerQuery.locations?.code
  const season = internalAssortmentsQuery._uiOptions.assortmentsQuery.season || routerQuery._uiOptions?.assortmentsQuery?.season
  const year = internalAssortmentsQuery._uiOptions.assortmentsQuery.year || routerQuery._uiOptions?.assortmentsQuery?.year
  const tab = internalAssortmentsQuery._uiOptions.frontendOptions.controlBarTab || routerQuery._uiOptions?.frontendOptions?.controlBarTab || 'browse'
  const title = internalAssortmentsQuery._uiOptions.assortmentsQuery.title || routerQuery._uiOptions?.assortmentsQuery?.title

  return { locationsCode, season, year, tab, title }
}

const state = {
  assortmentManagerSelectParams: 'title subTitle state thumbnail starred method productType locationId status archived alerts modifiedDate hierarchy region gender decimalsPlaces'
}

const actions = {
  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES]: async ({ dispatch }) => {
    // dispatch(VUEX_ASSORTMENTS_INTERNALS_DEFAULTS_SET) // Breaks AssortmentManager InternalAssortment Showroom routing
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_DOCUMENTS]: async ({ dispatch }) => {
    await dispatch(VUEX_ASSETS_CLEAR)

    await dispatch(VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET, '-files.modifiedDate')

    dispatch(VUEX_SUBHEADER_SHOW, {
      content: '_core/FilterBar/FilterBar.vue',
      props: {
        dataSet: productDocumentsConfig,
        constructFromQuery: true,
        dynamicOptionsData: dynamicOptions.product.documents,
        actions: [
          'share'
        ]
      }
    })
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_PRODUCTS]: async ({ dispatch }) => {
    await dispatch(VUEX_ASSETS_CLEAR)

    await dispatch(VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET, '-createdDate')

    await dispatch(VUEX_SEASONS_FETCH, {
      channel: 'Wholesale'
    })

    dispatch(VUEX_SUBHEADER_SHOW, {
      content: '_core/FilterBar/FilterBar.vue',
      props: {
        dataSet: productFootwearConfig,
        constructFromQuery: true,
        dynamicOptionsData: dynamicOptions.product.products,
        selectors: productProductsSelectors,
        actions: [
          'share',
          'download'
        ]
      }
    })
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_SEASONS]: async ({ dispatch }, payload) => {
    dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })
    dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    dispatch(VUEX_SUBHEADER_HIDE, { content: null })

    await dispatch(VUEX_SEASONS_FETCH, {
      channel: payload.meta.internalChannelType
    })

    await dispatch(VUEX_ASSORTMENTS_INTERNALS_OPTIONS_CLEAR, { clearAssortmentQuery: true })
    await dispatch(VUEX_ASSORTMENTS_INTERNALS_QUERY_CLEAR)
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_LANDING]: async ({ rootState, dispatch }, payload) => {
    dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })
    dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    dispatch(VUEX_SUBHEADER_HIDE, { content: null })

    const routerQuery = payload.to.query
    const internalAssortmentData = internalAssortmentRouteData(routerQuery, rootState)

    if (!internalAssortmentData.season || !internalAssortmentData.year /* || !internalAssortmentData.locationsCode */) {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--seasons--wholesale'
      })
      return
    }

    await dispatch(VUEX_CATEGORY_BROWSER_UPDATE_ACTIONS, { showUnpublished: false })
    dispatch(VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FETCH)
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_WHOLESALE]: async ({ rootState, dispatch }, payload) => {
    dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })

    const routerQuery = payload.to.query
    const internalAssortmentData = internalAssortmentRouteData(routerQuery, rootState)

    if (!internalAssortmentData.season || !internalAssortmentData.year /* || !internalAssortmentData.locationsCode */) {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--seasons--wholesale'
      })
      return
    }

    if (!internalAssortmentData.title && internalAssortmentData.tab === 'browse') {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--landing--wholesale'
      })
      return
    }

    dispatch(VUEX_APPBAR_HIDE)
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_WHOLESALE]: async ({ rootState, dispatch }, payload) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })

    const routerQuery = payload.to.query
    const internalAssortmentData = internalAssortmentRouteData(routerQuery, rootState)

    if (!internalAssortmentData.season || !internalAssortmentData.year || !internalAssortmentData.locationsCode) {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--seasons--wholesale'
      })
      return
    }

    if (!internalAssortmentData.title && internalAssortmentData.tab === 'browse') {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--landing--wholesale'
      })
      return
    }

    dispatch(VUEX_APPBAR_HIDE)
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_RETAIL]: async ({ rootState, dispatch }, payload) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })

    const routerQuery = payload.to.query
    const internalAssortmentData = internalAssortmentRouteData(routerQuery, rootState)

    if (!internalAssortmentData.season || !internalAssortmentData.year /* || !internalAssortmentData.locationsCode */) {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--seasons--retail'
      })
      return
    }

    if (!internalAssortmentData.title && internalAssortmentData.tab === 'browse') {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--landing--retail'
      })
      return
    }

    dispatch(VUEX_APPBAR_HIDE)
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_RETAIL]: async ({ rootState, dispatch }, payload) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })

    const routerQuery = payload.to.query
    const internalAssortmentData = internalAssortmentRouteData(routerQuery, rootState)

    if (!internalAssortmentData.season || !internalAssortmentData.year /* || !internalAssortmentData.locationsCode */) {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--seasons--retail'
      })
      return
    }

    if (!internalAssortmentData.title && internalAssortmentData.tab === 'browse') {
      dispatch(VUEX_ROUTING_ROUTE, {
        name: 'product--libraries--assortments-internal--landing--retail'
      })
      return
    }

    dispatch(VUEX_APPBAR_HIDE)
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DIVIDER]: async ({ rootState, commit, dispatch }, payload) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })
    commit(VUEX_PRODUCT_DETAIL_SET_PRODUCTS, rootState.assortmentProducts.productsWithGroups)

    // Do divider stuff here
    const groupSlug = payload.to.params.group_slug
    const item = rootState.productDetail.products.find(g => g.groupSlug === groupSlug)

    commit(VUEX_PRODUCT_DETAIL_SET_ITEM, item)
    dispatch(VUEX_APPBAR_HIDE)
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DETAIL]: async ({ dispatch }, payload) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })

    const [style, color] = payload.to.params.style_color_id.split('_')
    dispatch(VUEX_PRODUCT_DETAIL_FETCH_DATA, {
      type: ITS__ASSORTMENTS__ORG_TYPE__INTERNAL,
      style,
      color
    })

    dispatch(VUEX_APPBAR_HIDE)
  },

  /***************************/
  /***************************/

  /***************************/
  // Product Manage Routes
  /***************************/
  [VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PENDING]: async ({ dispatch }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Pending'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PUBLISHED]: async ({ dispatch }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Published'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UNPUBLISHED]: async ({ dispatch }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Unpublished'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_PRODUCT_MANAGE_DELETED]: async ({ dispatch }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Deleted'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UPLOAD]: ({ dispatch }) => {
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  /***************************/
  // Sample Order Routes
  /***************************/
  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_PRODUCTION]: ({ dispatch }, payload) => {
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })

    dispatch(VUEX_SAMPLE_INVENTORY_GRID_REKEY)
  },

  [VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_SALES]: ({ dispatch }, payload) => {
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  /***************************/
  // Assortment Manager Routes
  /***************************/
  [VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER]: ({ dispatch }, payload) => {
    //
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_ALL]: ({ dispatch }) => {
    dispatch(VUEX_ASSORTMENTS_FETCH, {
      params: {
        _select: state.assortmentManagerSelectParams,
        state: ITS__ASSORTMENTS__STATE__PUBLISHED
      }
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_ARCHIVED]: ({ dispatch }) => {
    dispatch(VUEX_ASSORTMENTS_FETCH, {
      params: {
        _select: state.assortmentManagerSelectParams,
        state: ITS__ASSORTMENTS__STATE__ARCHIVED
      }
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_STARRED]: ({ dispatch }) => {
    dispatch(VUEX_ASSORTMENTS_FETCH, {
      params: {
        _select: state.assortmentManagerSelectParams,
        starred: true,
        state: ITS__ASSORTMENTS__STATE__PUBLISHED
      }
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_RECENT]: ({ dispatch }) => {
    dispatch(VUEX_ASSORTMENTS_FETCH, {
      params: {
        _select: state.assortmentManagerSelectParams,
        _options: {
          limit: 20,
          sort: {
            modifiedDate: -1
          }
        },
        state: ITS__ASSORTMENTS__STATE__PUBLISHED
      }
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_QUICKACCESS]: ({ dispatch }) => {
    dispatch(VUEX_ASSORTMENTS_FETCH, {
      params: {},
      tempRouterOptions: {
        numAssortments: 2,
        // starred
        paramsAssortment1: {
          _select: state.assortmentManagerSelectParams,
          _options: {
            sort: {
              title: 1
            }
          },
          starred: true,
          state: ITS__ASSORTMENTS__STATE__PUBLISHED
        },
        // recent
        paramsAssortment2: {
          _select: state.assortmentManagerSelectParams,
          _options: {
            limit: 20,
            sort: {
              modifiedDate: -1
            }
          },
          state: ITS__ASSORTMENTS__STATE__PUBLISHED
        }
      }
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_REPORTING]: async ({ dispatch }, payload) => {
    await dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    await dispatch(VUEX_SUBHEADER_HIDE, { content: null })

    dispatch(VUEX_SEASONS_FETCH, {
      channel: 'Wholesale',
      subRoute: 'manage'
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_SEASONS_MANAGER]: async ({ dispatch }, payload) => {
    // Fetch Seasons data
    await dispatch(VUEX_SEASONS_FETCH, {
      channel: payload.meta.internalChannelType,
      subRoute: 'manage'
    })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_INTERNAL]: async ({ dispatch }, payload) => {
    await dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })

    dispatch(VUEX_SEASONS_FETCH, {
      channel: payload.meta.internalChannelType,
      subRoute: 'manage'
    })
  },

  [VUEX_ROUTING_LOADED_ASSORTMENT_MANAGER_INTERNAL]: ({ dispatch }, payload) => {
    dispatch(VUEX_ASSORTMENTS_FETCH, {
      ...payload,
      params: {
        _select: state.assortmentManagerSelectParams,
        state: ITS__ASSORTMENTS__STATE__PUBLISHED,
        isInternalAssortments: true
      }
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_CONTROLBAR_CLEAR_ALL_STYLE_DATA)

    // REMOVED ASSORTMEMT FETCH REQUEST | Moved to another location by Gabriel

    // Parent route fetches seasons
    await dispatch(VUEX_SEASONS_FETCH, {
      channel: 'Wholesale'
    })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__4,
      content: 'AssortmentManager/AssortmentManager_ControlBar.vue',
      closeButton: {
        visible: true
      }
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_MANAGE]: ({ dispatch }) => {
    dispatch(VUEX_ASSORTMENT_FETCH, {
      // id: payload.to.params.assortment_id,
      id: router.currentRoute.value.params.assortment_id,
      tempRouterOptions: {
        fromRouting: true
      },
      params: {}
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_GROUPSORT]: async ({ dispatch }, payload) => {
    await dispatch(VUEX_ASSORTMENT_FETCH, {
      id: payload.to.params.assortment_id,
      tempRouterOptions: {
        fromRouting: true
      },
      params: {}
    })

    dispatch(VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD)
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_EXPORT]: ({ rootState, dispatch }, payload) => {
    let assortment = rootState.assortments.assortment

    if (payload.to.params.assortment_id !== assortment._id) {
      dispatch(VUEX_ASSORTMENT_FETCH, {
        id: payload.to.params.assortment_id,
        tempRouterOptions: {
          fromRouting: true
        },
        params: {}
      })
    }
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM_CONFIG]: ({ rootState, dispatch }, payload) => {
    let assortment = rootState.assortments.assortment

    if (payload.to.params.assortment_id !== assortment._id) {
      dispatch(VUEX_ASSORTMENT_FETCH, {
        id: payload.to.params.assortment_id,
        tempRouterOptions: {
          fromRouting: true
        },
        params: {}
      })
    }
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_CREATE_ORDER]: ({ rootState, dispatch }, payload) => {
    let assortment = rootState.assortments.assortment

    if (payload.to.params.assortment_id !== assortment._id) {
      dispatch(VUEX_ASSORTMENT_FETCH, {
        id: payload.to.params.assortment_id,
        tempRouterOptions: {
          fromRouting: true
        },
        params: {}
      })
    }
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DIVIDER]: async ({ rootState, dispatch, commit }, payload) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })
    await commit(VUEX_PRODUCT_DETAIL_SET_PRODUCTS, rootState.assortmentProducts.productsWithGroups)

    // Get Divider data
    const groupSlug = payload.to.params.group_slug
    const item = rootState.productDetail.products.find(g => g.groupSlug === groupSlug)

    await commit(VUEX_PRODUCT_DETAIL_SET_ITEM, item)
    dispatch(VUEX_APPBAR_HIDE)
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DETAIL]: async ({ rootState, dispatch }, payload) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })

    const [style, color] = payload.to.params.style_color_id.split('_')
    const type = rootState.controlBar.currentTab === 'search' ? 'staticShowroomSearch' : 'staticShowroomBrowse'

    let assortment = rootState.assortments.assortment
    if (payload.to.params.assortment_id !== assortment._id || !assortment) {
      return dispatch(VUEX_ROUTING_ROUTE, {
        name: 'assortment-showroom',
        params: { assortment_id: payload.to.params.assortment_id }
      })
    }

    // TODO: Should we have the router make requests
    dispatch(VUEX_PRODUCT_DETAIL_FETCH_DATA, {
      type,
      style,
      color
    })

    dispatch(VUEX_APPBAR_HIDE)
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM]: async ({ rootState, dispatch }, payload) => {
    await dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, { padding: 0 })

    await dispatch(VUEX_SEASONS_FETCH, {
      channel: 'Wholesale'
    })

    let assortment = rootState.assortments.assortment
    if (payload.to.params.assortment_id !== assortment._id) {
      await dispatch(VUEX_ASSORTMENT_FETCH, {
        id: payload.to.params.assortment_id,
        tempRouterOptions: {
          fromRouting: true
        },
        params: {}
      })
    }

    dispatch(VUEX_APPBAR_HIDE)
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_CONTROLBAR_CLEAR_ALL_STYLE_DATA)

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__4,
      content: 'AssortmentManager/AssortmentManager_ControlBar.vue',
      closeButton: {
        visible: true
      }
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_MANAGE]: async ({ dispatch, rootState }, payload) => {
    await dispatch(VUEX_ASSORTMENT_FETCH, {
      id: router.currentRoute.value.params.assortment_id,
      orgType: ITS__ASSORTMENTS__ORG_TYPE__INTERNAL,
      tempRouterOptions: {
        fromRouting: true
      },
      params: {}
    })

    await dispatch(VUEX_SEASONS_FETCH, {
      channel: rootState.assortments.assortment.channel,
      subRoute: 'manage'
    })
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_GROUPSORT]: async ({ dispatch }, payload) => {
    await dispatch(VUEX_ASSORTMENT_FETCH, {
      id: payload.to.params.assortment_id,
      orgType: ITS__ASSORTMENTS__ORG_TYPE__INTERNAL,
      tempRouterOptions: {
        fromRouting: true
      },
      params: {}
    })

    dispatch(VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD)
  },

  [VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_EXPORT]: async ({ rootState, dispatch }, payload) => {
    let assortment = rootState.assortments.assortment

    if (payload.to.params.assortment_id !== assortment._id) {
      await dispatch(VUEX_ASSORTMENT_FETCH, {
        id: payload.to.params.assortment_id,
        orgType: ITS__ASSORTMENTS__ORG_TYPE__INTERNAL,
        tempRouterOptions: {
          fromRouting: true
        },
        params: {}
      })
    }
  }
}

const mutations = {}

export default {
  state,
  actions,
  mutations
}
