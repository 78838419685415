import feConstants from '@/store/constants/config/fe_constants'

export default {
  advertising: {
    assets: {
      libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET,
      optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__ADVERTISING_LIBRARIES__ASSETS,
      exclude: [
        'id',
        'name',
        'modifiedDate__gtd'
      ],
      include: [
        'assetType',
        'category',
        'gender',
        'tag',
        'season',
        'year'
      ]
    },
    logos: {
      libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO,
      optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__ADVERTISING_LIBRARIES__LOGOS,
      exclude: [
        'id',
        'name',
        'modifiedDate__gtd'
      ],
      include: [
        'category',
        'gender'
      ]
    }
  },

  product: {
    documents: {
      libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT,
      optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__PRODUCT_LIBRARIES__DOCUMENTS,
      exclude: [
        'id',
        'name',
        'description',
        'modifiedDate__gtd'
      ],
      include: [
        'divisionId',
        'docType',
        'gender',
        'region'
      ]
    },

    products: {
      libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT,
      optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__PRODUCT_LIBRARIES__PRODUCTS,
      exclude: [
        'id',
        'name',
        'description',
        'keyInitiative',
        'locations.lastProdDate',
        'availabilityDate'
      ],
      include: [
        'division',
        'gender',
        'components.color1',
        'components.material1',
        'components.color2',
        'components.material2',
        'components.color3',
        'components.material3',
        'features',
        'categories.type',
        'categories.shape',
        'categories.height',
        'categories.closure',
        'categories.sport',
        'categories.lighted',
        'categories.sport',
        'categories.lighted',
        'categories.vegan',
        'categories.width'
      ]
    }
  },

  globalRetail: {
    assets: {
      libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
      librarySubType: feConstants.ITS__LIBRARIES__SUB_TYPE__VISUAL_ASSET,
      optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__GLOBAL_RETAIL__ASSETS,
      exclude: [
        'name',
        'modifiedDate__gtd'
      ],
      include: [
        'initiative',
        'dimensions',
        'element',
        'season',
        'year',
        'lang',
        'category',
        'gender',
        'entity',
        'storeType'
      ]
    },

    resources: {
      libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
      librarySubType: feConstants.ITS__LIBRARIES__SUB_TYPE__RESOURCE,
      optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__GLOBAL_RETAIL__RESOURCES,
      exclude: [
        'keyword',
        'modifiedDate__gtd'
      ],
      include: [
        'assetType',
        'assetSubType',
        'initiative',
        'season',
        'year',
        'lang',
        'category',
        'gender',
        'entity',
        'storeType'
      ]
    }
  },

  internalAssortments: {
    controlBar: {
      searchAll: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT,
        optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__ASSORTMENTS__PRODUCTS,
        exclude: [
          'id',
          'name',
          'description',
          'dueDate',
          'locations.lastProdDate',
          'availabilityDate'
        ],
        include: [
          'boughtStatus',
          'category',
          'gender',
          'pillar',
          'lifeCycle',
          'samples',
          'components.color1',
          'components.material1',
          'components.color2',
          'components.material2',
          'components.color3',
          'components.material3',
          'features',
          'categories.type',
          'categories.shape',
          'categories.height',
          'categories.closure',
          'categories.sport',
          'categories.lighted',
          'categories.sport',
          'categories.lighted',
          'categories.vegan',
          'categories.width'
        ]
      }
    }
  },

  assortmentManager: {
    controlBar: {
      staticAssortment: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT,
        optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__ASSORTMENTS__PRODUCTS,
        exclude: [
          'id',
          'name',
          'description',
          'boughtStatus',
          'locations.lastProdDate',
          'availabilityDate',
          'keyInitiative'
        ],
        include: [
          'gender',
          'category',
          'samples',
          'components.color1',
          'components.material1',
          'components.color2',
          'components.material2',
          'components.color3',
          'components.material3',
          'features',
          'categories.type',
          'categories.shape',
          'categories.height',
          'categories.closure',
          'categories.sport',
          'categories.lighted',
          'categories.sport',
          'categories.lighted',
          'categories.vegan',
          'categories.width'
        ]
      },

      dynamicAssortment: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT,
        optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__ASSORTMENTS__PRODUCTS,
        exclude: [
          'id',
          'name',
          'description',
          'keyInitiative',
          'locations.lastProdDate',
          'availabilityDate',
          'modifiedDate__gtd'
        ],
        include: [
          'components.color1',
          'components.material1',
          'components.color2',
          'components.material2',
          'components.color3',
          'components.material3',
          'features',
          'categories.type',
          'categories.shape',
          'categories.height',
          'categories.closure',
          'categories.sport',
          'categories.lighted',
          'categories.sport',
          'categories.lighted',
          'categories.vegan',
          'categories.width'
        ]
      },

      showroom: {
        searchAll: {
          libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT,
          optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__ASSORTMENTS__PRODUCTS,
          exclude: [
            'id',
            'name',
            'description',
            'keyInitiative',
            'locations.lastProdDate',
            'availabilityDate',
            'modifiedDate__gtd'
          ],
          include: [
            'components.color1',
            'components.material1',
            'components.color2',
            'components.material2',
            'components.color3',
            'components.material3',
            'features',
            'categories.type',
            'categories.shape',
            'categories.height',
            'categories.closure',
            'categories.sport',
            'categories.lighted',
            'categories.sport',
            'categories.lighted',
            'categories.vegan',
            'categories.width'
          ]
        }
      }
    }
  },

  orders: {
    controlBar: {
      promoOrder: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT,
        optionsType: feConstants.ITS__DYNAMIC_OPTIONS__TYPE__ORDERS__PRODUCTS,
        exclude: [
          'id',
          'name',
          'description',
          'boughtStatus',
          'locations.lastProdDate',
          'availabilityDate',
          'keyInitiative'
        ],
        include: [
          'gender',
          'category',
          'samples',
          'components.color1',
          'components.material1',
          'components.color2',
          'components.material2',
          'components.color3',
          'components.material3',
          'features',
          'categories.type',
          'categories.shape',
          'categories.height',
          'categories.closure',
          'categories.sport',
          'categories.lighted',
          'categories.sport',
          'categories.lighted',
          'categories.vegan',
          'categories.width'
        ]
      }
    }
  }
}
