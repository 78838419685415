import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS,
  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS_LANDING,
  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES,
  VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES_LANDING
} from '@/store/constants/routing/routingGlobalRetail'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { globalRetailMeta } from '@/router/routeMeta'

export default {
  path: '/global-retail/libraries',
  // name: 'internationalRetail',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      callback: () => { store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()) }
    }),
  children: [
    {
      path: '',
      name: 'global-retail--default',
      redirect: { name: 'global-retail--libraries--assets' }
    },
    {
      path: 'assets',
      name: 'global-retail--libraries--assets',
      component: loadComponent('GlobalRetail/Assets/GlobalRetail_Assets.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        librarySubType: feConstants.ITS__LIBRARIES__SUB_TYPE__VISUAL_ASSET
      }
    },
    {
      path: 'assets-landing',
      name: 'global-retail--libraries--assets-landing',
      component: loadComponent('GlobalRetail/Assets/GlobalRetail_AssetsLanding.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS_LANDING, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        librarySubType: feConstants.ITS__LIBRARIES__SUB_TYPE__VISUAL_ASSET
      }
    },
    {
      path: 'resources',
      name: 'global-retail--libraries--resources',
      component: loadComponent('GlobalRetail/Resources/GlobalRetail_Resources.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        librarySubType: feConstants.ITS__LIBRARIES__SUB_TYPE__RESOURCE
      }
    },
    {
      path: 'resources-landing',
      name: 'global-retail--libraries--resources-landing',
      component: loadComponent('GlobalRetail/Resources/GlobalRetail_ResourcesLanding.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES_LANDING, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        librarySubType: feConstants.ITS__LIBRARIES__SUB_TYPE__RESOURCE
      }
    }
  ],
  meta: globalRetailMeta
}
