// import Vue from 'vue'

import _merge from 'lodash.merge'
import _set from 'lodash.set'
import _get from 'lodash.get'
import _unset from 'lodash.unset'
import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash.clonedeep'
import dotize from 'dotize'

import { regionToLocation } from '@/helpers/assortmentDataModifiers'
import { removeEmptyElements } from '@/helpers/objectModifiers'

import {
  VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FETCH,
  VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_SUCCESS,
  VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_CLEAR,

  VUEX_ASSORTMENTS_INTERNALS_DEFAULTS_SET,

  VUEX_ASSORTMENTS_INTERNALS_QUERY_SET,
  VUEX_ASSORTMENTS_INTERNALS_QUERY_CLEAR,

  VUEX_ASSORTMENTS_INTERNALS_OPTIONS_CLEAR,
  VUEX_ASSORTMENTS_INTERNALS_QUERY_UNSET,

  VUEX_ASSORTMENTS_INTERNALS_UPDATE_STATE_FROM_QUERY,

  VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_DIVISIONS_SET,
  VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_CONTENT_SET
} from '@/store/constants/models/assortments/assortmentsInternal'

import {
  VUEX_API_ASSORTMENTS_INTERNAL_TABLE_OF_CONTENTS_FETCH
} from '@/store/constants/api'

import { generateInternalAssortmentNameAlt } from '@/helpers/assortmentHelper'

const state = {
  tableOfContents: [],

  query: {
    'locations.code': null,
    'locations.lineStatus': null,
    productType: null,
    _uiOptions: {
      sort: {
        sortOrder: 1,
        assortmentIndex: 1
      },
      assortmentsQuery: {
        productType: null,
        orgType: null,
        method: null,
        channel: null,
        season: null,
        year: null,
        title: null,
        state: null,
        region: null,
        gender: null
      },
      frontendOptions: {
        controlBarTab: null
      }
    }
  },

  internalAssortmentProductDetailsQuery: {},

  sidebarSelectedDivisions: [],
  sidebarAssortmentSelectsOnly: false
}

const getters = {
  getFullinternalAssortmentsQuery: state => () => state.query,
  getKInternalAssortmentName: _ => () => generateInternalAssortmentNameAlt(),

  getInternalAssortmentProductDetailQuery: state => (style, color) => {
    let includeAssortmentsDataQuery = _cloneDeep(state.query._uiOptions.assortmentsQuery)

    delete includeAssortmentsDataQuery.title
    delete includeAssortmentsDataQuery.gender

    const query = {
      style,
      color,
      ...state.query,
      ...state.internalAssortmentProductDetailsQuery,
      _uiOptions: {
        includeAssortmentsDataQuery,
        streaming: false
      }
    }

    // Special case handling for query per region type
    switch (query._uiOptions.includeAssortmentsDataQuery.region) {
      case 'Global' :
        query._options.project['locations.availability'] = 0
        break
      default :
    }

    if (color) {
      query['color'] = color.map(colorObj => colorObj.color)
    }

    return query
  }
}

const actions = {
  [VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FETCH]: _debounce(async ({ state, commit, dispatch }, payload) => {
    let query = _cloneDeep(state.query)
    let assortmentsQuery = _cloneDeep(state.query._uiOptions.assortmentsQuery)

    let defaultPayload = {
      state: ITS__ASSORTMENTS__STATE__PUBLISHED,
      _options: {
        project: {
          gender: 1,
          title: 1,
          season: 1,
          year: 1,
          sortOrder: 1,
          method: 1,
          region: 1,
          logo: 1,
          state: 1
        }
      },
      _uiOptions: {
        sort: {
          sortOrder: 1
        }
      }
    }

    delete query._options

    let data = {
      ...query,
      ...assortmentsQuery,
      ...defaultPayload,
      ...payload?.data || {}
    }

    // delete data set from default
    delete data['gender']
    delete data['locations.code']
    delete data['locations.lineStatus']
    delete data['title']
    // delete data['productType']

    data = removeEmptyElements(data)
    delete payload?.data

    dispatch(VUEX_API_ASSORTMENTS_INTERNAL_TABLE_OF_CONTENTS_FETCH, { ...payload, data }).then(response => {
      commit(VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_SUCCESS, response.data)
    })
  }, 250),

  [VUEX_ASSORTMENTS_INTERNALS_QUERY_UNSET]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENTS_INTERNALS_QUERY_UNSET, payload)
  },

  [VUEX_ASSORTMENTS_INTERNALS_DEFAULTS_SET]: ({ rootState, commit }) => {
    const defaults = rootState.properties.data.InternalAssortments.defaults

    let data = {
      _uiOptions: {
        assortmentsQuery: {
          state: defaults.state,
          season: defaults.currentSeason,
          year: defaults.currentYear,
          region: defaults.defaultRegion
        }
      }
    }

    commit(VUEX_ASSORTMENTS_INTERNALS_DEFAULTS_SET, data)
  },

  [VUEX_ASSORTMENTS_INTERNALS_QUERY_SET]: async ({ state, commit }, payload) => {
    const data = _cloneDeep(payload)

    // Set location code based on regeion
    if (payload?._uiOptions?.assortmentsQuery?.hasOwnProperty('region')) {
      Object.assign(data, {
        'locations.code': regionToLocation(payload._uiOptions.assortmentsQuery.region)
      })
    }

    // Set lineStatus based on assortment props
    /* if (state.query._uiOptions.assortmentsQuery.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL &&
        state.query._uiOptions.assortmentsQuery.region === ITS__ENTITY__DOMESTIC &&
        state.query.productType === ITS__PRODUCT_TYPE__FOOTWEAR) {
      Object.assign(data, {
        'locations.lineStatus': 'A'
      })
    } */

    if (state.query._uiOptions.assortmentsQuery.channel === ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE &&
        ((state.query._uiOptions.assortmentsQuery.region === ITS__ENTITY__DOMESTIC && state.query.productType === ITS__PRODUCT_TYPE__FOOTWEAR) || state.query.productType === ITS__PRODUCT_TYPE__POP)) {
      Object.assign(data, {
        'locations.lineStatus': 'A'
      })
    }

    await commit(VUEX_ASSORTMENTS_INTERNALS_QUERY_SET, data)
  },

  [VUEX_ASSORTMENTS_INTERNALS_QUERY_CLEAR]: ({ commit }) => {
    commit(VUEX_ASSORTMENTS_INTERNALS_QUERY_CLEAR)
  },

  [VUEX_ASSORTMENTS_INTERNALS_OPTIONS_CLEAR]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENTS_INTERNALS_OPTIONS_CLEAR, payload)
  },

  [VUEX_ASSORTMENTS_INTERNALS_UPDATE_STATE_FROM_QUERY]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENTS_INTERNALS_UPDATE_STATE_FROM_QUERY, payload.query)

    if (payload.fetchKeyInitiavesToC && !state.tableOfContents.length) {
      dispatch(VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_FETCH, payload)
    }
  },

  [VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_DIVISIONS_SET]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_DIVISIONS_SET, payload)
  },
  [VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_CONTENT_SET]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_CONTENT_SET, payload)
  }
}

// Mutations //
const mutations = {
  [VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_SUCCESS]: (state, data) => {
    state.tableOfContents = data
  },

  [VUEX_ASSORTMENTS_INTERNALS_TABLE_OF_CONTENTS_CLEAR]: (state) => {
    state.tableOfContents = []
  },

  [VUEX_ASSORTMENTS_INTERNALS_DEFAULTS_SET]: (state, data) => {
    Object.assign(state.query, data)
    // if (data.query) Object.assign(state.query, data.query)
    // if (data.options) Object.assign(state.query._options, data.options)
  },

  [VUEX_ASSORTMENTS_INTERNALS_QUERY_SET]: (state, data) => {
    if (data.clearCurrentQuery) state.query = {}

    // Vue.set for reactivity
    if (data?._uiOptions?.assortmentsQuery) {
      Object.keys(data._uiOptions.assortmentsQuery).forEach(key => {
        state.query._uiOptions.assortmentsQuery[key] = data._uiOptions.assortmentsQuery[key]
        // Vue.set(state.query._uiOptions.assortmentsQuery, key, data._uiOptions.assortmentsQuery[key])
      })

      delete data._uiOptions.assortmentsQuery // remove from data to avoid double assign
    }

    // Vue.set for reactivity
    if (data?._uiOptions?.frontendOptions) {
      Object.keys(data._uiOptions.frontendOptions).forEach(key => {
        state.query._uiOptions.frontendOptions[key] = data._uiOptions.frontendOptions[key]
        // Vue.set(state.query._uiOptions.frontendOptions, key, data._uiOptions.frontendOptions[key])
      })

      delete data._uiOptions.frontendOptions // remove from data to avoid double assign
    }

    Object.assign(state.query, _merge(state.query, data))
  },

  [VUEX_ASSORTMENTS_INTERNALS_QUERY_CLEAR]: state => {
    Object.keys(state.query).forEach(key => {
      if (key !== '_uiOptions' && key !== '_options') delete state.query[key]
    })
  },

  [VUEX_ASSORTMENTS_INTERNALS_QUERY_UNSET]: (state, data) => {
    _unset(state.query, data)
  },

  [VUEX_ASSORTMENTS_INTERNALS_OPTIONS_CLEAR]: (state, data) => {
    if (!data || Object.keys(data).length === 0) state.query._uiOptions = {}
    if (data?.clearAssortmentQuery) state.query._uiOptions.assortmentsQuery = {}
  },

  [VUEX_ASSORTMENTS_INTERNALS_UPDATE_STATE_FROM_QUERY]: (state, data) => {
    // Convert dot notation strings to objects
    let dotized = dotize.convert(data)

    // Bail if not properties exist in query
    if (!Object.keys(dotized).length) return

    // Loop over props to add to state.query
    Object.keys(dotized).forEach(async key => {
      // Define value to be stored later
      let value

      // Modify prop value
      switch (typeof dotized[key]) {
        // If string - string or number (0 or 1 for project)
        case 'string' :
          // If 1 or 0 convert into int else decode string for spaces and special chars
          value = (dotized[key] === '1' || dotized[key] === '0')
            ? parseInt(dotized[key])
            : decodeURIComponent(dotized[key])
          break
        // Otherwise store as is (for bools)
        default :
          value = dotized[key]
      }

      // Check if top level on state.query has prop to be assigned
      if (state.query.hasOwnProperty(key)) {
        // If prop exists - add
        _set(state.query, key, value)
      } else {
        // Loop through state.query and look for mix of nested objects and
        // dot notated props. This is used to set specific props that have dot notation
        // so we don't convert them into nested object, ie 'query._options.project.locations.lineStatus'

        // Split dot notated keys into array
        const keyArr = key.split('.')

        // Loop through array to find where dot notated props begin
        /*
        * This is the major chunk of functionality. This algo splits dot notation strings and trys to match
        * the object with state. If it matches, save the data to state -- if not, it splits the string
        * at the next dot (.) and trys again until the prop is found.
        *
        * Example: [_options]['project.locations.lineStatus'] (NOT FOUND) --> [_options.project]['locations.lineStatus'] (FOUND)
        *
        * It keeps moving until the prop is found
        */
        let idx = 1
        while (idx < keyArr.length) {
          // Left side props (ie, _options.project)
          let keyLeft = [...keyArr].slice(0, idx).join('.')
          // Right side props (ie, locations.lineStatus)
          let keyRight = [...keyArr].splice(idx, keyArr.length - 1).join('.')

          // Is array item without nested dot notation
          if (/(\[[0-9]]?)/.test(keyRight) && keyRight.split('.').length === 1) {
            // Get key without array brackets
            const arrKeyLeft = keyRight.split(/(\[[0-9]]?)/)[0]
            // Get array index - int inside brackets
            const arrKeyRight = keyRight.split(/(\[[0-9]]?)/)[1].match(/([0-9])/)[1]

            // Assign keyRight as new formatted array key
            keyRight = `${arrKeyLeft}.${arrKeyRight}`
          }

          // Get base object with dynamic key
          let baseObj = _get(state.query, keyLeft)

          if (!baseObj) return

          // Validate if object exist as dot notated prop
          let valid = !!baseObj.hasOwnProperty(keyRight)

          // If exists OR key includes 'assortmentsQuery` - add
          // 'assortmentsQuery is initialize empty so theres nothing to validate (can we validate with null values????)
          if (valid /* || keyLeft.includes('assortmentsQuery') */) {
            // Set in state.query
            _set(baseObj, keyRight, value)
            // Return to abort loop
            return
          }

          // Index to progressing position of baseObj
          idx++
        }
      }
    })

    state.query = JSON.parse(JSON.stringify(state.query, (key, value) => {
      return (value === null ? undefined : value)
    }))
  },

  [VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_DIVISIONS_SET]: (state, data) => {
    state.sidebarSelectedDivisions = data
  },
  [VUEX_ASSORTMENTS_INTERNALS_SIDEBAR_SELECTED_CONTENT_SET]: (state, data) => {
    state.sidebarAssortmentSelectsOnly = data
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
