import axios from 'axios'
import api from '@/api'

import {
  VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS,
  VUEX_API_ASSIGNMENTS_REQUEST_CREATE_COORDINATOR,
  VUEX_API_ASSIGNMENTS_REQUEST_UPDATE_COORDINATOR,
  VUEX_API_ASSIGNMENTS_REQUEST_DELETE_ASSIGNMENT,

  VUEX_API_ASSIGNMENTS_REQUEST_FETCH_SUCCESS,
  VUEX_API_ASSIGNMENTS_REQUEST_FETCH_FAILED,

  VUEX_API_ASSIGNMENT_PATCH_REQUEST_STATUS,

  VUEX_API_ASSIGNMENTS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  assignmentsRequest: null,
  assignmentsCancelRequest: null,

  assignmentPatchRequests: []
}

const getters = {}

const actions = {
  [VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS]: async ({ commit, dispatch }) => {
    // if (!payload) payload = {}

    await commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS)

    if (state.VUEX_API_ASSIGNMENTS_SET_CANCEL_TOKEN) state.VUEX_API_ASSIGNMENTS_SET_CANCEL_TOKEN.cancel()
    await commit(VUEX_API_ASSIGNMENTS_SET_CANCEL_TOKEN, 'assignmentsCancelRequest')

    return api.get(`coordinatorAssignments`, {
      cancelToken: state.assignmentsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_SUCCESS)
      return response.data
    }).catch((err) => {
      commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ASSIGNMENTS_REQUEST_FETCH | Cannot load '/assignmentType' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ASSIGNMENTS_REQUEST_CREATE_COORDINATOR]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}

    const { data } = payload

    await commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS)

    return api.post(`coordinatorAssignments/`, data, {
    }).then(response => {
      commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_SUCCESS)
      return response.data
    }).catch((err) => {
      commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ASSIGNMENTS_REQUEST_CREATE_COORDINATOR | Cannot load '/assignmentType' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ASSIGNMENTS_REQUEST_UPDATE_COORDINATOR]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}

    const { id, data } = payload

    await commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS)

    return api.patch(`coordinatorAssignments/${id}`, data, {
    }).then(response => {
      commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_SUCCESS)
      return response.data
    }).catch((err) => {
      commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ASSIGNMENTS_REQUEST_UPDATE_COORDINATOR | Cannot load '/assignmentType' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ASSIGNMENTS_REQUEST_DELETE_ASSIGNMENT]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}

    const { id } = payload

    await commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS)

    return api.delete(`coordinatorAssignments/${id}`, {
    }).then(response => {
      commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_SUCCESS)
      return response.data
    }).catch((err) => {
      commit(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ASSIGNMENTS_REQUEST_DELETE_ASSIGNMENT | Cannot load '/assignmentType' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  }
}

const mutations = {
  [VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS]: state => {
    state.assignmentsRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_ASSIGNMENTS_REQUEST_FETCH_SUCCESS]: state => {
    state.assignmentsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_ASSIGNMENTS_REQUEST_FETCH_FAILED]: state => {
    state.assignmentsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUEX_API_ASSIGNMENT_PATCH_REQUEST_STATUS]: (state, { id, status }) => {
   state.assignmentPatchRequests[id] = status
  },

  // Set Cancelation token
  [VUEX_API_ASSIGNMENTS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
