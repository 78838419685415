import _clone from 'lodash.clonedeep'

let baseAvailObject = {
  label: 'Availability',
  allowMultiple: false,
  filterGroups: [
    {
      label: 'First Available',
      allowMultiple: false,
      layout: {
        type: 'columns',
        numOfCols: 1
      },
      filters: [
        {
          title: 'During',
          component: {
            type: 'date-single-select',
            placeholder: 'Select Range'
          },
          payloadType: 'object',
          sendType: 'offset', // date (default), offset
          key: 'availabilityDate',
          label: 'First Avail. Date',
          dateOptions: [
            {
              label: 'At Once',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -3
                },
                end: {
                  position: 'endOf',
                  time: 'day',
                  offset: 29
                }
              }
            },
            {
              label: 'Next 60 Days',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -3
                },
                end: {
                  position: 'endOf',
                  time: 'day',
                  offset: 59
                }
              }
            },
            {
              label: 'Next 90 Days',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -3
                },
                end: {
                  position: 'endOf',
                  time: 'day',
                  offset: 89
                }
              }
            },
            {
              label: 'Next 6 Months',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -3
                },
                end: {
                  position: 'endOf',
                  time: 'day',
                  offset: 181
                }
              }
            }
          ]
        },
        {
          payloadType: 'object',
          component: {
            type: 'date-picker'
          },
          pickerType: 'range',
          title: 'or Between',
          key: 'availabilityDate',
          label: 'First Avail. Date',
          timeSpan: 'future'
        }
      ]
    },
    {
      label: 'Last Produced',
      layout: {
        type: 'columns',
        numOfCols: 1
      },
      filters: [
        {
          title: 'During',
          component: {
            type: 'date-single-select',
            placeholder: 'Select Range'
          },
          payloadType: 'object',
          sendType: 'offset', // date (default), offset
          key: 'locations.lastProdDate',
          label: 'First Prod. Date',
          dateOptions: [
            {
              label: 'Last 30 Days',
              dates: {
                begin: {
                  position: 'endOf',
                  time: 'day',
                  offset: -30
                },
                end: {
                  position: 'startOf',
                  time: 'day',
                  offset: 0
                }
              }
            },
            {
              label: 'Last 90 Days',
              dates: {
                begin: {
                  position: 'endOf',
                  time: 'day',
                  offset: -90
                },
                end: {
                  position: 'startOf',
                  time: 'day',
                  offset: 0
                }
              }
            },
            {
              label: 'Last 6 Months',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -182
                },
                end: {
                  position: 'startOf',
                  time: 'day',
                  offset: 0
                }
              }
            },
            {
              label: 'Last Year',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -366
                },
                end: {
                  position: 'startOf',
                  time: 'day',
                  offset: 0
                }
              }
            }
          ]
        },
        {
          payloadType: 'object',
          component: {
            type: 'date-picker'
          },
          pickerType: 'range',
          title: 'or Between',
          key: 'locations.lastProdDate',
          label: 'First Prod. Date',
          timeSpan: 'past'
        }
      ]
    }
  ]
}

// ***********************************
// ProductType specific configs
// ***********************************
const sizeAvailFooterFootwear = {
  layout: {
    requireSubmit: true,
    footer: {
      field: {
        label: 'For an individual size (optional)',
        toUpperCase: true,
        placeholder: '9H',
        mask: 'XXXXX',
        key: 'size'
      }
    }
  },

  datePicker: {
    useExternalSubmit: true
  }
}

const sizeAvailFooterApparel = {
  layout: {
    requireSubmit: true,
    footer: {
      field: {
        label: 'For an individual size (optional)',
        toUpperCase: true,
        placeholder: 'M',
        mask: 'XXXXX',
        key: 'size'
      }
    }
  },

  datePicker: {
    useExternalSubmit: true
  }
}

/* const sizeAvailFooterPOP = {
  leyout: {
    requireSubmit: true,
    footer: {
      field: {
        label: 'For an individual size (optional)',
        toUpperCase: true,
        placeholder: 'M',
        mask: 'XXXXX',
        key: 'size'
      }
    }
  },

  datePicker: {
    useExternalSubmit: true
  }
} */
// ***********************************

export default (productType) => {
  let filterData = _clone(baseAvailObject)

  switch (productType) {
    case 'Apparel' :
      // Layout
      Object.assign(filterData.filterGroups[0].layout, sizeAvailFooterApparel.layout)
      Object.assign(filterData.filterGroups[0].filters[1].component, sizeAvailFooterApparel.datePicker)
      break
    case 'POP' :
      // Layout
      // Object.assign(filterData.filterGroups[0].layout, sizeAvailFooterPOP.layout)
      // Object.assign(filterData.filterGroups[0].filters[1].component, sizeAvailFooterPOP.datePicker)

      break
    case 'Footwear' :
    default :
      // Layout
      Object.assign(filterData.filterGroups[0].layout, sizeAvailFooterFootwear.layout)
      Object.assign(filterData.filterGroups[0].filters[1].component, sizeAvailFooterFootwear.datePicker)
      break
  }

  return filterData
}
