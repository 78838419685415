import store from '@/store/store'

import {
  VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL
} from '@/store/constants/ui/filterBar'

window.addEventListener('popstate', function (evt) {
  let path = evt.currentTarget.location.pathname

  switch (path) {
    case '/advertising/libraries/assets' :
    case '/advertising/libraries/logos' :
    case '/product/libraries/documents' :
    case '/product/libraries/products' :
    case '/global-retail/libraries/assets' :
    case '/global-retail/libraries/resources' :
      store.dispatch(VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL)
      break
  }
})
