export const VUEX_ROUTING_INIT = 'VUEX_ROUTING_INIT'

export const VUEX_ROUTING_ROUTE = 'VUEX_ROUTING_ROUTE'
export const VUEX_ROUTING_ROUTE_UPDATE = 'VUEX_ROUTING_ROUTE_UPDATE'
export const VEUX_ROUTING_ROUTE_CLEAR_QUERY = 'VEUX_ROUTING_ROUTE_CLEAR_QUERY'
export const VUEX_ROUTING_ROUTE_PREVIOUS = 'VUEX_ROUTING_ROUTE_PREVIOUS'
export const VUEX_ROUTING_ROUTE_SET_CURRENT = 'VUEX_ROUTING_ROUTE_SET_CURRENT'

export const VUEX_ROUTING_HISTORY_TREE_UPDATE = 'VUEX_ROUTING_HISTORY_TREE_UPDATE'
export const VUEX_ROUTING_HISTORY_ROUTE_BACK = 'VUEX_ROUTING_HISTORY_ROUTE_BACK'
