import _merge from 'lodash.merge'

import router from '@/router'

import {
  VUEX_NOTIFICATION_FETCH,
  VUEX_NOTIFICATIONS_FETCH,
  VUEX_NOTIFICATIONS_MARK_ALL_READ,
  VUEX_NOTIFICATIONS_UPDATE
} from '@/store/constants/models/notifications'

import {
  VUEX_API_NOTIFICATION_FETCH,
  VUEX_API_NOTIFICATIONS_FETCH,
  VUEX_API_NOTIFICATIONS_MARK_ALL_READ,
  VUEX_API_NOTIFICATIONS_BATCH_UPDATE
} from '@/store/constants/api'

const state = {
  notifications: []
}

const getters = {
  unreadFilterIcon (state) {
    const unread = state.notifications ? state.notifications.filter(n => n.state === 'New').length : 0
    return (unread > 9) ? 'filter_9_plus' : 'filter_' + unread
  },

  selectedSampleCoordinatorCodeNotifications: (_, rootGetters, rootState) => {
    const allowCoordinatorChange = router.currentRoute.value.meta.allowCoordinatorChange
    const userCode = rootState.user.user.userCode
    const selectedCoordinatorCode = rootState.orders.selectedCoordinator?.coordinatorCode
    const isSeniorSampleCoordinator = rootGetters.isSeniorSampleCoordinator().hasPerm
    const isSampleCoordinator = rootGetters.isSampleCoordinator().hasPerm

    return ((selectedCoordinatorCode && selectedCoordinatorCode !== userCode) && (isSeniorSampleCoordinator || isSampleCoordinator) && allowCoordinatorChange)
      ? { userCode: selectedCoordinatorCode || userCode }
      : null
  }
}

const actions = {
  [VUEX_NOTIFICATION_FETCH]: async ({ dispatch, commit }, payload) => {
    await dispatch(VUEX_API_NOTIFICATION_FETCH, payload).then(response => {
      commit(VUEX_NOTIFICATION_FETCH, response?.data)
    })
  },

  [VUEX_NOTIFICATIONS_FETCH]: async ({ getters, dispatch, commit }, payload) => {
    let data = payload
    data = _merge(data, getters.selectedSampleCoordinatorCodeNotifications)

    await dispatch(VUEX_API_NOTIFICATIONS_FETCH, data).then(response => {
      commit(VUEX_NOTIFICATIONS_FETCH, response?.data)
    })
  },

  [VUEX_NOTIFICATIONS_MARK_ALL_READ]: async ({ dispatch, commit }, payload) => {
    await dispatch(VUEX_API_NOTIFICATIONS_MARK_ALL_READ, payload).then(response => {
      commit(VUEX_NOTIFICATIONS_MARK_ALL_READ, response?.data)
    })
  },

  [VUEX_NOTIFICATIONS_UPDATE]: async ({ dispatch, commit }, payload) => {
    await dispatch(VUEX_API_NOTIFICATIONS_BATCH_UPDATE, payload).then(response => {
      commit(VUEX_NOTIFICATIONS_UPDATE, response?.data)
    })
  }
}

const mutations = {
  [VUEX_NOTIFICATION_FETCH]: (state, data) => {
    state.notifications = [data]
  },

  [VUEX_NOTIFICATIONS_FETCH]: (state, data) => {
    state.notifications = data
  },

  [VUEX_NOTIFICATIONS_MARK_ALL_READ]: (state, data) => {
    //
  },

  [VUEX_NOTIFICATIONS_UPDATE]: (state, data) => {
    //
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
