const state = {
  mode: null,
  category: null,
  activeIndex: 0,
  list: [],
  open: false,
  hasPrev: false,
  hasNext: false
}

const getters = {
  isMobile () {
    return window.innerWidth < 1024
  },
  hasNext (state) {
    return state.list.findIndex(i => i === state.category) < state.list.length - 1
  },

  hasPrev (state) {
    return state.list.findIndex(i => i === state.category) > 0
  },

  hasVideo (state) {
    return state.category && state.category.videoSrcs && state.category.videoSrcs.length > 0
  },

  hasCatalog (state) {
    return state.category && state.category.catalog
  }
}

const actions = {
  goTo ({ commit, state }, index) {
    let cur = state.list.findIndex(i => i === state.category)
    cur += index

    const next = state.list[cur]
    const nextMode = next.videoSrcs ? 'video' : 'catalog'

    commit('setCategory', null)

    setTimeout(() => {
      commit('setMode', nextMode)
      commit('setCategory', next)
    }, 0)
  }
}

const mutations = {
  clearDetail (state) {
    state.open = false
    state.category = null
    state.mode = null
  },

  setCategory (state, data) {
    state.open = true
    state.category = data
  },
  setMode (state, mode) {
    state.mode = mode
  },

  setList (state, list) {
    state.list = list
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
