export const VUEX_ASSORTMENT_SHOWROOM_TABLE_OF_CONTENTS_FETCH = 'VUEX_ASSORTMENT_SHOWROOM_TABLE_OF_CONTENTS_FETCH'
export const VUEX_ASSORTMENT_SHOWROOM_TABLE_OF_CONTENTS_SUCCESS = 'VUEX_ASSORTMENT_SHOWROOM_TABLE_OF_CONTENTS_SUCCESS'
export const VUEX_ASSORTMENT_SHOWROOM_TABLE_OF_CONTENTS_FAILURE = 'VUEX_ASSORTMENT_SHOWROOM_TABLE_OF_CONTENTS_FAILURE'

// export const VUEX_ASSORTMENT_SHOWROOM_DEFAULTS_SET = 'VUEX_ASSORTMENT_SHOWROOM_DEFAULTS_SET'

// export const VUEX_ASSORTMENT_SHOWROOM_QUERY_SET = 'VUEX_ASSORTMENT_SHOWROOM_QUERY_SET'
// export const VUEX_ASSORTMENT_SHOWROOM_QUERY_CLEAR = 'VUEX_ASSORTMENT_SHOWROOM_QUERY_CLEAR'

export const VUEX_ASSORTMENT_SHOWROOM_OPTIONS_SET = 'VUEX_ASSORTMENT_SHOWROOM_OPTIONS_SET'
export const VUEX_ASSORTMENT_SHOWROOM_OPTIONS_CLEAR = 'VUEX_ASSORTMENT_SHOWROOM_OPTIONS_CLEAR'
// export const VUEX_ASSORTMENT_SHOWROOM_OPTIONS_UNSET = 'VUEX_ASSORTMENT_SHOWROOM_OPTIONS_UNSET'

export const VUEX_ASSORTMENT_SHOWROOM_UPDATE_STATE_FROM_QUERY = 'VUEX_ASSORTMENT_SHOWROOM_UPDATE_STATE_FROM_QUERY'

export const VUEX_ASSORTMENT_SHOWROOM_UI_SET = 'VUEX_ASSORTMENT_SHOWROOM_UI_SET'
