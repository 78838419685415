const longWaitBlocker = {
  defaultMsg: 'Loading: Please wait...',
  timer: null,

  show: function (msg) {
    if (!msg) {
      msg = longWaitBlocker.defaultMsg
    }
    let el = document.getElementById('global-long-wait-blocker')
    el.innerHTML = '<div class="copy">' + msg + '</div>'
    el.className = 'show'
  },
  hide: function () {
    let el = document.getElementById('global-long-wait-blocker')
    el.className = ''
  }
}
export default longWaitBlocker
