// import Vue from 'vue'

import {
  VUEX_CATEGORY_BROWSER_ACTIVE_CATEGORY_UPDATE,
  VUEX_CATEGORY_BROWSER_UPDATE_ACTIONS
} from '@/store/constants/ui/categoryBrowser'

const state = {
  activeCategory: null,
  actions: {
    showUnpublished: false
  }
}

const getters = {
  //
}

const actions = {
  [VUEX_CATEGORY_BROWSER_ACTIVE_CATEGORY_UPDATE]: ({ commit }, payload) => {
    commit(VUEX_CATEGORY_BROWSER_ACTIVE_CATEGORY_UPDATE, payload)
  },

  [VUEX_CATEGORY_BROWSER_UPDATE_ACTIONS]: ({ commit }, payload) => {
    commit(VUEX_CATEGORY_BROWSER_UPDATE_ACTIONS, payload)
  }
}

const mutations = {
  [VUEX_CATEGORY_BROWSER_ACTIVE_CATEGORY_UPDATE]: (state, data) => {
    state.activeCategory = data
  },

  [VUEX_CATEGORY_BROWSER_UPDATE_ACTIONS]: (state, data) => {
    Object.entries(data).forEach(([key, value]) => {
      state.actions[key] = value
      // Vue.set(state.actions, key, value)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
