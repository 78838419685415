/**
 * detect Firefox
 *
 */
function detectFirefox () {
  let ua = window.navigator.userAgent

  let firefox = ua.indexOf('Firefox')
  if (firefox > 0) {
    return true
  }

  return false
}

function detectSafari () {
  // https://stackoverflow.com/questions/5899783/detect-safari-using-jquery
  // eslint-disable-next-line
  return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
}

function checkLocalStorage () {
  let test = 'lstest'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

// mobile device
function mobile () {
  let mobileTest = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i
  return mobileTest.test(navigator.userAgent)
}

function touch () {
  return 'ontouchstart' in window || // works on most browsers
    'onmsgesturechange' in window // works on ie10
}

// cross browser width with device pixel ratio multiplier (e.g. for retina)
function effectiveWidth () {
  let dpr = 1
  if (typeof window.devicePixelRatio !== 'undefined') dpr = window.devicePixelRatio
  let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  // http://stackoverflow.com/questions/1248081/get-the-browser-viewport-dimensions-with-javascript
  return width * dpr
}

function detectWebkitClipPath () {
  let base = 'clipPath'
  let prefixes = ['webkit', 'moz', 'ms', 'o']
  let properties = [base]
  let testElement = document.createElement('testelement')
  let attribute = 'polygon(50% 0%, 0% 100%, 100% 100%)'

  // Push the prefixed properties into the array of properties.
  for (let i = 0, l = prefixes.length; i < l; i++) {
    let prefixedProperty = prefixes[i] + base.charAt(0).toUpperCase() + base.slice(1) // remember to capitalize!
    properties.push(prefixedProperty)
  }

  // Interate over the properties and see if they pass two tests.
  for (let i = 0, l = properties.length; i < l; i++) {
    let property = properties[i]

    // First, they need to even support clip-path (IE <= 11 does not)...
    if (testElement.style[property] === '') {
      // Second, we need to see what happens when we try to create a CSS shape...
      testElement.style[property] = attribute
      if (testElement.style[property] !== '') {
        return true
      }
    }
  }

  return false
}

function ieVersion () {
  var ua = window.navigator.userAgent

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  var trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  var edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return false
}

function ios () {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

function android () {
  return /(android)/i.test(navigator.userAgent)
}

// https://stackoverflow.com/questions/23288918/check-if-user-has-webcam-or-not-using-javascript-only
// This checks for webcam by asking for permission to use it
// true, false, or undefined
function webcam (cb) {
  navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia)

  if (!navigator.getMedia) return cb() // old browsers

  navigator.getMedia({ video: true }, function () {
    // eslint-disable-next-line
    cb(true)
  }, function () {
    // eslint-disable-next-line
    cb(false)
  })
}

function isFacebook () {
  var ua = navigator.userAgent || navigator.vendor || window.opera
  return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1)
}

// http://stackoverflow.com/questions/2877393/detecting-support-for-a-given-javascript-event
let isEventSupported = (function () {
  let TAGNAMES = {
    'select': 'input',
    'change': 'input',
    'submit': 'form',
    'reset': 'form',
    'error': 'img',
    'load': 'img',
    'abort': 'img'
  }

  function isEventSupported (eventName) {
    let el = document.createElement(TAGNAMES[eventName] || 'div')
    eventName = 'on' + eventName
    let isSupported = (eventName in el)
    if (!isSupported) {
      el.setAttribute(eventName, 'return;')
      isSupported = typeof el[eventName] === 'function'
    }
    el = null
    return isSupported
  }

  return isEventSupported
})()

export default {
  detectFirefox,
  detectSafari,
  detectWebkitClipPath,
  checkLocalStorage,
  isFacebook,
  mobile,
  touch,
  effectiveWidth,
  ieVersion,
  ios,
  android,
  webcam,
  isEventSupported
}
