import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import {
  VUEX_LIBRARIES_FILE_ADD,
  VUEX_LIBRARIES_FILE_CHECK_FILENAME
} from '@/store/constants/models/libraries'
import {
  VUEX_API_S3_DOWNLOAD_SIGNED_REQUEST
} from '@/store/constants/api'
import _cloneDeep from 'lodash.clonedeep'
let PrimaryWorkingFileHelper = {
  /// /////////////////////////////////////////////////////////////////////////////////
  uploadObj: null,
  upload: async function (uploadObj) {
    PrimaryWorkingFileHelper.uploadObj = uploadObj
    let gotoStep2 = false
    await PrimaryWorkingFileHelper.uploadCheckFileName().then(response => {
      gotoStep2 = response
    })
    if (gotoStep2) {
      return PrimaryWorkingFileHelper.uploadFileStep2()
    } else {
      return 'fail-check-filename'
    }
  },
  uploadCheckFileName: async function () {
    // check if it exists
    const filenameCheckPayload = {
      filename: PrimaryWorkingFileHelper.uploadObj.finalFileName
    }

    let gotoStep2 = true
    await GridHelpers.mgThisArray[0].$store.dispatch(VUEX_LIBRARIES_FILE_CHECK_FILENAME, filenameCheckPayload).then(response => {
      // success means there is already a file name
      if (response?.data?.message === 'Success') {
        // now check and see if same asset ID.  if same, that's ok.  if different, then throw error
        if (response.data.asset?._id) {
          if (this.assetID !== response.data.asset._id) {
            gotoStep2 = false
          }
        }
      }
    })
    return gotoStep2
  },
  uploadFileStep2: async function () {
    // ADD NEW FILE
    let patch = {}
    if (PrimaryWorkingFileHelper.uploadObj.usageType === 'Primary') {
      patch.name = PrimaryWorkingFileHelper.uploadObj.finalFileName
    }

    let manageType = GridHelpers.mgThisArray[0].$route.meta.manageType

    let payload = {
      frontEnd: {
        t: this,
        cellComponentKey: PrimaryWorkingFileHelper.uploadObj.cellComponentKey,
        assetID: PrimaryWorkingFileHelper.uploadObj.assetID
      },
      addData: {
        sourceFile: 'uploads/' + manageType + '/' + PrimaryWorkingFileHelper.uploadObj.finalFileName,
        filename: PrimaryWorkingFileHelper.uploadObj.finalFileName,
        usageType: PrimaryWorkingFileHelper.uploadObj.usageType,
        addMethod: 'Upload',
        state: 'WaitingForFile',
        hero: false
      },
      deleteFiles: PrimaryWorkingFileHelper.getAllFileIDsOfUsageType(),
      patch: patch
    }
    await GridHelpers.mgThisArray[0].$store.dispatch(VUEX_LIBRARIES_FILE_ADD, payload).then(response => {

    })
    return 'success'
  },
  getAllFileIDsOfUsageType: function () {
    let ret = []
    let arr = []
    if (PrimaryWorkingFileHelper.uploadObj.assetData.files.length > 0) {
      // set primary
      arr = PrimaryWorkingFileHelper.uploadObj.assetData.files.filter(x => x['usageType'] === PrimaryWorkingFileHelper.uploadObj.usageType)
    }

    if (arr.length) {
      for (let i = 0; i < arr.length; i++) {
        ret.push({
          _id: arr[i]._id
        })
      }
    }
    return ret
  },
  uploadFileDone () {
    let manageType = GridHelpers.mgThisArray[0].$route.meta.manageType

    // NOW UPLOAD NEW FILE VIA coreFileUploader
    let data = [{
      file: PrimaryWorkingFileHelper.uploadObj.fileInputField,
      usageType: ITS__S3FUM__USAGE_TYPE__LIBRARIES_ASSET,
      filename: PrimaryWorkingFileHelper.uploadObj.finalFileName,
      libraryType: manageType
    }]
    PrimaryWorkingFileHelper.uploadObj.thisDialog.$refs.coreFileUploader.upload(data)

    setTimeout(() => {
      PrimaryWorkingFileHelper.uploadObj.thisDialog.uploadFileDone()
    }, 250)
  },

  /// /////////////////////////////////////////////////////////////////////////////////
  downloadWorkingFile: async function (params, returnBase64 = false) {
    let payload = {
      file: _cloneDeep(params.workingFile),
      returnBase64: returnBase64,
      // data for tracking stats
      statData: {
        libraryType: params.libraryType,
        assetType: params.assetType,
        assetSubType: params.assetSubType
      }
    }
    let ret = ''
    await GridHelpers.mgThisArray[0].$store.dispatch(VUEX_API_S3_DOWNLOAD_SIGNED_REQUEST, payload).then(response => {
      ret = response
    })
    return ret
  }
}
export default PrimaryWorkingFileHelper
