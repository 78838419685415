import _omit from 'lodash.omit'
import _forEach from 'lodash.foreach'
import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash.clonedeep'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import PrimaryWorkingFileHelper from '@/components/_core/GridsCore/helpers/PrimaryWorkingFileHelper'
import longWaitBlocker from '@/helpers/longWaitBlocker'
import Debug from 'logdown'

import {
  // ASSORTMENTS
  // VUEX_LIBRARIES_FETCH,
  // VUEX_LIBRARIES_FETCH_SUCCESS,
  // VUEX_LIBRARIES_FETCH_FAILURE,
  // VUEX_LIBRARIES_REFETCH,
  VUEX_LIBRARIES_SET_QUERY_PARAMS,

  VUEX_LIBRARIES_PAGED_DATA_FETCH,
  VUEX_LIBRARIES_PRIMARY_FILE_NAME_SEARCH,
  VUEX_LIBRARIES_GRID_REQUEST_CLEAR,

  VUEX_LIBRARIES_GRID_ROWS_UPDATE,
  VUEX_LIBRARIES_GRID_ROWS_STATUS_CHANGE,
  VUEX_LIBRARIES_GRID_ROWS_DELETE,
  VUEX_LIBRARIES_GRID_CONFIRMATION_ROWS_STATUS_CHANGE,

  VUEX_LIBRARIES_PRODUCTS_UPDATE,
  VUEX_LIBRARIES_FILE_DELETE,
  VUEX_LIBRARIES_FILE_ADD,
  VUEX_LIBRARIES_FILE_MERGE,
  VUEX_LIBRARIES_FILE_CHECK_FILENAME,
  VUEX_LIBRARIES_GRID_ROWDATA_LOADING_START,
  VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH,

  VUEX_LIBRARIES_REKEY
} from '@/store/constants/models/libraries'

import {
  VUEX_API_LIBRARIES_REQUEST_FETCH,
  VUEX_API_LIBRARIES_PRODUCTS_UPDATE,
  VUEX_API_LIBRARIES_PRODUCTS_DELETE,
  VUEX_API_LIBRARIES_FILE_CHECK_FILENAME
} from '@/store/constants/api'

import {
  VUEX_GRID_UPDATE_START,
  VUEX_GRID_UPDATE_SUCCESS,
  VUEX_GRID_UPDATE_FAILURE
} from '@/store/constants/ui/grid'
const d = new Debug('its:store:modules:libraries')

const state = {
  // repository assortments
  assortment: [], // array of all repository assets
  repositoryQueryParams: '',

  // API related
  agGridRequestData: {
    updateProducts: [],
    statusChangeProducts: [],
    deleteProducts: []
  },

  // AG Grid specific
  rekey: '', // if this value changes, the component will do a hard-rekey
  agGridRowData: [], // for main assortment
  agGridRowDataLoading: false, // if a new assortment or assortments request is happening
  assetFileChangedObjects: [], // keeps track of what working files changed to handle them display side
  assetFileChangedObjectsToggler: Math.random() // for deep watching
}

const getters = {}

const actions = {
  /*
  /VUEX_LIBRARIES_FETCH
  [VUEX_LIBRARIES_FETCH]: async ({ dispatch, commit }, payload) => {
    // store the parameters for later usage
    let commitPayload = {
      payload: payload
    }
    await commit(VUEX_LIBRARIES_FETCH, commitPayload)

    // now draw the page
    await dispatch(VUEX_LIBRARIES_REFETCH)
  },

  // refetch uses stored parameters to draw the page
  // this is because sometimes when rekeying a page, we just wat to ping VUEX_ASSORTMENTS_REFETCH to do a hard re-draw
  [VUEX_LIBRARIES_REFETCH]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_LIBRARIES_GRID_ROWDATA_LOADING_START)

    payload = state.repositoryQueryParams
    await dispatch(VUEX_API_LIBRARIES_REQUEST_FETCH, payload).then(response => {
      commit(VUEX_LIBRARIES_FETCH_SUCCESS, response)
      commit(VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH)
    }).catch(error => {
      commit(VUEX_LIBRARIES_FETCH_FAILURE, error)
      commit(VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH)
    })
  },
  */

  [VUEX_LIBRARIES_PAGED_DATA_FETCH]: async ({ rootState, dispatch, commit }, payload) => {
    // get page data
    let commitPayload = {
      params: payload
    }
    return dispatch(VUEX_API_LIBRARIES_REQUEST_FETCH, commitPayload).then(response => {
      commit(VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH)
      return response
    }).catch(error => {
      d.log('VUEX_API_LIBRARIES_REQUEST_FETCH error : ' + error)
      commit(VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH)
      return null
    })
  },

  [VUEX_LIBRARIES_PRIMARY_FILE_NAME_SEARCH]: async ({ rootState, dispatch, commit }, payload) => {
    // get page data
    let commitPayload = {
      params: payload.data
    }
    return dispatch(VUEX_API_LIBRARIES_REQUEST_FETCH, commitPayload).then(response => {
      return response
    }).catch(error => {
      d.log('VUEX_LIBRARIES_PRIMARY_FILE_NAME_SEARCH error : ' + error)
      return null
    })
  },

  [VUEX_LIBRARIES_GRID_ROWDATA_LOADING_START]: ({ commit }) => {
    commit(VUEX_LIBRARIES_GRID_ROWDATA_LOADING_START)
  },
  [VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH]: ({ commit }) => {
    commit(VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH)
  },

  [VUEX_LIBRARIES_SET_QUERY_PARAMS]: ({ commit }, payload) => {
    commit(VUEX_LIBRARIES_SET_QUERY_PARAMS, payload)
  },
  [VUEX_LIBRARIES_GRID_ROWS_UPDATE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_LIBRARIES_GRID_ROWS_UPDATE, payload)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.updateProducts
    let finalPayload = {
      frontEnd: {
        type: 'update'
      },
      rows: rows
    }
    dispatch(VUEX_LIBRARIES_PRODUCTS_UPDATE, finalPayload)
  },
  [VUEX_LIBRARIES_GRID_CONFIRMATION_ROWS_STATUS_CHANGE]: ({ dispatch }, payload) => {
    dispatch(VUEX_LIBRARIES_GRID_ROWS_STATUS_CHANGE, payload.params)
  },

  [VUEX_LIBRARIES_FILE_CHECK_FILENAME]: async ({ dispatch, commit }, payload) => {
    // kill all frontEnd passed keys
    let finalPayloadData = _omit(payload, ['frontEnd'])

    // lookup
    return dispatch(VUEX_API_LIBRARIES_FILE_CHECK_FILENAME, {
      data: finalPayloadData
    }).then(async response => {
      return response
    }).catch(err => {
      console.error('VUEX_LIBRARIES_FILE_CHECK_FILENAME | err', err)
      return err
    })
  },

  [VUEX_LIBRARIES_FILE_DELETE]: async ({ dispatch, commit }, payload) => {
    let finalPayload = {
      frontEnd: {
        type: 'fileDelete',
        cellComponentKey: payload.cellComponentKey
      },
      rows: [
        {
          _id: payload.assetID,
          deleteFiles: payload.fileIDs
        }
      ]
    }
    await commit(VUEX_LIBRARIES_FILE_DELETE, payload)
    dispatch(VUEX_LIBRARIES_PRODUCTS_UPDATE, finalPayload)
  },
  [VUEX_LIBRARIES_FILE_ADD]: async ({ dispatch, commit }, payload) => {
    let deleteFiles = []
    if (payload.deleteFiles) {
      deleteFiles = payload.deleteFiles
    }
    let finalPayload = {
      frontEnd: {
        type: 'fileAdd',
        t: payload.frontEnd.t,
        cellComponentKey: payload.frontEnd.cellComponentKey
      },
      rows: [
        {
          _id: payload.frontEnd.assetID,
          addFiles: [
            payload.addData
          ],
          deleteFiles: deleteFiles,
          patch: payload.patch
        }
      ]
    }
    await commit(VUEX_LIBRARIES_FILE_ADD, payload)
    await dispatch(VUEX_LIBRARIES_PRODUCTS_UPDATE, finalPayload)
  },

  [VUEX_LIBRARIES_FILE_MERGE]: async ({ dispatch, commit }, payload) => {
    let finalPayload = {
      frontEnd: {
        type: 'fileMerge',
        t: payload.frontEnd.t,
        cellComponentKey: payload.frontEnd.cellComponentKey
      },
      rows: [
        {
          _id: payload.targetAssetId,
          patch: payload.patch,
          updateFiles: []
        },
        {
          _id: payload.workingFileAssetId,
          moveFiles: [
            {
              _id: payload.workingFileFileId,
              targetAssetId: payload.targetAssetId
            }
          ]
        }
      ]
    }
    await commit(VUEX_LIBRARIES_FILE_MERGE, payload)
    await dispatch(VUEX_LIBRARIES_PRODUCTS_UPDATE, finalPayload)
  },

  [VUEX_LIBRARIES_GRID_ROWS_STATUS_CHANGE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_LIBRARIES_GRID_ROWS_STATUS_CHANGE, payload.rows)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.statusChangeProducts
    let finalPayload = {
      frontEnd: {
        type: payload.type // update, statusChange
      },
      rows: rows
    }
    longWaitBlocker.show()
    dispatch(VUEX_LIBRARIES_PRODUCTS_UPDATE, finalPayload)
  },

  [VUEX_LIBRARIES_PRODUCTS_UPDATE]: _debounce(async ({ state, dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)

    if (payload.rows) {
      // kill all frontEnd passed keys
      let finalPayloadData = []
      _forEach(payload.rows, function (obj) {
        obj = _omit(obj, ['frontEnd'])
        finalPayloadData.push(obj)
      })

      dispatch(VUEX_API_LIBRARIES_PRODUCTS_UPDATE, {
        data: finalPayloadData
      }).then(async response => {
        dispatch(VUEX_GRID_UPDATE_SUCCESS)
        await commit(VUEX_LIBRARIES_GRID_REQUEST_CLEAR)
        if (payload.frontEnd.type === 'statusChange') {
          GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })
          longWaitBlocker.hide()
          GridHelpers.deselectAll()
        } else if (payload.frontEnd.type === 'fileAdd') {
          PrimaryWorkingFileHelper.uploadFileDone()
        } else if (payload.frontEnd.type === 'fileMerge') {
          payload.frontEnd.t.saveDialogWorkingFileMergeDone()
        }
      }).catch(err => {
        dispatch(VUEX_GRID_UPDATE_FAILURE)
        longWaitBlocker.hide()

        console.error('VUEX_API_LIBRARIES_PRODUCTS_UPDATE | err', err)
        return err
      })
    } else {
      dispatch(VUEX_GRID_UPDATE_FAILURE)
      longWaitBlocker.hide()

      console.error('VUEX_API_LIBRARIES_PRODUCTS_UPDATE fail')
    }
  }, 1500),

  [VUEX_LIBRARIES_GRID_ROWS_DELETE]: _debounce(async ({ state, dispatch, commit }, payload) => {
    // get rows
    await commit(VUEX_LIBRARIES_GRID_ROWS_DELETE, payload.rows)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.deleteProducts
    longWaitBlocker.show()

    // start
    dispatch(VUEX_GRID_UPDATE_START)
    if (rows) {
      // kill all frontEnd passed keys
      let finalPayloadData = []
      // let i = 1
      _forEach(rows, function (obj) {
        /*
        obj = _omit(obj, ['frontEnd'])
        obj.i = i
        i++
         */
        let finalObj = {
          '_id': obj._id,
          'deleteAsset': true
        }
        finalPayloadData.push(finalObj)
      })

      dispatch(VUEX_API_LIBRARIES_PRODUCTS_DELETE, {
        data: finalPayloadData
      }).then(async response => {
        dispatch(VUEX_GRID_UPDATE_SUCCESS)
        await commit(VUEX_LIBRARIES_GRID_REQUEST_CLEAR)
        longWaitBlocker.hide()
        GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })
      }).catch(err => {
        dispatch(VUEX_GRID_UPDATE_FAILURE)
        longWaitBlocker.hide()
        GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })

        console.error('VUEX_API_LIBRARIES_PRODUCTS_DELETE | err', err)
        return err
      })

      /*
      _forEach(finalPayloadData, function (obj) {
        dispatch(VUEX_API_LIBRARIES_PRODUCTS_DELETE, {
          _id: obj._id
        }).then(async response => {
          // FINAL - ONLY DO ON LAST
          if (obj.i >= finalPayloadData.length) {
            dispatch(VUEX_GRID_UPDATE_SUCCESS)
            await commit(VUEX_LIBRARIES_GRID_REQUEST_CLEAR)
            longWaitBlocker.hide()
            GridHelpers.mgThisArray[0].gridApi.refreshServerSide({purge: true})
          }
        }).catch(err => {
          // FINAL - ONLY DO ON LAST
          if (obj.i >= finalPayloadData.length) {
            dispatch(VUEX_GRID_UPDATE_FAILURE)
            longWaitBlocker.hide()
            GridHelpers.mgThisArray[0].gridApi.refreshServerSide({purge: true})
          }

          console.error('VUEX_API_LIBRARIES_PRODUCTS_DELETE | err : ' + err)
        })
      })

       */
    } else {
      dispatch(VUEX_GRID_UPDATE_FAILURE)
      longWaitBlocker.hide()

      console.error('VUEX_API_LIBRARIES_PRODUCTS_DELETE fail')
    }
  }, 200),

  [VUEX_LIBRARIES_REKEY]: async ({ commit }, payload) => {
    await commit(VUEX_LIBRARIES_REKEY)
  }

}

const mutations = {
  // VUEX_LIBRARIES_FETCH
  /*
  [VUEX_LIBRARIES_FETCH]: (state, data) => {
    state.assortment = []
    state.repositoryQueryParams = payload.payload
  },
  [VUEX_LIBRARIES_FETCH_SUCCESS]: (state, data) => {
    if (payload && payload.data) {
      state.assortment = payload.data
      // TODO
      state.agGridRowData = DataMiddleware.convertAssortmentRepositoryToRowData(payload.data)
    } else {
      state.assortment = []
      state.agGridRowData = []
    }
    // rekey
    state.rekey = Math.random()
  },
  [VUEX_LIBRARIES_FETCH_FAILURE]: (state, data) => {
    console.error('VUEX_LIBRARIES_FETCH_FAILURE : ' + payload)
  },
   */

  [VUEX_LIBRARIES_GRID_ROWDATA_LOADING_START]: state => {
    state.agGridRowDataLoading = true
    state.assetFileChangedObjects = []
  },
  [VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH]: state => {
    state.agGridRowDataLoading = false
  },

  [VUEX_LIBRARIES_SET_QUERY_PARAMS]: (state, data) => {
    state.repositoryQueryParams = data.params
  },

  [VUEX_LIBRARIES_GRID_ROWS_UPDATE]: (state, data) => {
    data.map(item => {
      state.agGridRequestData.updateProducts.push(item)
    })
  },
  [VUEX_LIBRARIES_GRID_ROWS_STATUS_CHANGE]: (state, data) => {
    data.map(item => {
      state.agGridRequestData.statusChangeProducts.push(item)
    })
  },
  [VUEX_LIBRARIES_GRID_ROWS_DELETE]: (state, data) => {
    data.map(item => {
      state.agGridRequestData.deleteProducts.push(item)
    })
  },

  [VUEX_LIBRARIES_FILE_DELETE]: (state, data) => {
    d.log('VUEX_LIBRARIES_FILE_DELETE commit', data)
    state.assetFileChangedObjects[data.cellComponentKey] = {
      deleted: true
    }
    state.assetFileChangedObjectsToggler = Math.random()
  },

  [VUEX_LIBRARIES_FILE_ADD]: (state, data) => {
    state.assetFileChangedObjects[data.frontEnd.cellComponentKey] = {}
    state.assetFileChangedObjectsToggler = Math.random()
  },

  [VUEX_LIBRARIES_FILE_MERGE]: (state, data) => {
    state.assetFileChangedObjects[data.frontEnd.cellComponentKey] = {}
    state.assetFileChangedObjectsToggler = Math.random()
  },

  [VUEX_LIBRARIES_GRID_REQUEST_CLEAR]: (state) => {
    Object.keys(state.agGridRequestData).forEach(item => {
      if (item !== 'patch') state.agGridRequestData[item] = []
    })
  },

  [VUEX_LIBRARIES_REKEY]: (state) => {
    state.rekey = Date.now()
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
