export class UploadModel {
  constructor (data) {
    const { bucket, key, file, signedURLData } = data || {}

    this.file = file || {}
    this.uploadId = signedURLData?.uploadId
    this.signedURLs = signedURLData?.urls || []
    this.bucket = bucket || null
    this.key = key || null
    this.parts = this.signedURLs.map((url, index) => new UploadPartModel({ index, url })) || []
    this.progress = { loaded: 0, total: 0, status: null }
  }
}

export class UploadPartModel {
  constructor (data) {
    const { index, url, progress, etag } = data || {}

    this.index = index
    this.url = url || ''
    this.progress = progress || { loaded: 0, total: 0, status: null }
    this.etag = etag || null
  }
}
