import Debug from 'logdown'
// import Vue from 'vue'
// import VueRouter from 'vue-router'
import { createWebHistory, createRouter } from 'vue-router'

import store from '@/store/store'

import {
  VUEX_ROUTING_HISTORY_TREE_UPDATE
} from '@/store/constants/routing'

import {
  VUEX_APPBAR_SHOW
} from '@/store/constants/ui/appBar'

import {
  VUEX_API_PHOTOS_ABORT
} from '@/store/constants/api'

/* import {
  VUEX_SUBHEADER_HIDE,
  VUEX_SUBHEADER_SET_SIZE
} from '@/store/constants/ui/subheader' */

import {
  VUEX_CONTENT_CONTAINER_SET_STYLES
} from '@/store/constants/ui/contentContainer'

// NOTE!! New route files are only picked up if you restart "yarn serve". If anything is still stuck you can run "yarn run cache:clear" and then "yarn serve"
import * as routeModules from './modules/*.js'
import * as utilityRouteModules from './modules/utilityRoutes/*.js'

import query from '@/utils/query'
import feConstants from '@/store/constants/config/fe_constants.js'

const d = new Debug('its:router')

// Vue.use(VueRouter)

/***
 *
 * Important!
 *
 * Do not interact directly with router. Use the router module instead. It has all the guards and protections and checks we need.
 *
 * Do not use router-links for same reason.
 *
 * To trigger a route event: this.$store.dispatch(VUEX_ROUTING_ROUTE,routeData);
 *
 */

// load routes from files since we have a lot of data with each route
let routes = []

const routesObj = Object.keys(routeModules)
const utilityRoutesObj = Object.keys(utilityRouteModules)
d.log('Found routes', routeModules, utilityRoutesObj)
routesObj.sort().forEach(function (routeName) {
  routes.push(routeModules[routeName])
})

utilityRoutesObj.sort().forEach(function (routeName) {
  routes.push(utilityRouteModules[routeName])
})

const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes: [
    ...routes,
    {
      // path: "*",
      path: '/:catchAll(.*)',
      redirect: { name: feConstants.ITS__ROUTING__DEFAULT__LOGGED_IN }
    },
    {
      path: '/',
      redirect: { name: feConstants.ITS__ROUTING__DEFAULT__LOGGED_IN }
    }
  ],
  parseQuery: query.parse,
  stringifyQuery: query.stringify
})

let promise = new Promise(resolve => {
  router.start = resolve
})

router.beforeEach(async (to, from, next) => {
  await promise

  // Hard reset state
  if (to.name !== from.name) {
    store.dispatch(VUEX_ROUTING_HISTORY_TREE_UPDATE, to)
    store.dispatch(VUEX_API_PHOTOS_ABORT)

    if (to.meta && to.meta.appBar !== false) store.dispatch(VUEX_APPBAR_SHOW)

    store.dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__SMALL
    })
  }

  next()
})

export default router
