import {
  VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET
} from '@/store/constants/ui/filterBar/filterBar_ItemsDisplay'

// Sort TODO's/open questions
// Either consolidate to one property (e.g. sortOrder or sort) or add a second action for the different properties
// If we do consolidate, we will need to explicitly set the default sort value whenever routing into somewhere that needs sort
// currently, we are depending on implicit defaults, and then setting it only if it differs.

// Another possibility is to extend the 'getSortOptions' method to work with values that are strings but also values that are objects.
// That way we could have a single 'sortOptions' lookup

const state = {
  sortOrder: '-modifiedDate',
  sortOptions: [
    { title: 'Newest', value: '-modifiedDate' },
    { title: 'Newest', value: '-files.modifiedDate' },
    { title: 'Newest', value: '-locations.addDate' },
    { title: 'Newest', value: '-createdDate' },
    { title: 'Name', value: 'name' },
    { title: 'Style', value: 'style,color' },
    { title: 'Style', value: 'styleNumeric,color' },
    { title: 'Newest', value: { 'locations.addDate': -1 } },
    { title: 'Name', value: { 'name': 1 } }
  ],
  kiSort: { 'locations.addDate': -1 }
}

const getters = {
  getSortOptions: state => optionsArr => {
    return state.sortOptions.filter(option => optionsArr.includes(option.value))
  }
}

const actions = {
  [VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET]: async ({ commit }, payload) => {
    await commit(VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET, payload)
  }
}

const mutations = {
  [VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET]: (state, data) => {
    if (data.sortProp === 'kiSort') {
      state.kiSort = data.sortValue
    } else {
      state.sortOrder = data
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
