import {
  VUEX_STATISTICS_SEND
} from '@/store/constants/statistics'

import {
  VUEX_API_STATISTICS_SEND
} from '@/store/constants/api'

const state = {}

const getters = {}

const actions = {
  [VUEX_STATISTICS_SEND]: ({ dispatch }, payload) => {
    dispatch(VUEX_API_STATISTICS_SEND, payload)
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
