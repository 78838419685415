import productsAvailabilityModule from '@/components/_core/FilterBar/_config/modules/productsAvailabilityModule.js'

export default {
  modules: [
    {
      label: 'Style / Color',
      filterGroups: [
        {
          hint: 'i.e. 220001 BKGR, 54358, BBK',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter IDs separated by commas'
              },
              key: 'id',
              multiQuery: {
                type: 'multiselect',
                selectionRequired: false,
                // ifAllSelectedUseKey: 'name',
                options: [
                  {
                    label: 'Style Suffix Only',
                    key: 'styleSuffix',
                    value: false
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      label: 'Keyword',
      allowMultiple: false,
      filterGroups: [
        {
          label: 'Name',
          hint: 'Name is combination of Outsole and Upper',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter Name'
              },
              key: 'name',
              multiQuery: {
                type: 'multiselect',
                ifAllSelectedUseKey: 'name',
                options: [
                  {
                    label: 'Outsole',
                    key: 'outsole',
                    value: true
                  },
                  {
                    label: 'Upper',
                    key: 'upper',
                    value: true
                  }
                ]
              }
            }
          ]
        },
        {
          label: 'Description',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter Description'
              },
              key: 'description'
            }
          ]
        }
      ]
    },
    {
      label: 'Key Initiative Season',
      allowMultiple: false,
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Key Initiative'
              },
              key: 'keyInitiative',
              placeholder: 'Key Initiative',
              // data: 'properties.Product.properties.keyInitiative.options'
              data: {
                getter: 'seasonSetsFlattened',
                filters: {
                  productType: 'Footwear'
                }
              }
            }
          ]
        }
      ]
    },
    {
      label: 'Division',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Division Name or ID',
                multiselect: true,
                filterable: true
                // lazyLoad: false
              },
              key: 'division',
              chipLabelProp: 'value',
              data: 'properties.Product.properties.division.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Gender',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'gender',
              data: 'properties.Product.properties.gender.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Color / Material',
      filterGroups: [
        {
          layout: {
            type: 'relational'
          },
          filters: [
            {
              group: 'Primary',
              component: {
                type: 'select',
                placeholder: 'Color',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.color1',
              data: 'properties.Product.properties.components.properties.color1.options'
            },
            {
              group: 'Primary',
              component: {
                type: 'select',
                placeholder: 'Material',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.material1',
              data: 'properties.Product.properties.components.properties.material1.options'
            },
            {
              group: 'Secondary',
              component: {
                type: 'select',
                placeholder: 'Color',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.color2',
              data: 'properties.Product.properties.components.properties.color2.options'
            },
            {
              group: 'Secondary',
              component: {
                type: 'select',
                placeholder: 'Material',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.material2',
              data: 'properties.Product.properties.components.properties.material2.options'
            },
            {
              group: 'Tertiary',
              component: {
                type: 'select',
                placeholder: 'Color',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.color3',
              data: 'properties.Product.properties.components.properties.color3.options'
            },
            {
              group: 'Tertiary',
              component: {
                type: 'select',
                placeholder: 'Material',
                filterable: true,
                hiddenMenu: true
              },
              key: 'components.material3',
              data: 'properties.Product.properties.components.properties.material3.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Features',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Feature',
                multiselect: true,
                filterable: true
              },
              key: 'features',
              chipLabelProp: 'value',
              data: 'properties.Product.properties.features.optionsFootwear'
            }
          ]
        }
      ]
    },
    {
      label: 'Categories',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Type',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.type',
              data: 'properties.Product.properties.categories.properties.type.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Shape',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.shape',
              data: 'properties.Product.properties.categories.properties.shape.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Height',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.height',
              data: 'properties.Product.properties.categories.properties.height.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Closure',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.closure',
              data: 'properties.Product.properties.categories.properties.closure.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Sport',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.sport',
              data: 'properties.Product.properties.categories.properties.sport.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Lighted',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.lighted',
              data: 'properties.Product.properties.categories.properties.lighted.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Vegan',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.vegan',
              data: 'properties.Product.properties.categories.properties.vegan.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Width',
                filterable: true,
                hiddenMenu: true
              },
              key: 'categories.width',
              data: 'properties.Product.properties.categories.properties.width.options'
            }
          ]
        }
      ]
    },
    productsAvailabilityModule('Footwear'),
    {
      label: 'Price',
      allowMultiple: false,
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'price-span',
                fields: [
                  {
                    label: 'From',
                    key: '$gte'
                  },
                  {
                    label: 'To',
                    key: '$lte'
                  }
                ]
              },
              dependencies: {
                product: [{ location: 'locations.code' }]
              },
              payloadType: 'object',
              multiQuery: {
                type: 'singleselect',
                options: [
                  {
                    label: 'Cost',
                    key: 'locations.wholesalePrice',
                    value: true
                  },
                  {
                    label: 'Sugg. Retail',
                    key: 'locations.suggestedRetail',
                    value: false
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ]
}
