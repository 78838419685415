<template>
    <v-app id="app">
        <router-view v-if="!browserObj.isBlocked"/>
        <!-- Browser block -->
        <div v-else class="blocked-browser">
          <div class="blocked-content">
              <h1 class="text-h3">You're using an unsupported browser.</h1>
              <p>We built It's the S using the latest tchnologies to enhance your experience and make the site faster and easier to use. Unfortunately, your browser doesn't support thos technologies</p>
              <p>To access It's the S please <a href="https://bestvpn.org/outdatedbrowser/en">click here</a> to upgrade your browser to the latest version.</p>
          </div>
        </div>
      <!-- Toast Container -->
      <ToastContainer />
      <!-- Global Action Prompt -->
      <GlobalActionPrompt/>
    </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import bowser from 'bowser'
import supportedBrowsers from '../ua_supportedBrowsers'

import ToastContainer from '@/components/_core/Containers/Toast_Container'
import GlobalActionPrompt from '@/components/_core/GlobalActionPrompt/Global_Action_Prompt'

import {
  VUEX_ALERT_DIALOG_SHOW,
  VUEX_ALERT_DIALOG_HIDE
} from '@/store/constants/ui/alertDialog'

const browserDetect = bowser.getParser(navigator.userAgent)

export default {
  name: 'app-root',

  data: () => ({
    browserObj: {}
  }),

  components: {
    ToastContainer,
    GlobalActionPrompt
  },

  computed: {
    ...mapState({
      userDismissedAlerDialog: state => state.alertDialog.userDismissed
    })
  },

  created: async function () {
    // Define and add client info to store
    this.browserObj = {
      isSupported: browserDetect.parsedResult.browser.name !== 'Internet Explorer' &&
                   supportedBrowsers.test(navigator.userAgent),
      // isBlocked: matchesUA(browserDetect._ua, { browsers: blockedBrowsers }),
      isBlocked: this.blockedBrowser(),
      isMobile: (browserDetect.parsedResult.platform.type === 'mobile' || browserDetect.parsedResult.platform.type === 'tablet'),
      isTablet: (browserDetect.parsedResult.platform.type === 'tablet'),
      // supportsGrid: (modernizr.cssgrid || modernizr.cssgridlegacy),
      // supportsOrientation: modernizr.devicemotiondeviceorientation,
      info: {
        width: window.innerWidth,
        height: window.innerHeight,
        ...browserDetect.parsedResult,
        _ua: browserDetect._ua
      },
      scrollData: {
        retainScrollPosition: false,
        scrollPosition: null
      }
    }

    this.setBodyBrowserClass()
    this.$store.commit('setBrowser', this.browserObj)
    window.addEventListener('resize', this.updateWindowSize)

    if (browserDetect.parsedResult.os.name !== 'macOS' && browserDetect.parsedResult.os.name !== 'iOS') {
      if ('ondeviceorientation' in window) {
        window.addEventListener('deviceorientation', this.rotationHandler, true)
      }
    } else {
      if (window.innerWidth < 1024 && (browserDetect.parsedResult.platform.type === 'tablet')) {
        this.showAlertDialog({
          content: '_core/Dialogs/Alerts/Dialog_Alert_Orientation.vue'
        })
      }
    }

    await this.$store.dispatch('init')
  },

  methods: {
    ...mapActions({
      showAlertDialog: VUEX_ALERT_DIALOG_SHOW,
      hideAlertDialog: VUEX_ALERT_DIALOG_HIDE
    }),

    setBodyBrowserClass () {
      let name = browserDetect.parsedResult.browser.name
      let version = parseInt(browserDetect.parsedResult.browser.version)
      let browserClass

      switch (name) {
        case 'Chrome':
          break
        case 'Firefox':
          break
        case 'Safari':
          browserClass = version === 10 ? 'safari-10' : undefined
          break
        case 'Microsoft Edge':
          browserClass = version === 15 ? 'edge-15' : undefined
          break
        default:
          break
      }

      if (browserClass) document.body.classList.add(browserClass)
    },

    blockedBrowser: () => {
      let name = browserDetect.parsedResult.browser.name
      let version = parseInt(browserDetect.parsedResult.browser.version)
      let isBlocked

      switch (name) {
        case 'Microsoft Edge':
          isBlocked = version < 16
          break
        case 'Safari':
          isBlocked = version < 11
          break
        default:
          isBlocked = false
          break
      }

      if (isBlocked) document.body.classList.add('blocked')
      return isBlocked
    },

    updateWindowSize (evt) {
      this.$store.commit('setWindowDims', {
        width: evt.currentTarget.innerWidth,
        height: evt.currentTarget.innerHeight
      })
    },

    rotationHandler (evt) {
      if (!this.userDismissedAlerDialog && window.innerWidth < 1024) {
        if (evt.target.screen.orientation.type.startsWith('portrait')) {
          this.showAlertDialog({
            content: '_core/Dialogs/Alerts/Dialog_Alert_Orientation.vue'
          })
        } else if (evt.target.screen.orientation.type.startsWith('landscape')) {
          this.hideAlertDialog()
        }
      }
    }
  },

  mounted () {
    let checkReady = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(checkReady)
        const vueLoaded = document.getElementById('vue-loaded-hook')
        vueLoaded && vueLoaded.remove()
      }
    }, 500)
  }
}
</script>

<style lang="css">
    /* Dependancy CSS Files */

    /* AG Grid */
    @import "ag-grid-community/styles/ag-grid.css";
    @import "ag-grid-community/styles/ag-theme-balham.css";

    /* Cropper JS */
    @import 'cropperjs/dist/cropper.css';

    /* Vue Virtual Scroller */
    @import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

    /* Vue Resize */
    /* @import 'vue-resize/dist/vue-resize.css';*/
    @import 'vue3-resize/dist/vue3-resize.css'
</style>

<style lang="scss">
    /* App SCSS Files */
    @import "@/styles/global-styles.scss";

    #app {
      position: relative;
      min-height: 100vh;
      background: {
        color: $app-color-background;
      }

    .blocked-browser {
      position: relative;
      width: 100vw;
      height: 100vh;
      display: none;

      .blocked-content {
        @include center-content();
        background: #FFF;
        max-width: 750px;
        padding: 3rem;

        h1,
        p {
          color: #222;
          background: #FFF;
        }

        h1{
          padding: {
            bottom: 1rem;
          }
        }

        a {
          background: #FFF;
        }
      }
    }
  }

  /* Broswer specific overrides */
  .edge-15 {
    @extend .edge-15-styles;
  }
  .safari-10 {
    @extend .safari-10-styles;
  }
</style>
