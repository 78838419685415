import axios from 'axios'
import api from '@/api'

import ApiValidator from '@/classes/ApiValidator'

import {
  VUEX_API_ASSORTMENT_GROUPSORT_UPDATE,
  VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_SUCCESS,
  VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_FAILURE,

  VUEX_API_ASSORTMENT_GROUPSORT_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const apiValidator = new ApiValidator('assortmentGroupSortBatch')

const state = {
  groupSortRequest: null,
  groupSortCancelRequest: null
}

const getters = {}

const actions = {
  // UPDATE: Assortment GroupSort
  [VUEX_API_ASSORTMENT_GROUPSORT_UPDATE]: async ({ commit, dispatch }, payload) => {
    if (!payload || !payload.id) { /* Throw error | return false */ }
    if (!payload.params) payload.params = {}

    const validatedPayload = apiValidator.validate(payload.data)

    commit(VUEX_API_ASSORTMENT_GROUPSORT_UPDATE)

    if (state.groupSortCancelRequest) state.groupSortCancelRequest.cancel()
    await commit(VUEX_API_ASSORTMENT_GROUPSORT_SET_CANCEL_TOKEN, 'groupSortCancelRequest')

    return api.patch(`/${payload.orgType}/groupsort/batch/${payload.id}`, validatedPayload, {
      params: payload.params,
      cancelToken: state.groupSortCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_SUCCESS)
      return response
    }).catch(err => {
      if (!axios.isCancel(err)) {
        commit(VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_FAILURE)

        let dispatchObject = {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/${payload.orgType}/groupsort/batch/${payload.id}' - ${err.response && err.response.data.message}`
          }
        }

        // for user lock error, add a longer timeout and give them additional refresh text
        let msgAddition = ``
        if (err.response?.data?.errorType === 'userLock') {
          dispatchObject.timeout = 5000
          msgAddition = `Your changes were not saved.  The underlying data might be different than how you see it. Click <a href=''>here</a> to reload the page.`
        }

        // set message with addition
        dispatchObject.data.message = `${err.response?.data?.message}.${msgAddition}`

        dispatch(VUEX_TOAST_ADD_TO_QUEUE, dispatchObject)
      }
    })
  }
}

const mutations = {
  // UPDATE: Assortment GroupSort
  [VUEX_API_ASSORTMENT_GROUPSORT_UPDATE]: state => {
    state.groupSortRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_SUCCESS]: state => {
    state.groupSortRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_FAILURE]: state => {
    state.groupSortRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_ASSORTMENT_GROUPSORT_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
