<template>
    <v-snackbar
        location="bottom"
        v-model="openState"
        :timeout="parseInt(toastTimeout)"
        class="toast-container"
        color="white">

        <div class="toast__content">
          <component :is="contentComponent" :data="toastData"/>
        </div>

        <v-btn
            v-if="parseInt(toastTimeout) === parseInt(-1)"
            small
            color="primary"
            class="toast__clear-btn"
            @click="clearToast">OK</v-btn>

    </v-snackbar>
</template>

<script>
import { loadShallowAsyncComponent } from '@/helpers/asyncLoaders'
import { mapState, mapActions } from 'vuex'

import {
  VUEX_TOAST_CLEAR,
  VUEX_TOAST_SET_STATE
} from '@/store/constants/ui/toast'

export default {
  name: 'toast-container',

  data: () => ({
    contentComponent: null
  }),

  computed: {
    ...mapState({
      toastOpenState: state => state.toast.openState,
      toastTimeout: state => state.toast.timeout,
      toastData: state => state.toast.data,
      toastComponent: state => state.toast.component
    }),

    openState: {
      get () { return this.toastOpenState },
      set (val) { this.setToastState(val) }
    }
  },

  created () {
    this.loadContent()
  },

  methods: {
    ...mapActions({
      clearToast: VUEX_TOAST_CLEAR,
      setToastState: VUEX_TOAST_SET_STATE
    }),

    loadContent () {
      this.contentComponent = this.toastComponent ? loadShallowAsyncComponent(this.toastComponent) : null
    }
  },

  watch: {
    toastComponent: {
      deep: true,
      handler () {
        this.loadContent()
      }
    }
  }
}
</script>

<style lang="scss">
  .toast-container {
    z-index: 5000 !important;

    .v-snack__wrapper {
      background: {
        color: $app-color-grey--dark-4;
      }
    }

    .v-snackbar__content {
      display: flex;

      .toast__clear-btn {
        margin: {
          left: 0.5rem;
        }
      }
    }
  }
</style>
