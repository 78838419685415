const state = {
  nav: [],
  navItem: '',
  navColor: '',
  displayMode: 'video',
  bgColor: '#D9EFC9',
  loaded: false,
  introHasPlayed: false
}

const getters = {
}

const actions = {
}

const mutations = {
  setLoaded (state) {
    state.loaded = true
  },

  setNav (state, { items, selected, color }) {
    if (items) {
      state.nav = items
    }

    if (selected) {
      state.navItem = { ...selected }
    }

    if (color) {
      state.navColor = color
    }
  },

  setMode (state, mode) {
    state.displayMode = mode
  },

  setBg (state, bg) {
    state.bgColor = bg
  },

  setIntroHasPlayed (state) {
    state.introHasPlayed = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
