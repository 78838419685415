import { loadComponent } from '@/helpers/asyncLoaders'
import store from '@/store/store'

import {
  VUEX_USER_HAS_CONFERENCE_ASIAN,
  VUEX_USER_HAS_CONFERENCE_DOMESTIC,
  VUEX_USER_HAS_CONFERENCE_INTERNATIONAL
} from '@/store/constants/user'

const hasPerms = () => {
  return store.getters[VUEX_USER_HAS_CONFERENCE_ASIAN] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_DOMESTIC] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_INTERNATIONAL]
}

export const routes = {
  path: 'spring25',
  name: 'spring25--main',
  meta: {
    conferenceClass: 'spring25'
  },
  component: loadComponent('GlobalConference/Spring25/Main.vue'),
  redirect: { name: 'spring25--landing' },
  children: [
    {
      path: 'load',
      name: 'spring25--load',
      component: loadComponent('GlobalConference/Spring25/Pages/Load.vue')
    },
    {
      path: 'landing',
      name: 'spring25--landing',
      component: loadComponent('GlobalConference/Spring25/Pages/Landing.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.state.globalConference.spring25.loaded) {
          return next({ name: 'spring25--load' })
        }
        next()
      }
    },
    {
      path: '',
      name: 'spring25--dashboard',
      component: loadComponent('GlobalConference/Spring25/Dashboard.vue'),
      beforeEnter: (to, from, next) => {
        if (hasPerms()) {
          next()
        } else {
          next('/')
        }
      },
      children: [
        {
          path: '',
          name: 'spring25--dashboard-default',
          redirect: { name: 'spring25--landing' }
        },
        {
          path: 'categories',
          name: 'spring25--categories',
          component: loadComponent('GlobalConference/Spring25/Pages/Categories.vue'),
          meta: {
            eventCategory: 'spring25',
            title: 'Product Categories',
            navigation: [
              'Womens',
              'Mens',
              'Kids',
              'Unisex'
            ]
          }
        },
        {
          path: 'ads',
          name: 'spring25--ads',
          component: loadComponent('GlobalConference/Spring25/Pages/Advertising.vue'),
          meta: {
            eventCategory: 'spring25',
            title: 'Advertising',
            navigation: [
              'Marketing',
              'Womens',
              'Mens',
              'Kids',
              'Unisex',
              'Work',
              'BTS',
              'International',
              'Social',
              'Radio',
              'Apparel'
            ]
          }
        },

        {
          path: 'sessions',
          name: 'spring25--sessions',
          component: loadComponent('GlobalConference/Spring25/Pages/Sessions.vue'),
          meta: {
            eventCategory: 'spring25',
            title: 'General Sessions'
          }
        }
      ]
    }
  ]
}
