// Product Libraries
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_DOCUMENTS = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_DOCUMENTS'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_PRODUCTS = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_PRODUCTS'

export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_SEASONS = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_SEASONS'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_LANDING = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_LANDING'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_WHOLESALE = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_WHOLESALE'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DETAIL = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DETAIL'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DIVIDER = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DIVIDER'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_RETAIL = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_INTERNATIONAL_RETAIL'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_WHOLESALE = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_WHOLESALE'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_RETAIL = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_INTERNALASSORTMENTS_DOMESTIC_RETAIL'

// Product Libraries Manage
export const VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PENDING = 'VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PENDING'
export const VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PUBLISHED = 'VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PUBLISHED'
export const VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UNPUBLISHED = 'VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UNPUBLISHED'
export const VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UPLOAD = 'VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UPLOAD'
export const VUEX_ROUTING_ENTER_PRODUCT_MANAGE_DELETED = 'VUEX_ROUTING_ENTER_PRODUCT_MANAGE_DELETED'

// Sample Orders
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_PRODUCTION = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_PRODUCTION'
export const VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_SALES = 'VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_SALES'

// Reporting - Assortment and User
export const VUEX_ROUTING_ENTER_ASSORTMENT_REPORTING = 'VUEX_ROUTING_ENTER_ASSORTMENT_REPORTING'

// Seasons Management
export const VUEX_ROUTING_ENTER_ASSORTMENT_SEASONS_MANAGER = 'VUEX_ROUTING_ENTER_ASSORTMENT_SEASONS_MANAGER'

// Assortment Manager - REGULAR
export const VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER = 'VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER'
export const VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_ALL = 'VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_ALL'
export const VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_ARCHIVED = 'VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_ARCHIVED'
export const VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_STARRED = 'VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_STARRED'
export const VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_RECENT = 'VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_RECENT'
export const VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_QUICKACCESS = 'VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_QUICKACCESS'

// Showroom - REGULAR
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS'
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_MANAGE = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_MANAGE'
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_GROUPSORT = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_GROUPSORT'
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_EXPORT = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_EXPORT'
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM_CONFIG = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM_CONFIG'
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_CREATE_ORDER = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_CREATE_ORDER'
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DETAIL = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DETAIL'
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DIVIDER = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_PRODUCT_DIVIDER'
export const VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM = 'VUEX_ROUTING_ENTER_ASSORTMENT_DETAILS_SHOWROOM'

// Assortment Manager - INTERNAL
export const VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_INTERNAL = 'VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_INTERNAL'
export const VUEX_ROUTING_LOADED_ASSORTMENT_MANAGER_INTERNAL = 'VUEX_ROUTING_LOADED_ASSORTMENT_MANAGER_INTERNAL'

// Showroom - INTERNAL
export const VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS = 'VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS'
export const VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_MANAGE = 'VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_MANAGE'
export const VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_GROUPSORT = 'VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_GROUPSORT'
export const VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_EXPORT = 'VUEX_ROUTING_ENTER_ASSORTMENT_INTERNAL_DETAILS_EXPORT'
