import assetsModifiedDateModule from '@/components/_core/FilterBar/_config/modules/assetsModifiedDataModule.js'

export default {
  // shareLink: true,
  modules: [
    {
      label: 'Type',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'assetType',
              data: 'properties.GlobalRetailAsset.properties.assetType.optionsVisualAsset'
              /* specialOptions: [
                {
                  label: 'ALL',
                  value: '___ALL___'
                }
              ] */
            }
          ]
        }
      ]
    },
    {
      label: 'Keyword',
      filterGroups: [
        {
          hint: 'Searches within filename',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter Keyword'
              },
              key: 'name'
            }
          ]
        }
      ]
    },
    {
      label: 'Season',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Season',
                filterable: true,
                hiddenMenu: true
                // multiselect: true
              },
              key: 'season',
              data: 'properties.GlobalRetailAsset.properties.season.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Year',
                filterable: true,
                hiddenMenu: true
                // multiselect: true
              },
              key: 'year',
              data: 'properties.GlobalRetailAsset.properties.year.optionsInUse'
            }
          ]
        }
      ]
    },
    {
      label: 'Language',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Language',
                filterable: true
              },
              key: 'lang',
              data: 'properties.GlobalRetailAsset.properties.lang.optionsInUse'
              /* specialOptions: [
                {
                  label: 'ALL',
                  value: '___ALL___'
                }
              ] */
            }
          ]
        }
      ]
    },
    {
      label: 'Product',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Category',
                filterable: true,
                hiddenMenu: true
              },
              key: 'category',
              data: 'properties.GlobalRetailAsset.properties.category.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Select Gender',
                filterable: true,
                hiddenMenu: true
              },
              key: 'gender',
              data: 'properties.GlobalRetailAsset.properties.gender.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Initiative',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Initiative',
                filterable: true
              },
              key: 'initiative',
              data: 'properties.GlobalRetailAsset.properties.initiative.optionsInUse'
            }
          ]
        }
      ]
    },
    {
      label: 'Size/Element',
      filterGroups: [
        {
          label: 'Size',
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Sizes',
                filterable: true
              },
              key: 'dimensions',
              data: 'properties.GlobalRetailAsset.properties.dimensions.optionsInUse'
            }
          ]
        },
        {
          label: 'Element',
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Elements',
                filterable: true
              },
              key: 'element',
              data: 'properties.GlobalRetailAsset.properties.element.optionsInUse'
            }
          ]
        }
      ]
    },
    {
      label: 'Partner Type',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Entity',
                filterable: true,
                hiddenMenu: true
              },
              key: 'entity',
              data: 'properties.GlobalRetailAsset.properties.entity.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Store Type',
                filterable: true,
                hiddenMenu: true
              },
              key: 'storeType',
              data: 'properties.GlobalRetailAsset.properties.storeType.options'
            }
          ]
        }
      ]
    },
    assetsModifiedDateModule
  ]
}
