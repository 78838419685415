import assetsModifiedDateModule from '@/components/_core/FilterBar/_config/modules/assetsModifiedDataModule.js'

export default {
  modules: [
    {
      label: 'Name',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter full or partial names separated by commas'
              },
              key: 'name'
            }
          ]
        }
      ]
    },
    {
      label: 'Category',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Category'
              },
              key: 'category',
              data: 'properties.AdvertisingLogo.properties.category.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Gender',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'gender',
              data: 'properties.AdvertisingLogo.properties.gender.options'
            }
          ]
        }
      ]
    },
    assetsModifiedDateModule
  ]
}
