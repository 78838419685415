import {
  VUEX_APPBAR_SHOW,
  VUEX_APPBAR_HIDE
} from '@/store/constants/ui/appBar'

const state = {
  openState: true
}

const getters = {}

const actions = {
  [VUEX_APPBAR_SHOW]: ({ commit }) => {
    commit(VUEX_APPBAR_SHOW)
  },

  [VUEX_APPBAR_HIDE]: ({ commit }) => {
    commit(VUEX_APPBAR_HIDE)
  }
}

const mutations = {
  [VUEX_APPBAR_SHOW]: (state) => {
    state.openState = true
  },

  [VUEX_APPBAR_HIDE]: state => {
    state.openState = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
