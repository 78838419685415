
export function clone (object) {
  if (typeof object === 'string' || object === null || object === undefined) return object
  return JSON.parse(JSON.stringify(object))
}

export function capitalizeEachWord (str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
};

// camelcase to title case
export function toTitleCase (string) {
  // convert from camel case to title case so email displays correctly
  let result = string.replace(/([A-Z])/g, ' $1')
  result = result.charAt(0).toUpperCase() + result.slice(1)
  return result
  // return string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
};

export function filterLimit (arr, checkFn, limit) {
  let result = []
  for (let i = 0; i < arr.length; i++) {
    if (checkFn(arr[i])) result.push(arr[i])
    if (result.length >= limit) break
  }
  return result
}

// insert index into a sorted array
// https://stackoverflow.com/a/21822316
export function insertSortedIndex (array, value) {
  var low = 0
  var high = array.length

  while (low < high) {
    var mid = (low + high) >>> 1
    if (array[mid] < value) low = mid + 1
    else high = mid
  }
  return low
}

export function removeDuplicate (arr, prop) {
  let obj = {}
  return Object.keys(arr.reduce((prev, next) => {
    if (!obj[next[prop]]) obj[next[prop]] = next
    return obj
  }, obj)).map((i) => obj[i])
}
