// Reference: https://stackoverflow.com/questions/28993157/visibilitychange-event-is-not-triggered-when-switching-program-window-with-altt

var browserPrefixes = ['moz', 'ms', 'o', 'webkit']

// get the correct attribute name
function getHiddenPropertyName (prefix) {
  return (prefix ? prefix + 'Hidden' : 'hidden')
}

// get the correct event name
function getVisibilityEvent (prefix) {
  return (prefix || '') + 'visibilitychange'
}

// get current browser vendor prefix
function getBrowserPrefix () {
  for (var i = 0; i < browserPrefixes.length; i++) {
    if (getHiddenPropertyName(browserPrefixes[i]) in document) {
      // return vendor prefix
      return browserPrefixes[i]
    }
  }

  // no vendor prefix needed
  return null
}

// bind and handle events
var browserPrefix = getBrowserPrefix()

// pass in a handler that looks for hidden or visible as parameter
function handleVisibilityChange (handlerFn) {
  return function () {
    if (document[getHiddenPropertyName(browserPrefix)]) {
      // the page is hidden
      handlerFn('hidden')
    } else {
      // the page is visible
      handlerFn('visible')
    }
  }
}

function setEventHandler (handlerFn) {
  document.addEventListener(getVisibilityEvent(browserPrefix), handleVisibilityChange(handlerFn), false)
}

// TODO: set up removal function??

export default setEventHandler
