import axios from 'axios'
import api from '@/api'

import {
  VUEX_API_NOTIFICATION_FETCH,
  VUEX_API_NOTIFICATIONS_FETCH,
  VUEX_API_NOTIFICATIONS_MARK_ALL_READ,
  VUEX_API_NOTIFICATIONS_BATCH_UPDATE,

  VUEX_API_NOTIFICATIONS_FETCH_SUCCESS,
  VUEX_API_NOTIFICATIONS_FETCH_FAILED,

  VUEX_API_NOTIFICATIONS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  notificationsRequest: null,
  notificationsCancelRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_NOTIFICATION_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { notificationType, id, params } = payload

    await commit(VUEX_API_NOTIFICATIONS_FETCH)

    if (state.notificationsCancelRequest) state.notificationsCancelRequest.cancel()
    await commit(VUEX_API_NOTIFICATIONS_SET_CANCEL_TOKEN, 'notificationsCancelRequest')

    return api.get(`/notifications/${notificationType}/${id}`, {
      params,
      cancelToken: state.notificationsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_NOTIFICATIONS_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_NOTIFICATIONS_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_NOTIFICATION_FETCH | Cannot load '/api/notifications/${notificationType}/${id}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_NOTIFICATIONS_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { notificationType, userCode, data } = payload

    await commit(VUEX_API_NOTIFICATIONS_FETCH)

    if (state.notificationsCancelRequest) state.notificationsCancelRequest.cancel()
    await commit(VUEX_API_NOTIFICATIONS_SET_CANCEL_TOKEN, 'notificationsCancelRequest')

    return api.post(`/notifications/${notificationType}/query${userCode ? '/' + userCode : ''}`, data, {
      cancelToken: state.notificationsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_NOTIFICATIONS_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_NOTIFICATIONS_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_NOTIFICATIONS_FETCH | Cannot load '/api/notifications/${notificationType}/query' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_NOTIFICATIONS_MARK_ALL_READ]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { notificationType, data } = payload

    await commit(VUEX_API_NOTIFICATIONS_FETCH)

    if (state.notificationsCancelRequest) state.notificationsCancelRequest.cancel()
    await commit(VUEX_API_NOTIFICATIONS_SET_CANCEL_TOKEN, 'notificationsCancelRequest')

    // compose options payload
    return api.post(`/notifications/${notificationType}/setStateAll/Read`, data, {
      cancelToken: state.notificationsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_NOTIFICATIONS_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_NOTIFICATIONS_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_NOTIFICATIONS_MARK_ALL_READ | Cannot load '/api/notifications/${notificationType}/setStateAll/Read' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_NOTIFICATIONS_BATCH_UPDATE]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { notificationType, data } = payload

    await commit(VUEX_API_NOTIFICATIONS_FETCH)
    if (state.notificationsCancelRequest) state.notificationsCancelRequest.cancel()
    await commit(VUEX_API_NOTIFICATIONS_SET_CANCEL_TOKEN, 'notificationsCancelRequest')

    // compose options payload
    return api.post(`/notifications/${notificationType}/batch/many`, data, {
      cancelToken: state.notificationsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_NOTIFICATIONS_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_NOTIFICATIONS_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_NOTIFICATIONS_BATCH_UPDATE | Cannot load '/api/notifications/${notificationType}/batch/many' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  }
}

const mutations = {
  // Notifications
  [VUEX_API_NOTIFICATIONS_FETCH]: state => {
    state.notificationsRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_NOTIFICATIONS_FETCH_SUCCESS]: state => {
    state.notificationsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_NOTIFICATIONS_FETCH_FAILED]: state => {
    state.notificationsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_NOTIFICATIONS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
