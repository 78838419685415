export const VUEX_LIBRARIES_COLLECTION_ADD_TO_COLLECTION = 'VUEX_LIBRARIES_COLLECTION_ADD_TO_COLLECTION'
export const VUEX_LIBRARIES_COLLECTION_BULK_ADD_TO_COLLECTION = 'VUEX_LIBRARIES_COLLECTION_BULD_ADD_TO_COLLECTION'
export const VUEX_LIBRARIES_COLLECTION_REMOVE_FROM_COLLECTION = 'VUEX_LIBRARIES_COLLECTION_REMOVE_FROM_COLLECTION'
export const VUEX_LIBRARIES_COLLECTION_CLEAR_COLLECTION = 'VUEX_LIBRARIES_COLLECTION_CLEAR_COLLECTION'

export const VUEX_LIBRARIES_COLLECTION_INIT_FROM_LOCAL_STORAGE = 'VUEX_LIBRARIES_COLLECTION_INIT_FROM_LOCAL_STORAGE'
export const VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE = 'VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE'

export const VUEX_LIBRARIES_COLLECTION_TOGGLE_INCLUDE_WORKING_FILES = 'VUEX_LIBRARIES_COLLECTION_TOGGLE_INCLUDE_WORKING_FILES'
export const VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_WORKING_FILES_ONLY = 'VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_WORKING_FILES_ONLY'
export const VUEX_LIBRARIES_COLLECTION_SET_VIEW_TYPE = 'VUEX_LIBRARIES_COLLECTION_SET_VIEW_TYPE'

// TODO: Consider moving to different store
export const VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_NEW_STYLES_ONLY = 'VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_NEW_STYLES_ONLY'
