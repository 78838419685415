// Global Imports
// import Vue from 'vue'
import { createApp, h } from 'vue'

import VueResize from 'vue3-resize'
import VueScrollTo from 'vue-scrollto'
import Debug from 'logdown'

// Original App Imports
import { RecycleScroller } from 'vue-virtual-scroller'

// Mixins | Filters
import { loadView, loadComponent } from '@/helpers/asyncLoaders'
import checkUserPermissions from '@/mixins/checkUserPermissions'

// Helpers
import customValidators from '@/helpers/customValidators.js'

// Plugins
import Vuetify from '@/plugins/vuetify'

// Directives
import { MenuClose } from '@/directives'

// Libraries
import router from '@/router'
import store from '@/store/store'
import { sync } from 'vuex-router-sync'

// emitter library
import mitt from 'mitt'

// AGGrid libs
import { LicenseManager, AllModules } from 'ag-grid-enterprise'
import { ModuleRegistry } from 'ag-grid-community'

// Main app import
import App from '@/App.vue'

// Import actions
import {
  VUEX_APP_INIT
} from '@/store/constants/app'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

import AutoComplete from '@/components/_core/Common/AutoComplete.vue'
import ComboBox from '@/components/_core/Common/ComboBox.vue'
import DatePicker from '@/components/_core/Common/DatePicker.vue'
import Select from '@/components/_core/Common/Select.vue'
import TextArea from '@/components/_core/Common/TextArea.vue'
import TextField from '@/components/_core/Common/TextField.vue'

router.onError(error => {
  if (/loading chunk [\w-]+ failed/i.test(error.message) ||
      /CHUNK_LOAD_FAILED/i.test(error.code)) {
    store.dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
      component: '_core/Toast/Toast_Message.vue',
      data: {
        type: 'error',
        message: `Warning: Please disable ad blockers or whitelist Its the S to use this site`
      }
    })
  }
})

require('@/utils/navigation_controller')
// require('@/config/vue-config')

const debug = new Debug('its:index')

// Instantiate/Register library instances
// LicenseManager.setLicenseKey('CompanyName=The Branding Farm,LicensedApplication=Its The S,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-037130,SupportServicesEnd=18_March_2024_[v2]_MTcxMDcyMDAwMDAwMA==6defe993a3f1a44c0d22846d6d79f8b9')
LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-052941}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{The_Branding_Farm}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Its_The_S}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Its_The_S}_need_to_be_licensed___{Its_The_S}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{18_March_2025}____[v3]_[01]_MTc0MjI1NjAwMDAwMA==0ae5e3ebc56488e51d763a9868521499')
ModuleRegistry.registerModules(AllModules)
sync(store, router)

let app = createApp({
  // vuetify,
  router,
  store,
  methods: {
    loadView,
    loadComponent
  },
  watch: {
    '$route.name': () => {
      window.Appcues && window.Appcues.page()
    }
  },
  render: () => h(App)
})

// emitter library
const emitter = mitt()
app.config.globalProperties.emitter = emitter

// Global components
app.component('RecycleScroller', RecycleScroller)

app.component('its-autocomplete', AutoComplete)
app.component('its-combobox', ComboBox)
app.component('its-date-picker', DatePicker)
app.component('its-select', Select)
app.component('its-textarea', TextArea)
app.component('its-text-field', TextField)

// Directives
app.directive('menu-close', MenuClose)

// Mixins
app.mixin(customValidators)
app.mixin(checkUserPermissions)

// Vue Plugins
app.use(Vuetify)
app.use(VueResize)
app.use(VueScrollTo)
app.use(store)
app.use(router)

app.config.productionTip = false
app.config.devtools = process.env.VUE_APP_DEV_TOOLS
app.config.performance = process.env.VUE_APP_PERFORMANCE_TOOLS
window.localStorage.debug = process.env.VUE_APP_DEBUG

store.dispatch(VUEX_APP_INIT).then(() => {
  debug.log('App mounted')
  app.mount('#app')
}).catch(err => {
  console.error('src/main.js | VUEX_APP_INIT | Cannot load app', err)
  return err
})

export default app

export { emitter }
