//
// Front-end constants
//
// NOTE! These are exposed on window so don't need to be explicitly imported
// NOTE! If you add more, make sure to add them to eslintrc.js so the linter doesn't complain
//
export default {
  // ITS User
  ITS__USER__STRATEGY_TYPE__LOCAL: 'local',

  // ITS Permissions
  ITS__PERMISSION__ADVERTISING__ASSETS: 'its:advertisingAssets',
  ITS__PERMISSION__ADVERTISING__LOGOS: 'its:advertisingLogos',

  ITS__PERMISSION__GLOBALRETAIL__ASSETS: 'its:globalRetailAssets',
  ITS__PERMISSION__GLOBALRETAIL__RESOURCES: 'its:globalRetailResources',

  ITS__PERMISSION__PRODUCTS: 'its:products',
  ITS__PERMISSION__PRODUCT__DOCUMENTS: 'its:productDocuments',
  ITS__PERMISSION__PRODUCT__ASSORTMENTS: 'its:productAssortments',
  ITS__PERMISSION__PRODUCT__INTERNAL_ASSORTMENTS: 'its:internalAssortments',

  ITS__PERMISSION__ORDERS__SAMPLE_ORDERS: 'its:sampleOrders',
  ITS__PERMISSION__ORDERS__PROMO_ORDERS: 'its:promoOrders',

  ITS__PERMISSION__CONFERENCE: 'its:conference',

  ITS__PERMISSION__ADMIN__USERS: 'its:admin:users',
  ITS__PERMISSION__ADMIN__DIVISION_GROUPS: 'its:admin:divisionGroups',
  ITS__PERMISSION__ADMIN__TERRITORIES: 'its:admin:territories',
  ITS__PERMISSION__ADMIN__CUSTOMERS: 'its:admin:customers',
  ITS__PERMISSION__ADMIN__REPORTS: 'its:admin:reports',
  ITS__PERMISSION__ADMIN__ORDERS: 'its:admin:orders',

  ITS__PERMISSION__SALES__STEP_TRAINING: 'its:stepTraining',
  ITS__PERMISSION__SALES__TRAVEL_CALENDAR: 'its:travelCalendar',
  ITS__PERMISSION__SALES__KEY_ITEM_PRICING: 'its:keyItemPrice',
  ITS__PERMISSION__SALES__SPENDING_APPROVAL: 'its:spendingApproval',
  ITS__PERMISSION__SALES__UPC_LOOKUP: 'its:upcLookup',

  ITS__PERMISSION__SAMPLE_INVENTORY: 'its:sampleInventory',

  // ITS Roles
  ITS__ROLE__USER: 'user',
  ITS__ROLE__EDITOR: 'editor',
  ITS__ROLE__SENIOR_EDITOR: 'seniorEditor',
  ITS__ROLE__ADMIN: 'admin',
  ITS__ROLE__ADMIN_EDITOR: 'adminEditor',
  ITS__ROLE__APPROVER: 'approver',
  ITS__ROLE__SENIOR_APPROVER: 'seniorApprover',
  ITS__ROLE__COORDINATOR: 'coordinator',
  ITS__ROLE__SENIOR_COORDINATOR: 'seniorCoordinator',
  ITS__ROLE__SAMPLE_COORDINATOR: 'sampleCoordinator',
  ITS__ROLE__DISTRIBUTOR: 'distributor',
  ITS__ROLE__MANAGER: 'manager',

  // -------------------------------------------------------------

  ITS__ROUTING__DEFAULT__LOGGED_IN: 'dashboard--its',
  ITS__ROUTING__DEFAULT__LOGGED_OUT: 'dashboard--default',

  // API request states
  ITS__API__REQUEST_TYPE__PENDING: 'Pending',
  ITS__API__REQUEST_TYPE__SUCCESS: 'Success',
  ITS__API__REQUEST_TYPE__FAILED: 'Failed',

  // Containers UI
  ITS__UI__CONTENT_CONTAINER__PADDING__NONE: '0',
  ITS__UI__CONTENT_CONTAINER__PADDING__SMALL: '12',

  // Header (toolbar) sizes
  ITS__UI__HEADER__SIZE__SMALL: '45',
  ITS__UI__HEADER__SIZE__MEDIUM: '80',
  ITS__UI__HEADER__SIZE__NORMAL: '133',
  ITS__UI__HEADER__SIZE__LARGE: '178',

  // Subheader (toolbar extension) sizes
  ITS__UI__SUBHEADER__SIZE__1: '45',
  ITS__UI__SUBHEADER__SIZE__2: '121',
  ITS__UI__SUBHEADER__SIZE__3: '133',
  ITS__UI__SUBHEADER__SIZE__4: '178',
  ITS__UI__SUBHEADER__SIZE__5: '280',

  // Notification (snackbar) display hold time
  ITS__UI__TOAST__DELAY__DEFAULT: 3000,

  // Remove file from File Manager delay
  ITS__UI__PROGRESS__DELAY__FILE_POP: 3000,

  // Misc
  ITS__LONG_WAIT_BLOCKER__NUM_OF_ITEMS: 100,

  // File Processing statuses
  ITS__FILE__PROCESSING__STATUS__PENDING: 'Pending',
  ITS__FILE__PROCESSING__STATUS__STARTED: 'Started',
  ITS__FILE__PROCESSING__STATUS__SUCCESS: 'Success',
  ITS__FILE__PROCESSING__STATUS__FAILED: 'Failed',
  ITS__FILE__PROCESSING__STATUS__CANCELLED: 'cancelled',

  // File Request types
  ITS__FILE__REQUEST_TYPE__DOCUMENT__CREATE_DOWNLOAD: 'document_create_download',
  ITS__FILE__REQUEST_TYPE__ASSORTMENT__PRODUCTS_DOWNLOAD: 'assortment_products_download',

  // File Download types
  ITS__FILE__DOWNLOAD_TYPE__ZIP: 'zip',
  ITS__FILE__DOWNLOAD_TYPE__PDF: 'pdf',
  ITS__FILE__DOWNLOAD_TYPE__XLSX: 'xlsx',

  // File Action type
  ITS__FILE__ACTION_TYPE__UPLOAD: 'Upload',
  ITS__FILE__ACTION_TYPE__DOWNLOAD: 'Download',
  ITS__FILE__ACTION_TYPE__PREVIEW: 'Preview',

  // Libraries File Usage Types
  ITS__FILE__LIBRARIES__USAGE_TYPE__WORKING_FILE: 'Working File',

  // S3 File Upload Manager Usage Types
  ITS__S3FUM__USAGE_TYPE__ASSORTMENT_LOGO: 's3File_UsageType__AssortmentLogo',
  ITS__S3FUM__USAGE_TYPE__ASSORTMENT_PRODUCT: 's3File_UsageType__AssortmentProduct',
  ITS__S3FUM__USAGE_TYPE__ASSORTMENT_DISPLAYGROUP: 's3File_UsageType__AssortmentDisplayGroup',
  ITS__S3FUM__USAGE_TYPE__LIBRARIES_ASSET: 's3File_UsageType__LibrariesAsset',
  ITS__S3FUM__USAGE_TYPE__SEASON_COVER: 's3File_UsageType__SeasonCover',

  ITS__S3__BUCKET_TYPE__PUBLIC: 'public',
  ITS__S3__BUCKET_TYPE__SYNC: 'sync',

  // Default Timezone
  ITS__TIMEZONE__DEFAULT: 'America/Los_Angeles',

  // Statistics Actions
  ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__ADD_STYLE: 'add:assortment:style',
  ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__ADD_SEARCH: 'add:assortment:search',
  ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__ADD_EXCEL: 'add:assortment:excel',

  ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__CLEAR_ALERTS: 'clear:assortment:alerts',
  ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__REMOVE_ALERTS: 'remove:assortment:alerts',

  ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__IMAGE_UPLOAD_STYLE: 'upload:assortment:image:style',
  ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__IMAGE_UPLOAD_LOGO: 'upload:assortment:image:logo',

  ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__DOWNLOAD_ZIP_IMAGES: 'download:assortment:zip:images',

  ITS__STATISTICS__ACTION_TYPE__DOWNLOAD__ASSET_PRIMARY: 'download:asset:primaryFile',
  ITS__STATISTICS__ACTION_TYPE__DOWNLOAD__ASSET_WORKING: 'download:asset:workingFile',

  ITS__STATISTICS__ACTION_TYPE__DOCUMENT__DOWNLOAD: 'download:assortment:document:trigger',
  ITS__STATISTICS__ACTION_TYPE__DOCUMENT__PREVIEW: 'preview:assortment:document:trigger',

  ITS__STATISTICS__ACTION_TYPE__HELP_ASSORTMENTS: 'help:assortment',
  ITS__STATISTICS__ACTION_TYPE__HELP_KI_CENTER: 'help:kiCenter',

  ITS__STATISTICS__ACTION_TYPE__OPEN_DIGITAL_SHOWROOM: 'help:showroom',

  // Regions
  ITS__REGION__ALL: 'All',
  ITS__REGION__INTERNATIONAL: 'International',
  ITS__REGION__EUROPE: 'Europe',
  ITS__REGION__DOMESTIC: 'Domestic',

  // Entities
  ITS__ENTITY__DOMESTIC: 'Domestic',
  ITS__ENTITY__SUBSIDIARY: 'Subsidiary',
  ITS__ENTITY__JOINT_EVENTURE: 'Joint Venture',
  ITS__ENTITY__FRANCHISE: 'Fanchise',

  // LIBRARIES CONSTS ======================================
  // Manage Types
  ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET: 'AdvertisingAsset',
  ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO: 'AdvertisingLogo',
  ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET: 'GlobalRetailAsset',
  ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__RESOURCE: 'GlobalRetailResource',
  ITS__LIBRARIES__LIBRARY_TYPE__ASSORTMENT: 'Assortment',
  ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT: 'Product',
  ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT: 'ProductDocument',
  ITS__LIBRARIES__LIBRARY_TYPE__STEP__ASSET: 'StepAsset',

  ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET: 'AdvertisingAsset',
  ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO: 'AdvertisingLogo',
  ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET: 'GlobalRetailAsset',
  ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__RESOURCE: 'GlobalRetailResource',
  ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR: 'AssortmentsRegular',
  ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL: 'AssortmentsInternal',
  ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__SEASONSMANAGER: 'SeasonsManager',
  ITS__LIBRARIES__MANAGE_TYPE__PRODUCT: 'Product',
  ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT: 'ProductDocument',
  ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET: 'StepAsset',
  ITS__LIBRARIES__MANAGE_TYPE__SAMPLE_INVENTORY__PRODUCTION: 'Production',
  ITS__LIBRARIES__MANAGE_TYPE__SAMPLE_INVENTORY__SALES: 'Sales',

  ITS__LIBRARIES__SUB_TYPE__VISUAL_ASSET: 'VisualAsset',
  ITS__LIBRARIES__SUB_TYPE__RESOURCE: 'Resource',
  // =======================================================

  // DYNAMIC OPTIONS ======================================
  ITS__DYNAMIC_OPTIONS__TYPE__GLOBAL_RETAIL__ASSETS: 'GlobalRetailAssetsOptions',
  ITS__DYNAMIC_OPTIONS__TYPE__GLOBAL_RETAIL__RESOURCES: 'GlobalRetailResourcesOptions',
  ITS__DYNAMIC_OPTIONS__TYPE__PRODUCT_LIBRARIES__PRODUCTS: 'ProductLibrariesProducts',
  ITS__DYNAMIC_OPTIONS__TYPE__PRODUCT_LIBRARIES__DOCUMENTS: 'ProductLibrariesDocuments',
  ITS__DYNAMIC_OPTIONS__TYPE__ADVERTISING_LIBRARIES__ASSETS: 'AdvertisingLibrariesAssets',
  ITS__DYNAMIC_OPTIONS__TYPE__ADVERTISING_LIBRARIES__LOGOS: 'AdvertisingLibrariesLogos',
  ITS__DYNAMIC_OPTIONS__TYPE__ASSORTMENTS__PRODUCTS: 'AssortmentsProducts',
  ITS__DYNAMIC_OPTIONS__TYPE__ORDERS__PRODUCTS: 'OrdersProducts',
  // ======================================================

  // SEASONS CONSTS ======================================
  // Statuses
  ITS__SEASONS__STATUS_TYPE__LIVE: 'Live',
  ITS__SEASONS__STATUS_TYPE__ARCHIVED: 'Archived',
  ITS__SEASONS__STATUS_TYPE__PREPARATION: 'Preparation',

  // ASSORTMENT CONSTS ======================================
  // Orignization Types
  ITS__ASSORTMENTS__ORG_TYPE__INTERNAL: 'Internal',
  ITS__ASSORTMENTS__ORG_TYPE__REGULAR: 'Regular',

  // Channel Types
  ITS__ASSORTMENTS__CHANNEL_TYPE__ALL: 'All',
  ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE: 'Wholesale',
  ITS__ASSORTMENTS__CHANNEL_TYPE__RETAIL: 'Retail',

  ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC: 'Dynamic',
  ITS__ASSORTMENTS__METHOD_TYPE__STATIC: 'Static',

  ITS__ASSORTMENTS__STATE__PUBLISHED: 'Published',
  ITS__ASSORTMENTS__STATE__UNPUBLISHED: 'Unpublished',
  ITS__ASSORTMENTS__STATE__ARCHIVED: 'Archived',

  // Assortment Types
  ITS__ASSORTMENTS__ASSORTMENT_TYPE__STATIC: 'StaticAssortment',
  ITS__ASSORTMENTS__ASSORTMENT_TYPE__DYNAMIC: 'DynamicAssortment',
  ITS__ASSORTMENTS__ASSORTMENT_TYPE__INTERNATIONAL_WHOLESALE: 'InternationalWholesaleAssortment',
  ITS__ASSORTMENTS__ASSORTMENT_TYPE__DOMESTIC_WHOLESALE: 'DomesticWholesaleAssortment',
  ITS__ASSORTMENTS__ASSORTMENT_TYPE__INTERNATIONAL_RETAIL: 'InternationalRetailAssortment',
  ITS__ASSORTMENTS__ASSORTMENT_TYPE__DOMESTIC_RETAIL: 'DomesticRetailAssortment',

  // Product Types
  ITS__PRODUCT_TYPE__ALL: 'All',
  ITS__PRODUCT_TYPE__FOOTWEAR: 'Footwear',
  ITS__PRODUCT_TYPE__APPAREL: 'Apparel',
  ITS__PRODUCT_TYPE__POP: 'POP',

  // Orders
  ITS__ORDERS__ORDER_TYPE__SAMPLE: 'SAMPLE',
  ITS__ORDERS__ORDER_TYPE__PROMO: 'PROMO',

  ITS__ORDERS__PROMO__NEEDS_REVIEW: 'PromoNeedsReview',
  ITS__ORDERS__PROMO__REVIEWED: 'PromoReviewed',
  ITS__ORDERS__PROMO__ARCHIVED: 'PromoArchived',
  ITS__ORDERS__PROMO__DETAIL: 'PromoDetail',
  ITS__ORDERS__SAMPLE__SAMPLE_STYLES: 'SampleStyles',
  ITS__ORDERS__SAMPLE__ACTIVE_ORDERS: 'SampleActiveOrders',
  ITS__ORDERS__SAMPLE__ARCHIVED_ORDERS: 'SampleArchivedOrders',
  ITS__ORDERS__SAMPLE__DETAIL: 'SampleDetail'
  // =======================================================
}
