import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_PRODUCTION,
  VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_SALES
} from '@/store/constants/routing/routingProduct'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

// import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { sampleLibraryMeta } from '@/router/routeMeta'
import feConstants from '@/store/constants/config/fe_constants'

// ----------------------------------------------------------
const generateSampleInventoryRoute = () => {
  if (store.getters.hasSampleInventoryPermission(['Production']).hasPerm) {
    return 'product--libraries--sample-inventory--production'
  } else if (!store.getters.hasSampleInventoryPermission(['Production']).hasPerm && store.getters.hasSampleInventoryPermission(['Sales']).hasPerm) {
    return 'product--libraries--sample-inventory--sales'
  } else {
    return ITS__ROUTING__DEFAULT__LOGGED_IN
  }
}
// ----------------------------------------------------------

export default {
  path: '/product/libraries',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      callback: async () => {
        await store.dispatch(VUEX_OPTIONS_FETCH, 'samples').then(() => next())
      }
    }),
  children: [
    {
      path: '',
      name: 'product--libraries--sample-inventory--default',
      beforeEnter: () => {
        store.dispatch('VUEX_ROUTING_ROUTE', { name: generateSampleInventoryRoute() })
      }
    },
    {
      path: 'samples/production',
      name: 'product--libraries--sample-inventory--production',
      component: loadComponent('Product/SampleLibrary/SampleLibrary_Production.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters.hasSampleInventoryPermission(['Production']).hasPerm) store.dispatch('VUEX_ROUTING_ROUTE', { name: ITS__ROUTING__DEFAULT__LOGGED_IN })
        store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_PRODUCTION, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__SAMPLE_INVENTORY__PRODUCTION
      }
    },
    {
      path: 'samples/sales',
      name: 'product--libraries--sample-inventory--sales',
      component: loadComponent('Product/SampleLibrary/SampleLibrary_Sales.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters.hasSampleInventoryPermission(['Sales']).hasPerm) store.dispatch('VUEX_ROUTING_ROUTE', { name: ITS__ROUTING__DEFAULT__LOGGED_IN })
        store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_LIBRARIES_SAMPLES_SALES, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__SAMPLE_INVENTORY__SALES
      }
    }
  ],
  meta: sampleLibraryMeta
}
