// ------------------------------------------------------------
// SWAP:
// Swaps items in an array
//
// EXAMPLE:
// In  : swap(['a', 'b', 'c'], 0, 1)
// Out : ['b', 'a', 'c']
// ------------------------------------------------------------
const swap = (arr, from, to) => {
  [ arr[from], arr[to] ] = [ arr[to], arr[from] ]
}

// ------------------------------------------------------------
// MOVE:
// Similar to Swap, Move will move an item to a desired location
// but all other items will be shifted out of the way
//
// EXAMPLE:
// In  : move(['a', 'b', 'c', 'd'], 3, 1)
// Out : ['a', 'd', 'b', 'c']
// ------------------------------------------------------------
const move = (array, moveIndex, toIndex) => {
  const item = array[moveIndex]
  const length = array.length
  const diff = moveIndex - toIndex

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length)
    ]
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length)
    ]
  }
  return array
}

// ------------------------------------------------------------
// PARTITION:
// Creates a multi-dimentional array at desired index
//
// EXAMPLE:
// In  : partition(['a', 'b', 'c'], 1)
// Out : [['b', 'a'], ['c']]                               ^
// ------------------------------------------------------------
const partition = (array, splitIndex) => {
  return array.length ? [array.splice(0, splitIndex)].concat(partition(array, splitIndex)) : []
}

export default {
  swap,
  move,
  partition
}
