export const VUEX_DIALOG_SHOW = 'VUEX_DIALOG_SHOW'
export const VUEX_DIALOG_HIDE = 'VUEX_DIALOG_HIDE'

export const VUEX_DIALOG_SET_PROPS = 'VUEX_DIALOG_SET_PROPS'
export const VUEX_DIALOG_MODIFY_DATA_PROP = 'VUEX_DIALOG_MODIFY_DATA_PROP'
export const VUEX_DIALOG_INACTIVATED_MESSAGE = 'Your account has been deactivated due to inactivity. Please contact your Itsthes administrator at itsthes_admin@skechers.com.'
export const VUEX_DIALOG_NOT_APPROVED_MESSAGE = 'Your request for access is currently is under review. You will be notified by email when your access has been approved.'
export const VUEX_DIALOG_SKECHERS_ASSISTANCE_MESSAGE = 'Skechers employees should contact the Skechers IT department to reset their password.'
export const VUEX_DIALOG_MUST_CONFIRM_EMAIL_MESSAGE = 'Thank you for your request. Please check your email for an email verification link. Once verified an administrator will review your request and you will be notified when activated.'
export const VUEX_DIALOG_LOGGED_OUT_MESSAGE = 'You have been logged out due to inactivity. Please note that if you have left a download in progress it was not interrupted. Please check your downloads area.'
