export default {
  label: 'Modified',
  allowMultiple: false,
  filterGroups: [
    {
      layout: {
        type: 'date-vertical'
      },
      filters: [
        {
          payloadType: 'string',
          component: {
            type: 'date-picker'
          },
          title: 'Modified Since',
          key: 'modifiedDate__gtd',
          label: 'Modified Date',
          timeSpan: 'past' /* 'future' */,
          timeoffset: '08:00:00.000' // HH:mm:ss:ms
        }
      ]
    }
  ]
}
