import axios from 'axios'
// import PQueue from 'p-queue/dist'
import Debug from 'logdown'

import feConstants from '@/store/constants/config/fe_constants'
// import AWSApi from '@/api/aws'
import api from '@/api'
import { s3Href } from '@/utils/file'

import MultipartUploader from '@/classes/MultipartUploader'

import {
  VUEX_API_S3_GET_SIGNED_URLS,
  VUEX_API_S3_COMPLETE_UPLOAD,

  VUEX_API_S3_UPLOAD_REQUEST,
  VUEX_API_S3_UPLOAD_REQUEST_SUCCESS,
  VUEX_API_S3_UPLOAD_REQUEST_FAILURE,
  VUEX_API_S3_DOWNLOAD_PUBLIC,
  VUEX_API_S3_DOWNLOAD_SIGNED_REQUEST,
  VUEX_API_S3_ASSETS_SIGNED_REQUEST
} from '@/store/constants/api'

import {
  VUEX_S3FUM_ASSIGN_S3_KEY,
  VUEX_S3FUM_UPLOAD_PROGRESS,
  VUEX_S3FUM_UPLOAD_RESULT_HANDLER,
  VUEX_S3FUM_CHANGE_STATUS
} from '@/store/constants/fileManagement/s3FileUploadManager'

import {
  VUEX_S3FUM_RESPONSE_HANDLER
} from '@/store/constants/fileManagement/s3FileUploadResponseManager'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

import {
  VUEX_STATISTICS_SEND
} from '@/store/constants/statistics'

/* import {
  VUEX_USER_FETCH_AWS_CREDENTIALS
} from '@/store/constants/user' */

// const QUEUE_SIZE = 4
// const CHUNK_SIZE = (10 * 1024 * 1024) // 10MB
const SIGNED_URL_BASE = { urls: [], uploadId: null }

const d = new Debug('its:store:modules:api:aws')

const state = {
  signedAsset: null,
  signedURLData: {
    upload: SIGNED_URL_BASE
  }
}

const getters = {}

const actions = {
  /* ------------------------------------------- */
  // SIGNED URLS
  /* ------------------------------------------- */
  // Get signed URLs from BE to prepare for asset upload
  [VUEX_API_S3_GET_SIGNED_URLS]: async ({ commit }, payload) => {
    return api.post(`/upload/aws/s3/create`, payload).then(response => {
      const { urls, uploadId } = response.data
      const returnData = { type: 'upload', data: { urls, uploadId } }

      commit(VUEX_API_S3_GET_SIGNED_URLS, returnData)
      return returnData
    })
  },

  // Send completion request to BE
  [VUEX_API_S3_COMPLETE_UPLOAD]: async ({ commit }, payload) => {
    return api.post(`/upload/aws/s3/complete`, payload).then(response => {
      commit(VUEX_API_S3_COMPLETE_UPLOAD, response)
      return response
    })
  },
  /* ------------------------------------------- */
  /* ------------------------------------------- */

  [VUEX_API_S3_UPLOAD_REQUEST]: async ({ rootState, dispatch, commit }, payload) => {
    await dispatch(VUEX_S3FUM_CHANGE_STATUS, {
      hashId: payload.hashId,
      status: ITS__FILE__PROCESSING__STATUS__STARTED
    })

    let destinationFileKey = ''
    /*
     * BUCKETS:
      bucket: Server controlled resources
      publicBucket: Open bucket for unsigned resources
      syncBucket: Bucket for synced resources
    */
    let bucket = rootState.options.data.aws.s3.bucket

    if (!payload.filename) {
      d.log('store/modules/api/aws.js | `filename` not defined in payload -> aborting')
      return
    }

    switch (payload.usageType) {
      case feConstants.ITS__S3FUM__USAGE_TYPE__ASSORTMENT_LOGO:
      case feConstants.ITS__S3FUM__USAGE_TYPE__ASSORTMENT_PRODUCT:
        /* ---------------------------------------- */
        // Set path destination and is also used as file key for upload request
        // This can be extended to include other models
        /* ---------------------------------------- */
        if (!payload.attachTo) {
          d.log('store/modules/api/aws.js | `attachTo` not defined in payload -> aborting')
          return
        }

        bucket = payload.bucket || rootState.options.data.aws.s3.publicBucket
        destinationFileKey = `assortments/${payload.attachTo}/${payload.filename}`
        break
      case feConstants.ITS__S3FUM__USAGE_TYPE__ASSORTMENT_DISPLAYGROUP :
        bucket = payload.bucket || rootState.options.data.aws.s3.publicBucket
        destinationFileKey = `assortments/${payload.attachTo}/customContent/${payload.filename}`
        break
      case feConstants.ITS__S3FUM__USAGE_TYPE__LIBRARIES_ASSET:
        if (!payload.libraryType) {
          d.log('store/modules/api/aws.js | `libraryType` not defined in payload -> aborting')
          return
        }

        destinationFileKey = `uploads/${payload.libraryType}/${payload.filename}`
        bucket = payload.bucket || rootState.options.data.aws.s3.syncBucket
        break
      case feConstants.ITS__S3FUM__USAGE_TYPE__SEASON_COVER:
        bucket = payload.bucket || rootState.options.data.aws.s3.publicBucket
        destinationFileKey = `seasons/${payload.year}/${payload.season}/${payload.channel}/${payload.filename}`
        break
    }

    /* ---------------------------------------- */
    /* ---------------------------------------- */
    dispatch(VUEX_S3FUM_ASSIGN_S3_KEY, {
      hashId: payload.hashId,
      key: destinationFileKey
    })

    const multipartUploader = new MultipartUploader({
      awsConfig: {
        bucket: bucket,
        key: destinationFileKey,
        fileSize: payload.file.size,
        forceOverwrite: true
      }
    })

    multipartUploader.upload(payload.file)
      .onUploadProgress(progress => {
        commit(VUEX_S3FUM_UPLOAD_PROGRESS, {
          ...progress,
          key: destinationFileKey
        })
      })
      .onUploadComplete(status => {
        if (!status) return

        if (status === ITS__FILE__PROCESSING__STATUS__SUCCESS) {
          dispatch(VUEX_API_S3_UPLOAD_REQUEST_SUCCESS, {
            ...payload,
            uri: s3Href(payload.key, bucket),
            data: status
          })
        } else {
          dispatch(VUEX_API_S3_UPLOAD_REQUEST_FAILURE, {
            ...payload,
            error: status
          })
        }
      })
  },

  /**
   * Amend the DB record upon success
   */
  [VUEX_API_S3_UPLOAD_REQUEST_SUCCESS]: async ({ dispatch }, payload) => {
    dispatch(VUEX_S3FUM_UPLOAD_RESULT_HANDLER, {
      hashId: payload.hashId,
      uri: payload.uri,
      status: ITS__FILE__PROCESSING__STATUS__SUCCESS
    })

    dispatch(VUEX_S3FUM_RESPONSE_HANDLER, payload)
  },

  /**
   * Amend the DB record upon failure
   */
  [VUEX_API_S3_UPLOAD_REQUEST_FAILURE]: async ({ dispatch }, payload) => {
    dispatch(VUEX_S3FUM_UPLOAD_RESULT_HANDLER, {
      hashId: payload.hashId,
      status: ITS__FILE__PROCESSING__STATUS__FAILED
    })

    dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
      component: '_core/Toast/Toast_Message.vue',
      data: {
        type: 'error',
        message: `Error: Attachment failed upload to Amazon S3`
      }
    })
  },

  [VUEX_API_S3_DOWNLOAD_PUBLIC]: async ({ dispatch }, payload) => {
    const file = payload.file
    const statData = payload.statData

    // const assetMimeType = payload.file.mimeType ? payload.file.mimeType + ':' : ''
    // const assetHref = `${assetMimeType}https://${s3.publicBucket}.${s3.domain}/${file.sourceFile}`
    const assetHref = s3Href(file.sourceFile)
    const link = document.createElement('a')

    link.target = '_blank'
    link.download = file.filename
    link.href = assetHref
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // create statistic record for this download action
    let actionName
    switch (file.usageType) {
      case 'Primary':
        actionName = ITS__STATISTICS__ACTION_TYPE__DOWNLOAD__ASSET_PRIMARY
        break
      case 'Working':
        actionName = ITS__STATISTICS__ACTION_TYPE__DOWNLOAD__ASSET_WORKING
        break
    }

    await dispatch(VUEX_STATISTICS_SEND, {
      action: actionName,
      modelType: 'AssetFile',
      modelId: file._id,
      data: Object.assign({}, statData || {}, {
        sourceFile: file.sourceFile,
        usageType: file.usageType
      })
    })
  },

  // TODO: Refactor this to integrate it into proper VUEX flows
  // this is just a very simple example of how to do this
  // payload needs whole asset file passed for several properties like { file }
  // this is only needed for asset sourceFile not for previews
  // you can also pass statData for data to be sent with the statistic
  [VUEX_API_S3_DOWNLOAD_SIGNED_REQUEST]: async ({ rootState, dispatch }, payload) => {
    const file = payload.file
    const propertyName = payload.propertyName || 'sourceFile'
    const statData = payload.statData

    // TODO: Better way to get the libraryType
    let libraryType = rootState.route.query.libraryType
    let librarySubType = rootState.route.query.librarySubType
    // NOTE from Matt 8-7-2020 - This search above was failing the libraries working file download
    // I added this new check because libraryType was returning blanks - and we definite it specifically now in the route meta data
    if (!libraryType) {
      libraryType = rootState.route.meta.libraryType
    }
    // I added this new check because libraryType was returning blanks - and we definite it specifically now in the route meta data
    if (!librarySubType) {
      librarySubType = rootState.route.meta.librarySubType
    }

    // !! Not needed now that using signed urls from server
    // Fetch user creds and AWS data from server
    // Disabled for now await dispatch(VUEX_USER_FETCH_AWS_CREDENTIALS)
    // Create S3 object from AWSApi class | pass in user creds, S3 region, S3 bucket name
    // const s3 = awsS3.createS3(rootState.user.awsCredentials.Credentials, rootState.options.data.aws.s3.region, rootState.options.data.aws.s3.syncBucket)
    // var params = { Key: file.sourceFile, Expires: 120, ResponseContentType: file.mimeType, ResponseContentDisposition: `attachment; filename="${encodeURIComponent(file.filename)}"` }
    // var signedUrl = await s3.getSignedUrlPromise('getObject', params)

    const data = {
      libraryType,
      'files._id': file._id,

      _options: {
        signedUrls: true
      },
      _select: {
        _id: 1,
        'files._id': 1,
        [`files.${propertyName}`]: 1
        // 'files.sourceFile': 1,
        // 'files.previewJpg': 1,
        // 'files.previewPdf': 1,
        // 'files.previewVideo': 1
      }
    }
    if (librarySubType) data.librarySubType = librarySubType

    // const response = await api.post({ url: '/api/assets/upload/signed-url', data: { key: })
    const response = await api.post(`/assets/${libraryType}`, data)

    const responseFile = response.data?.[0]?.files?.find(f => f._id === file._id)
    const signedUrl = responseFile?.[propertyName]
    d.log('Signed url', signedUrl, payload)

    // set returnBase64 to trun to return base24 data for the asset, instead of triggering a download
    if (payload.returnBase64) {
      let ret = ''
      await axios
        .get(signedUrl, {
          responseType: 'arraybuffer'
        })
        .then(response => {
          ret = Buffer.from(response.data, 'binary').toString('base64')
        })
      return ret
    } else {
      // generate url
      const link = document.createElement('a')
      link.download = file.filename
      document.body.appendChild(link)
      link.href = signedUrl
      link.click()
      document.body.removeChild(link)

      // create statistic record for this download action
      let actionName
      switch (file.usageType) {
        case 'Primary':
          actionName = ITS__STATISTICS__ACTION_TYPE__DOWNLOAD__ASSET_PRIMARY
          break
        case 'Working':
          actionName = ITS__STATISTICS__ACTION_TYPE__DOWNLOAD__ASSET_WORKING
          break
      }

      await dispatch(VUEX_STATISTICS_SEND, {
        action: actionName,
        modelType: 'AssetFile',
        modelId: file._id,
        data: Object.assign({}, statData || {}, {
          sourceFile: file.sourceFile,
          usageType: file.usageType
        })
      })
    }
  },

  [VUEX_API_S3_ASSETS_SIGNED_REQUEST]: async ({ rootState, commit }, payload) => {
    const file = payload.file

    // TODO: Better way to get the libraryType
    let libraryType = rootState.route.query.libraryType
    let librarySubType = rootState.route.query.librarySubType
    // NOTE from Matt 8-7-2020 - This search above was failing the libraries working file download
    // I added this new check because libraryType was returning blanks - and we definite it specifically now in the route meta data
    if (!libraryType) {
      libraryType = rootState.route.meta.libraryType
    }
    // I added this new check because libraryType was returning blanks - and we definite it specifically now in the route meta data
    if (!librarySubType) {
      librarySubType = rootState.route.meta.librarySubType
    }

    const data = {
      libraryType,
      'files._id': file._id,

      _options: {
        signedUrls: true
      },
      _select: {
        _id: 1,
        'files._id': 1,
        'files.sourceFile': 1,
        'files.previewJpg': 1,
        'files.previewPdf': 1,
        'files.previewVideo': 1
      }
    }

    if (librarySubType) data.librarySubType = librarySubType

    const response = await api.post(`/assets/${libraryType}`, data)

    const responseFile = response.data?.[0]?.files?.find(f => f._id === file._id)

    await commit(VUEX_API_S3_ASSETS_SIGNED_REQUEST, responseFile)
  }
}

const mutations = {
  [VUEX_API_S3_GET_SIGNED_URLS]: (state, data) => {
    state.signedURLData[data.type] = data.data
  },

  [VUEX_API_S3_COMPLETE_UPLOAD]: (state, data) => {
    //
  },

  [VUEX_API_S3_ASSETS_SIGNED_REQUEST]: (state, data) => {
    state.signedAsset = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
