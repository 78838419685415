import { emitter } from '@/main'
import isEqual from 'lodash.isequal'
import { uuid } from 'vue3-uuid'
import Debug from 'logdown'

import {
  VUEX_ASSORTMENT_DOWNLOADS_ADD_TO_QUEUE,

  VUEX_ASSORTMENT_DOWNLOADS_HASH_PUSH,
  VUEX_ASSORTMENT_DOWNLOADS_HASH_POP,

  VUEX_ASSORTMENT_DOWNLOADS_ENQUEUE,

  VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER,
  VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_SUCCESS,
  VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_FAILED,

  VUEX_ASSORTMENT_DOWNLOADS_MOVE_TO_BUCKET,

  VUEX_ASSORTMENT_DOWNLOADS_CHANGE_STATUS
} from '@/store/constants/models/assortments/assortmentDownloads'

import {
  VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD
} from '@/store/constants/api'
const d = new Debug('its:store:modules:assortmentsDownloads')

const ASSORTMENT_DOWNLOADS_QUEUE_SIZE = 3

const ASSORTMENT_DOWNLOADS_QUEUED_BUCKET = 'requestsQueued'
const ASSORTMENT_DOWNLOADS_PROCESSING_BUCKET = 'requestsProcessing'
const ASSORTMENT_DOWNLOADS_COMPLETED_BUCKET = 'requestsCompleted'
const ASSORTMENT_DOWNLOADS_FAILED_BUCKET = 'requestsFailed'

const state = {
  requestHash: {}, // request objects

  requestsQueued: [],
  requestsProcessing: [],
  requestsCompleted: [],
  requestsFailed: []
}

const getters = {
  assortmentDownloads_requestsQueued (state) {
    return params => state.requestsQueued.map(hashId => state.requestHash[hashId]).reduce(function (filtered, requestObject) {
      if (!params || isEqual(requestObject.attachTo, params.attachTo)) {
        filtered.push(requestObject)
      }
      return filtered
    }, [])
  },

  assortmentDownloads_requestsProcessing (state) {
    return params => state.requestsProcessing.map(hashId => state.requestHash[hashId]).reduce(function (filtered, requestObject) {
      if (!params || isEqual(requestObject.attachTo, params.attachTo)) {
        filtered.push(requestObject)
      }
      return filtered
    }, [])
  },

  assortmentDownloads_requestsCompleted (state) {
    return params => state.requestsCompleted.map(hashId => state.requestHash[hashId]).reduce(function (filtered, requestObject) {
      if (!params || isEqual(requestObject.attachTo, params.attachTo)) {
        filtered.push(requestObject)
      }
      return filtered
    }, [])
  },

  assortmentDownloads_requestsFailed (state) {
    return params => state.requestsFailed.map(hashId => state.requestHash[hashId]).reduce(function (filtered, requestObject) {
      if (!params || isEqual(requestObject.attachTo, params.attachTo)) {
        filtered.push(requestObject)
      }
      return filtered
    }, [])
  }
}

const actions = {
  [VUEX_ASSORTMENT_DOWNLOADS_ADD_TO_QUEUE]: async ({ commit, dispatch }, payload) => {
    let requestObject

    for (let request in payload) {
      requestObject = payload[request]

      let requestHashId = uuid.v4().replace(/-/g, '')
      requestObject.hashId = requestHashId

      requestObject.addedToQueue = Date.now()
      requestObject.status = ITS__FILE__PROCESSING__STATUS__PENDING
      requestObject.filename = requestObject.filename.replace(/ /g, '_')
      requestObject.displayName = requestObject.filename.replace(/_/g, ' ')

      await commit(VUEX_ASSORTMENT_DOWNLOADS_HASH_PUSH, { key: requestHashId, val: requestObject })
      await commit(VUEX_ASSORTMENT_DOWNLOADS_MOVE_TO_BUCKET, { hashId: requestHashId, from: null, to: ASSORTMENT_DOWNLOADS_QUEUED_BUCKET })

      dispatch(VUEX_ASSORTMENT_DOWNLOADS_ENQUEUE)
    }
  },

  [VUEX_ASSORTMENT_DOWNLOADS_ENQUEUE]: async ({ state, dispatch, commit }) => {
    if (!state.requestsQueued.length || state.requestsProcessing.length >= ASSORTMENT_DOWNLOADS_QUEUE_SIZE) return

    let requestHashId = state.requestsQueued[0]

    await dispatch(VUEX_ASSORTMENT_DOWNLOADS_CHANGE_STATUS, {
      hashId: requestHashId,
      status: ITS__FILE__PROCESSING__STATUS__STARTED
    })

    await commit(VUEX_ASSORTMENT_DOWNLOADS_MOVE_TO_BUCKET, {
      hashId: requestHashId,
      from: ASSORTMENT_DOWNLOADS_QUEUED_BUCKET,
      to: ASSORTMENT_DOWNLOADS_PROCESSING_BUCKET
    })

    let requestObject = state.requestHash[requestHashId]

    // dispatch request
    dispatch(VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD, requestObject).then(response => {
      // if (response.status !== ITS__FILE__PROCESSING__STATUS__SUCCESS || response.status !== ITS__FILE__PROCESSING__STATUS__FAILED) return
      dispatch(VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER, response)
    })

    dispatch(VUEX_ASSORTMENT_DOWNLOADS_ENQUEUE)
  },

  [VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER, payload)

    // TODO: This block could be streamlined
    if (payload.status === ITS__FILE__PROCESSING__STATUS__SUCCESS) {
      // move to bucket
      await commit(VUEX_ASSORTMENT_DOWNLOADS_MOVE_TO_BUCKET, {
        hashId: payload.hashId,
        from: ASSORTMENT_DOWNLOADS_PROCESSING_BUCKET,
        to: ASSORTMENT_DOWNLOADS_COMPLETED_BUCKET
      })
      // handle success
      dispatch(VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_SUCCESS, payload)
    } else if (payload.status === ITS__FILE__PROCESSING__STATUS__FAILED || payload.status === ITS__FILE__PROCESSING__STATUS__CANCELLED) {
      // move to bucket
      await commit(VUEX_ASSORTMENT_DOWNLOADS_MOVE_TO_BUCKET, {
        hashId: payload.hashId,
        from: ASSORTMENT_DOWNLOADS_PROCESSING_BUCKET,
        to: ASSORTMENT_DOWNLOADS_FAILED_BUCKET
      })
      // handle failed
      dispatch(VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_FAILED, payload)
    }

    // See if we have more uploading to do
    dispatch(VUEX_ASSORTMENT_DOWNLOADS_ENQUEUE)
  },

  [VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_SUCCESS]: ({ dispatch, commit }, payload) => {
    emitter.emit(`file-download-handler--${payload.action.toLowerCase()}`, payload)

    // remove file from requestQueue
    setTimeout(() => {
      commit(VUEX_ASSORTMENT_DOWNLOADS_HASH_POP, payload)
    }, ITS__UI__PROGRESS__DELAY__FILE_POP)
  },

  [VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_FAILED]: ({ dispatch, commit }, payload) => {
    d.log('VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER_FAILED', payload)
    setTimeout(() => {
      commit(VUEX_ASSORTMENT_DOWNLOADS_HASH_POP, payload)
    }, ITS__UI__PROGRESS__DELAY__FILE_POP)
  },

  [VUEX_ASSORTMENT_DOWNLOADS_CHANGE_STATUS]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_DOWNLOADS_CHANGE_STATUS, payload)
  }
}

const mutations = {
  [VUEX_ASSORTMENT_DOWNLOADS_HASH_PUSH]: (state, data) => {
    state.requestHash[data.key] = data.val
  },

  [VUEX_ASSORTMENT_DOWNLOADS_HASH_POP]: (state, data) => {
    let index = null

    switch (data.status) {
      case ITS__FILE__PROCESSING__STATUS__SUCCESS :
        index = state.requestsCompleted.indexOf(data.hashId)
        state.requestsCompleted.splice(index, 1)
        break
      case ITS__FILE__PROCESSING__STATUS__FAILED :
      case ITS__FILE__PROCESSING__STATUS__CANCELLED :
        index = state.requestsFailed.indexOf(data.hashId)
        state.requestsFailed.splice(index, 1)
        break
    }

    delete state.requestHash[data.hashId]
  },

  [VUEX_ASSORTMENT_DOWNLOADS_RESULT_HANDLER]: (state, data) => {
    state.requestHash[data.hashId].status = data.status
  },

  [VUEX_ASSORTMENT_DOWNLOADS_MOVE_TO_BUCKET]: (state, data) => {
    // Remove it from preceding bucket
    if (data.from) {
      let index = state[data.from].indexOf(data.hashId)
      if (index > -1) state[data.from].splice(index, 1)
    }

    // Add it to next bucket
    state[data.to].unshift(data.hashId)
  },

  // Change status of file object
  [VUEX_ASSORTMENT_DOWNLOADS_CHANGE_STATUS]: (state, data) => {
    state.requestHash[data.hashId].status = data.status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
