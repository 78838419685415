import axios from 'axios'
import memoize, { collectStats } from 'moize'

import store from '@/store/store'

import { VUEX_USER_SET } from '@/store/constants/user'
import { VUEX_DIALOG_SHOW } from '@/store/constants/ui/dialog'

import Debug from 'logdown'

const d = new Debug('its:api')

const maxSize = 10000
const maxAge = 1000 * 60 * 60 // 1 hr

const axiosDefaults = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true
}
// can set default (auth) header later like this if needed
// axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
// ref: https://stackoverflow.com/a/52115487

// eslint-disable-next-line
const customAxios = axios.create(axiosDefaults)

const memoizeDefaults = {
  maxSize,
  maxAge,
  isDeepEqual: false, // probably not needed since we stringify with qs?
  isPromise: true,
  updateExpire: false,
  onCacheAdd: function () { d.log('Cache add', this.getStats()) },
  onCacheHit: function () { d.log('Cache hit', this.getStats()) },
  onCacheChange: function () { d.log('Cache change', this.getStats()) }
}

collectStats()

export function factory (memoizeOptions, axiosOptions) {
  // create a new axios only if passed in new axios options
  const extendedAxios = axiosOptions ? axios.create(Object.assign({}, axiosDefaults, axiosOptions || {})) : customAxios

  /**
   * A custom axios interceptor. The function wraps every error response and
   * when a 401 Unathorized is detected it will dispatch a Vuex action to invalidate the
   * local app user, then redirect to login.
   *
   * @function
   */
  //
  extendedAxios.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })

  extendedAxios.interceptors.response.use(response => {
    return response
  }, error => {
    // d.warn('Intercepted error', error, error.response)
    if (error.response && error.response.status === 401 && (!error.config || !error.response.config.url.includes('/users/session'))) {
      d.warn('401 intercept!')
      store.dispatch(VUEX_USER_SET, null)
    } else if (error.response && error.response.status >= 500) {
      d.error('500 error intercept!', error)
      store.dispatch(VUEX_DIALOG_SHOW, {
        content: '_core/Dialogs/Alerts/Dialog_ServerError.vue',
        title: 'Unexpected Server Error',
        message: 'Please refresh your browser and try again. If that has failed, please contact the It\'s the S administrator at itsthes_admin@skechers.com, let them know what you were doing and provide the information below.',
        props: {
          actions: ['reloadable']
        },
        data: {
          message: error.response.data?.message,
          details: error.response.data?.details || error.response.data || ' - No additional information available -'
        }
      })
    } else if (!error.response && !error.message) {
      // Check to see if this is a legit cancel request - if so, don't output the default error message
      if (axios.isCancel(error)) {
        d.error('axios.isCancel thrown')
      } else {
        // Best we can do here...
        // Ref: https://github.com/axios/axios/issues/838
        d.error('Network error intercept!')
        store.dispatch(VUEX_DIALOG_SHOW, {
          content: '_core/Dialogs/Alerts/Dialog_ServerError.vue',
          title: 'Network Error',
          message: 'Please refresh your browser and try again. If that has failed, please contact the It\'s the S administrator at itsthes_admin@skechers.com, let them know what you were doing, what browser (and version) you use, whether you can connect to other websites, and provide the information below.',
          data: {
            details: 'This error is because you could not connect to our server. This could be because you are not connected to the internet. If you can connect to other websites, please notify the admin that it is probably a CORS issue.'
          }
        })
      }
    }
    return Promise.reject(error)
  })

  // freeze for Vue performance: https://github.com/vuejs/vuejs.org/issues/978
  const frozenGet = async (uri) => {
    const results = await axios.get(uri)
    if (typeof results !== 'object') return results
    else if (!Array.isArray(results)) return Object.freeze(results)
    for (let i = 0; i < results.length; i++) {
      Object.freeze(results[i])
    }
    return results
  }

  // memoize/cache get requests
  const memoizedGet = memoize(extendedAxios.get, Object.assign({}, memoizeDefaults, memoizeOptions || {}))

  const cachedGet = (uri) => {
    return memoizedGet(uri)
  }

  // cache and freeze for Vue performance: https://github.com/vuejs/vuejs.org/issues/978
  const cachedFrozenGet = async (uri) => {
    const results = await cachedGet(uri)
    if (typeof results !== 'object') return results
    else if (!Array.isArray(results)) return Object.freeze(results)
    let api = uri.split('?')[0]
    if (api.charAt(api.length - 1) !== '/') api += '/'
    for (let i = 0; i < results.length; i++) {
      cachedGet.add(`${api}${results[i]._id}`, results[i]) // Cache individual items too. We assume a normal REST API structure here, and object id is at _id. TODO: make configurable
      Object.freeze(results[i])
    }
    return results
  }

  // invalidate cache when needed
  const forget = (uri) => {
    cachedGet.remove(uri)
  }

  // clear all cache entries
  const forgetAll = () => {
    cachedGet.clear()
  }

  Object.assign(extendedAxios, {
    frozenGet,
    cachedGet,
    cachedFrozenGet,
    forget,
    forgetAll
  })

  return extendedAxios
}

// export a default instance
export default factory()
