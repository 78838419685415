// import axios from 'axios'
import api from '@/api'
import Debug from 'logdown'

import {
  VUEX_API_STATISTICS_SEND
} from '@/store/constants/api'

const d = new Debug('its:store:modules:api:statistics')

const state = {}

const getters = {}

const actions = {
  [VUEX_API_STATISTICS_SEND]: ({ state, rootState, dispatch, commit }, payload) => {
    // d.log(payload, 'Payload')
    if (!payload.action) {
      d.error(`api/stats | 'action' missing on payload object`)
      return
    }

    let data = {
      ...payload,
      // TODO: would probably be better to pass assortment in where needed since this function serves the entire app, not just assortment manager
      // and because stats now support modelType and modelId fields generally
      ...(payload.action.includes('assortment') ? { assortment: payload.assortment ? payload.assortment : rootState.assortments.assortment._id } : {})
    }

    return api.post(`/stats`, data, {
      params: {}
    }).then(response => {
      return response
    }).catch(err => {
      d.error(err)
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
