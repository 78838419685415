export const VUEX_GRID_ROWS_ADD = 'VUEX_GRID_ROWS_ADD'
export const VUEX_GRID_ROWS_REMOVE = 'VUEX_GRID_ROWS_REMOVE'
export const VUEX_GRID_REQUEST_SNAPSHOT = 'VUEX_GRID_REQUEST_SNAPSHOT'
export const VUEX_GRID_IMPORT_SPREADSHEET_CLOSE = 'VUEX_GRID_IMPORT_SPREADSHEET_CLOSE'
export const VUEX_GRID_LIST_SET_FILTER = 'VUEX_GRID_LIST_SET_FILTER'

export const VUEX_GRID_REKEY = 'VUEX_GRID_REKEY'
export const VUEX_GRID_ROWDATA_LOADING_START = 'VUEX_GRID_ROWDATA_LOADING_START'
export const VUEX_GRID_ROWDATA_LOADING_FINISH = 'VUEX_GRID_ROWDATA_LOADING_FINISH'
export const VUEX_GRID_CONFIRMATION_ROW_DELETE = 'VUEX_GRID_CONFIRMATION_ROW_DELETE'
export const VUEX_GRID_CONFIRMATION_ROW_DELETE_CLEAR = 'VUEX_GRID_CONFIRMATION_ROW_DELETE_CLEAR'

export const VUEX_GRID_UPDATE_START = 'VUEX_GRID_UPDATE_START'
export const VUEX_GRID_UPDATE_SUCCESS = 'VUEX_GRID_UPDATE_SUCCESS'
export const VUEX_GRID_UPDATE_FAILURE = 'VUEX_GRID_UPDATE_FAILURE'

export const VUEX_GRID_SETTINGS_SET = 'VUEX_GRID_SETTINGS_SET'
