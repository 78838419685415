const removeEmptyElements = (obj) => {
  let finalObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const nestedObj = removeEmptyElements(obj[key])
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj
      }
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null && obj[key] !== 'null') {
      finalObj[key] = obj[key]
    }
  })
  return finalObj
}

export {
  removeEmptyElements
}
