import {
  VUEX_DIALOG_SHOW,
  VUEX_DIALOG_HIDE,
  VUEX_DIALOG_SET_PROPS,
  VUEX_DIALOG_MODIFY_DATA_PROP
} from '@/store/constants/ui/dialog'

const state = {
  openState: false,
  type: null,
  content: null,
  title: null,
  props: null,
  subtitle: null,
  message: null,
  data: null,
  tabs: [],
  tabBar: null
}

const getters = {

}

const actions = {

  [VUEX_DIALOG_SHOW]: ({ commit }, payload) => {
    commit(VUEX_DIALOG_SHOW, payload)
  },

  [VUEX_DIALOG_HIDE]: ({ state, commit }, dialogType) => {
    if (!state.type && !dialogType) commit(VUEX_DIALOG_HIDE)
    if (dialogType === state.type) commit(VUEX_DIALOG_HIDE)
  },

  [VUEX_DIALOG_SET_PROPS]: ({ commit }, payload) => {
    commit(VUEX_DIALOG_SET_PROPS, payload)
  },

  [VUEX_DIALOG_MODIFY_DATA_PROP]: ({ commit }, payload) => {
    commit(VUEX_DIALOG_MODIFY_DATA_PROP, payload)
  }
}

const mutations = {
  [VUEX_DIALOG_SHOW]: (state, data) => {
    if (typeof data.type === 'string') state.type = data.type
    state.openState = true
    state.content = data.content
    state.title = data.title
    state.subtitle = data.subtitle
    state.props = data.props
    state.message = data.message
    state.data = data.data
    state.tabs = data.tabs
    state.tabBar = data.tabBar
  },

  [VUEX_DIALOG_HIDE]: (state) => {
    state.openState = false
    state.type = null
    state.content = null
    state.title = null
    state.subtitle = null
    state.message = null
    // state.data = null (don't wipe data, some components use this state after a dialogue is closed)
    state.props = null
    state.tabs = []
    state.tabBar = null
  },

  [VUEX_DIALOG_SET_PROPS]: (state, data) => {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
  },

  [VUEX_DIALOG_MODIFY_DATA_PROP]: (state, data) => {
    Object.keys(data).forEach(key => {
      state.data[key] = data[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
