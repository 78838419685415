import { emitter } from '@/main'

import dayjs from 'dayjs'

import {
  VUEX_REPORTING_SUBMIT,
  VUEX_REPORTING_REPORT_ADD_TO_QUEUE,
  VUEX_REPORTING_REPORT_STATUS_UPDATE,
  VUEX_REPORTING_REPORT_POP
} from '@/store/constants/reporting'

import {
  VUEX_API_REPORTING_FETCH
} from '@/store/constants/api'

let utc = require('dayjs/plugin/utc') // dependent on utc plugin
let timezone = require('dayjs/plugin/timezone')
var advanced = require('dayjs/plugin/advancedFormat')
dayjs.extend(utc)
dayjs.extend(advanced)
dayjs.extend(timezone)

const state = {
  reportsQueue: []
}

const getters = {
  reportsQueued (state) {
    return status => state.reportsQueue.filter(item => {
      return status ? item.status === status : item.status
    })
  }
}

const actions = {
  [VUEX_REPORTING_SUBMIT]: async ({ dispatch, commit }, payload) => {
    let request = {
      status: ITS__FILE__PROCESSING__STATUS__STARTED,
      ...payload.settings,
      fileData: {
        ...payload.fileData,
        ...payload.fileData?.filters
      }
    }

    // ----------------------------------------
    // Options
    // Checkbox values that will will be Boolean props on the request
    // ----------------------------------------
    if (payload?.fileData?.options) {
      // eslint-disable-next-line
      payload?.fileData?.options.forEach(option => { request.fileData[option] = true })
      payload.fileData.options.forEach(option => { request.fileData[option] = true })
    }

    // ----------------------------------------
    // Field specific updates
    // ----------------------------------------
    // Add Seasons data
    if (payload.fileData?.period?.seasons) {
      request.fileData.seasons = [
        { season: payload.fileData?.period?.seasons.season, year: payload.fileData?.period?.seasons.year }
      ]
    }

    // Add Dates data
    if (payload.fileData?.period?.dates?.length) {
      // '00:00:00' Changed to 1am as a workaround for daylight savings issue in dayjs
      // https://github.com/iamkun/dayjs/issues/1260
      const timeStart = '01:00:00'
      const timeEnd = '23:59:59'

      const formattedDates = payload.fileData?.period?.dates.map((item, idx) => {
        const formatStr = `YYYY-MM-DDT${!idx ? timeStart : timeEnd}Z`
        return dayjs(item).tz(ITS__TIMEZONE__DEFAULT).format(formatStr)
      })

      request.fileData.dateStart = formattedDates[0]
      request.fileData.dateEnd = formattedDates[1] || formattedDates[0].replace(timeStart, timeEnd)
    }

    // Wrap prop values in array
    // if (payload.fileData?.filters?.divisions) request.fileData.divisions = [payload.fileData.filters.divisions]
    if (payload.fileData?.filters?.accounts) request.fileData.accounts = [payload.fileData.filters.accounts]
    if (payload.fileData?.filters?.seasons) request.fileData.seasons = [payload.fileData.filters.seasons]
    if (payload.fileData?.filters?.territories) request.fileData.territories = payload.fileData.filters.territories
    // ----------------------------------------
    // ----------------------------------------
    // Create new object with only the required properties
    if (payload.fileData?.keyInitiative) {
      request.fileData.keyInitiative = (({ channel, region, productType, season, year }) => ({ channel, region, productType, season, year }))(payload.fileData.keyInitiative)
    }

    // ----------------------------------------
    // // Clean up request
    // ----------------------------------------
    delete request.fileData.filters
    delete request.fileData.options
    delete request.fileData.period

    //
    await commit(VUEX_REPORTING_REPORT_ADD_TO_QUEUE, request)

    //
    dispatch(VUEX_API_REPORTING_FETCH, request).then(async response => {
      await commit(VUEX_REPORTING_REPORT_STATUS_UPDATE, response.status)

      if (response.status === ITS__FILE__PROCESSING__STATUS__SUCCESS) {
        emitter.emit(`file-download-handler--${response.action.toLowerCase()}`, response)
        setTimeout(() => {
          commit(VUEX_REPORTING_REPORT_POP)
        }, 1000)
      }
    })
  }
}

const mutations = {
  [VUEX_REPORTING_REPORT_ADD_TO_QUEUE]: (state, data) => {
    state.reportsQueue.push(data)
  },

  [VUEX_REPORTING_REPORT_STATUS_UPDATE]: (state, data) => {
    state.reportsQueue[0].status = data
  },

  [VUEX_REPORTING_REPORT_POP]: state => {
    state.reportsQueue = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
