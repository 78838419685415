import isEqual from 'lodash.isequal'
import { uuid } from 'vue3-uuid'
// import cloneDeep from 'lodash/cloneDeep'
import {
  VUEX_S3FUM_ADD_FILES,
  VUEX_S3FUM_HASH_FILE_PUSH,
  VUEX_S3FUM_HASH_FILE_POP,

  VUEX_S3FUM_FILE_MOVE_TO_BUCKET,

  VUEX_S3FUM_ENQUEUE_UPLOAD,

  VUEX_S3FUM_ASSIGN_S3_KEY,
  VUEX_S3FUM_UPLOAD_PROGRESS,
  VUEX_S3FUM_CHANGE_STATUS,

  VUEX_S3FUM_UPLOAD_RESULT_HANDLER,
  VUEX_S3FUM_UPLOAD_RESULT_HANDLER_SUCCESS,
  VUEX_S3FUM_UPLOAD_RESULT_HANDLER_FAILED
} from '@/store/constants/fileManagement/s3FileUploadManager'

import {
  VUEX_API_S3_UPLOAD_REQUEST
} from '@/store/constants/api'

const S3FUM_QUEUE_SIZE = 3

const S3FUM_QUEUED_BUCKET = 'filesQueued'
const S3FUM_UPLOADING_BUCKET = 'filesUploading'
const S3FUM_COMPLETED_BUCKET = 'filesCompleted'
const S3FUM_FAILED_BUCKET = 'filesFailed'

const state = {
  fileHash: {}, // file objects

  filesQueued: [],
  filesUploading: [],
  filesCompleted: [],
  filesFailed: []
}

const getters = {
  s3FUM_filesQueued (state) {
    return params => state.filesQueued.map(hashId => state.fileHash[hashId]).reduce(function (filtered, fileObject) {
      if (!params || isEqual(fileObject.attachTo, params.attachTo)) {
        filtered.push(fileObject)
      }
      return filtered
    }, [])
  },

  s3FUM_filesUploading (state) {
    return params => state.filesUploading.map(hashId => state.fileHash[hashId]).reduce(function (filtered, fileObject) {
      if (!params || isEqual(fileObject.attachTo, params.attachTo)) {
        filtered.push(fileObject)
      }
      return filtered
    }, [])
  },

  s3FUM_filesCompleted (state) {
    return params => state.filesCompleted.map(hashId => state.fileHash[hashId]).reduce(function (filtered, fileObject) {
      if (!params || isEqual(fileObject.attachTo, params.attachTo)) {
        filtered.push(fileObject)
      }
      return filtered
    }, [])
  },

  s3FUM_filesFailed (state) {
    return params => state.filesFailed.map(hashId => state.fileHash[hashId]).reduce(function (filtered, fileObject) {
      if (!params || isEqual(fileObject.attachTo, params.attachTo)) {
        filtered.push(fileObject)
      }
      return filtered
    }, [])
  }
}

const actions = {
  /*
  * Add files to `fileHash` for processing
  */
  [VUEX_S3FUM_ADD_FILES]: async ({ dispatch, commit }, payload) => {
    let fileObject

    for (let item in payload) {
      fileObject = payload[item]

      let fileHashId = uuid.v4().replace(/-/g, '')

      fileObject.hashId = fileHashId
      fileObject.status = ITS__FILE__PROCESSING__STATUS__PENDING
      fileObject.filename = fileObject.filename || fileObject.file.name.replace(/\s/g, '_')
      fileObject.displayName = fileObject.filename.replace(/_/g, ' ') || fileObject.file.name.replace(/_/g, ' ')
      fileObject.progress = {}

      // For the sorting in the UI
      fileObject.addedToQueue = Date.now()

      // Push file into `fileHash` with key of `fileHashId`
      await commit(VUEX_S3FUM_HASH_FILE_PUSH, { key: fileHashId, val: fileObject })
      // Move file representation into approriate bucket
      await commit(VUEX_S3FUM_FILE_MOVE_TO_BUCKET, { hashId: fileHashId, from: null, to: S3FUM_QUEUED_BUCKET })
      // Start upload process
    }

    dispatch(VUEX_S3FUM_ENQUEUE_UPLOAD)
  },

  /*
  * Initiate upload Queue
  */
  [VUEX_S3FUM_ENQUEUE_UPLOAD]: async ({ state, dispatch, commit }) => {
    // Only start process if there is work to do
    if (!state.filesQueued.length || state.filesUploading.length >= S3FUM_QUEUE_SIZE) {
      return
    }

    let fileHashId = state.filesQueued[0]

    // Set status on file
    await commit(VUEX_S3FUM_CHANGE_STATUS, { hashId: fileHashId, status: ITS__FILE__PROCESSING__STATUS__STARTED })
    // Move from queued to upload bucket
    await commit(VUEX_S3FUM_FILE_MOVE_TO_BUCKET, { hashId: fileHashId, from: S3FUM_QUEUED_BUCKET, to: S3FUM_UPLOADING_BUCKET })

    // Get file object fist
    let fileObject = state.fileHash[fileHashId]

    // Initiate upload to S3
    dispatch(VUEX_API_S3_UPLOAD_REQUEST, fileObject)

    // Initiate more uploads if possible
    // if (state.files.filesQueued.length && state.filesUploading.length < S3FUM_QUEUE_SIZE) {}
    dispatch(VUEX_S3FUM_ENQUEUE_UPLOAD)
  },

  /*
  * Handle upload result from `api/aws'
  */
  [VUEX_S3FUM_UPLOAD_RESULT_HANDLER]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_S3FUM_UPLOAD_RESULT_HANDLER, payload)

    // TODO: This block could be streamlined
    if (payload.status === ITS__FILE__PROCESSING__STATUS__SUCCESS) {
      await commit(VUEX_S3FUM_FILE_MOVE_TO_BUCKET, { hashId: payload.hashId, from: S3FUM_UPLOADING_BUCKET, to: S3FUM_COMPLETED_BUCKET })
      dispatch(VUEX_S3FUM_UPLOAD_RESULT_HANDLER_SUCCESS, payload)
    } else if (payload.status === ITS__FILE__PROCESSING__STATUS__FAILED) {
      await commit(VUEX_S3FUM_FILE_MOVE_TO_BUCKET, { hashId: payload.hashId, from: S3FUM_UPLOADING_BUCKET, to: S3FUM_FAILED_BUCKET })
      dispatch(VUEX_S3FUM_UPLOAD_RESULT_HANDLER_FAILED, payload)
    }

    // See if we have more uploading to do
    dispatch(VUEX_S3FUM_ENQUEUE_UPLOAD)
  },

  // Handle upload SUCCESS
  [VUEX_S3FUM_UPLOAD_RESULT_HANDLER_SUCCESS]: ({ commit }, payload) => {
    setTimeout(() => {
      commit(VUEX_S3FUM_HASH_FILE_POP, payload)
    }, ITS__UI__PROGRESS__DELAY__FILE_POP)
  },
  // Handle upload FAILED
  [VUEX_S3FUM_UPLOAD_RESULT_HANDLER_FAILED]: ({ commit }, payload) => {
    setTimeout(() => {
      commit(VUEX_S3FUM_HASH_FILE_POP, payload)
    }, ITS__UI__PROGRESS__DELAY__FILE_POP)
  },

  [VUEX_S3FUM_CHANGE_STATUS]: ({ commit }, payload) => {
    commit(VUEX_S3FUM_CHANGE_STATUS, payload)
  },

  /*
  * Set AWS key on file object
  */
  [VUEX_S3FUM_ASSIGN_S3_KEY]: ({ commit }, payload) => {
    commit(VUEX_S3FUM_ASSIGN_S3_KEY, payload)
  }
}

const mutations = {
  // Expects { key: hashId, val: fileObject }
  [VUEX_S3FUM_HASH_FILE_PUSH]: (state, data) => {
    state.fileHash[data.key] = data.val
  },

  [VUEX_S3FUM_HASH_FILE_POP]: (state, data) => {
    let index = null

    switch (data.status) {
      case ITS__FILE__PROCESSING__STATUS__SUCCESS :
        index = state.filesCompleted.indexOf(data.hashId)
        state.filesCompleted.splice(index, 1)
        break
      case ITS__FILE__PROCESSING__STATUS__FAILED :
        index = state.filesFailed.indexOf(data.hashId)
        state.filesFailed.splice(index, 1)
        break
    }

    delete state.fileHash[data.hashId]
  },

  [VUEX_S3FUM_FILE_MOVE_TO_BUCKET]: (state, data) => {
    // Remove it from preceding bucket
    if (data.from) {
      let index = state[data.from].indexOf(data.hashId)
      if (index > -1) state[data.from].splice(index, 1)
    }

    // Add it to next bucket
    state[data.to].unshift(data.hashId)
  },

  // Assign the S3 key to the file object
  [VUEX_S3FUM_ASSIGN_S3_KEY]: (state, data) => {
    state.fileHash[data.hashId].key = data.key
  },

  // Set the upload progress on a file object
  [VUEX_S3FUM_UPLOAD_PROGRESS]: (state, data) => {
    Object.keys(state.fileHash).forEach(hashId => {
      if (state.fileHash[hashId].key === data.key) {
        state.fileHash[hashId].progress = {
          loaded: data.loaded,
          total: data.total
        }
      }
    })
    // this is what triggers reactivity on the getters
    state.fileHash = { ...state.fileHash }
  },

  // Once the S3 upload is complete, we store the result
  [VUEX_S3FUM_UPLOAD_RESULT_HANDLER]: (state, data) => {
    state.fileHash[data.hashId].status = data.status
    state.fileHash[data.hashId].uri = data.uri ? data.uri : null
  },

  // Change status of file object
  [VUEX_S3FUM_CHANGE_STATUS]: (state, data) => {
    state.fileHash[data.hashId].status = data.status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
