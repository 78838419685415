import feConstants from '@/store/constants/config/fe_constants'

import footwearConfig from '@/components/_core/FilterBar/_config/dataSets/product/products/footwear'
import apparelConfig from '@/components/_core/FilterBar/_config/dataSets/product/products/apparel'
import popConfig from '@/components/_core/FilterBar/_config/dataSets/product/products/pop'

export default {
  config: [
    {
      type: 'single-select',
      data: {
        key: 'productType',
        label: 'Product Type',
        addToRequest: true,
        changeEvent: 'changeFilterBarConfig',
        default: feConstants.ITS__PRODUCT_TYPE__FOOTWEAR,
        options: [
          {
            label: feConstants.ITS__PRODUCT_TYPE__FOOTWEAR,
            value: feConstants.ITS__PRODUCT_TYPE__FOOTWEAR,
            config: footwearConfig,
            sortOrderDefault: true
          },
          {
            label: feConstants.ITS__PRODUCT_TYPE__APPAREL,
            value: feConstants.ITS__PRODUCT_TYPE__APPAREL,
            config: apparelConfig,
            sortOrderDefault: true
          },
          {
            label: feConstants.ITS__PRODUCT_TYPE__POP,
            value: feConstants.ITS__PRODUCT_TYPE__POP,
            config: popConfig,
            sortOrderDefault: true
          }
        ]
      }
    }
  ],
  options: [
    {
      type: 'single-select',
      data: {
        key: 'locations.code',
        label: 'Location',
        changeEvent: 'changeSelectedLocation',
        // showLabel: false,
        addToRequest: true,
        options: 'availableLocations',
        itemText: 'name',
        itemValue: 'code',
        hideIfNoOptions: true,
        default: '___ALL___',
        specialOptions: [
          {
            label: 'ALL',
            value: '___ALL___',
            useProp: 'availableLocations',
            additionalData: {
              selectorOptions: [{
                key: 'locations.lineStatus',
                value: ['A', 'R', 'O']
              }]
              /* filterBarOptions: [{
                key: 'name',
                value: 'test'
              }] */
            },
            omitOptionFrom: [
              {
                key: 'productType',
                values: [feConstants.ITS__PRODUCT_TYPE__POP]
              }
            ],
            disable: {
              modules: [
                { label: 'Availability' },
                { label: 'Price' }
              ]
            }
          }
        ]
      }
    },
    {
      type: 'single-select',
      data: {
        key: 'locations.lineStatus',
        label: 'Status',
        changeEvent: 'changeSelectedStatus',
        addToRequest: true,
        options: 'availableStatuses',
        itemText: 'name',
        itemValue: 'code',
        hideIfNoOptions: true,
        default: 'All',
        specialOptions: [
          {
            label: 'All',
            useProp: 'availableStatuses'
          }
        ]
      }
    }
  ]
}
