import axios from 'axios'
import api from '@/api'
import router from '@/router'
import {
  VUEX_API_LIBRARIES_REQUEST_FETCH,
  VUEX_API_LIBRARIES_REQUEST_FETCH_SUCCESS,
  VUEX_API_LIBRARIES_REQUEST_FETCH_FAILED,

  VUEX_API_LIBRARIES_SET_CANCEL_TOKEN,

  VUEX_API_LIBRARIES_PRODUCTS_UPDATE,
  VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS,
  VUEX_API_LIBRARIES_PRODUCTS_DELETE,
  VUEX_API_LIBRARIES_PRODUCTS_DELETE_SUCCESS,

  VUEX_API_LIBRARIES_FILE_CHECK_FILENAME,

  VUEX_API_LIBRARIES_DOWNLOAD_ASSETS_XLSX_REPORT

} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  librariesRequest: null,
  librariesCancelRequest: null,

  librariesProductUpdate: false,
  librariesProductDelete: false
}

const getters = {}

const actions = {
  [VUEX_API_LIBRARIES_REQUEST_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    if (!payload.params) payload.params = {}

    await commit(VUEX_API_LIBRARIES_REQUEST_FETCH)

    if (state.librariesCancelRequest) state.librariesCancelRequest.cancel()
    await commit(VUEX_API_LIBRARIES_SET_CANCEL_TOKEN, 'librariesCancelRequest')

    // compose options payload
    return api.get(`/assets`, {
      params: payload.params,
      cancelToken: state.librariesCancelRequest.token
    }).then(response => {
      commit(VUEX_API_LIBRARIES_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_LIBRARIES_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/assets' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_LIBRARIES_PRODUCTS_UPDATE]: ({ commit, dispatch }, payload) => {
    commit(VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS, false)
    let assetSlug = router.currentRoute.value.meta.manageType
    return api.patch(`/assets/` + assetSlug + `/batch/many`, payload.data).then(response => {
      commit(VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS, true)
      return response
    }).catch((err) => {
      commit(VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS, false)
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
    })
  },

  [VUEX_API_LIBRARIES_PRODUCTS_DELETE]: ({ commit, dispatch }, payload) => {
    commit(VUEX_API_LIBRARIES_PRODUCTS_DELETE_SUCCESS, false)
    let assetSlug = router.currentRoute.value.meta.manageType
    return api.patch(`/assets/` + assetSlug + `/batch/many`, payload.data).then(response => {
      commit(VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS, true)
      return response
    }).catch((err) => {
      commit(VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS, false)
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
    })
  },

  [VUEX_API_LIBRARIES_FILE_CHECK_FILENAME]: async ({ rootState, commit, dispatch }, payload) => {
    let manageType = rootState.route.meta.manageType
    return api.post(`/assets/` + manageType + `/check-filename`, payload.data).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
      return null
    })
  },

  [VUEX_API_LIBRARIES_DOWNLOAD_ASSETS_XLSX_REPORT]: async ({ dispatch }, payload) => {
    return api.post(`/assets/${payload.libraryType}/report/xlsx`, payload.data, {
      responseType: 'arraybuffer',
      cancelToken: payload.cancelToken.token
    }).then(response => {
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = payload.spreadsheetName
      link.click()
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    })
  }

}

const mutations = {
  // Assortments
  [VUEX_API_LIBRARIES_REQUEST_FETCH]: state => {
    state.assortmentsRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_LIBRARIES_REQUEST_FETCH_SUCCESS]: state => {
    state.assortmentsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_LIBRARIES_REQUEST_FETCH_FAILED]: state => {
    state.assortmentsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_LIBRARIES_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  },

  [VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS]: (state, data) => {
    state.librariesProductUpdate = data
  },

  [VUEX_API_LIBRARIES_PRODUCTS_DELETE_SUCCESS]: (state, data) => {
    state.librariesProductDelete = data
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
