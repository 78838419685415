import api from '@/api'
import {
  VUEX_VERSION_INIT,
  VUEX_VERSION_INITIALIZED,
  VUEX_VERSION_SET_VERSION
} from '@/store/constants/config/version'

/**
 * Constants module state
 * @const
 */
const state = {
  initialized: false
}

/**
 * Constants module getters
 * @const
 */
const getters = {
  versionIsLoaded: state => state.initialized
}

/**
 * Constants module action definitions
 * @const
 */
const actions = {
  [VUEX_VERSION_INIT]: async ({ commit, state }) => {
    commit(VUEX_VERSION_INIT)

    await api.get('/config/version').then((response) => {
      commit(VUEX_VERSION_SET_VERSION, response.data.data)
    })

    commit(VUEX_VERSION_INITIALIZED)
  }
}

/**
 * Constants module mutation definitions
 * @const
 */
const mutations = {
  [VUEX_VERSION_INIT]: () => {},

  [VUEX_VERSION_INITIALIZED]: state => {
    state.initialized = true
  },

  [VUEX_VERSION_SET_VERSION]: (state, data) => {
    state.version = data.version
  }
}

/**
 * The Constants module of our Vuex store.
 * @module store/modules/constants
 */
export default {
  state,
  getters,
  actions,
  mutations
}
