import assetsModifiedDateModule from '@/components/_core/FilterBar/_config/modules/assetsModifiedDataModule.js'

export default {
  modules: [
    {
      label: 'File Name',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter full or partial names separated by commas'
              },
              key: 'name'
            }
          ]
        }
      ]
    },
    {
      label: 'Type',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Enter type'
              },
              key: 'assetType',
              data: 'properties.AdvertisingAsset.properties.assetType.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Category',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'category',
              data: 'properties.AdvertisingAsset.properties.category.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Gender',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'gender',
              data: 'properties.AdvertisingAsset.properties.gender.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Tag',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Select Tag',
                filterable: true,
                multiselect: true
              },
              key: 'tag',
              data: 'properties.AdvertisingAsset.properties.tag.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Season',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Select Season',
                filterable: true,
                hiddenMenu: true
                // multiselect: true
              },
              key: 'season',
              data: 'properties.AdvertisingAsset.properties.season.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Select Year',
                filterable: true,
                hiddenMenu: true
                // multiselect: true
              },
              key: 'year',
              data: 'properties.AdvertisingAsset.properties.year.options'
            }
          ]
        }
      ]
    },
    assetsModifiedDateModule
  ]
}
