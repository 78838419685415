// import _debounce from 'lodash/debounce'
// import _merge from 'lodash/merge'

import router from '@/router'

import {
  VUEX_FILTERBAR_SELECTOR_ADD_PROP
} from '@/store/constants/ui/filterBar/filterBar_Selectors'

import {
  VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY
} from '@/store/constants/ui/filterBar/filterBar_QueryUpdate'

import {
  VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY
} from '@/store/constants/ui/filterBar/filterBar_ProductSearch'

import {
  VUEX_FILTERBAR_OPTIONSLISTS_UPDATE
} from '@/store/constants/ui/filterBar'

const state = {
  selectorProps: {}
}

const getters = {}

const actions = {
  [VUEX_FILTERBAR_SELECTOR_ADD_PROP]: async ({ dispatch, commit }, payload) => {
    Object.keys(payload).forEach(async prop => {
      if (payload[prop]) {
        await commit(VUEX_FILTERBAR_SELECTOR_ADD_PROP, { [prop]: payload[prop] })
        await dispatch(VUEX_FILTERBAR_OPTIONSLISTS_UPDATE)

        if (router.currentRoute?.value.meta?.queryRequest) {
          // Product/Document
          dispatch(VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY)
        } else {
          // Bulk Search & Add
          dispatch(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY)
        }
      }
    })
  }
}

const mutations = {
  [VUEX_FILTERBAR_SELECTOR_ADD_PROP]: (state, data) => {
    state.selectorProps = { ...state.selectorProps, ...data }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
