import store from '@/store/store'
import checkUserPermissions from '@/mixins/checkUserPermissions'

export default {
  // -------------------------
  // Advertising Assets
  // -------------------------
  /* hasAdvertisingAssetPermission: () => () => {
    return checkUserPermissions({
      accessRequires: [{
        permission: ITS__PERMISSION__ADVERTISING__ASSETS,
        roles: [ ITS__ROLE__EDITOR, ITS__ROLE__SENIOR_EDITOR ]
      }]
    }, store)
  }, */

  // -------------------------
  // Sample Inventory
  // -------------------------
  hasSampleInventoryPermission: () => (libraries = ['Production', 'Sales']) => {
    return checkUserPermissions({
      accessRequires: [{
        permission: ITS__PERMISSION__SAMPLE_INVENTORY,
        infoKinds: [{
          UserSampleInventoryData: { libraries }
        }]
      }]
    }, store)
  },

  // -------------------------
  // Orders
  // -------------------------
  isSampleCoordinator: () => () => {
    return checkUserPermissions({
      accessRequires: [
        {
          permission: ITS__PERMISSION__ORDERS__SAMPLE_ORDERS,
          roles: [ITS__ROLE__COORDINATOR, ITS__ROLE__SENIOR_COORDINATOR]
        },
        {
          permission: ITS__PERMISSION__ORDERS__PROMO_ORDERS,
          roles: [ITS__ROLE__COORDINATOR, ITS__ROLE__SENIOR_COORDINATOR]
        }
      ]
    }, store)
  },

  isSeniorSampleCoordinator: () => () => {
    return checkUserPermissions({
      accessRequires: [
        {
          permission: ITS__PERMISSION__ORDERS__SAMPLE_ORDERS,
          roles: [ITS__ROLE__SENIOR_COORDINATOR]
        },
        {
          permission: ITS__PERMISSION__ORDERS__PROMO_ORDERS,
          roles: [ITS__ROLE__SENIOR_COORDINATOR]
        }
      ]
    }, store)
  },
  isApprover: () => () => {
    return checkUserPermissions({
      accessRequires: [
        {
          permission: ITS__PERMISSION__ORDERS__SAMPLE_ORDERS,
          roles: [ITS__ROLE__APPROVER, ITS__ROLE__SENIOR_APPROVER]
        },
        {
          permission: ITS__PERMISSION__ORDERS__PROMO_ORDERS,
          roles: [ITS__ROLE__APPROVER, ITS__ROLE__SENIOR_APPROVER]
        }
      ]
    }, store)
  },

  isSeniorApprover: () => () => {
    return checkUserPermissions({
      accessRequires: [
        {
          permission: ITS__PERMISSION__ORDERS__SAMPLE_ORDERS,
          roles: [ITS__ROLE__SENIOR_APPROVER]
        },
        {
          permission: ITS__PERMISSION__ORDERS__PROMO_ORDERS,
          roles: [ITS__ROLE__SENIOR_APPROVER]
        }
      ]
    }, store)
  },

  // -------------------------
  // Global Conference
  // -------------------------
  getUserConferenceGroup: () => group => {
    return checkUserPermissions({
      accessRequires: [{
        permission: ITS__PERMISSION__CONFERENCE,
        infoKinds: [{
          UserConferenceData: { conferenceGroups: [group] }
        }]
      }]
    }, store)

    /* if (!state.user) return false

    const permission = state.user?.permissions.find(p => p.permission.domain === 'its:conference')
    if (!permission) return false

    const conferenceData = permission.info.find(i => i.kind === 'UserConferenceData')
    if (!conferenceData) return false

    return conferenceData.conferenceGroups.find(cg => cg === group) */
  }
}
