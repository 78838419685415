import axios from 'axios'
import api from '@/api'

import ApiValidator from '@/classes/ApiValidator'

import {
  VUEX_API_DOCUMENTS_FETCH,
  VUEX_API_DOCUMENTS_SUCCESS,
  VUEX_API_DOCUMENTS_FAILED,

  VUEX_API_DOCUMENT_FETCH,
  VUEX_API_DOCUMENT_SUCCESS,
  VUEX_API_DOCUMENT_FAILURE,

  VUEX_API_DOCUMENT_CREATE,
  VUEX_API_DOCUMENT_CREATE_CANCEL,
  VUEX_API_DOCUMENT_DELETE,

  VUEX_API_DOCUMENT_SCHEDULED_CREATE,
  VUEX_API_DOCUMENT_SCHEDULED_DELETE,

  VUE_API_DOCUMENT_EMAIL_FILE_CREATE,

  VUEX_API_SHARED_FILE_DOWNLOAD,
  VUEX_API_SHARED_FILE_DOWNLOAD_CANCEL,

  VUEX_API_SCHEDULED_FILE_DOWNLOAD,
  VUEX_API_SCHEDULED_FILE_DOWNLOAD_CANCEL,

  VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'
import router from '@/router'

const state = {
  // Documents
  documentsRequest: null,
  documentsCancelRequest: null,
  // Document
  documentRequest: null,
  documentCancelRequest: null,
  // cancellation Reqests
  documentCreateCancelRequest: null,
  sharedFileDownloadRequest: null,
  scheduledFileDownloadRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_DOCUMENTS_FETCH]: async ({ dispatch, commit }, payload) => {
    if (!payload) payload = {}
    if (!payload.params) payload.params = {}

    await commit(VUEX_API_DOCUMENTS_FETCH)

    if (state.documentsCancelRequest) state.documentsCancelRequest.cancel()
    await commit(VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN, 'documentsCancelRequest')

    return api.get('assortment-documents', {
      params: payload.params,
      cancelToken: state.documentsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_DOCUMENTS_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_DOCUMENTS_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_DOCUMENT_FETCH]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_API_DOCUMENT_FETCH)

    if (state.documentCancelRequest) state.documentCancelRequest.cancel()
    await commit(VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN, 'documentCancelRequest')

    // let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'assortment-documents' : 'assortment-documents'
    let pathSlug = 'assortment-documents'

    return api.get(`/${pathSlug}/${payload.id}`, {
      params: {},
      cancelToken: state.documentCancelRequest.token
    }).then(response => {
      commit(VUEX_API_DOCUMENT_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_DOCUMENT_FAILURE)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_DOCUMENT_CREATE]: async ({ dispatch, commit }, payload) => {
    if (state.documentCreateCancelRequest) state.documentCreateCancelRequest.cancel()
    await commit(VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN, 'documentCreateCancelRequest')

    let data = {
      kind: payload.kind,
      documentType: payload.documentType,
      productType: payload.productType,
      settings: payload.settings,
      assortment: payload.assortment,
      filename: payload.filename
    }

    let fileType = data.documentType.type === 'Spreadsheet' ? 'xlsx' : 'pdf'
    let pathSlug = 'assortment-documents'

    if (payload.isSidebarKI || router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
      // intercept key initiatives with some special values
      let collectionsQueryId = payload.assortmentObject.collectionsQueryId
      const { state, season, channel, year, productType, orgType, method, region } = payload.assortmentObject

      data.assortmentsQuery = {
        channel,
        method,
        orgType,
        productType,
        season,
        year,
        region
      }

      if (state) data.assortmentsQuery.state = state

      if (collectionsQueryId) {
        data.settings = {
          ...data.settings,
          productFilteringQuery: {
            _options: {
              collectionsQuery: {
                _id: collectionsQueryId
              }
            }
          }
        }
      } else {
        if (payload.assortmentObject.titles) {
          let titleArray = payload.assortmentObject.titles
          let finalTitleQuery = []
          for (let i = 0; i < titleArray.length; i++) {
            let newobj = {
              title: { '$in': [titleArray[i].title] },
              gender: titleArray[i].gender
            }
            finalTitleQuery.push(newobj)
          }
          data.assortmentsQuery['$or'] = finalTitleQuery
        } else if (payload.assortmentObject.gender) {
          data.assortmentsQuery['$or'] = [
            {
              title: { '$in': [payload.assortmentObject.title] },
              gender: payload.assortmentObject.gender
            }
          ]
        } else {
          // just try one title
          data.assortmentsQuery.title = { '$in': [payload.assortmentObject.title] }
        }
      }
      delete data.assortment
      // delete data.settings.assortmentStatusOnly
    }

    const apiValidator = new ApiValidator('documentCreate')
    const validatedPayload = apiValidator.validate(data)

    // TODO : add create, success, failure mutations?
    return api.post(`/${pathSlug}/${fileType}`, validatedPayload, {
      params: {},
      cancelToken: state.documentCreateCancelRequest.token,
      responseType: 'blob'
    }).then(response => {
      return {
        ...response,
        action: 'completed'
      }
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: err.message ? 'success' : 'error',
          message: err.message || (err.response && err.response.data.message)
        }
      })

      if (err.message === 'Your document has been canceled') {
        return {
          ...err,
          action: 'cancelled'
        }
      } else {
        return {
          ...err,
          action: 'failed'
        }
      }
    })
  },

  [VUEX_API_DOCUMENT_CREATE_CANCEL]: ({ state }) => {
    state.documentCreateCancelRequest.cancel('Your document has been canceled')
  },

  [VUEX_API_DOCUMENT_SCHEDULED_CREATE]: async ({ dispatch }, payload) => {
    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'assortment-documents' : 'assortment-documents'

    return api.post(`/${pathSlug}`, payload, {
      params: {}
    }).then(response => {
      return response
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    })
  },

  [VUEX_API_DOCUMENT_SCHEDULED_DELETE]: async ({ dispatch }, payload) => {
    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'assortment-documents' : 'assortment-documents'

    return api.delete(`/${pathSlug}/${payload.id}`, {
      params: {}
    }).then(response => {
      return response
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    })
  },

  [VUE_API_DOCUMENT_EMAIL_FILE_CREATE]: async ({ dispatch }, payload) => {
    let data = {
      kind: payload.kind,
      documentType: payload.documentType,
      productType: payload.productType,
      settings: payload.settings,
      assortment: payload.assortment,
      filename: payload.filename,
      subject: payload.subject,
      body: payload.body,
      bccRecipients: payload.bccRecipients,
      recipients: payload.recipients
    }

    let fileType = data.documentType.type === 'Spreadsheet' ? 'xlsx' : 'pdf'

    if (payload.isSidebarKI || router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
      // intercept key initiatives with some special values
      let collectionsQueryId = payload.assortmentObject.collectionsQueryId
      const { state, season, channel, year, productType, orgType, method, region } = payload.assortmentObject

      data.assortmentsQuery = {
        channel,
        method,
        orgType,
        productType,
        season,
        year,
        region
      }

      data.productType = productType

      if (state) data.assortmentsQuery.state = state

      if (collectionsQueryId) {
        data.settings.productFilteringQuery = {
          _options: {
            collectionsQuery: {
              _id: collectionsQueryId
            }
          }
        }
      } else {
        if (payload.assortmentObject.titles) {
          let titleArray = payload.assortmentObject.titles
          let finalTitleQuery = []
          for (let i = 0; i < titleArray.length; i++) {
            let newobj = {
              title: { '$in': [titleArray[i].title] },
              gender: titleArray[i].gender
            }
            finalTitleQuery.push(newobj)
          }
          data.assortmentsQuery['$or'] = finalTitleQuery
        } else if (payload.assortmentObject.gender) {
          data.assortmentsQuery['$or'] = [
            {
              title: { '$in': [payload.assortmentObject.title] },
              gender: payload.assortmentObject.gender
            }
          ]
        } else {
          // just try one title
          data.assortmentsQuery.title = { '$in': [payload.assortmentObject.title] }
        }
      }
      delete data.assortment
      // delete data.settings.assortmentStatusOnly
    }

    return api.post(`/assortment-documents/share/email/${fileType}`, data, {
      params: {}
    }).then(response => {
      return response
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    })
  },

  [VUEX_API_SHARED_FILE_DOWNLOAD]: async ({ state, commit }, payload) => {
    if (state.sharedFileDownloadRequest) state.sharedFileDownloadRequest.cancel()
    await commit(VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN, 'sharedFileDownloadRequest')

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'assortment-documents' : 'assortment-documents'

    const { fileType, documentId, accessCode, filename } = payload
    return api.get(`/${pathSlug}/${fileType}/shared/${documentId}/${accessCode}/${filename}`, {
      responseType: 'arraybuffer',
      cancelToken: state.sharedFileDownloadRequest.token
    }).then(response => response)
  },

  [VUEX_API_SHARED_FILE_DOWNLOAD_CANCEL]: ({ state }) => {
    state.sharedFileDownloadRequest.cancel()
  },

  [VUEX_API_SCHEDULED_FILE_DOWNLOAD]: async ({ state, commit }, payload) => {
    if (state.scheduledFileDownloadRequest) state.scheduledFileDownloadRequest.cancel()
    await commit(VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN, 'scheduledFileDownloadRequest')

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'assortment-documents' : 'assortment-documents'

    const { fileType, documentId, timeStamp, filename } = payload
    return api.get(`/${pathSlug}/${fileType}/scheduled/${documentId}/${timeStamp}/${filename}`, {
      responseType: 'arraybuffer',
      cancelToken: state.scheduledFileDownloadRequest.token
    }).then(response => response)
  },

  [VUEX_API_SCHEDULED_FILE_DOWNLOAD_CANCEL]: () => {
    state.scheduledFileDownloadRequest.cancel()
  },

  [VUEX_API_DOCUMENT_DELETE]: async ({ state, dispatch, commit }, payload) => {
    await commit(VUEX_API_DOCUMENT_DELETE)

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'assortment-documents' : 'assortment-documents'

    return api.delete(`/${pathSlug}/${payload.id}`, {
      params: {}
    }).then(response => {
      commit(VUEX_API_DOCUMENT_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_DOCUMENT_FAILURE)
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `Cannot delete document - ${err.response && err.response.data.message}`
        }
      })
    })
  }
}

const mutations = {
  // Documents
  [VUEX_API_DOCUMENTS_FETCH]: state => {
    state.documentsRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_DOCUMENTS_SUCCESS]: state => {
    state.documentsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_DOCUMENTS_FAILED]: state => {
    state.documentsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Document
  [VUEX_API_DOCUMENT_FETCH]: state => {
    state.documentRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_DOCUMENT_SUCCESS]: state => {
    state.documentRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_DOCUMENT_FAILURE]: state => {
    state.documentRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
