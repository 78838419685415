import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash.clonedeep'
import _merge from 'lodash.merge'

import store from '@/store/store'
import router from '@/router'

import {
  VUEX_CATEGORY_BROWSER_UPDATE_QUERY,
  VUEX_CATEGORY_BROWSER_UPDATE_STATE_FROM_QUERY
} from '@/store/constants/ui/categoryBrowser/categoryBrowser_QueryUpdate'

import {
  VUEX_PHOTOS_FETCH
} from '@/store/constants/models/photos'

import {
  VUEX_ASSETS_FETCH
} from '@/store/constants/models/assets'

import {
  VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH
} from '@/store/constants/models/assortments/assortmentProducts'

import {
  VUEX_ROUTING_ROUTE_UPDATE
} from '@/store/constants/routing'

const state = {
  query: {}
}

const getters = {}

const actions = {
  [VUEX_CATEGORY_BROWSER_UPDATE_QUERY]: _debounce(async ({ state, dispatch, commit }) => {
    await commit(VUEX_CATEGORY_BROWSER_UPDATE_QUERY)

    // dispatch(VUEX_ROUTING_ROUTE_UPDATE, null)
    dispatch(VUEX_ROUTING_ROUTE_UPDATE, state.query)

    switch (router.currentRoute.value.name) {
      case 'product--libraries--products' :
        dispatch(VUEX_PHOTOS_FETCH, state.query)
        break

      case 'advertising--libraries--assets' :
      case 'advertising--libraries--logos' :
      case 'global-retail--libraries--assets' :
      case 'global-retail--libraries--resources' :
      case 'product--libraries--documents' :
        dispatch(VUEX_ASSETS_FETCH, state.query)
        break

      case 'product--libraries--assortments-internal--international-wholesale' :
      case 'product--libraries--assortments-internal--domestic-wholesale' :
      case 'product--libraries--assortments-internal--international-retail' :
      case 'product--libraries--assortments-internal--domestic-retail' :
      case 'assortment-showroom' :
        dispatch(VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH, state.query)
        break

      default :
        console.error('Vuex | ui:categoryBrowser:categoryBrowser_QueryUpdate | VUEX_CATEGORY_BROWSER_UPDATE_QUERY | route not configured')
        break
    }
  }, 0),

  [VUEX_CATEGORY_BROWSER_UPDATE_STATE_FROM_QUERY]: ({ dispatch, commit }, payload) => {
    commit(VUEX_CATEGORY_BROWSER_UPDATE_STATE_FROM_QUERY, payload)
  }
}

const mutations = {
  [VUEX_CATEGORY_BROWSER_UPDATE_QUERY]: state => {
    let routeName = router.currentRoute.value.name
    let query = {}

    switch (routeName) {
      case 'product--libraries--assortments-internal--international-wholesale' :
      case 'product--libraries--assortments-internal--domestic-wholesale' :
      case 'product--libraries--assortments-internal--international-retail' :
      case 'product--libraries--assortments-internal--domestic-retail' :
        Object.assign(query, store.getters.getFullinternalAssortmentsQuery())
        _merge(query, {
          _options: {
            includeCustomContent: true
          }
        })
        break

      case 'assortment-showroom' :
      case 'assortment-showroom--detail' :
        let assortment = store.state.assortments.assortment
        let assortmentShowroomOptionsData = _cloneDeep(store.getters.getAssortmentShowroomOptions('browse'))

        let data = {
          'locations.code': assortment.locationId,
          'productType': assortment.productType,
          ...assortmentShowroomOptionsData
        }

        if (assortment.uiSettings?.digitalShowroomSettings?.assortmentStatusOnly) {
          data['locations.lineStatus'] = assortment.status
        }

        Object.assign(data._uiOptions.assortmentsQuery, {
          _id: assortment._id,
          orgType: assortment.orgType,
          method: assortment.method,
          productType: assortment.productType
        })

        Object.assign(query, data)
        break

      // No options needed
      default :
        //
        break
    }

    state.query = query
  },

  [VUEX_CATEGORY_BROWSER_UPDATE_STATE_FROM_QUERY]: async (state, data) => {
    const queryStrArr = router.currentRoute.value.fullPath.split('?')[1]?.split('&') || null
    if (!queryStrArr) return

    let queryStrObj = {}
    queryStrArr.forEach(p => {
      const kvPair = p.split('=')
      queryStrObj[`${kvPair[0]}`] = kvPair[1]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
