import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PENDING,
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PUBLISHED,
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UNPUBLISHED,
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UPLOAD,
  VUEX_ROUTING_ENTER_PRODUCT_MANAGE_DELETED
} from '@/store/constants/routing/routingProduct'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { librariesManageMeta } from '@/router/routeMeta'

export default {
  path: '/product/libraries/manage',
  // name: 'product--libraries--manage',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      permissions: {
        accessRequires: [
          {
            permission: feConstants.ITS__PERMISSION__PRODUCT__DOCUMENTS,
            roles: [
              feConstants.ITS__ROLE__EDITOR,
              feConstants.ITS__ROLE__SENIOR_EDITOR
            ]
          },
          {
            permission: feConstants.ITS__PERMISSION__PRODUCT__DOCUMENTS,
            roles: [
              feConstants.ITS__ROLE__EDITOR,
              feConstants.ITS__ROLE__SENIOR_EDITOR
            ]
          }
        ]
      }
    }),
  children: [
    {
      path: '',
      name: 'product--libraries--manage--default',
      redirect: { name: 'product--libraries--documents--manage--pending' }
    },
    {
      path: 'documents/pending',
      name: 'product--libraries--documents--manage--pending',
      component: loadComponent('Product/Product_Manage.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PENDING, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT,
        optionalNavAppBarLabel: 'Manage Product Documents'
      }
    },
    {
      path: 'documents/published',
      name: 'product--libraries--documents--manage--published',
      component: loadComponent('Product/Product_Manage.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_MANAGE_PUBLISHED, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT,
        optionalNavAppBarLabel: 'Manage Product Documents'
      }
    },
    {
      path: 'documents/unpublished',
      name: 'product--libraries--documents--manage--unpublished',
      component: loadComponent('Product/Product_Manage.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UNPUBLISHED, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT,
        optionalNavAppBarLabel: 'Manage Product Documents'
      }
    },
    {
      path: 'documents/deleted',
      name: 'product--libraries--documents--manage--deleted',
      component: loadComponent('Product/Product_Manage.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_MANAGE_DELETED, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT,
        optionalNavAppBarLabel: 'Manage Product Documents'
      }
    },
    {
      path: 'documents/upload',
      name: 'product--libraries--documents--manage--upload',
      component: loadComponent('Product/Product_Upload.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()),
          store.dispatch(VUEX_ROUTING_ENTER_PRODUCT_MANAGE_UPLOAD, { to: to, from: from })
        ]).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT,
        optionalNavAppBarLabel: 'Manage Product Documents'
      }
    }
  ],
  meta: librariesManageMeta
}
