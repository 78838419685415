import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import store from '@/store/store'

import {
  VUEX_ROUTING_ENTER_DASHBOARD
} from '@/store/constants/routing/routingDashboard'

export default {
  path: '/not-approved',
  // name: 'notApproved',
  component: loadView('app/Headless.vue'),
  children: [
    // {
    //   path: '',
    //   name: 'notApproved--default',
    //   redirect: { name: 'notApproved--its' }
    // },
    {
      path: '',
      name: 'notApproved--its',
      component: loadComponent('Dashboard/Dashboard_Its.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_DASHBOARD, { to: to, from: from }).then(() => next())
      }
    }
  ],
  meta: {
    label: 'Dashboard'
  }
}
