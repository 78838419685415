import { properties } from '@/store/constants/config/properties'

let settingsProperties = properties.AssortmentDocument.properties.settings.properties

let defaultShowroomSettings = {
  'division': true,
  'seasonCodes': true,
  'pillar': true,
  'features': true,
  'sizeRange': true,
  'sizeScale': true,
  'nrf': true,
  'boxCategory': true,
  'dutyCode': true,
  'availability': true,
  'otherSpecialWidths': true,
  'marketingDescription': true,
  'originalCost': false,
  'cost': true,
  'netCost': false,
  'suggestedRetail': true,
  'initialMarkup': false,
  'styleSellThru': false,
  'colorSellThru': false,
  'inventory': true,
  'percentage': false,
  'quantity': false,
  'assortmentStatusOnly': true,
  'cadOnly': false,
  'startScreen': true
}

let defaultPopShowroomSettings = {
  'popCategory': true,
  'pillar': true,
  'dimensions': true,
  'materials': true,
  'usage': true,
  'cost': true,
  'availability': true,
  'capacityUnit': true,
  'units': true,
  'relationships': true,
  'expirationDate': true,
  'assortmentStatusOnly': true,
  'startScreen': true
}

const getDefaultDigitalShowroomSettings = function (productType, status) {
  if (productType === ITS__PRODUCT_TYPE__POP) {
    return defaultPopShowroomSettings
  }

  let showroomSettings = { ...defaultShowroomSettings }

  if (status === 'R') {
    delete showroomSettings['availability']
  } else {
    delete showroomSettings['inventory']
  }

  if (productType === 'Apparel') {
    delete showroomSettings['boxCategory']
    delete showroomSettings['otherSpecialWidths']
  }

  if (productType === 'Footwear') {
    delete showroomSettings['seasonCodes']
  }

  return showroomSettings
}

const getDefaultShowroomSettingsForDisplay = function (productType, status) {
  let settings = getDefaultDigitalShowroomSettings(productType, status)
  return convertShowroomSettingsforDisplay(settings)
}

const convertShowroomSettingsforDisplay = function (settings) {
  let settingsForDisplay = []
  Object.keys(settings).map(key => {
    settingsForDisplay.push({
      key: key,
      label: settingsProperties[key]?.shortLabel,
      value: settings[key]
    })
  })
  return settingsForDisplay
}

const convertDisplaySettingsToObject = function (displaySettings) {
  let settings = {}

  displaySettings.forEach((option) => {
    if (option.disabled) {
      return
    }
    settings[option.key] = option.value
  })

  return settings
}

const userSettingsAreUpToDate = function (productType, status, userSettings) {
  if (!userSettings) {
    return false
  }

  let defaultSettings = getDefaultDigitalShowroomSettings(productType, status)

  let userKeys = Object.keys(userSettings).sort()
  let defaultKeys = Object.keys(defaultSettings).sort()

  return JSON.stringify(userKeys) === JSON.stringify(defaultKeys)
}

export {
  getDefaultDigitalShowroomSettings,
  getDefaultShowroomSettingsForDisplay,
  convertDisplaySettingsToObject,
  convertShowroomSettingsforDisplay,
  userSettingsAreUpToDate
}
