import {
  VUEX_ROUTING_ENTER_DASHBOARD
} from '@/store/constants/routing/routingDashboard'

import {
  VUEX_CONTENT_CONTAINER_SET_STYLES
} from '@/store/constants/ui/contentContainer'

const actions = {
  [VUEX_ROUTING_ENTER_DASHBOARD]: async ({ dispatch }) => {
    dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__NONE
    })
  }
}

const mutations = {}

export default {
  actions,
  mutations
}
