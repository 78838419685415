export const VUEX_FILTERBAR_MODULE_CREATE = 'VUEX_FILTERBAR_MODULE_CREATE'
export const VUEX_FILTERBAR_MODULE_DISABLE = 'VUEX_FILTERBAR_MODULE_DISABLE'
export const VUEX_FILTERBAR_MODULE_ENABLE_ALL = 'VUEX_FILTERBAR_MODULE_ENABLE_ALL'
export const VUEX_FILTERBAR_MODULE_DESTROY_ALL = 'VUEX_FILTERBAR_MODULE_DESTROY_ALL'
export const VUEX_FILTERBAR_OPTIONSLIST_LOADING = 'VUEX_FILTERBAR_OPTIONSLIST_LOADING'
export const VUEX_FILTERBAR_SET_HOVER_STATE = 'VUEX_FILTERBAR_SET_HOVER_STATE'

export const VUEX_FILTERBAR_OPTIONSLIST_DATA_SET = 'VUEX_FILTERBAR_OPTIONSLIST_DATA_SET'
export const VUEX_FILTERBAR_OPTIONSLISTS_UPDATE = 'VUEX_FILTERBAR_OPTIONSLISTS_UPDATE'

export const VUEX_FILTERBAR_MODULE_PANEL_ACTIVATE = 'VUEX_FILTERBAR_MODULE_PANEL_ACTIVATE'
export const VUEX_FILTERBAR_MODULE_SET_ACTIVE_STATE = 'VUEX_FILTERBAR_MODULE_SET_ACTIVE_STATE'
export const VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE = 'VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE'
export const VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL = 'VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL'

export const VUEX_FILTERBAR_PARAMETER_ADD = 'VUEX_FILTERBAR_PARAMETER_ADD'
export const VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY = 'VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY'
export const VUEX_FILTERBAR_PARAMETER_REMOVE = 'VUEX_FILTERBAR_PARAMETER_REMOVE'
export const VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL = 'VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL'

export const VUEX_FILTERBAR_MODULE_SET_DEPENDENCIES = 'VUEX_FILTERBAR_MODULE_SET_DEPENDENCIES'

export const VUEX_FILTERBAR_PRODUCTS_UPDATE = 'VUEX_FILTERBAR_PRODUCTS_UPDATE'
export const VUEX_FILTERBAR_SET_FETCH_PENDING = 'VUEX_FILTERBAR_SET_FETCH_PENDING'
export const VUEX_FILTERBAR_UPDATE_ADD_QUERY = 'VUEX_FILTERBAR_UPDATE_ADD_QUERY'
export const VUEX_FILTERBAR_UPDATE_SEARCH_QUERY = 'VUEX_FILTERBAR_UPDATE_SEARCH_QUERY'
export const VUEX_FILTERBAR_PRODUCTS_FETCH = 'VUEX_FILTERBAR_PRODUCTS_FETCH'
export const VUEX_FILTERBAR_UPDATE_PENDING_ROWS = 'VUEX_FILTERBAR_UPDATE_PENDING_ROWS'
export const VUEX_FILTERBAR_GRID_UPDATE_ROWS = 'VUEX_FILTERBAR_GRID_UPDATE_ROWS'
export const VUEX_FILTERBAR_GRID_UPDATE_ROWS_CLEAR = 'VUEX_FILTERBAR_GRID_UPDATE_ROWS_CLEAR'

export const VUEX_FILTERBAR_CLEAR_ADD_QUERY = 'VUEX_FILTERBAR_CLEAR_ADD_QUERY'

export const VUEX_FILTERBAR_QUERY_CONSTRUCT = 'VUEX_FILTERBAR_QUERY_CONSTRUCT'
