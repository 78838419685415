<template>
  <div class="its-date-picker">
    <its-text-field
      readonly
      persistent-hint
      prepend-inner-icon="insert_invitation"
      :append-inner-icon="dropdownArrowIcon"
      :model-value="modelText"
      :hint="hint"
      :disabled="disabled"
      :rules="rules"
      :label="label"
      @click="togglePicker" />

    <div class="date-picker-container" :class="{ 'open': pickerMenu }">
      <v-date-picker
        color="primary"
        class="elevation-3 date-picker"
        show-adjacent-months
        :multiple="multiple"
        @click:cancel="revertData"
        @click:save="datePickerSave"
        @update:modelValue="update" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'core--date-picker',

  emits: ['update:modelValue'],

  props: {
    label: {
      type: String,
      default: 'Select Date'
    },

    hint: {
      type: String,
      default: ''
    },

    modelValue: {
      type: [String, Array, Date]
    },

    multiple: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    rules: {
      type: Array,
      default: () => [ v => v !== 'Select Date' || 'Required' ]
    }
  },

  data: () => ({
    model: null,
    prevModel: null,
    pickerMenu: true,
    text: null
  }),

  computed: {
    dropdownArrowIcon () {
      return this.pickerMenu ? 'arrow_drop_up' : 'arrow_drop_down'
    },

    modelText () {
      if (this.model && !this.model.length) {
        return dayjs(this.model).format('MM/DD/YYYY')
      }
      if (this.model && this.model[0]) {
        return this.model?.map(date => dayjs(date).format('MM/DD/YYYY')).join(' - ')
      }
      // If no value
      return 'Select Date'
    },

    dateIsComplete () {
      return this.model?.length > 0 || !this.model?.length
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.update([this.modelValue])
    })
  },

  methods: {
    datePickerSave () {
      this.pickerMenu = false
    },

    emitData () {
      if (!this.dateIsComplete) this.revertData()

      if (this.multiple) {
        return this.$emit('update:modelValue', this.model)
      }

      return this.$emit('update:modelValue', this.model[0] || this.model)
    },

    revertData () {
      this.pickerMenu = false
      this.model = this.prevModel
    },

    update (date) {
      this.model = date
      this.emitData()
    },

    togglePicker () {
      if (this.pickerMenu && !this.dateIsComplete) {
        this.revertData()
      }

      this.prevModel = this.model
      // this.pickerMenu = !this.pickerMenu

      setTimeout(() => {
        this.pickerMenu = !this.pickerMenu
      }, 200)
    }
  },

  watch: {
    model: {
      deep: true,
      handler (val) {
        if (val) this.togglePicker()
      }
    }
  }
}
</script>

<style lang="scss">
.its-date-picker {
  position: relative;

  .date-picker-container {
    position: absolute;
    top: 1rem;
    z-index: 500;
    height: 0;
    overflow: hidden;

    &.open {
      height: 600px;
    }

    .date-picker {
      border: 1px solid $app-color-grey--light-2;
    }
  }
}

</style>
