import axios from 'axios'
import api from '@/api'

import {
  // Seasons
  VUEX_API_SEASONS_REQUEST_FETCH,
  VUEX_API_SEASONS_REQUEST_SUCCESS,
  VUEX_API_SEASONS_REQUEST_FAILED,

  // Season
  VUEX_API_SEASON_REQUEST_CREATE,
  VUEX_API_SEASON_REQUEST_FETCH,
  VUEX_API_SEASON_REQUEST_UPDATE,
  VUEX_API_SEASON_REQUEST_DELETE,

  // Season Set,
  VUEX_API_SEASON_SET_UPDATE_STATE,

  VUEX_API_SEASONS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  // Seasons
  seasonsRequest: null,
  seasonsCancelRequest: null,

  // Season
  seasonRequest: null,
  seasonCancelRequest: null
}

const getters = {}

const actions = {
  // Fetch all Seasons
  [VUEX_API_SEASONS_REQUEST_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    if (!payload.params) payload.params = {}

    await commit(VUEX_API_SEASONS_REQUEST_FETCH)

    if (state.seasonsCancelRequest) state.seasonsCancelRequest.cancel()
    await commit(VUEX_API_SEASONS_SET_CANCEL_TOKEN, 'seasonsCancelRequest')

    // compose options payload
    return api.get(`/assortmentSeasons/${payload.channel}${payload?.subRoute ? '/' + payload?.subRoute : ''}`, {
      params: payload.params,
      cancelToken: state.seasonsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SEASONS_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_SEASONS_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/seasons' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Fetch a single Season
  [VUEX_API_SEASON_REQUEST_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    if (!payload.params) payload.params = {}

    const newPayload = {
      _options: payload._options
    }

    await commit(VUEX_API_SEASONS_REQUEST_FETCH)

    if (state.seasonRequest) state.seasonRequest.cancel()
    await commit(VUEX_API_SEASONS_SET_CANCEL_TOKEN, 'seasonCancelRequest')

    return api.get(`/assortmentSeasons/${payload.id}`, {
      params: newPayload,
      cancelToken: state.seasonCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SEASONS_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_SEASONS_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Create new season
  [VUEX_API_SEASON_REQUEST_CREATE]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}

    await commit(VUEX_API_SEASONS_REQUEST_FETCH)

    if (state.seasonRequest) state.seasonRequest.cancel()
    await commit(VUEX_API_SEASONS_SET_CANCEL_TOKEN, 'seasonCancelRequest')

    return api.post(`/assortmentSeasons`, {
      ...payload,
      sourceFile: `seasons/${payload.year}/${payload.season}/${payload.channel}/sourceFile.jpg`,
      previewJpg: `seasons/${payload.year}/${payload.season}/${payload.channel}/preview.jpg`
    }, {
      cancelToken: state.seasonCancelRequest.token
    }).then(response => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: 'Season created succesfully'
        }
      })
      commit(VUEX_API_SEASONS_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_SEASONS_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Update single season
  [VUEX_API_SEASON_REQUEST_UPDATE]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}

    await commit(VUEX_API_SEASONS_REQUEST_FETCH)

    if (state.seasonRequest) state.seasonRequest.cancel()
    await commit(VUEX_API_SEASONS_SET_CANCEL_TOKEN, 'seasonCancelRequest')

    return api.patch(`/assortmentSeasons/${payload.id}`, payload.data, {
      cancelToken: state.seasonCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SEASONS_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_SEASONS_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Update single season
  [VUEX_API_SEASON_REQUEST_DELETE]: async ({ commit, dispatch }, payload) => {
    await commit(VUEX_API_SEASONS_REQUEST_FETCH)

    if (state.seasonRequest) state.seasonRequest.cancel()
    await commit(VUEX_API_SEASONS_SET_CANCEL_TOKEN, 'seasonCancelRequest')

    return api.delete(`/assortmentSeasons/${payload}`, {
      cancelToken: state.seasonCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SEASONS_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_SEASONS_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Transition Season set state
  [VUEX_API_SEASON_SET_UPDATE_STATE]: async ({ commit, dispatch }, { seasonId, seasonSetId, newState }) => {
    await commit(VUEX_API_SEASONS_REQUEST_FETCH)

    if (state.seasonRequest) state.seasonRequest.cancel()
    await commit(VUEX_API_SEASONS_SET_CANCEL_TOKEN, 'seasonCancelRequest')

    return api.patch(`/assortmentSeasons/${seasonId}/${seasonSetId}`, { state: newState }, {
      cancelToken: state.seasonCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SEASONS_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_SEASONS_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  }
}

const mutations = {
  // Seasons
  [VUEX_API_SEASONS_REQUEST_FETCH]: state => {
    state.seasonsRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_SEASONS_REQUEST_SUCCESS]: state => {
    state.seasonsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_SEASONS_REQUEST_FAILED]: state => {
    state.seasonsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_SEASONS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
