import {
  VUEX_S3FUM_RESPONSE_HANDLER
} from '@/store/constants/fileManagement/s3FileUploadResponseManager'

import {
  VUEX_ASSORTMENT_UPDATE,
  VUEX_ASSORTMENT_GRID_ROWS_UPDATE
} from '@/store/constants/models/assortments'

import {
  VUEX_GROUPSORT_ASSORTMENT_UPDATE_CUSTOM_CONTENT
} from '@/store/constants/ui/groupSort'

import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'

const state = {}
const getters = {}

function s3ThumbnailUpdate (aggridRowId, imgVal, key = 'thumbnail') {
  if (aggridRowId) {
    let obj = {}
    obj.properties = {}
    obj.id = aggridRowId
    obj.properties[key] = imgVal

    let data = {
      type: 'rowsUpdate',
      payload: [obj]
    }
    DataMiddleware.emitSwitchboard(data)
  }
}

const actions = {
  [VUEX_S3FUM_RESPONSE_HANDLER]: async ({ dispatch }, payload) => {
    let propertiesData = {}
    propertiesData[payload.propToUpdate] = payload.uri

    // Handle updating model
    switch (payload.usageType) {
      case ITS__S3FUM__USAGE_TYPE__ASSORTMENT_LOGO :
        dispatch(VUEX_ASSORTMENT_UPDATE, {
          id: payload.attachTo,
          properties: propertiesData
        })
        break

      case ITS__S3FUM__USAGE_TYPE__ASSORTMENT_DISPLAYGROUP :
        dispatch(VUEX_GROUPSORT_ASSORTMENT_UPDATE_CUSTOM_CONTENT, {
          displayGroup: payload.attachmentData.displayGroup,
          item: { displayGroup: payload.attachmentData.displayGroup },
          data: { [payload.propToUpdate]: payload.uri }
        })
        break

      case ITS__S3FUM__USAGE_TYPE__SEASON_COVER :
        s3ThumbnailUpdate(payload?.attachmentData?.aggridRowId, payload?.data?.Location, 'previewJpg')
        break

      case ITS__S3FUM__USAGE_TYPE__ASSORTMENT_PRODUCT :
        // if thumbnail, then we are updating the grid - so send through the grid emit process
        // if printImage (and all else) - send directly to the API. that is not used by AG Grid
        if (propertiesData.hasOwnProperty('thumbnail')) {
          s3ThumbnailUpdate(payload?.attachmentData?.aggridRowId, propertiesData?.thumbnail)
        } else {
          await dispatch(VUEX_ASSORTMENT_GRID_ROWS_UPDATE, [{
            id: payload.attachTo,
            style: payload.attachmentData.style,
            color: payload.attachmentData.color,
            properties: propertiesData
          }])
        }
        break

      case ITS__S3FUM__USAGE_TYPE__LIBRARIES_ASSET :
        // Respoitories assets S3 upload response
        break
    }
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
