export const VUEX_OVERLAY_SHOW = 'VUEX_OVERLAY_SHOW'
export const VUEX_OVERLAY_HIDE = 'VUEX_OVERLAY_HIDE'
export const VUEX_OVERLAY_SET_CONTENT = 'VUEX_OVERLAY_SET_CONTENT'

export const VUEX_OVERLAY_HEADER_SHOW = 'VUEX_OVERLAY_HEADER_SHOW'
export const VUEX_OVERLAY_HEADER_HIDE = 'VUEX_OVERLAY_HEADER_SHOW'
export const VUEX_OVERLAY_HEADER_SET_SIZE = 'VUEX_OVERLAY_HEADER_SET_SIZE'
export const VUEX_OVERLAY_HEADER_SET_TITLE = 'VUEX_OVERLAY_HEADER_SET_TITLE'

export const VUEX_OVERLAY_SUBHEADER_SHOW = 'VUEX_OVERLAY_SUBHEADER_SHOW'
export const VUEX_OVERLAY_SUBHEADER_HIDE = 'VUEX_OVERLAY_SUBHEADER_HIDE'
export const VUEX_OVERLAY_SUBHEADER_SET_CONTENT = 'VUEX_OVERLAY_SUBHEADER_SET_CONTENT'
export const VUEX_OVERLAY_SUBHEADER_SET_SIZE = 'VUEX_OVERLAY_SUBHEADER_SET_SIZE'

export const VUEX_OVERLAY_UNLOAD_SUBHEADER_CONTENT = 'VUEX_OVERLAY_UNLOAD_SUBHEADER_CONTENT'
