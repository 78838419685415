import _cloneDeep from 'lodash.clonedeep'

import store from '@/store/store'
import router from '@/router'

import { removeEmptyElements } from '@/helpers/objectModifiers'
import feConstants from '@/store/constants/config/fe_constants'
import checkUserPermissions from '@/mixins/checkUserPermissions'

import {
  VUEX_FILTERBAR_OPTIONSLISTS_LIBRARYTYPE_SET,
  VUEX_FILTERBAR_OPTIONSLISTS_FETCH
} from '@/store/constants/ui/filterBar/filterBar_OptionsLists'

import {
  VUEX_API_OPTIONS_FETCH,
  VUEX_API_OPTIONS_INTERNAL_ASSORTMENT_FETCH
} from '@/store/constants/api'

let editorObj = {
  accessRequires: [
    {
      permission: feConstants.ITS__PERMISSION__PRODUCT__INTERNAL_ASSORTMENTS,
      roles: [ feConstants.ITS__ROLE__USER, feConstants.ITS__ROLE__ADMIN_EDITOR ],
      infoKinds: [
        {
          InternalAssortmentsData: {
            subRoles: {
              region: [ feConstants.ITS__REGION__ALL ],
              channel: [ feConstants.ITS__ASSORTMENTS__CHANNEL_TYPE__ALL ],
              productType: [ feConstants.ITS__PRODUCT_TYPE__ALL ],
              role: [ feConstants.ITS__ROLE__EDITOR, feConstants.ITS__ROLE__SENIOR_EDITOR ]
            }
          }
        }
      ]
    }
  ]
}

const state = {
  currentLibraryType: null
}

const getters = {}

const actions = {
  [VUEX_FILTERBAR_OPTIONSLISTS_FETCH]: async ({ rootState, commit, dispatch }, payload) => {
    const routeName = router.currentRoute.value.name
    const optionsType = payload.optionsData.optionsType
    const libraryType = payload.libraryType
    const librarySubType = payload.librarySubType

    let query = {} // Instantiate object used for query
    let queryTemp = {}
    let returnData = {} // Instantiate object use for return data
    let emptyRequest = true
    let props = {}
    let nullValues = false

    // Iterate over modules to get all applied parameters
    payload.modules.forEach(m => {
      m.filterGroups.forEach(fg => {
        fg.filters.forEach(f => {
          f.parameters.forEach(p => {
            let key = f.optionsKey || p.key

            if (f.payloadType && f.payloadType === 'object') {
              // If 'payloadType' is 'object' add prop as Object
              Object.assign(queryTemp, { [key]: p.value })
            } else {
              // Else add as array
              if (!queryTemp[key]) queryTemp[key] = []
              queryTemp[key].push(p.value)
            }
          })
        })
      })
    })

    // IF: 'query' has properties is the request is NOT empty
    if (Object.keys(queryTemp).length > 0) emptyRequest = false
    if (!optionsType) console.error('VUEX_FILTERBAR_OPTIONSLISTS_FETCH > No optionsType found on config')

    switch (optionsType) {
      case ITS__DYNAMIC_OPTIONS__TYPE__GLOBAL_RETAIL__ASSETS :
      case ITS__DYNAMIC_OPTIONS__TYPE__GLOBAL_RETAIL__RESOURCES :
           Object.assign(query, {
          'status': 'Published',
          'libraryType': ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
          'librarySubType': librarySubType
        })
        break

      case ITS__DYNAMIC_OPTIONS__TYPE__PRODUCT_LIBRARIES__PRODUCTS :
        // get from Selectors
        Object.assign(query, store.state.filterBarSelectors.selectorProps)
        break

      case ITS__DYNAMIC_OPTIONS__TYPE__PRODUCT_LIBRARIES__DOCUMENTS :
      case ITS__DYNAMIC_OPTIONS__TYPE__ADVERTISING_LIBRARIES__ASSETS :
      case ITS__DYNAMIC_OPTIONS__TYPE__ADVERTISING_LIBRARIES__LOGOS :
        Object.assign(query, {
          'status': 'Published',
          'libraryType': libraryType
        })
        break

      /* case ITS__DYNAMIC_OPTIONS__TYPE__ADVERTISING_LIBRARIES__ASSETS :
        Object.assign(query, {
          'status': 'Published',
          'libraryType': ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET
        })
        break */

      /* case ITS__DYNAMIC_OPTIONS__TYPE__ADVERTISING_LIBRARIES__LOGOS :
        Object.assign(query, {
          'status': 'Published',
          'libraryType': ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO
        })
        break */

      case ITS__DYNAMIC_OPTIONS__TYPE__ASSORTMENTS__PRODUCTS :
        // get from Assortment data
        Object.assign(query, {
          'productType': rootState.assortments.assortment?.productType
        })

        if (rootState.assortments?.assortment?.orgType === ITS__ASSORTMENTS__ORG_TYPE__REGULAR) {
          Object.assign(query, {
            'locations.code': rootState.assortments.assortment?.locationId,
            'locations.lineStatus': rootState.assortments.assortment?.status
          })
        }

        break

      case ITS__DYNAMIC_OPTIONS__TYPE__ORDERS__PRODUCTS :
        // get from Order data
        Object.assign(query, {
          'productType': rootState.orders.order?.productType
          // 'locations.code': rootState.orders.order?.locationId,
          // 'locations.lineStatus': rootState.orders.order?.status
        })

        break
    }

    /* nullValues = Object.values(query).some(prop => !prop)
    if (nullValues) {
      console.error('filterBar_OptionsLists | query contains null values : ' + query)
      return
    } */

    query = removeEmptyElements(query)

    // Define initial payload
    let dataObj = {
      libraryType: payload.libraryType,
      data: {
        query: query,
        requestedOptions: payload.keys.filter(k => !payload.exclude?.includes(k))
      }
    }

    // Set state.currentLibraryType for comparison on next request
    commit(VUEX_FILTERBAR_OPTIONSLISTS_LIBRARYTYPE_SET, payload.libraryType)

    // Add chips to query before full fetch
    Object.assign(dataObj.data.query, queryTemp)

    dataObj.data.requestedOptions = emptyRequest || !state.initialOptions ? payload.keys.filter(k => !payload.exclude?.includes(k)) : payload.keys.filter(k => payload.include?.includes(k))
    dataObj.data.requestedOptions = dataObj.data.requestedOptions.filter(o => o)

    let optionsEndpoint = ''

    switch (routeName) {
      case 'product--libraries--assortments-internal--international-wholesale' :
      case 'product--libraries--assortments-internal--domestic-wholesale' :
      case 'product--libraries--assortments-internal--international-retail' :
      case 'product--libraries--assortments-internal--domestic-retail' :
        optionsEndpoint = VUEX_API_OPTIONS_INTERNAL_ASSORTMENT_FETCH

        if (dataObj.data.query) {
          dataObj.data.query.productType = rootState.assortmentsInternal.query.productType

          if (!dataObj.data.query._uiOptions) dataObj.data.query._uiOptions = {}
          dataObj.data.query._uiOptions.assortmentsQuery = _cloneDeep(rootState.assortmentsInternal.query._uiOptions.assortmentsQuery)

          // ------------------------------------------------
          // User dependent 'state'
          const queryChannel = dataObj.data.query._uiOptions.assortmentsQuery.channel
          const queryRegion = dataObj.data.query._uiOptions.assortmentsQuery.region
          const queryProductType = dataObj.data.query._uiOptions.assortmentsQuery.productType

          if (queryChannel) editorObj.accessRequires[0].infoKinds[0].InternalAssortmentsData.subRoles.channel.push(queryChannel)
          if (queryRegion) editorObj.accessRequires[0].infoKinds[0].InternalAssortmentsData.subRoles.region.push(queryRegion)
          if (queryProductType) editorObj.accessRequires[0].infoKinds[0].InternalAssortmentsData.subRoles.productType.push(queryProductType)

          if (checkUserPermissions(editorObj, store).hasPerm) {
            dataObj.data.query._uiOptions.assortmentsQuery.state = ITS__ASSORTMENTS__STATE__PUBLISHED
          }
          // ------------------------------------------------

          delete dataObj.data.query._uiOptions.assortmentsQuery.title
          delete dataObj.data.query._uiOptions.assortmentsQuery.gender
        }

        break
      default :
        optionsEndpoint = VUEX_API_OPTIONS_FETCH
    }

    // Check for any null values (built for selectors)
    nullValues = Object.values(query).some(prop => !prop)
    if (!Object.entries(query).length || nullValues) return

    return dispatch(optionsEndpoint, dataObj).then(async response => {
      props = rootState.properties.data[payload.libraryType]?.properties // Define properties object needed for Library type (i.e Product)

      // Filter out return dynamic options from BE
      Object.keys(response).forEach(key => {
        // Only use options from BE if they aren't exluded
        if (!payload.exclude?.includes(key) || emptyRequest) {
          let k = key.split('.')
          let listObj = k.length > 1 ? props[k[0]].properties[k[1]] : props[k[0]]

          if (!listObj) return

          let optionsList = listObj[`options${payload.librarySubType}`] || listObj?.options
          if (!optionsList) {
            console.error(`filterBar_OptionsList | Local option list data not found for - ${key} -`)
            return
          }

          // Normalized to lowercase for comparison
          let normalizedResponseListItems = response[key].filter(entry => entry).map(entry => {
            return entry.toLowerCase()
          })

          returnData[key] = optionsList?.filter(option => {
            if (!option) return

            let optionItem = typeof option === 'string' ? option.toLowerCase() : option?.value?.toLowerCase() || option.label?.toLowerCase()
            return normalizedResponseListItems.includes(optionItem)
          }) || response[key]
        }
      })

      return returnData
    }).catch(err => {
      console.error(err)
      return err
    })
  }
}

const mutations = {
  [VUEX_FILTERBAR_OPTIONSLISTS_LIBRARYTYPE_SET]: (state, data) => {
    state.currentLibraryType = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
