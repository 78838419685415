import axios from 'axios'
import Oboe from 'oboe'

import api from '@/api'
import ApiValidator from '@/classes/ApiValidator'

// import queryCtrl from '@/utils/query'
// import feConstants from '@/store/constants/config/fe_constants'
import {
  VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH,
  VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_STREAMING,

  VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_FAILED,
  VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS,

  VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

let oboeInstance
const BUFFER_SIZE = 20
const ACTIVE_BUFFER_SIZE = 20

const state = {
  assortmentProductsRequest: null,
  assortmentProductsCanelRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH]: async ({ commit, dispatch }, payload) => {
    if (state.assortmentProductsCanelRequest) state.assortmentProductsCanelRequest.cancel()
    if (!Object.keys(payload).length) return

    const apiValidator = new ApiValidator('assortmentProductsFetch')
    const validatedPayload = apiValidator.validate(payload)

    await commit(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_SET_CANCEL_TOKEN, 'assortmentProductsCanelRequest')
    await commit(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH)

    const apiEndpoint = (payload._uiOptions?.assortmentsQuery?.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) ? 'internalAssortments/products/query' : 'assortments/products/query'

    return api.post(apiEndpoint, validatedPayload, {
      cancelToken: state.assortmentProductsCanelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/assets' - ${err.response && err.response.data.message}`
          }
        })

        return err
      }
    })
  },

  [VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_STREAMING]: async ({ state, dispatch, commit }, payload) => {
    try {
      commit(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS)
      oboeInstance.abort()
    } catch (err) {}

    if (!Object.keys(payload.query).length) return

    const apiValidator = new ApiValidator('productSearch')
    const validatedPayload = apiValidator.validate(payload.query)

    let itemCount = 0
    let itemRows = 0
    let totalItems = 0
    let buffer = []

    const apiEndpoint = (payload.query?._uiOptions?.assortmentsQuery?.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) ? 'internalAssortments/products/query' : 'products/fetch'

    await commit(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH)

    Oboe({
      method: 'POST',
      url: process.env.VUE_APP_API_BASE_URL + `/${apiEndpoint}`,
      body: validatedPayload,
      withCredentials: true
    }).on('start', function () {
      oboeInstance = this
    }).node('totalCount', function (totalCount) {
      totalItems = totalCount
      payload.callback({ totalCount: totalCount })
    }).node('row', function (row) {
      if (state.assortmentProductsRequest !== ITS__API__REQUEST_TYPE__PENDING) {
        this.abort()
        return
      }

      const item = Object.freeze(row)
      itemCount++

      if (itemRows > ACTIVE_BUFFER_SIZE) {
        buffer.push(item)

        if (buffer.length === BUFFER_SIZE || itemCount === itemRows) {
          payload.callback({ items: buffer })
          buffer = []
        }
      } else {
        payload.callback({ items: [item] })
      }

      return Oboe.drop
    }).on('done', function () {
      if (itemCount === totalItems) {
        commit(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS)
      }
    }).on('fail', function () {
      commit(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_FAILED)
    })
  }
}

const mutations = {
  [VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH]: state => {
    state.assortmentProductsRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS]: state => {
    state.assortmentProductsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_FAILED]: state => {
    state.assortmentProductsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
