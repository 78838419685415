import {
  VUEX_SAMPLE_INVENTORY_FETCH,
  VUEX_SAMPLE_INVENTORY_GRID_REKEY,
  VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_START,
  VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH,
  VUEX_SAMPLE_INVENTORY_SET_QUERY_PARAMS,
  VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD,
  VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD_COMPLETE,
  VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE,
  VUEX_SAMPLE_INVENTORY_GRID_ROWS_DELETE,
  VUEX_SAMPLE_INVENTORY_GRID_REQUEST_CLEAR,
  VUEX_SAMPLE_INVENTORY_PRODUCTS_UPDATE,
  VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FETCH,
  VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_SUCCESS,
  VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FAILURE,
  VUEX_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION
} from '@/store/constants/models/samples'

import {
  VUEX_API_SAMPLE_INVENTORY_DELETE,
  VUEX_API_SAMPLE_INVENTORY_BATCH,
  VUEX_API_SAMPLE_INVENTORY_FETCH,
  VUEX_API_SAMPLE_INVENTORY_VALIDATE,
  VUEX_API_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION
} from '@/store/constants/api'

import {
  VUEX_GRID_UPDATE_START,
  VUEX_GRID_UPDATE_SUCCESS,
  VUEX_GRID_UPDATE_FAILURE
} from '@/store/constants/ui/grid'

import Debug from 'logdown'

import _cloneDeep from 'lodash.clonedeep'
import _debounce from 'lodash.debounce'
import longWaitBlocker from '@/helpers/longWaitBlocker'
import _forEach from 'lodash.foreach'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'

import _omit from 'lodash.omit'
import { VUEX_TOAST_ADD_TO_QUEUE } from '@/store/constants/ui/toast'

const d = new Debug('its:store:modules:samples')
const state = {
  // processing
  updatingInformation: {}, // used to show if any of these API calls are still updating

  // API related
  agGridRequestData: {
    updateProducts: [],
    statusChangeProducts: [],
    deleteProducts: []
  },
  validatedProductsResponse: [], // a response for the validated products - used in excel import
  sampleInventoryRowsAddedToggler: null, // toggles when products are finall excited imported

  agGridRowData: [],
  agGridRowDataLoading: false,
  rekey: '' // if this value changes, the loaded aggrid component will do a hard-rekey
}

const getters = {}

const actions = {
  [VUEX_SAMPLE_INVENTORY_FETCH]: async ({ dispatch, commit }, payload) => {
    let commitPayload = {
      params: payload,
      subtype: payload?.subtype // production or sales
    }
    return dispatch(VUEX_API_SAMPLE_INVENTORY_FETCH, commitPayload).then(response => {
      commit(VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH)
      return response
    }).catch(error => {
      d.log('VUEX_API_LIBRARIES_REQUEST_FETCH error : ' + error)
      commit(VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH)
      return null
    })
  },
  [VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_START]: ({ commit }) => {
    commit(VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_START)
  },
  [VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH]: ({ commit }) => {
    commit(VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH)
  },

  [VUEX_SAMPLE_INVENTORY_GRID_REKEY]: ({ commit }) => {
    commit(VUEX_SAMPLE_INVENTORY_GRID_REKEY)
  },

  [VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE, payload)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.updateProducts
    let finalPayload = {
      frontEnd: {
        type: 'add'
      },
      rows: rows
    }
    dispatch(VUEX_SAMPLE_INVENTORY_PRODUCTS_UPDATE, finalPayload)
  },

  [VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE, payload)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.updateProducts
    let finalPayload = {
      frontEnd: {
        type: 'update'
      },
      rows: rows
    }
    dispatch(VUEX_SAMPLE_INVENTORY_PRODUCTS_UPDATE, finalPayload)
  },
  [VUEX_SAMPLE_INVENTORY_PRODUCTS_UPDATE]: _debounce(async ({ state, dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)

    if (payload.rows) {
      // kill all frontEnd passed keys
      let finalPayloadData = []
      _forEach(payload.rows, function (obj) {
        obj = _omit(obj, ['frontEnd'])
        if (payload.frontEnd.type === 'add') {
          obj = {
            add: obj
          }
        }
        finalPayloadData.push(obj)
      })

      dispatch(VUEX_API_SAMPLE_INVENTORY_BATCH, {
        data: finalPayloadData
      }).then(async response => {
        dispatch(VUEX_GRID_UPDATE_SUCCESS)
        await commit(VUEX_SAMPLE_INVENTORY_GRID_REQUEST_CLEAR)
        if (payload.frontEnd.type === 'statusChange') {
          GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })
          longWaitBlocker.hide()
          GridHelpers.deselectAll()
        }

        if (payload.frontEnd.type === 'add') {
          commit(VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD_COMPLETE)
        }
      }).catch(err => {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: err.data.message
          }
        })
        dispatch(VUEX_GRID_UPDATE_FAILURE)
        longWaitBlocker.hide()
        return err
      })
    } else {
      dispatch(VUEX_GRID_UPDATE_FAILURE)
      longWaitBlocker.hide()

      console.error('VUEX_SAMPLE_INVENTORY_PRODUCTS_UPDATE fail')
    }
  }, 1500),

  [VUEX_SAMPLE_INVENTORY_GRID_ROWS_DELETE]: _debounce(async ({ state, dispatch, commit }, payload) => {
    // get rows
    await commit(VUEX_SAMPLE_INVENTORY_GRID_ROWS_DELETE, payload.rows)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.deleteProducts
    longWaitBlocker.show()

    // start
    dispatch(VUEX_GRID_UPDATE_START)
    if (rows) {
      // kill all frontEnd passed keys
      let finalPayloadData = []
      // let i = 1
      _forEach(rows, function (obj) {
        let finalObj = {
          '_id': obj._id,
          'delete': true
        }
        finalPayloadData.push(finalObj)
      })

      dispatch(VUEX_API_SAMPLE_INVENTORY_DELETE, {
        data: finalPayloadData
      }).then(async response => {
        dispatch(VUEX_GRID_UPDATE_SUCCESS)
        await commit(VUEX_SAMPLE_INVENTORY_GRID_REQUEST_CLEAR)
        longWaitBlocker.hide()
        GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })
      }).catch(err => {
        dispatch(VUEX_GRID_UPDATE_FAILURE)
        longWaitBlocker.hide()
        GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })

        console.error('VUEX_API_LIBRARIES_PRODUCTS_DELETE | err', err)
        return err
      })
    } else {
      dispatch(VUEX_GRID_UPDATE_FAILURE)
      longWaitBlocker.hide()

      console.error('VUEX_API_SAMPLE_INVENTORY_PRODUCTS_DELETE fail')
    }
  }, 200),

  [VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FETCH]: ({ commit, dispatch }, payload) => {
    let data = {
      params: payload
    }
    // server error occurs if no products are passed, so first check
    if (payload.products && payload.products.length > 0) {
      dispatch(VUEX_API_SAMPLE_INVENTORY_VALIDATE, data).then((response) => {
        commit(VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_SUCCESS, response)
        return response
      }).catch(() => {
        commit(VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FAILURE)
      })
    } else {
      let data = {
        message: 'Success',
        messageSub: 'No Products',
        errors: []
      }
      commit(VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_SUCCESS, data)
      return data
    }
  },


  [VUEX_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION]: ({ state, dispatch, commit }, payload) => {
    if (payload) {
      // kill all frontEnd passed keys
      let finalPayloadData = []
      // let i = 1
      _forEach(payload.params, function (obj) {
        finalPayloadData.push(obj.id)
      })

      dispatch(VUEX_API_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION, finalPayloadData).then(async response => {
        GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })
      }).catch(err => {
        GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })

        console.error('VUEX_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION | err', err)
        return err
      })
    }
  },
}

const mutations = {
  [VUEX_SAMPLE_INVENTORY_GRID_REKEY]: state => {
    state.rekey = Math.random()
  },
  [VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_START]: state => {
    state.agGridRowDataLoading = true
  },
  [VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH]: state => {
    state.agGridRowDataLoading = false
  },

  [VUEX_SAMPLE_INVENTORY_SET_QUERY_PARAMS]: (state, data) => {
    state.repositoryQueryParams = data.params
  },

  [VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE]: (state, data) => {
    data.map(item => {
      state.agGridRequestData.updateProducts.push(item)
    })
  },

  [VUEX_SAMPLE_INVENTORY_GRID_ROWS_DELETE]: (state, data) => {
    data.map(item => {
      state.agGridRequestData.deleteProducts.push(item)
    })
  },

  [VUEX_SAMPLE_INVENTORY_GRID_REQUEST_CLEAR]: state => {
    Object.keys(state.agGridRequestData).forEach(item => {
      if (item !== 'patch') state.agGridRequestData[item] = []
    })
  },
  [VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_SUCCESS]: (state, data) => {
    state.validatedProductsResponse = data
  },

  [VUEX_SAMPLE_INVENTORY_VALIDATE_PRODUCTS_FAILURE]: (_, data) => {
    d.log('VUEX_GRID_VALIDATE_PRODUCTS_FAILURE : ' + data)
  },

  [VUEX_SAMPLE_INVENTORY_GRID_ROWS_ADD_COMPLETE]: state => {
    state.sampleInventoryRowsAddedToggler = Math.random()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
