import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import feConstants from '@/store/constants/config/fe_constants'

export default {
  path: '/logged-out',
  // name: 'loggedOut',
  component: loadView('app/Headless.vue'),
  children: [
    {
      path: '',
      name: 'loggedOut--default',
      redirect: { name: feConstants.ITS__ROUTING__DEFAULT__LOGGED_OUT }
    },
    {
      path: '/logged-out',
      name: 'loggedOut--its',
      component: loadComponent('Dashboard/Dashboard_Its.vue'),
      beforeEnter: (to, from, next) => {
        next()
      }
    }
  ],
  meta: {
    label: 'Dashboard'
  }
}
