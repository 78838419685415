import store from '@/store/store'

// import feConstants from '@/store/constants/config/fe_constants'
import { loadView, loadComponent } from '@/helpers/asyncLoaders'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { ordersSampleMeta } from '@/router/routeMeta'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import {
  VUEX_ROUTING_ENTER_ORDERS_COORDINATOR_ASSIGNMENTS
} from '@/store/constants/routing/routingOrders'

export default {
  path: '/orders',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) => {
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      callback: async (next) => {
        await store.dispatch(VUEX_OPTIONS_FETCH, 'orders')
        next()
      }
    })
  },
  children: [
    {
      path: 'coordinator-assignments',
      name: 'orders--manage-assignments',
      component: loadComponent('Orders/CoordinatorAssignments/Orders_CoordinatorAssignments.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ORDERS_COORDINATOR_ASSIGNMENTS, { to: to, from: from }).then(next)
      },
      meta: {}
    }
  ],
  meta: ordersSampleMeta
}
