import axios from 'axios'
import api from '@/api'
import store from '@/store/store'
import router from '@/router'

import ApiValidator from '@/classes/ApiValidator'

import { getUserPOPColors } from '@/helpers/pop_colors'
// import Debug from 'logdown'

import {
  VUEX_API_PRODUCTS_REQUEST_FETCH,
  VUEX_API_PRODUCTS_REQUEST_SUCCESS,
  VUEX_API_PRODUCTS_REQUEST_FAILED,

  VUEX_API_PRODUCTS_STYLES_FETCH,
  VUEX_API_PRODUCTS_STYLE_COLORS_FETCH,

  VUEX_API_PRODUCTS_VALIDATE,

  VUEX_API_PRODUCT_TEARSHEET,

  VUEX_API_PRODUCTS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

// const d = new Debug('its:store:modules:api:products')

const state = {
  // Products Request
  productsRequest: null,
  productsCancelRequest: null,

  // Product Styles request (for autocompletes)
  productStylesCancelRequest: null,
  // Product Style Colors request (for autocompletes)
  productStylesColorsCancelRequest: null,

  // for validate
  productValidateCancelRequest: null
}

const getters = {}

// so we can handle annoying popColors stuff in one place
// and any other final modifications before send to server
function preFetchPayloadModify (state, data) {
  let assortment = store.state.assortments.assortment

  if (data.productType === ITS__PRODUCT_TYPE__POP) {
    let location
    let status

    switch (router.currentRoute.value.name) {
      case 'assortment-details--manage' :
      // case 'assortment-internal-details' :
        location = assortment.locationId
        status = assortment.status
        break

      case 'assortment-showroom--detail':
        // this function was breaking showroom
        // because filterBarSelectors is not populated there
        return

      default :
        location = state.filterBarSelectors.selectorProps['locations.code']
        status = state.filterBarSelectors.selectorProps['locations.lineStatus']
        break
    }

    if (!data._options) data._options = {}
    data._options.popColors = getUserPOPColors(location, status)
  }
}

const actions = {
  // Fetch Product(s)
  [VUEX_API_PRODUCTS_REQUEST_FETCH]: async ({ state, dispatch, commit, rootState }, payload) => {
    await commit(VUEX_API_PRODUCTS_REQUEST_FETCH)

    if (state.productsCancelRequest) state.productsCancelRequest.cancel()
    await commit(VUEX_API_PRODUCTS_SET_CANCEL_TOKEN, 'productsCancelRequest')
    preFetchPayloadModify(rootState, payload)

    const apiValidator = new ApiValidator('productSearch')
    const validatedPayload = apiValidator.validate(payload)

    // in most cases, you want product fetch from the products API
    // in some cases, you want to fetch a product with assortment specific data
    // if that is the case, check for assortmentsQuery -- if assortmentsQuery is set, then use a different endpoint
    let endpoint = '/products/fetch'
    if (payload?._uiOptions?.assortmentsQuery?._id) {
      if (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
        endpoint = '/internalAssortments/products/query'
      } else {
        endpoint = '/assortments/products/query'
      }
    }

    return api.post(endpoint, validatedPayload, {
      cancelToken: state.productsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_PRODUCTS_REQUEST_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_PRODUCTS_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        /* dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/products/fetch' - ${err.response && err.response.data.message}`
          }
        }) */
      }
    })
  },

  // Fetch Product(s) Styles for autocomplete
  [VUEX_API_PRODUCTS_STYLES_FETCH]: async ({ rootState, state, dispatch, commit }, payload) => {
    if (state.productStylesCancelRequest) state.productStylesCancelRequest.cancel()
    await commit(VUEX_API_PRODUCTS_SET_CANCEL_TOKEN, 'productStylesCancelRequest')

    if (!payload.params) payload.params = {}

    return api.post(`/products/autocomplete/styles/${payload.data}${payload.params.productType === ITS__PRODUCT_TYPE__POP ? `/${store.state.assortments.popColors}` : ''}`,
      null, // post needs the additional arg for the body
      {
        params: payload.params,
        cancelToken: state.productStylesCancelRequest.token
      }).then(response => {
      return response
    }).catch((err) => {
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/products/autocomplete/styles/${payload.data}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Fetch Product(s) Styles Colors for autocomplete
  [VUEX_API_PRODUCTS_STYLE_COLORS_FETCH]: async ({ state, dispatch, commit }, payload) => {
    if (state.productStylesColorsCancelRequest) state.productStylesColorsCancelRequest.cancel()
    await commit(VUEX_API_PRODUCTS_SET_CANCEL_TOKEN, 'productStylesColorsCancelRequest')

    if (!payload.params) payload.params = {}

    return api.post(`/products/autocomplete/colors/${payload.data}${payload.params.productType === ITS__PRODUCT_TYPE__POP ? `/${store.state.assortments.popColors}` : ''}`,
      payload.body,
      {
        params: payload.params,
        cancelToken: state.productStylesColorsCancelRequest.token
      }).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `Cannot load '/products/autocomplete/colors/${payload.data}' - ${err.response && err.response.data.message}`
        }
      })
    })
  },

  // Validate Product(s) Styles Colors - currently for excel import
  [VUEX_API_PRODUCTS_VALIDATE]: ({ state, dispatch, commit }, payload) => {
    if (state.productValidateCancelRequest) state.productValidateCancelRequest.cancel()
    commit(VUEX_API_PRODUCTS_SET_CANCEL_TOKEN, 'productValidateCancelRequest')

    return api.post(`/products/validate`, payload.params).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `Cannot load '/products/autocomplete/colors/${payload.data}' - ${err.response && err.response.data.message}`
        }
      })
    })
  },

  [VUEX_API_PRODUCT_TEARSHEET]: ({ dispatch }, payload) => {
    const { color, style, productType, locationCode } = payload

    return api.get(`/products/tearsheet/${productType}/${style}/${color}/${locationCode}`,
      {
        responseType: 'blob'
      }
    ).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `Cannot load '/products/tearsheet/${style}/${color}/${locationCode}' - ${err.response && err.response.data.message}`
        }
      })
    })
  }
}

const mutations = {
  // Assortments
  [VUEX_API_PRODUCTS_REQUEST_FETCH]: state => {
    state.productsRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_PRODUCTS_REQUEST_SUCCESS]: state => {
    state.productsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_PRODUCTS_REQUEST_FAILED]: state => {
    state.productsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_PRODUCTS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
