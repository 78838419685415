// import Vue from 'vue'
import api from '@/api'
import _clone from 'lodash.clonedeep'
import _merge from 'lodash.merge'
import Debug from 'logdown'

import {
  VUEX_OPTIONS_FETCH,
  VUEX_OPTIONS_ASSIGN,
  VUEX_OPTIONS_PERMITTED_LOCATIONS
} from '@/store/constants/options'

import {
  VUEX_PROPERTIES_UPDATE
} from '@/store/constants/config/properties'

const d = new Debug('its:modules:options:store')

let loadedSections = {}

const state = {
  data: {}
}

const actions = {
  [VUEX_OPTIONS_FETCH]: async ({ commit, dispatch }, section) => {
    if (loadedSections[section] === true && section !== 'loggedIn') return

    try {
      const result = await api.get(`options/data/${section}`)
      await commit(VUEX_OPTIONS_ASSIGN, result.data)
      dispatch(VUEX_PROPERTIES_UPDATE)

      loadedSections[section] = true
    } catch (err) {
      d.log('VUEX_OPTIONS_FETCH', (err.response && err.response.data) || err)
    }
  }
}

const getters = {
  [VUEX_OPTIONS_PERMITTED_LOCATIONS]: (state, _, rootState) => {
    const allowed = {}
    let tbes = (rootState.user.user && rootState.user.user.userTBEs) || []
    const tbeAdmin = tbes.some(tbe => tbe.territory === 'ADMIN' && tbe.businessEntity === 'ADMIN')
    if (tbeAdmin) tbes = state.data.tbe || tbes
    for (let tbe of tbes) {
      for (let loc of tbe.locations) {
        allowed[loc.key] = loc
      }
    }
    d.log(allowed, 'permittedLocations', tbeAdmin)
    return allowed
  }
}

const mutations = {
  [VUEX_OPTIONS_ASSIGN]: (state, data) => {
    const stateCopy = _clone(state.data)
    state.data = _merge(stateCopy, data)
    // state.data = Object.freeze(_merge(stateCopy, data))

    d.log('Options set. *DO NOT reference directly!* If you do, your references may break without notice!')
    d.log('Always reference through properties store. Those will not be changed.', state)
  }
}

export default {
  strict: true,
  state,
  actions,
  mutations,
  getters
}
