import router from '@/router'
import store from '@/store/store'
import _debounce from 'lodash.debounce'
import _get from 'lodash.get'
import _clone from 'lodash.clonedeep'

import chipTransformer from '@/components/_core/FilterBar/helpers/transformers/chipTransformer'

import {
  VUEX_FILTERBAR_MODULE_CREATE,
  VUEX_FILTERBAR_MODULE_DISABLE,
  VUEX_FILTERBAR_MODULE_ENABLE_ALL,
  VUEX_FILTERBAR_MODULE_DESTROY_ALL,
  VUEX_FILTERBAR_SET_HOVER_STATE,

  VUEX_FILTERBAR_OPTIONSLIST_DATA_SET,
  VUEX_FILTERBAR_OPTIONSLISTS_UPDATE,
  VUEX_FILTERBAR_OPTIONSLIST_LOADING,

  VUEX_FILTERBAR_MODULE_PANEL_ACTIVATE,
  VUEX_FILTERBAR_MODULE_SET_ACTIVE_STATE,
  VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE,
  VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL,

  VUEX_FILTERBAR_MODULE_SET_DEPENDENCIES,

  VUEX_FILTERBAR_PARAMETER_ADD,
  VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY,
  VUEX_FILTERBAR_PARAMETER_REMOVE,
  VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL
} from '@/store/constants/ui/filterBar'

import {
  VUEX_FILTERBAR_OPTIONSLISTS_FETCH
} from '@/store/constants/ui/filterBar/filterBar_OptionsLists'

import {
  VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY,
  VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCT_QUERY_CLEAR
} from '@/store/constants/ui/filterBar/filterBar_ProductSearch'

import {
  VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY
} from '@/store/constants/ui/filterBar/filterBar_QueryUpdate'

const state = {
  modules: [],
  modulesMem: {},
  dynamicOptionsData: null,
  dynamicOptionsLoading: true,
  modules_HoverState: false,
  modules_PanelActiveState: false
}

const getters = {
  moduleData: state => moduleID => state.modules.find(m => m.id === moduleID),

  paramParentData: state => moduleMemKey => {
    return state.modulesMem[moduleMemKey]
  },

  moduleActiveState: state => moduleID => {
    let module = state.modules.find(module => module.id === moduleID)
    return module ? module.activeState : false
  },

  moduleDisabledState: state => moduleID => {
    let module = state.modules.find(module => module.id === moduleID)
    return module ? module.disabled : false
  },

  appliedParameters: state => moduleID => {
    if (moduleID) {
      let module = state.modules.find(m => m.id === moduleID)
      return module ? module.filterGroups.flatMap(fg => fg.filters.flatMap(f => f.parameters)) : []
    } else {
      return state.modules.flatMap(m => m.filterGroups.flatMap(fg => fg.filters.flatMap(f => f.parameters)))
    }
  },

  filterKeys: state => () => state.modules.flatMap(m => m.filterGroups.flatMap(fg => fg.filters.flatMap(f => f.optionsKey || f.key)))
}

const actions = {
  // FilterBar
  [VUEX_FILTERBAR_OPTIONSLIST_DATA_SET]: ({ commit }, payload) => {
    commit(VUEX_FILTERBAR_OPTIONSLIST_DATA_SET, payload)
  },

  [VUEX_FILTERBAR_OPTIONSLISTS_UPDATE]: _debounce(async ({ state, getters, commit, dispatch }) => {
    await commit(VUEX_FILTERBAR_OPTIONSLIST_LOADING, false)

    const keys = await getters.filterKeys()

    await dispatch(VUEX_FILTERBAR_OPTIONSLISTS_FETCH, {
      keys,
      libraryType: state.dynamicOptionsData?.libraryType,
      librarySubType: state.dynamicOptionsData?.librarySubType || null,
      exclude: state.dynamicOptionsData?.exclude,
      include: state.dynamicOptionsData?.include,
      modules: state.modules,
      optionsData: state.dynamicOptionsData
    }).then(async response => {
      if (response && Object.keys(response).length > 0) {
        await commit(VUEX_FILTERBAR_OPTIONSLISTS_UPDATE, response)
      }
    }).catch(() => {
      // Handle error event
    })

    commit(VUEX_FILTERBAR_OPTIONSLIST_LOADING, true)
  }, 250),

  [VUEX_FILTERBAR_SET_HOVER_STATE]: ({ commit }, payload) => {
    commit(VUEX_FILTERBAR_SET_HOVER_STATE, payload)
  },

  // Modules
  [VUEX_FILTERBAR_MODULE_CREATE]: ({ commit }, payload) => {
    commit(VUEX_FILTERBAR_MODULE_CREATE, payload)
  },

  [VUEX_FILTERBAR_MODULE_DISABLE]: ({ commit }, payload) => {
    commit(VUEX_FILTERBAR_MODULE_DISABLE, payload)
  },

  [VUEX_FILTERBAR_MODULE_ENABLE_ALL]: ({ commit }) => {
    commit(VUEX_FILTERBAR_MODULE_ENABLE_ALL)
  },

  // Module Panel
  [VUEX_FILTERBAR_MODULE_PANEL_ACTIVATE]: async ({ dispatch, commit }, payload) => {
    await dispatch(VUEX_FILTERBAR_MODULE_SET_DEPENDENCIES, payload) // payload = filterGroup ID

    await commit(VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL)
    commit(VUEX_FILTERBAR_MODULE_PANEL_ACTIVATE, payload)
  },

  [VUEX_FILTERBAR_MODULE_SET_ACTIVE_STATE]: ({ commit }, payload) => {
    commit(VUEX_FILTERBAR_MODULE_SET_ACTIVE_STATE, payload)
  },

  [VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE]: ({ commit }, payload) => {
    commit(VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE, payload)
  },

  [VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL]: ({ commit }) => {
    commit(VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL)
  },

  [VUEX_FILTERBAR_MODULE_DESTROY_ALL]: ({ commit }) => {
    commit(VUEX_FILTERBAR_MODULE_DESTROY_ALL)
  },

  // Filter Dependencies
  [VUEX_FILTERBAR_MODULE_SET_DEPENDENCIES]: async ({ getters, commit }, payload) => {
    let moduleData = await getters.moduleData(payload)

    moduleData.filterGroups.forEach(fg => {
      fg.filters.forEach(async f => {
        let depObj = {}
        if (!f.dependencies) return // bail

        Object.keys(f.dependencies).forEach(depKey => {
          let stateObj

          switch (depKey) {
            case 'assortment' :
              stateObj = store.state.assortments.assortment
              break

            case 'product' :
              stateObj = store.state.filterBarSelectors.selectorProps
              break

            case 'keyInitiative' :
              stateObj = store.state.assortmentsInternal.query
              break
          }

          if (!stateObj) return // bail

          f.dependencies[depKey].forEach(dep => {
            let key = Object.keys(dep)[0]
            depObj[key] = _get(stateObj, dep[key])
          })
        })

        if (!Object.keys(depObj).length) return

        await commit(VUEX_FILTERBAR_MODULE_SET_DEPENDENCIES, {
          moduleId: payload,
          filterGroupId: fg.id,
          filterId: f.id,
          data: depObj
        })
      })
    })
  },

  // Params
  [VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL]: async ({ state, dispatch, commit }, payload) => {
    await commit(VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL)
    await commit(VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL)

    if (payload?.updateOptions) await dispatch(VUEX_FILTERBAR_OPTIONSLISTS_UPDATE)

    if (router.currentRoute.value.name === 'assortment-details--manage' ||
        router.currentRoute.value.name === 'assortment-internal-details--manage' ||
        router.currentRoute.value.name === 'orders--promo-orders--details' ||
        router.currentRoute.value.name === 'orders--sample-orders--details') {
      dispatch(VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCT_QUERY_CLEAR)
    } else {
      dispatch(VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY)
    }
  },

  [VUEX_FILTERBAR_PARAMETER_ADD]: async ({ dispatch, commit }, payload) => {
    let modifiedPayload = _clone(payload)
    modifiedPayload.value = chipTransformer.transformValue(payload.key, payload.value)

    await commit(VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL)
    await commit(VUEX_FILTERBAR_PARAMETER_ADD, modifiedPayload)

    await dispatch(VUEX_FILTERBAR_OPTIONSLISTS_UPDATE)

    // TODO: Might be better to use a different hook for determining which
    // functionality to use
    if (router.currentRoute.value.name === 'assortment-details--manage' ||
      router.currentRoute.value.name === 'assortment-internal-details--manage' ||
      router.currentRoute.value.name === 'orders--promo-orders--details' ||
      router.currentRoute.value.name === 'orders--sample-orders--details') {
      dispatch(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY, {
        data: payload,
        action: 'add'
      })
    } else {
      dispatch(VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY)
    }
  },

  [VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY]: async ({ commit }, payload) => {
    let modifiedPayload = _clone(payload)
    if (!payload.precomputed) modifiedPayload.chipLabel = chipTransformer.transformLabel(payload.key, payload.chipLabel)

    await commit(VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY, modifiedPayload)
  },

  [VUEX_FILTERBAR_PARAMETER_REMOVE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL)
    await commit(VUEX_FILTERBAR_PARAMETER_REMOVE, payload)

    await dispatch(VUEX_FILTERBAR_OPTIONSLISTS_UPDATE)
    // }

    // TODO: Might be better to use a different hook for determining which
    // functionality to use
    if (router.currentRoute.value.name === 'assortment-details--manage' ||
        router.currentRoute.value.name === 'assortment-internal-details--manage' ||
        router.currentRoute.value.name === 'orders--promo-orders--details' ||
        router.currentRoute.value.name === 'orders--sample-orders--details') {
      dispatch(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY, {
        data: payload,
        action: 'remove'
      })
    } else {
      dispatch(VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY)
    }
  }
}

const mutations = {
  // FilterBar
  [VUEX_FILTERBAR_OPTIONSLIST_DATA_SET]: (state, data) => {
    state.dynamicOptionsData = data
  },

  [VUEX_FILTERBAR_OPTIONSLISTS_UPDATE]: (state, data) => {
    Object.keys(data).forEach(key => {
      state.modules.forEach(m => {
        m.filterGroups.forEach(fg => {
          fg.filters.forEach(f => {
            let fKey = f.optionsKey || f.key
            if (fKey === key) f.options = data[key]
          })
        })
      })
    })
  },

  [VUEX_FILTERBAR_SET_HOVER_STATE]: (state, data) => {
    state.modules_HoverState = data
  },

  // Modules
  [VUEX_FILTERBAR_MODULE_CREATE]: (state, data) => {
    state.modules.push(data)

    // Create filter data lookup
    // [module ID - Filter Group ID - Filter ID] = {}
    data.filterGroups.forEach((fg, fgIndex) => {
      fg.filters.forEach((f, fIndex) => {
        state.modulesMem[`${data.id}-${fg.id}-${f.id}`] = {
          moduleIndex: state.modules.length - 1,
          moduleId: data.id,
          moduleLabel: data.label,

          filterGroupIndex: fgIndex,
          filterGroupId: fg.id,
          filterGroupLabel: fg.label,

          numOfFilters: fg.filters.length,
          filterLabel: f.placeholder,
          filterRowLabel: f.group,
          filterIndex: fIndex,
          filterId: f.id,
          filterType: f.type,
          filterKey: f.key,
          filterOptions: f.options,
          multiqueryKeys: f.multiQuery ? f.multiQuery.options.map(option => option.key) : []
        }
      })
    })
  },

  [VUEX_FILTERBAR_MODULE_DISABLE]: (state, data) => {
    let key = Object.keys(data)[0] // use first matching key
    state.modules.forEach(m => {
      if (m[key] === data[key]) {
        m.disabled = true

        m.filterGroups.forEach(fg => {
          fg.filters.forEach(f => { f.parameters = [] })
        })
      }
    })
  },

  [VUEX_FILTERBAR_MODULE_ENABLE_ALL]: state => {
    state.modules.forEach(m => { m.disabled = false })
  },

  // Module Panels
  [VUEX_FILTERBAR_MODULE_PANEL_ACTIVATE]: (state, data) => {
    state.modules.map(m => {
      if (m.id === data && !m.activeState) m.activeState = true
    })
  },

  [VUEX_FILTERBAR_MODULE_SET_ACTIVE_STATE]: (state, data) => {
    state.modules_PanelActiveState = data
  },

  [VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE]: (state, data) => {
    state.modules_PanelActiveState = false
    state.modules.map(m => {
      if (m.id === data) m.activeState = false
    })
  },

  [VUEX_FILTERBAR_OPTIONSLIST_LOADING]: (state, data) => {
    state.dynamicOptionsLoading = data || false
  },

  [VUEX_FILTERBAR_MODULE_PANEL_DEACTIVATE_ALL]: state => {
    state.modules_PanelActiveState = false
    state.modules.forEach(m => {
      if (m.activeState) m.activeState = false
    })
  },

  [VUEX_FILTERBAR_MODULE_DESTROY_ALL]: state => {
    state.modules_HoverState = false
    state.modules_PanelActiveState = false

    /* while (state.modules.length > 0) {
      state.modules.pop()
    } */

    state.modules = []
    state.modulesMem = {}
  },

  [VUEX_FILTERBAR_MODULE_SET_DEPENDENCIES]: (state, data) => {
    let moduleMemData = state.modulesMem[`${data.moduleId}-${data.filterGroupId}-${data.filterId}`]
    let moduleIndex = moduleMemData.moduleIndex
    let filterGroupIndex = moduleMemData.filterGroupIndex
    let filterIndex = moduleMemData.filterIndex

    state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].dependencies.computed = data.data
  },

  // Params
  [VUEX_FILTERBAR_PARAMETER_ADD]: (state, data) => {
    let moduleMemData = state.modulesMem[`${data.moduleId}-${data.filterGroupId}-${data.filterId}`]
    let moduleIndex = moduleMemData.moduleIndex
    let filterGroupIndex = moduleMemData.filterGroupIndex
    let filterIndex = moduleMemData.filterIndex

    let paramIndex = state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].parameters.findIndex(p => {
      return (data.key === p.key) && (JSON.stringify(data.value) === JSON.stringify(p.value))
    })

    // bail if param already exists
    if (paramIndex > -1) return

    let allowMultipleModules = state.modules[moduleIndex].allowMultiple
    let allowMultipleFilterGroups = state.modules[moduleIndex].filterGroups[filterGroupIndex].allowMultiple
    let allowMultipleFilters = state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].allowMultiple

    // use `allowMultiple` prop to clear params
    if (!allowMultipleModules || !allowMultipleFilterGroups || !allowMultipleFilters) {
      if (!allowMultipleFilters) state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].parameters = []

      if (!allowMultipleFilterGroups) {
        state.modules[moduleIndex].filterGroups[filterGroupIndex].filters.forEach(f => {
          f.parameters = []
        })
      }

      if (!allowMultipleModules) {
        state.modules[moduleIndex].filterGroups.forEach(fg => {
          fg.filters.forEach(f => {
            f.parameters = []
          })
        })
      }
    }

    state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].parameters.push(data)
  },

  [VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY]: (state, data) => {
    let libraryType = state.dynamicOptionsData?.libraryType

    if (!libraryType) {
      console.error("FilterBar | filterBar/index.js | VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY -> Cannot find 'libraryType'")
      return
    }

    // Set properties object as var
    let storeProps = store.state.properties.data?.[`${libraryType}`]?.properties

    // Loop over all modules > filterGroups > filters
    let chipMem = {}

    state.modules.forEach(m => {
      m.filterGroups.forEach(fg => {
        fg.filters.forEach(f => {
          let matchMultiQuery = false
          if (f.multiQuery) matchMultiQuery = f.multiQuery.options.some(option => option.key === data.key)

          if ((f.key === data.key || matchMultiQuery) && !chipMem[`${data.key}-${data.value}`]) {
            chipMem[`${data.key}-${data.value}`] = true

            let filterData = Object.values(state.modulesMem).find(moduleMem => {
              return moduleMem.filterKey === data.key || moduleMem.multiqueryKeys.includes(data.key)
            })

            /* -------------- */
            let moduleIndex = filterData.moduleIndex
            let filterGroupIndex = filterData.filterGroupIndex
            let filterIndex = filterData.filterIndex
            let paramIndex = state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].parameters.findIndex(p => {
              return (data.key === p.key) && (JSON.stringify(data.value) === JSON.stringify(p.value))
            })

            // let allowMultipleModules = state.modules[moduleIndex].allowMultiple
            // let allowMultipleFilterGroups = state.modules[moduleIndex].filterGroups[filterGroupIndex].allowMultiple
            // let allowMultipleFilters = state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].allowMultiple

            // bail if param already exists
            if (paramIndex > -1) return

            /* ------------- */

            if (data.precomputed) {
              f.parameters.push({
                ...data,
                moduleId: filterData.moduleId,
                filterGroupId: filterData.filterGroupId,
                filterId: filterData.filterId
              })
            } else {
              let chipValue = decodeURIComponent(data.value.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) // <----- Attempt to control chip values that contain a `%`

              // Reverse lookup the query values with FE properties
              let prop
              if (storeProps[data.key]?.options) {
                prop = storeProps[data.key].options.filter(p => {
                  if (p.value === chipValue || p === chipValue) return p
                })[0]
              }

              // push parsed param into filters param
              f.parameters.push({
                ...data,
                moduleId: filterData.moduleId,
                filterGroupId: filterData.filterGroupId,
                filterId: filterData.filterId,
                value: prop?.value || chipValue,
                chipLabel: prop?.label || data.chipLabel || chipValue,
                fromQuery: true
              })
            }
          }
        })
      })
    })
  },

  [VUEX_FILTERBAR_PARAMETER_REMOVE]: (state, data) => {
    if (data.fromQuery) {
      state.modules.forEach(m => {
        m.filterGroups.forEach(fg => {
          fg.filters.forEach(f => {
            let matchMultiQuery = false
            if (f.multiQuery) matchMultiQuery = f.multiQuery.options.some(option => option.key === data.key)

            if (f.key === data.key || matchMultiQuery) {
              let index = f.parameters.findIndex(p => p.value === data.value)
              if (index > -1) f.parameters.splice(index, 1)
            }
          })
        })
      })
    } else {
      let moduleMemData = state.modulesMem[`${data.moduleId}-${data.filterGroupId}-${data.filterId}`]
      let moduleIndex = moduleMemData.moduleIndex
      let filterGroupIndex = moduleMemData.filterGroupIndex
      let filterIndex = moduleMemData.filterIndex

      let paramIndex = state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].parameters.findIndex(p => p.id === data.id)

      /* console.info({
        modules: state.modules,
        memObj: state.modulesMem,
        memKey: `${data.moduleId}-${data.filterGroupId}-${data.filterId}`,

        ['moduleIndex: ' + moduleIndex]: state.modules[moduleIndex],
        ['filterGroupIndex: ' + filterGroupIndex]: state.modules[moduleIndex].filterGroups[filterGroupIndex],
        ['filterIndex: ' + filterIndex]: state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex]
      }) */

      state.modules[moduleIndex].filterGroups[filterGroupIndex].filters[filterIndex].parameters.splice(paramIndex, 1)
    }
  },

  [VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL]: state => {
    state.modules.forEach(m => {
      m.filterGroups.forEach(fg => {
        fg.filters.forEach(f => {
          f.parameters = []
        })
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
