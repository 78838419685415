import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS,
  VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS_LANDING,
  VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS,
  VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS_LANDING
} from '@/store/constants/routing/routingAdvertising'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { advertisingMeta } from '@/router/routeMeta'

export default {
  path: '/advertising/libraries',
  // name: 'advertising--libraries',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      callback: () => { store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()) }
    }),
  children: [
    {
      path: '',
      name: 'advertising--libraries--default',
      redirect: { name: 'advertising--libraries--assets-landing' }
    },
    {
      path: 'assets-landing',
      name: 'advertising--libraries--assets-landing',
      component: loadComponent('Advertising/Assets/Advertising_AssetsLanding.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS_LANDING, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET
      }
    },
    {
      path: 'assets',
      name: 'advertising--libraries--assets',
      component: loadComponent('Advertising/Assets/Advertising_Assets.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET
      }
    },
    {
      path: 'logos-landing',
      name: 'advertising--libraries--logos-landing',
      component: loadComponent('Advertising/Logos/Advertising_LogosLanding.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS_LANDING, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO
      }
    },
    {
      path: 'logos',
      name: 'advertising--libraries--logos',
      component: loadComponent('Advertising/Logos/Advertising_Logos.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS, { to: to, from: from }).then(() => next())
      },
      meta: {
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO
      }
    },
    {
      path: 'previewer',
      name: 'advertising--libraries--previewer',
      component: loadComponent('Prototypes/Previewer_Prototype.vue')
    }
  ],
  meta: advertisingMeta
}
