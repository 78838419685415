import axios from 'axios'
import api from '@/api'

import {
  VUEX_API_USERS_FETCH,

  VUEX_API_USERS_FETCH_SUCCESS,
  VUEX_API_USERS_FETCH_FAILURE,

  VUEX_API_USERS_AUTOCOMPLETE,

  VUEX_API_USERS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  usersRequest: null,
  usersCancelRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_USERS_FETCH]: async ({ rootState, commit, dispatch }, payload) => {
    // Set `usersRequest` to PENDING
    commit(VUEX_API_USERS_FETCH)

    // Cancel previous requests
    if (state.usersCancelRequest) state.usersCancelRequest.cancel()
    await commit(VUEX_API_USERS_SET_CANCEL_TOKEN, 'usersCancelRequest')

    return api.get('/users/autocomplete', {
      params: payload.params,
      cancelToken: state.usersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_USERS_FETCH_SUCCESS)

      return response.data
    }).catch(err => {
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Users fetch failed!`
          }
        })
      }
    })
  },

  [VUEX_API_USERS_AUTOCOMPLETE]: async ({ commit, dispatch }, payload) => {
    commit(VUEX_API_USERS_FETCH)

    if (state.usersCancelRequest) state.usersCancelRequest.cancel()
    await commit(VUEX_API_USERS_SET_CANCEL_TOKEN, 'usersCancelRequest')

    const { params } = payload

    return api.get(`/users/autocomplete`, {
      cancelToken: state.usersCancelRequest.token,
      params
    }).then(response => {
      commit(VUEX_API_USERS_FETCH_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_USERS_FETCH_FAILURE)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load 'AUTOCOMPLETE:/users/autocomplete' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  }
}

const mutations = {
  [VUEX_API_USERS_FETCH]: state => {
    state.usersRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_USERS_FETCH_SUCCESS]: state => {
    state.usersRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_USERS_FETCH_FAILURE]: state => {
    state.usersRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUEX_API_USERS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
