import assetsModifiedDateModule from '@/components/_core/FilterBar/_config/modules/assetsModifiedDataModule.js'

export default {
  // shareLink: true,
  dynamicOptionsData: true,
  modules: [
    {
      label: 'Type',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'assetType',
              data: 'properties.GlobalRetailAsset.properties.assetType.optionsResource'
              /* specialOptions: [
                {
                  label: 'ALL',
                  value: '___ALL___'
                }
              ] */
            }
          ]
        }
      ]
    },
    {
      label: 'Sub-type',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Asset SubType',
                filterable: true,
                multiselect: true
              },
              key: 'assetSubType',
              data: 'properties.GlobalRetailAsset.properties.assetSubType.optionsInUse'
            }
          ]
        }
      ]
    },
    {
      label: 'Keyword',
      filterGroups: [
        {
          // hint: 'Searches within filename',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter Keyword'
              },
              key: 'keyword',
              multiQuery: {
                type: 'singleselect',
                ifAllSelectedUseKey: 'keyword',
                options: [
                  {
                    label: 'Search filename',
                    key: 'name',
                    value: true
                  },
                  {
                    label: 'Search body document',
                    key: 'body'
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      label: 'Season',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Season',
                filterable: true,
                hiddenMenu: true
              },
              key: 'season',
              data: 'properties.GlobalRetailAsset.properties.season.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Year',
                filterable: true,
                hiddenMenu: true
              },
              key: 'year',
              data: 'properties.GlobalRetailAsset.properties.year.optionsInUse'
            }
          ]
        }
      ]
    },
    {
      label: 'Language',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'lang',
              data: 'properties.GlobalRetailAsset.properties.lang.optionsInUse'
              /* specialOptions: [
                {
                  label: 'ALL',
                  value: '___ALL___'
                }
              ] */
            }
          ]
        }
      ]
    },
    {
      label: 'Product',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Category',
                filterable: true,
                hiddenMenu: true
              },
              key: 'category',
              data: 'properties.GlobalRetailAsset.properties.category.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Gender',
                filterable: true,
                hiddenMenu: true
              },
              key: 'gender',
              data: 'properties.GlobalRetailAsset.properties.gender.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Initiative',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Initiative',
                filterable: true
              },
              key: 'initiative',
              data: 'properties.GlobalRetailAsset.properties.initiative.optionsInUse'
            }
          ]
        }
      ]
    },
    {
      label: 'Partner Type',
      filterGroups: [
        {
          layout: {
            type: 'columns',
            numOfCols: 2
          },
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Entity',
                filterable: true,
                hiddenMenu: true
              },
              key: 'entity',
              data: 'properties.GlobalRetailAsset.properties.entity.options'
            },
            {
              component: {
                type: 'select',
                placeholder: 'Store Type',
                filterable: true,
                hiddenMenu: true
              },
              key: 'storeType',
              data: 'properties.GlobalRetailAsset.properties.storeType.options'
            }
          ]
        }
      ]
    },
    assetsModifiedDateModule
  ]
}
