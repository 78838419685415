import api from '@/api'
import timeout from './timeout'
import userPermChecks from './userPermChecks'

import Debug from 'logdown'

import {
  VUEX_USER_FETCH_AWS_CREDENTIALS,
  VUEX_USER_HAS_ACCESS,
  VUEX_USER_HAS_CONFERENCE_ASIAN,
  VUEX_USER_HAS_CONFERENCE_DOMESTIC,
  VUEX_USER_HAS_CONFERENCE_INTERNATIONAL,
  VUEX_USER_INIT,
  VUEX_USER_LOGIN,
  VUEX_USER_LOGOUT,
  VUEX_USER_SET,
  VUEX_USER_SET_AWS_CREDENTIALS,
  VUEX_USER_SET_AWS_TOKEN,
  VUEX_USER_SET_REDIRECT_PATH
} from '@/store/constants/user'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import {
  VUEX_ORDERS_CLEAR_SELECTED_COORDINATOR
} from '@/store/constants/models/orders'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

import {
  VUEX_ROUTING_ROUTE
} from '@/store/constants/routing'

const d = new Debug('its:modules:user:store')

const state = {
  user: null,
  awsCredentials: null,
  awsToken: null,
  loginRedirectPath: window.location.pathname
}

const getters = {
  [VUEX_USER_HAS_ACCESS]: state => {
    return params => {
      const { permission, roles } = params
      if (!permission || !state.user) return

      return state.user?.permissions.find(perm => {
        const hasPerm = perm.permission.domain === permission

        if (hasPerm && !roles) return perm
        if (hasPerm && roles.includes(perm.permission.role)) return perm
      })
    }
  },

  // TODO: Consolidate these to use `getUserConferenceGroup()` directly
  [VUEX_USER_HAS_CONFERENCE_ASIAN]: (_, getters) => getters.getUserConferenceGroup('Asian JV').hasPerm,
  [VUEX_USER_HAS_CONFERENCE_DOMESTIC]: (_, getters) => getters.getUserConferenceGroup('Domestic').hasPerm,
  [VUEX_USER_HAS_CONFERENCE_INTERNATIONAL]: (_, getters) => getters.getUserConferenceGroup('International').hasPerm,

  // Import externally defined perms checks
  ...userPermChecks
}

const actions = {
  [VUEX_USER_INIT]: async (context) => {
    const { dispatch, getters, state, rootState } = context
    timeout.setContext(context)

    await dispatch(VUEX_USER_SET, rootState.options.data.user)

    d.log('User init', state.user)

    // INIT APPCUES
    if (window.Appcues && state.user) {
      let appcuesObj = {
        name: state.user.name,
        lastName: state.user.lastName,
        email: state.user.email,
        userActiveLocation: state.user.userActiveLocation,
        strategy: state.user.strategy,
        role: getters.hasProductAssortmentRole,
        departmentAccess: state.user?.departmentAccess[0] || null
      }
      window.Appcues.identify(state.user.email, appcuesObj)
    }
  },

  // pass in false instead of null to indicate a user triggered logout
  [VUEX_USER_SET]: async ({ commit, state, dispatch }, user) => {
    if (!user) {
      const redirectQuery = window.location.href.split('?')[1]
      const redirectPath = window.location.pathname === '/logged-out' ? '/' : `${window.location.pathname}${redirectQuery ? `?${redirectQuery}` : ''}`
      d.log(`Set user logged out ${user}. On login redirect to`, redirectPath)
      commit(VUEX_USER_SET_REDIRECT_PATH, redirectPath) // window.location.pathname
      commit(VUEX_USER_SET_AWS_CREDENTIALS, null)
      commit(VUEX_USER_SET_AWS_TOKEN, null)

      timeout.setStateFromUpdatedContext()
    } else {
      d.log('Set user logged in', user)
      commit(VUEX_USER_SET, user)
      timeout.setStateFromUpdatedContext()
      // go back to path before logout
      if (state.loginRedirectPath && state.loginRedirectPath !== window.location.pathname) {
        dispatch(VUEX_ROUTING_ROUTE, { path: state.loginRedirectPath }, { root: true })
      }
    }
  },

  [VUEX_USER_LOGIN]: async ({ dispatch }, data) => {
    let session

    try {
      session = await api.post('users/session', data)
    } catch (err) {
      d.info('VUEX_USER_LOGIN', err)
      const errorMsg = (err.response && err.response.data && err.response.data.message) || 'Unknown error'

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        timeout: -1,
        data: {
          type: 'error',
          message: errorMsg
        }
      })
    }

    await dispatch(VUEX_OPTIONS_FETCH, 'loggedIn')
    await dispatch(VUEX_USER_SET, session.data)

    await dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
      component: '_core/Toast/Toast_Message.vue',
      data: {
        type: 'success',
        message: `You've successfully logged in.`
      }
    })
  },

  [VUEX_USER_LOGOUT]: async ({ dispatch, commit }) => {
    await timeout.logout()
    await commit(VUEX_ORDERS_CLEAR_SELECTED_COORDINATOR)
    dispatch(VUEX_USER_SET, false)
  },

  // TODO: Remove this once we get uploads working with signed urls
  // get AWS credentials for an AWS action. Limited duration, refreshed as needed
  [VUEX_USER_FETCH_AWS_CREDENTIALS]: async context => {
    if (!context.state.user || !context.rootState.options.data) return
    const creds = context.state.awsCredentials && context.state.awsCredentials.Credentials

    // still valid, nothing to do
    if (creds && creds.Expiration > new Date()) return

    let token = context.state.awsToken
    // fetch token if missing or expired
    if (!token || token.expiration < new Date()) {
      try {
        let result = await api('users/aws/token')
        token = result.data
        context.commit(VUEX_USER_SET_AWS_TOKEN, token)
      } catch (err) {
        d.error('AWS token error', err)
        throw err
      }
    }

    const AWS = window.AWS
    const sts = new AWS.STS({
      region: context.rootState.options.data?.aws.identityPool.region
    })

    const params = {
      DurationSeconds: 60 * 60, // 1 hour
      RoleArn: context.rootState.options.data.aws.identityPool.clientRoleArn,
      RoleSessionName: context.state.user.email,
      WebIdentityToken: token.Token
    }

    try {
      const data = await sts.assumeRoleWithWebIdentity(params).promise()
      context.commit(VUEX_USER_SET_AWS_CREDENTIALS, data)
      d.log('AWS token and creds', context.state.awsToken, context.state.awsCredentials)
    } catch (err) {
      d.error('AWS creds error', err)
      throw err
    }
  }
}

const mutations = {
  [VUEX_USER_SET]: (state, user) => {
    state.user = user
  },
  [VUEX_USER_SET_AWS_CREDENTIALS]: (state, creds) => {
    state.awsCredentials = creds
  },
  [VUEX_USER_SET_AWS_TOKEN]: (state, token) => {
    state.awsToken = token
  },
  [VUEX_USER_SET_REDIRECT_PATH]: (state, path) => {
    state.loginRedirectPath = path
  }
}

export default {
  strict: true,
  state,
  actions,
  mutations,
  getters
}
