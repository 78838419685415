export default class CurrencyFormatter {
  constructor (availableLocations = null, defaultLocation = 'US') {
    this.L = availableLocations

    this.defaults = {
      location: this.L.find(loc => loc.code === defaultLocation) || {}
    }
  }

  // Public methods
  formatPriceByLocation (value, location) {
    // if (typeof value === 'string') return value

    let symbol = this.getCurrencySymbol(location)
    let formattedValue = this.formatDecimals(value, location)

    return `${symbol}${formattedValue}`
  }

  formatDecimals (num, location) {
    let decimalsPlaces = this.getCurrencyDecimalPlaces(location)
    let parsedNum = parseFloat(num).toFixed(decimalsPlaces)

    return parsedNum
  }

  getCurrencySymbol (location) {
    let locationData = this._getLocationData(location)

    if (!locationData.currencySymbol === undefined) console.error("Cannot get currency `currencySymbol`. Please make sure you're passing location.code", locationData)
    return locationData.currencySymbol
  }

  getCurrencyDecimalPlaces (location) {
    let locationData = this._getLocationData(location)

    if (!locationData.decimalPlaces === undefined) console.error("Cannot get currency `decimalPlaces`. Please make sure you're passing location.code", locationData)
    return locationData.decimalPlaces
  }

  // Private methods
  _getLocationData (location) {
    return this.L.find(loc => loc.code === location) || this.defaults.location
  }

  /* _checkHasAllValidData (type) {} */
}
