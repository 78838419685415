import {
  VUEX_NAVIGATION_DIALOG_SHOW,
  VUEX_NAVIGATION_DIALOG_HIDE,
  VUEX_NAVIGATION_DIALOG_SET_PROPS
} from '@/store/constants/ui/navigationDialog'

const state = {
  actionButtons: [],
  content: null,
  data: null,
  hasNext: true,
  hasPrevious: true,
  maxWidth: '1280px',
  openState: false,
  title: null,
  width: '80vw'
}

const getters = {}

const actions = {
  [VUEX_NAVIGATION_DIALOG_SHOW]: ({ commit }, payload) => {
    commit(VUEX_NAVIGATION_DIALOG_SET_PROPS, payload)
    commit(VUEX_NAVIGATION_DIALOG_SHOW)
  },

  [VUEX_NAVIGATION_DIALOG_HIDE]: ({ commit }) => {
    commit(VUEX_NAVIGATION_DIALOG_HIDE)
  },

  [VUEX_NAVIGATION_DIALOG_SET_PROPS]: ({ commit }, payload) => {
    commit(VUEX_NAVIGATION_DIALOG_SET_PROPS, payload)
  }
}

const mutations = {
  [VUEX_NAVIGATION_DIALOG_SHOW]: state => {
    state.openState = true
  },

  [VUEX_NAVIGATION_DIALOG_HIDE]: state => {
    state.openState = false
    state.title = null
    state.data = null
  },

  [VUEX_NAVIGATION_DIALOG_SET_PROPS]: (state, data) => {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
