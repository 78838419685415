export const VUEX_PRODUCT_DETAIL_CLEAR_CACHE = 'VUEX_PRODUCT_DETAIL_CLEAR_CACHE'
export const VUEX_PRODUCT_DETAIL_CLOSE_ANGLE_SELECTOR = 'VUEX_PRODUCT_DETAIL_CLOSE_ANGLE_SELECTOR'
export const VUEX_PRODUCT_DETAIL_FETCH_DATA = 'VUEX_PRODUCT_DETAIL_FETCH_DATA'
export const VUEX_PRODUCT_DETAIL_OPEN_ANGLE_SELECTOR = 'VUEX_PRODUCT_DETAIL_OPEN_ANGLE_SELECTOR'
export const VUEX_PRODUCT_DETAIL_SET_IMAGES_AVAILABLE = 'VUEX_PRODUCT_DETAIL_SET_IMAGES_AVAILABLE'
export const VUEX_PRODUCT_DETAIL_SET_IS_SIDEBAR_OPEN = 'VUEX_PRODUCT_DETAIL_SET_IS_SIDEBAR_OPEN'
export const VUEX_PRODUCT_DETAIL_SET_ITEM = 'VUEX_PRODUCT_DETAIL_SET_ITEM'
export const VUEX_PRODUCT_DETAIL_SET_LOADING = 'VUEX_PRODUCT_DETAIL_SET_LOADING'
export const VUEX_PRODUCT_DETAIL_SET_SELECTED_ANGLE = 'VUEX_PRODUCT_DETAIL_SET_SELECTED_ANGLE'
export const VUEX_PRODUCT_DETAIL_SET_SELECTED_COLOR = 'VUEX_PRODUCT_DETAIL_SET_SELECTED_COLOR'
export const VUEX_PRODUCT_DETAIL_SET_SHOWROOM_SETTINGS = 'VUEX_PRODUCT_DETAIL_SET_SHOWROOM_SETTINGS'
export const VUEX_PRODUCT_DETAIL_SET_VIEW = 'VUEX_PRODUCT_DETAIL_SET_VIEW'
export const VUEX_PRODUCT_DETAIL_SET_PANEL = 'VUEX_PRODUCT_DETAIL_SET_PANEL'
export const VUEX_PRODUCT_DETAIL_SET_PRODUCTS = 'VUEX_PRODUCT_DETAIL_SET_PRODUCTS'
export const VUEX_PRODUCT_DETAIL_SET_ORGANIZATIONAL_TYPE = 'VUEX_PRODUCT_DETAIL_SET_ORGANIZATIONAL_TYPE'
