export default {
  mounted (el, binding, vnode) {
    if (!binding.instance.menuClose) binding.instance.menuClose = {}

    binding.instance.menuClose.menuState = binding.value?.menuState || 'menuState'
    binding.instance.menuClose.closeDelay = binding.value?.delay || 350
    binding.instance.menuClose.intervalTimer = null
    binding.instance.menuClose.methods = binding.dir.methods

    el.addEventListener('mouseleave', binding.dir.methods.mouseLeave, true)
    el.addEventListener('mouseenter', binding.dir.methods.killDelayClose, true)

    // Add binding to new `menuClose` param
    el.menuClose = binding
  },

  unmounted (el) {
    el.removeEventListener('mouseleave', el.menuClose.dir.methods.mouseLeave)
    el.removeEventListener('mouseenter', el.menuClose.dir.methods.killDelayClose)
  },

  methods: {
    mouseLeave (evt) {
      const binding = evt.target.menuClose
      binding?.instance.menuClose.methods.initDelayClose(evt)
    },

    initDelayClose (evt) {
      const binding = evt.target.menuClose
      const closeDelay = binding.instance.menuClose.closeDelay

      binding.instance.menuClose.intervalTimer = setInterval(() => {
        clearInterval(binding.instance.menuClose?.intervalTimer)
        binding.instance[binding.instance.menuClose.menuState] = false
      }, closeDelay)
    },

    killDelayClose (evt) {
      const binding = evt.target.menuClose
      if (binding?.instance.menuClose.intervalTimer) clearInterval(binding.instance.menuClose.intervalTimer)
      if (binding?.instance) binding.instance.menuClose.intervalTimer = null
    }
  }

  /* bind (el, binding, node) {
    if (!node.context.menuClose) node.context.menuClose = {}

    node.context.menuClose.menuState = binding.value?.menuState || 'menuState'
    node.context.menuClose.closeDelay = binding.value?.delay || 350
    node.context.menuClose.intervalTimer = null
    node.context.menuClose.methods = binding.def.methods

    el.addEventListener('mouseleave', binding.def.methods.mouseLeave.bind(null, node))
    el.addEventListener('mouseenter', binding.def.methods.killDelayClose.bind(null, node))
  },

  unbind (el, binding) {
    el.removeEventListener('mouseleave', binding.def.methods.mouseLeave)
    el.removeEventListener('mouseenter', binding.def.methods.killDelayClose)
  } */
}
