import store from '@/store/store'

const getUserPOPColors = (location, status) => {
  let tbes = (store.state.user.user && store.state.user.user.userTBEs) || []
  const tbeAdmin = tbes.some(tbe => tbe.territory === 'ADMIN' && tbe.businessEntity === 'ADMIN')

  if (tbeAdmin) tbes = store.state.options.data?.tbe || tbes
  const popColorLookup = { AST: true }

  for (const tbe of tbes) {
    for (const loc of tbe.locations) {
      const split = loc.key.split('_')
      if (split[0] === location && split[1].includes(status)) {
        for (const color of tbe.colorFilters.POP) {
          popColorLookup[color] = true
        }
      }
    }
  }
  const popColors = []
  for (const color of Object.keys(popColorLookup)) {
    popColors.push(color)
  }

  return popColors
}

export {
  getUserPOPColors
}
