import _debounce from 'lodash.debounce'
import _clone from 'lodash.clonedeep'
import _merge from 'lodash.merge'
import dayjs from 'dayjs'

import store from '@/store/store'
import router from '@/router'

import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import { regionToLocation } from '@/helpers/assortmentDataModifiers'
import { removeEmptyElements } from '@/helpers/objectModifiers'

import {
  VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL
} from '@/store/constants/ui/filterBar'

import {
  VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY,
  VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_UPDATE,

  VUEX_FILTERBAR_PRODUCTSEARCH_SET_FETCH_PENDING,
  VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_ADD_QUERY,
  VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_FETCH,
  VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_PENDING_ROWS,
  VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS,
  VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS_CLEAR,
  VUEX_FILTERBAR_PRODUCTSEARCH_CLEAR_ADD_QUERY,
  VUEX_FILTERBAR_PRODUCTSEARCH_QUERY_CONSTRUCT,
  VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCT_QUERY_CLEAR
} from '@/store/constants/ui/filterBar/filterBar_ProductSearch'

import {
  VUEX_API_PRODUCTS_REQUEST_FETCH
} from '@/store/constants/api'

import {
  VUEX_STATISTICS_SEND
} from '@/store/constants/statistics'

import {
  VUEX_ASSORTMENT_UPDATE_QUERY
} from '@/store/constants/models/assortments'

import {
  VUEX_PRODUCTS_FETCH,
  VUEX_PRODUCTS_CLEAR
} from '@/store/constants/models/products'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  fetchPending: false,
  pendingRowUpdates: {
    add: [],
    remove: []
  },
  searchQueryBase: {},
  productsQuery: {
    search: {},
    add: {
      '$or': [],
      _options: {
        totalCount: true,
        sort: {
          style: 1
        }
      }
    }
  }
}

const getters = {}

const actions = {
  [VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY]: async ({ rootState, state, getters, dispatch, commit }, payload) => {
    const manageType = router.currentRoute.value.meta.manageType
    const searchData = payload

    switch (manageType) {
      // ASSORTMENTS SEARCH ----------------------------------------------
      case 'AssortmentsRegular' :
      case 'AssortmentsInternal' :
        const assortment = rootState.assortments.assortment

        if (assortment.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC) {
          await commit(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY, { state: rootState, data: searchData })

          let dataObj = _clone(state.productsQuery.search)
          if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
            Object.assign(dataObj, rootState.filterBarSelectors.selectorProps)
          }

          // Remove empty|null values
          dataObj = removeEmptyElements(dataObj)

          if (getters.appliedParameters().length > 0) {
            dispatch(VUEX_PRODUCTS_FETCH, {
              data: dataObj,
              modelType: 'assortment',
              action: 'replace'
            })
          } else {
            dispatch(VUEX_PRODUCTS_CLEAR)
          }
        } else if (assortment.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
          await commit(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY, { state: rootState, data: searchData })
          dispatch(VUEX_ASSORTMENT_UPDATE_QUERY, getters.appliedParameters())

          if (searchData?.action === 'add') {
            await dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
              component: '_core/Toast/Toast_Message.vue',
              timeout: 2000,
              data: {
                type: 'success',
                message: `1 Filter added and assortment updated`
              }
            })
          } else {
            await dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
              component: '_core/Toast/Toast_Message.vue',
              timeout: 2000,
              data: {
                type: 'success',
                message: `1 Filter removed and assortment updated`
              }
            })
          }
        }
        break

      // ORDERS SEARCH ---------------------------------------------------
      case 'PromoDetail' :
      case 'SampleDetail' :
        await commit(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY, { state: rootState, data: searchData })

        let dataObj = _clone(state.productsQuery.search)

        // Remove empty|null values
        dataObj = removeEmptyElements(dataObj)

        if (getters.appliedParameters().length > 0) {
          dispatch(VUEX_PRODUCTS_FETCH, {
            data: dataObj,
            modelType: 'order',
            action: 'replace'
          })
        } else {
          dispatch(VUEX_PRODUCTS_CLEAR)
        }
        break

      default :
        console.error(`filterBar_ProductSearch.js | actions.VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY | 'manageType' missing | ${manageType}`)
        break
    }
  },

  /*  */
  /* GET/SAVE FULL PRODUCT DATA ---------- */
  /*  */
  /*  */
  // (STEP 1)
  [VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_UPDATE]: async ({ state, dispatch, commit }, payload) => {
    if (payload.action === 'add' || payload.action === 'add_all') {
      // If `add` action ->
      // 1. Set `fetchPending` flag to TRUE to wait for full
      // product data
      await commit(VUEX_FILTERBAR_PRODUCTSEARCH_SET_FETCH_PENDING, true)
      // 2. Update the Query for product editing
      await commit(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_ADD_QUERY, payload)
      // 3. Fetch product data
      dispatch(VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_FETCH, payload)
      if (payload.action !== 'add_all') commit(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_PENDING_ROWS, payload)
    } else if (payload.action === 'remove' || payload.action === 'remove_all') {

      // If `remove` action ->
      // If not currently fetching data items ->
      // Update grid rows (VUEX_GRID_ROWS_REMOVE)
      if (payload.action !== 'remove_all') await commit(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_PENDING_ROWS, payload)
      if (!state.fetchPending) dispatch(VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS, payload)
    }
  },
  // (STEP 2)
  [VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_FETCH]: _debounce(async ({ state, getters, dispatch, commit }, payload) => {
    const manageType = router.currentRoute.value.meta.manageType

    // Clone data to decouple from state
    let data = _clone(state.productsQuery.add)

    const computedAppliedParams = {}

    getters.appliedParameters().forEach(param => {
      if (['availabilityDate'].includes(param.key)) {
        computedAppliedParams[param.key] = param.value
      }
    })

    switch (manageType) {
      // ASSORTMENTS SEARCH ----------------------------------------------
      case 'AssortmentsRegular' :
      case 'AssortmentsInternal' :
        // DO SOMETHING HERE
        break

      // ORDERS SEARCH ---------------------------------------------------
      case 'PromoDetail' :
      case 'SampleDetail' :
        data['locations.code'] = 'US'

        /* if (!order.availabilityDate) {
          const dateFormat = 'YYYY/MM/DD'
          const beginDate = dayjs().format(dateFormat)
          const endDate = dayjs().add(45, 'day').format(dateFormat)
          searchQuery.availabilityDate = { beginDate, endDate }
        } */

        Object.assign(data._options, {
          includeWarehouseAvailability: 'US'
        })
        break
    }

    // remove empty style/color objects before request
    removeEmptyElements(data['$or'])

    // Clear the previous data from `productsQuery.add`
    commit(VUEX_FILTERBAR_PRODUCTSEARCH_CLEAR_ADD_QUERY)
    // Fetch Product data from API action

    dispatch(VUEX_API_PRODUCTS_REQUEST_FETCH, data).then(async response => {
      // Update `pendingRowUpdates` with full product data
      if (payload.action === 'add') {
        await commit(VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_PENDING_ROWS, {
          action: 'add',
          data: response.data[0].data,
          method: 'replace'
        })
      }
      // Set `fetchPending` flag to FALSE
      await commit(VUEX_FILTERBAR_PRODUCTSEARCH_SET_FETCH_PENDING, false)
      // Update grid rows (VUEX_GRID_ROWS_ADD)
      dispatch(VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS, {
        data: response.data[0].data,
        action: payload.action
      })
    })
  }, 1000),
  // (STEP 3)
  [VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS]: _debounce(async ({ state, dispatch, commit }, payload) => {
    const manageType = router.currentRoute.value.meta.manageType

    // If product data is still being fetched -> bail
    if (state.fetchPending) return

    // Keep track of number of added and delete rows for notification
    let itemsUpdated = { add: 0, remove: 0 }

    if (payload.action === 'add' || payload.action === 'remove') {
      // Loop through add/remove rows in `pendingRowUpdates`
      Object.keys(state.pendingRowUpdates).map(key => {
        // Get item that aren't pending (ie still fetching product data)
        // only used for `add` rows
        let items = state.pendingRowUpdates[key].filter(p => !p._pending)

        if (items.length === 0) return // Fast fail if no item are pending
        itemsUpdated[key] += items.length // Add items to `itemsUpdated` count

        // VUEX_GRID_ROWS_ADD / VUEX_GRID_ROWS_REMOVE row
        const obj = { type: 'products', rows: items }

        switch (manageType) {
          // ASSORTMENTS SEARCH ----------------------------------------------
          case 'AssortmentsRegular' :
          case 'AssortmentsInternal' :
            // DO NOTHING MORE
            break

          // ORDERS SEARCH ---------------------------------------------------
          case 'PromoDetail' :
          case 'SampleDetail' :
            const order = store.state.orders.order
            const user = store.state.user.user
            obj.rows = DataMiddleware.convertOrderToRowData(obj.rows, order, user)

            /*
              This function is for removing products based on the `idenfitier`
              property. Muliple products of the same style/color can be added to
              Sample and Promo orders.
            */
            if (payload.action === 'remove') {
              const rows = GridHelpers.getRowNodes()
              const mem = []

              obj.rows = obj.rows.map(item => {
                const styleColorKey = `${item.style}-${item.color}`

                const rowItem = rows.find(r => {
                  // if style/color string match AND `identifier is NOT in mem array
                  if (`${r.data.style}-${r.data.color}` === styleColorKey && !mem.includes(r.data.identifier)) return r
                })

                mem.push(rowItem.data.identifier)

                return {
                  ...item,
                  identifier: rowItem.data.identifier
                }
              })
            }

            break
        }

        dispatch(`VUEX_GRID_ROWS_${key.toUpperCase()}`, obj)

        // Commit mutation for row changes
        commit(VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS_CLEAR, items)
      })
    } else if (payload.action === 'add_all' || payload.action === 'remove_all') {
      // Bypass processing pending item
      // Set number of added/remove items for toast
      itemsUpdated[(payload.action === 'add_all') ? 'add' : 'remove'] = payload.data.length
      // VUEX_GRID_ROWS_ADD / VUEX_GRID_ROWS_REMOVE row
      const obj = {
        type: 'products',
        rows: payload.data
      }
      dispatch(`VUEX_GRID_ROWS_${payload.action === 'add_all' ? 'ADD' : 'REMOVE'}`, obj)
    }

    if (payload.action === 'add' || payload.action === 'add_all') {
      dispatch(VUEX_STATISTICS_SEND, {
        action: ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__ADD_SEARCH,
        data: {
          itemCount: itemsUpdated.add
        }
      })
    }

    if ((itemsUpdated.add > 0 && itemsUpdated.add < ITS__LONG_WAIT_BLOCKER__NUM_OF_ITEMS) || (itemsUpdated.remove > 0 && itemsUpdated.remove < ITS__LONG_WAIT_BLOCKER__NUM_OF_ITEMS)) {
      /* Component string for notification */
      let toastMessage = ''
      Object.keys(itemsUpdated).map(key => {
        if (itemsUpdated[key] === 0) return
        let action = `${key}${(key.split('')[key.split('').length - 1] === 'e') ? 'd' : 'ed'}`
        toastMessage += `${itemsUpdated[key]} Item${itemsUpdated[key] > 1 ? 's' : ''} ${action} <br/>`
      })

      /* Fire notification event */
      await dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        timeout: 2000,
        data: {
          type: 'success',
          message: toastMessage
        }
      })
    }
  }, 1250),
  /*  */
  /*  */
  /* END: GET/SAVE FULL PRODUCT DATA ---------- */
  /*  */

  [VUEX_FILTERBAR_PRODUCTSEARCH_QUERY_CONSTRUCT]: async ({ commit }, payload) => {
    await commit(VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL)

    let mem = {}
    let match = false

    payload.forEach(param => {
      match = false

      store.state.filterBar.modules.forEach(m => {
        if (match) return

        m.filterGroups.forEach(fg => {
          if (match) return

          fg.filters.forEach(f => {
            if (param.key === f.key) {
              match = true

              let matchMultiQuery = false
              if (f.multiQuery) matchMultiQuery = f.multiQuery.options.some(option => option.key === payload.key)

              let paramValue = `'${JSON.stringify(param.value)}'`
              if ((f.key === param.key || matchMultiQuery) && !mem[`${payload.key}-${paramValue}`]) {
                mem[`${payload.key}-${paramValue}`] = true
                commit(VUEX_FILTERBAR_PRODUCTSEARCH_QUERY_CONSTRUCT, {
                  ...param,
                  moduleId: m.id,
                  filterGroupId: fg.id,
                  filterId: f.id
                })
              }
            }
          })
        })
      })
    })
  },

  [VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCT_QUERY_CLEAR]: ({ commit, dispatch }) => {
    dispatch(VUEX_PRODUCTS_CLEAR)
    commit(VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCT_QUERY_CLEAR)
  }
}

const mutations = {
  // Mutation used for modifying the search(ui) or add(full data) queries
  [VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY]: state => {
    const manageType = router.currentRoute.value.meta.manageType
    let searchQuery = {}

    store.state.filterBar.modules.forEach(m => {
      m.filterGroups.forEach(fg => {
        fg.filters.forEach(f => {
          f.parameters.forEach(p => {
            if (f.payloadType && f.payloadType === 'object') {
              Object.assign(searchQuery, { [p.key]: p.value })
            } else {
              if (!searchQuery[p.key]) searchQuery[p.key] = []
              searchQuery[p.key].push(p.value)
            }
          })
        })
      })
    })

    switch (manageType) {
      // ASSORTMENTS SEARCH ----------------------------------------------
      case 'AssortmentsRegular' :
      case 'AssortmentsInternal' :
        let assortment = store.state.assortments.assortment
        if (assortment.productType) searchQuery.productType = assortment.productType

        if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__REGULAR) {
          if (assortment.locationId) Object.assign(searchQuery, { 'locations.code': assortment.locationId })
          if (assortment.status) Object.assign(searchQuery, { 'locations.lineStatus': assortment.status })
        } else if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
          searchQuery._options = {
            markInAssortmentsQuery: {
              state: assortment?.state || ITS__ASSORTMENTS__STATE__PUBLISHED,
              method: assortment?.method,
              orgType: assortment.orgType,
              productType: assortment.productType,
              channel: assortment.channel,
              season: assortment.season,
              year: assortment.year,
              region: assortment.region
            }
          }
        }
        break

      // ORDERS SEARCH ---------------------------------------------------
      case 'PromoDetail' :
      case 'SampleDetail' :
        const order = store.state.orders.order

        if (order.productType) searchQuery.productType = order.productType
        if (!order.availabilityDate) {
          const dateFormat = 'YYYY/MM/DD'
          const beginDate = dayjs().format(dateFormat)
          const endDate = dayjs().add(45, 'day').format(dateFormat)
          searchQuery.availabilityDate = { beginDate, endDate }
        }

        searchQuery['locations.code'] = 'US'
        break
      default :
        console.error(`filterBar_ProductSearch.js | mutations.VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY | 'manageType' missing | ${manageType}`)
        break
    }

    state.productsQuery.search = _merge(searchQuery, state.searchQueryBase)
  },

  [VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_ADD_QUERY]: (state, data) => {
    const manageType = router.currentRoute.value.meta.manageType

    switch (manageType) {
      // ASSORTMENTS SEARCH ----------------------------------------------
      case 'AssortmentsRegular' :
      case 'AssortmentsInternal' :
        let assortment = store.state.assortments.assortment

        Object.assign(state.productsQuery.add, { 'productType': assortment.productType })

         // Is INTERNAL
        if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
          if (assortment.region) _merge(state.productsQuery.add, { 'locations.code': regionToLocation(assortment.region) })
          if (assortment.region) _merge(state.productsQuery.add._options, { region: assortment.region })

          // Is WHOLESALE and (INTERNATIONAL or EUROPE)
          if (assortment.channel === ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE && (assortment.region === ITS__REGION__INTERNATIONAL || assortment.region === ITS__REGION__EUROPE)) {
            if (assortment.carryoverDate) _merge(state.productsQuery.add._options, { carryoverDate: assortment.carryoverDate })
          }
        // Is REGULAR
        } else if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__REGULAR) {
          if (!state.productsQuery.add['locations.code'] && assortment.locationId) Object.assign(state.productsQuery.add, { 'locations.code': assortment.locationId })
        }
        break

      // ORDERS SEARCH ---------------------------------------------------
      case 'PromoDetail' :
      case 'SampleDetail' :
        const order = store.state.orders.order

        Object.assign(state.productsQuery.add, { 'productType': order.productType })
        break
      default :
        console.error(`filterBar_ProductSearch.js | mutations.VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_ADD_QUERY | 'manageType' missing | ${manageType}`)
        break
    }

    data.data.map(item => {
      state.productsQuery.add['$or'].push(item)
    })
  },

  [VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_PENDING_ROWS]: (state, data) => {
    const manageType = router.currentRoute.value.meta.manageType

    let assortment = store.state.assortments.assortment
    let order = store.state.orders.order

    let existsInProductArray = false
    let currentProducts

    switch (manageType) {
      // ASSORTMENTS SEARCH ----------------------------------------------
      case 'AssortmentsRegular' :
      case 'AssortmentsInternal' :
        currentProducts = assortment.products
        break

      // ORDERS SEARCH ---------------------------------------------------
      case 'PromoDetail' :
      case 'SampleDetail' :
        currentProducts = order.products
        break
      default :
        console.error(`filterBar_ProductSearch.js | mutations.VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_ADD_QUERY | 'manageType' missing | ${manageType}`)
        break
    }

    data.data.map(p => {
      let newItem = `${p.style}-${p.color}`

      // Traverse pending updates to see if item exists
      Object.keys(state.pendingRowUpdates).map(key => {
        state.pendingRowUpdates[key].map((item, index) => {
          let existingItem = `${item.style}-${item.color}`
          if (newItem === existingItem && key !== data.action) state.pendingRowUpdates[key].splice(index, 1)
        })
      })

      // Traverse existing assortment|order products to see if item exists
      currentProducts.map(item => {
        let existingItem = `${item.style}-${item.color}`
        if (newItem === existingItem) existsInProductArray = true
      })

      // [Add/remove] new item if [not/is] in assortment|order
      if ((data.action === 'remove' && existsInProductArray)) {
        // If action:remove && DOES exist in assortment|order
        state.pendingRowUpdates[data.action].push(p)
      } else if ((data.action === 'add' && !existsInProductArray)) {
        // else if action:add and DOES NOT exist in assortment|order
        if (data.method === 'replace') {
          // if method:replace swap out temp data with full product data
          state.pendingRowUpdates[data.action].map((item, index) => {
            let existingItem = `${item.style}-${item.color}`
            if (newItem === existingItem) state.pendingRowUpdates[data.action][index] = p
          })
        } else {
          // else !methods:replace push new data
          state.pendingRowUpdates[data.action].push({
            ...p,
            _pending: true
          })
        }
      } else {
        // do not update rows
      }
    })
  },

  [VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS_CLEAR]: (state, data) => {
    data.map(p => {
      let clearItem = `${p.style}-${p.color}`

      Object.keys(state.pendingRowUpdates).map(key => {
        state.pendingRowUpdates[key].map((item, index) => {
          let existingItem = `${item.style}-${item.color}`
          if (clearItem === existingItem) state.pendingRowUpdates[key].splice(index, 1)
        })
      })
    })
  },

  [VUEX_FILTERBAR_PRODUCTSEARCH_QUERY_CONSTRUCT]: (_, data) => {
    let mem = {}

    store.state.filterBar.modules.forEach(m => {
      m.filterGroups.forEach(fg => {
        fg.filters.forEach(f => {
          let matchMultiQuery = false
          if (f.multiQuery) matchMultiQuery = f.multiQuery.options.some(option => option.key === data.key)

          let paramValue = `'${JSON.stringify(data.value)}'`
          if ((f.key === data.key || matchMultiQuery) && !mem[`${data.key}-${paramValue}`]) {
            mem[`${data.key}-${paramValue}`] = true
            f.parameters.push(data)
          }
        })
      })
    })
  },

  [VUEX_FILTERBAR_PRODUCTSEARCH_SET_FETCH_PENDING]: (state, data) => {
    state.fetchPending = data
  },

  [VUEX_FILTERBAR_PRODUCTSEARCH_CLEAR_ADD_QUERY]: state => {
    state.productsQuery.add['$or'] = []
  },

  [VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCT_QUERY_CLEAR]: state => {
    Object.keys(state.productsQuery.search).forEach((key) => ['_options'].includes(key) || delete state.productsQuery.search[key])
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
