import dayjs from 'dayjs'
import _clone from 'lodash.clonedeep'

import store from '@/store/store'

import CurrencyFormatter from '@/classes/CurrencyFormatter'

import AvailDateData from '@/components/_core/FilterBar/_config/modules/productsAvailabilityModule'
import DueDateData from '@/components/_core/FilterBar/_config/modules/dueDateModule'

export default (key, value, queryObj) => {
  // Strip array index and brackets from key ([0], [1], etc)
  key = key.replace(/\[.*\]/g, '')
  // Define base data object for chip data
  const objectDataOptions = { precomputed: true, fromQuery: true, payloadType: 'object' }

  // Create global/reusable variables
  let dateData
  let offsetData
  let priceData

  switch (key) {
    /* ***************************************** */
    // Date Chips | beginDate / endDate
    /* ***************************************** */
    case 'dueDate.beginDate' :
    case 'dueDate.endDate' :
    case 'availabilityDate.beginDate' :
    case 'availabilityDate.endDate' :
    case 'locations.lastProdDate.beginDate' :
    case 'locations.lastProdDate.endDate' :
      // remove last .* (.beginDate, .endDate)
      key = key.replace(/\.[^.]*?$/, '')
      dateData = computeDateData(key, queryObj)

      return {
        ...objectDataOptions,
        key,
        chipLabel: dateData.chipLabel,
        value: {
          beginDate: dateData.beginDate,
          endDate: dateData.endDate
        }
      }

    /* ***************************************** */
    // Offset Chips | beginOffset / endOffset
    /* ***************************************** */
    case 'dueDate.beginOffset' :
    case 'dueDate.endOffset' :
    case 'availabilityDate.beginOffset' :
    case 'availabilityDate.endOffset' :
    case 'locations.lastProdDate.beginOffset' :
    case 'locations.lastProdDate.endOffset' :
      // remove last .* (.beginOffset, .endOffset)
      key = key.replace(/\.[^.]*?$/, '')
      const dataFile = key.includes('dueDate') ? DueDateData : AvailDateData()
      offsetData = computeOffsetData(key, queryObj, dataFile)

      return {
        ...objectDataOptions,
        key,
        chipLabel: offsetData.label,
        value: {
          beginOffset: offsetData.dates.begin.offset,
          endOffset: offsetData.dates.end.offset
        }
      }

    /* ***************************************** */
    // Price Chips | $gte / $lte
    /* ***************************************** */
    case 'cost.$gte' :
    case 'cost.$lte' :
    case 'suggestedRetail.$gte' :
    case 'suggestedRetail.$lte' :
    case 'locations.wholesalePrice.$gte' :
    case 'locations.suggestedRetail.$gte' :
      // remove last .* (.$gte, .$lte)
      key = key.replace(/\.[^.]*?$/, '')
      priceData = computePriceData(key, queryObj)

      return {
        ...objectDataOptions,
        key,
        chipLabel: priceData.chipLabel,
        value: {
          '$gte': priceData.data['$gte'],
          '$lte': priceData.data['$lte']
        }
      }

    /* ***************************************** */
    // Default
    /* ***************************************** */
    default :
      let chipLabel = (key === 'files.modifiedDate__gtd')
        ? dayjs(value).format('MM/DD/YYYY')
        : value

      return { key, chipLabel, value }
  }
}

/* ***************************************** */
// Compute Date Data Function
/* ***************************************** */
const computeDateData = (key, queryObj) => {
  const dateFormat = 'MM/DD/YYYY'

  let beginDate = queryObj[`${key}.beginDate`]
  let endDate = queryObj[`${key}.endDate`]
  let size = queryObj[`${key}.size`]

  let dateLabelBegin = dayjs(beginDate).format(dateFormat)
  let dateLabelEnd = dayjs(endDate).format(dateFormat)

  let datesAreMatching = dayjs(beginDate).format(dateFormat) === dayjs(endDate).format(dateFormat)
  let sizeLabel = size ? ` (Size: ${size})` : ''
  let chipLabel = datesAreMatching ? `${dateLabelBegin}` : `${dateLabelBegin} - ${dateLabelEnd}${sizeLabel}`

  return { beginDate, endDate, size, chipLabel }
}
/* ***************************************** */

/* ***************************************** */
// Compute Offset Data Function
/* ***************************************** */
const computeOffsetData = (key, queryObj, OffsetData) => {
  let matchedData
  let returnData = {}

  OffsetData.filterGroups.forEach(fg => {
    let filterMatches = fg.filters.filter(f => (f.key === key && f.dateOptions))
    if (!filterMatches.length) return

    // looks for matching offset dates
    filterMatches.forEach(fm => {
      matchedData = fm?.dateOptions.find(option => (option.dates.begin.offset === parseInt(queryObj[`${key}.beginOffset`]) && option.dates.end.offset === parseInt(queryObj[`${key}.endOffset`])))
    })
  })

  if (matchedData) returnData = _clone(matchedData)

  let size = queryObj['availabilityDate.size']
  if (size) returnData.label = `${returnData.label} (Size: ${size})`

  return returnData
}
/* ***************************************** */

/* ***************************************** */
// Compute Price Data Function
/* ***************************************** */
const computePriceData = (key, queryObj) => {
  const currencyFormatter = new CurrencyFormatter(store.state.properties.data.locations)

  const fromPrice = queryObj[`${key}.$gte`]
  const toPrice = queryObj[`${key}.$lte`]

  let priceData = { data: {}, label: {} }

  if (fromPrice) {
    priceData.data['$gte'] = fromPrice
    priceData.label['$gte'] = currencyFormatter.formatPriceByLocation(fromPrice, queryObj.locations?.code)
  }

  if (toPrice) {
    priceData.data['$lte'] = toPrice
    priceData.label['$lte'] = currencyFormatter.formatPriceByLocation(toPrice, queryObj.locations?.code)
  }

  let chipLabel = `${priceData.label['$gte'] ? priceData.label['$gte'] : ''}${priceData.data['$gte'] && priceData.data['$lte'] ? ' - ' : ''}${priceData.label['$lte'] ? priceData.label['$lte'] : ''}`

  return { ...priceData, chipLabel }
}
/* ***************************************** */
