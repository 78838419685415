import _uniq from 'lodash.uniq'

import { stringToSlug } from '@/helpers/stringModifiers'

import {
  VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH,
  VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS,
  VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_FAILURE,

  VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_STATE_CLEAR,

  VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_STATE_UPDATE
} from '@/store/constants/models/assortments/assortmentProducts'

import {
  VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH,
  VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_STREAMING
} from '@/store/constants/api'

import store from '@/store/store'

let tempProducts = []

const state = {
  totalCount: 0,
  products: [],
  productsWithGroups: []
}

// NOTE: This helper may be out of place and be better placed as part of
// centralized store helpers or options/properties helpers

let getDisplayGroupTitle = (displayGroup) => {
  let groupByValue = store.state.assortments.assortment.uiSettings?.groupSettings?.groupBy.value

  if (groupByValue === 'division') {
    const divisionList = store.state.properties.data.Product.properties.division.options
    return divisionList.find(prop => prop.value === displayGroup)?.name || 'Uncategorized'
  }

  return displayGroup
}

const getters = {
  productDisplayGroupList: state => {
    return _uniq(state.products.map(product => product.colors[0].displayGroup)).filter(displayGroup => displayGroup !== undefined).map(displayGroup => getDisplayGroupTitle(displayGroup))
  }
}

const actions = {
  [VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH]: ({ commit, dispatch }, payload) => {
    commit(VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH)

    if (!payload) return

    if (payload?._options.streaming || false) {
      dispatch(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_STREAMING, {
        query: payload,
        callback: data => {
          commit(VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_STATE_UPDATE, data)
        }
      }).catch(() => {
        // clears products state on failure/cancel
        commit(VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH)
      })
    } else {
      dispatch(VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH, payload).then(response => {
        commit(VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_STATE_UPDATE, response.data[0])
      })
    }
  },

  [VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_STATE_CLEAR]: ({ commit }) => {
    commit(VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_STATE_CLEAR)
  }
}

const mutations = {
  [VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH]: state => {
    state.products = []
    state.totalCount = 0
  },

  [VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS]: (state, data) => {
    //
  },

  [VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_FAILURE]: (state, data) => {
    //
  },

  [VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_STATE_CLEAR]: state => {
    state.products = []
    state.totalCount = 0
  },

  [VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_STATE_UPDATE]: (state, data) => {
    /* IF - NON-STREAMING */
    /* ELSE - STREAMING */

    if (data.data) {
      state.products = data.data
      state.totalCount = data.totalCount

      // --------------------------------------
      // *state.productsWithGroups*
      // Build Products group data
      // (currently only needed for non-streaming [Browse By Category])
      // --------------------------------------
      let productsWithGroups = []
      let currentGroup = ''

      data.data.forEach(item => {
        const displayGroup = item.colors[0].displayGroup
        const customContent = item.colors[0].customContent

        if (displayGroup && currentGroup !== displayGroup) {
          currentGroup = displayGroup
          productsWithGroups.push({
            groupCard: true,
            title: getDisplayGroupTitle(displayGroup),
            groupSlug: stringToSlug(displayGroup),
            customContent
          })
        }

        productsWithGroups.push(item)
      })

      state.productsWithGroups = productsWithGroups
      // --------------------------------------
      // --------------------------------------
    } else {
      Object.keys(data).forEach(key => {
        switch (key) {
          case 'totalCount' :
            state.totalCount = data[key]
            tempProducts = []
            break

          case 'items' :
            tempProducts.push(...data[key])

            if (tempProducts.length === 100) {
              state.products = [...tempProducts]
            }

            if (tempProducts.length > 100 && tempProducts.length % 500 === 0) {
              state.products = [...tempProducts]
            }

            if (tempProducts.length === state.totalCount) {
              state.products = [...tempProducts]
              tempProducts = []
            }

            break
        }
      })
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
