// import axios from 'axios'
import api from '@/api'
import Debug from 'logdown'

import {
  VUEX_API_USER_FLAGS_UPDATE_SEEN_STATUS
} from '@/store/constants/api'

const d = new Debug('its:store:modules:api:userFlags')

const state = {}

const getters = {}

const actions = {
  [VUEX_API_USER_FLAGS_UPDATE_SEEN_STATUS]: ({ state, rootState, dispatch, commit }, payload) => {
    const data = {
      'flag': 'Seen',
      'modelType': 'Asset',
      'modelId': payload
    }

    return api.post(`/userFlags`, data, {
      params: {}
    }).then(response => {
      return response
    }).catch(err => {
      d.error(err)
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
