import axios from 'axios'
import api from '@/api'

import _sortedUniq from 'lodash.sorteduniq'

import ApiValidator from '@/classes/ApiValidator'

import {
  VUEX_API_SAMPLE_INVENTORY_FETCH,
  VUEX_API_SAMPLE_INVENTORY_CREATE,
  VUEX_API_SAMPLE_INVENTORY_PUT,
  VUEX_API_SAMPLE_INVENTORY_PATCH,
  VUEX_API_SAMPLE_INVENTORY_BATCH,
  VUEX_API_SAMPLE_INVENTORY_DELETE,
  VUEX_API_SAMPLE_INVENTORY_VALIDATE,
  VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE,
  VUEX_API_SAMPLE_INVENTORY_EXPORT,

  VUEX_API_SAMPLE_INVENTORY_TRACKING_FETCH,
  VUEX_API_SAMPLE_INVENTORY_TRACKING_CREATE,

  VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE,
  VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN,
  VUEX_API_SAMPLE_INVENTORY_PRODUCTS_DELETE_SUCCESS,
  VUEX_API_SAMPLE_INVENTORY_PRODUCTS_UPDATE_SUCCESS,
  VUEX_API_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION,
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'
import router from '@/router'

const state = {
  sampleInventoryRequest: null,
  sampleInventoryCancelRequest: null,

  sampleInventoryProductUpdate: false,
  sampleInventoryProductDelete: false
}

const getters = {}

const actions = {
  // -----------------------------------------
  // Fetch Samples
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_FETCH]: async ({ state, dispatch, commit }, payload = {}) => {
    await commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__PENDING)

    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    // set subtype
    const subtype = payload?.params?.subtype
    delete payload?.params?.subtype

    // stringify options
    payload._options = JSON.stringify(payload._options)

    return api.get(`/sampleInventory/${subtype}`, payload, {
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__SUCCESS)
      return response.data
    }).catch(err => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load 'FETCH:/api/sampleInventory' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // -----------------------------------------
  // Create Samples
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_CREATE]: async ({ state, dispatch, commit }, payload = {}) => {
    await commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__PENDING)

    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    return api.post(`/sampleInventory`, payload, {
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__SUCCESS)
      return response.data
    }).catch(err => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load 'CREATE:/api/sampleInventory' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // -----------------------------------------
  // PUT Samples
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_PUT]: async ({ state, dispatch, commit }, payload = {}) => {
    await commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__PENDING)

    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    const sampleId = payload.id || payload._id

    return api.put(`/sampleInventory/${sampleId}`, payload, {
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__SUCCESS)
      return response.data
    }).catch(err => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load 'PUT:/api/sampleInventory/:sampleId' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // -----------------------------------------
  // PATCH Samples
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_PATCH]: async ({ state, dispatch, commit }, payload = {}) => {
    await commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__PENDING)

    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    const sampleId = payload.id || payload._id

    return api.patch(`/sampleInventory/${sampleId}`, payload, {
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__SUCCESS)
      return response.data
    }).catch(err => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load 'PATCH:/api/sampleInventory/:sampleId' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // -----------------------------------------
  // BATCH Samples
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_BATCH]: async ({ state, dispatch, commit }, payload = {}) => {
    await commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__PENDING)

    // TODO JASON - restore this
    const apiValidator = new ApiValidator('sampleInventoryBatchSchema')
    const validatedPayload = apiValidator.validate(payload.data)
    // const validatedPayload = payload.data
    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    const libraryType = router.currentRoute.value.meta.manageType
    return api.patch(`/sampleInventory/${libraryType}/batch/many`, validatedPayload, {
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      if (response?.data?.details === 'Nothing to do') {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Error. Your request was empty.  Nothing has been submitted. Please try again.`
          }
        })
      }
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__SUCCESS)
      return response.data
    }).catch(err => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Error: ${err?.response?.data?.message}`
          }
        })
      }
    })
  },

  // -----------------------------------------
  // DELETE Samples
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_DELETE]: async ({ state, dispatch, commit }, payload = {}) => {
    const apiValidator = new ApiValidator('sampleInventoryDeleteSchema')
    const validatedPayload = apiValidator.validate(payload.data)

    commit(VUEX_API_SAMPLE_INVENTORY_PRODUCTS_DELETE_SUCCESS, false)
    const libraryType = router.currentRoute.value.meta.manageType

    return api.patch(`/sampleInventory/` + libraryType + `/batch/many`, validatedPayload).then(response => {
      commit(VUEX_API_SAMPLE_INVENTORY_PRODUCTS_UPDATE_SUCCESS, true)
      return response
    }).catch((err) => {
      commit(VUEX_API_SAMPLE_INVENTORY_PRODUCTS_UPDATE_SUCCESS, false)
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
    })
  },

  // -----------------------------------------
  // Validate Samples
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_VALIDATE]: async ({ state, dispatch, commit }, payload = {}) => {
    await commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__PENDING)

    const apiValidator = new ApiValidator('sampleInventoryValidateSchema')
    const validatedPayload = apiValidator.validate(payload.params.products)

    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    /* const validatePayload = {
      rows: payload.params.products
    } */
    return api.post(`/sampleInventory/validate/${payload.params.library}`, { rows: validatedPayload }, {
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__SUCCESS)
      return response.data
    }).catch(err => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load 'VALIDATE:/api/sampleInventory/validate/:library' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // -----------------------------------------
  // Autocomplete Samples Data
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE]: async ({ state, dispatch, commit }, payload = {}) => {
    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    const { property, value } = payload
    const libraryType = router.currentRoute.value.meta.manageType

    return api.get(`/sampleInventory/autocomplete/${libraryType}/${property}/${value}`, {
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__SUCCESS)
      return _sortedUniq(response.data.sort())
    }).catch(err => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load 'AUTOCOMPLETE:/sampleInventory/autocomplete/:proprty/:value/:library' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // -----------------------------------------
  // Export .xslx
  // -----------------------------------------
  [VUEX_API_SAMPLE_INVENTORY_EXPORT]: async ({ state, dispatch, commit }, payload = {}) => {
    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    return api.post(`/sampleInventory/export/xlsx/${payload.library}`, payload, {
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__SUCCESS)
      return response.data
    }).catch(err => {
      commit(VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE, ITS__API__REQUEST_TYPE__FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load 'EXPORT:/sampleInventory/export/xlsx/:library' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_SAMPLE_INVENTORY_TRACKING_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload.params) payload.params = {}
    const manageType = payload.manageType

    // await commit(VUEX_API_SAMPLE_INVENTORY_TRACKING_FETCH)

    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    return api.get(`/sampleInventoryTracking/${manageType}/${payload.id}`, {
      params: payload.params,
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      return response
    }).catch(err => {
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/sampleInventoryTracking/${manageType}/:id' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_SAMPLE_INVENTORY_TRACKING_CREATE]: async ({ commit, dispatch }, payload) => {
    if (!payload.params) payload.params = {}

    const manageType = payload.manageType

    // await commit(VUEX_API_SAMPLE_INVENTORY_TRACKING_CREATE)

    if (state.sampleInventoryCancelRequest) state.sampleInventoryCancelRequest.cancel()
    await commit(VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN, 'sampleInventoryCancelRequest')

    return api.post(`/sampleInventoryTracking/${manageType}`, payload, {
      params: payload.params,
      cancelToken: state.sampleInventoryCancelRequest.token
    }).then(response => {
      return response
    }).catch(err => {
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/sampleInventoryTracking/${manageType}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },


  [VUEX_API_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION]: async ({ state, dispatch, commit }, payload = {}) => {
    let finalPayload = {
      "samples": payload
    }
    return api.patch(`/sampleInventory/Sales/transfer`, finalPayload).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
    })
  },

}

const mutations = {
  [VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE]: (state, data) => {
    state.sampleInventoryRequest = data
  },

  [VUEX_API_SAMPLE_INVENTORY_PRODUCTS_UPDATE_SUCCESS]: (state, data) => {
    state.sampleInventoryProductUpdate = data
  },

  [VUEX_API_SAMPLE_INVENTORY_PRODUCTS_DELETE_SUCCESS]: (state, data) => {
    state.sampleInventoryProductDelete = data
  },

  // Set Cancelation token
  [VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
