import { shallowRef, defineAsyncComponent } from 'vue'

export const loadView = (file) => () => import(
  /* webpackChunkName: "view-[request]" */
  /* webpackMode: "lazy" */
  `@/views/${file}`)

export const loadComponent = (file) => () => import(
  /* webpackChunkName: "view-[request]" */
  /* webpackMode: "lazy" */
  `@/components/${file}`)

export const loadShallowAsyncComponent = (file) => shallowRef(defineAsyncComponent(() => import(
  /* webpackChunkName: "view-[request]" */
  /* webpackMode: "lazy" */
  `@/components/${file}`)))
