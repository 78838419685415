import store from '@/store/store'

import {
  VUEX_OVERLAY_SHOW,
  VUEX_OVERLAY_HIDE,

  VUEX_OVERLAY_HEADER_SHOW,
  VUEX_OVERLAY_HEADER_HIDE,
  VUEX_OVERLAY_HEADER_SET_TITLE,

  VUEX_OVERLAY_SUBHEADER_SHOW,
  VUEX_OVERLAY_SUBHEADER_HIDE,
  VUEX_OVERLAY_SUBHEADER_SET_SIZE,

  VUEX_OVERLAY_UNLOAD_SUBHEADER_CONTENT
} from '@/store/constants/ui/overlay'

const state = {
  openState: false,
  content: null,
  closeActions: null,
  data: null,

  styles: {
    padding: null
  },

  header: {
    visible: true,
    openState: false,
    title: null,
    subtitle: null,
    // content: null,
    size: 45
  },

  subheader: {
    openState: false,
    content: null,
    size: null,
    props: {}
  }
}

const getters = {}

const actions = {
  [VUEX_OVERLAY_SHOW]: ({ commit }, payload) => {
    commit(VUEX_OVERLAY_SHOW, payload)
  },

  [VUEX_OVERLAY_HIDE]: async ({ state, commit }) => {
    await commit(VUEX_OVERLAY_HIDE)
    if (state.closeActions) {
      setTimeout(() => {
        state.closeActions.forEach(action => { store.dispatch(action) })
      }, 250)
    }
  },

  [VUEX_OVERLAY_HEADER_SHOW]: ({ commit }, payload) => {
    commit(VUEX_OVERLAY_HEADER_SHOW, payload)
  },

  [VUEX_OVERLAY_HEADER_HIDE]: ({ commit }) => {
    commit(VUEX_OVERLAY_HEADER_HIDE)
  },

  [VUEX_OVERLAY_HEADER_SET_TITLE]: ({ commit }, payload) => {
    commit(VUEX_OVERLAY_HEADER_SET_TITLE, payload)
  },

  [VUEX_OVERLAY_SUBHEADER_SHOW]: ({ commit }, payload) => {
    commit(VUEX_OVERLAY_SUBHEADER_SHOW, payload)
  },

  [VUEX_OVERLAY_SUBHEADER_HIDE]: ({ commit }) => {
    commit(VUEX_OVERLAY_SUBHEADER_HIDE)
  },

  [VUEX_OVERLAY_SUBHEADER_SET_SIZE]: ({ commit }, payload) => {
    commit(VUEX_OVERLAY_SUBHEADER_SET_SIZE, payload)
  },

  [VUEX_OVERLAY_UNLOAD_SUBHEADER_CONTENT]: ({ commit }) => {
    commit(VUEX_OVERLAY_UNLOAD_SUBHEADER_CONTENT)
  }
}

const mutations = {
  /* ---------- OVERLAY */
  /* */
  // Overlay | SHOW
  [VUEX_OVERLAY_SHOW]: (state, data) => {
    state.header.openState = data.header.openState || data.subheader.openState
    // state.header.content = data.header.content ? data.header.content : null
    state.header.title = data.header.title ? data.header.title : null
    state.header.subtitle = data.header.subtitle ? data.header.subtitle : null
    state.header.size = data.header.size ? data.header.size : state.header.openState || state.subheader.openState ? ITS__UI__HEADER__SIZE__SMALL : null

    state.subheader.openState = (data.subheader && data.subheader.openState) ? data.subheader.openState : false
    state.subheader.content = (data.subheader && data.subheader.content) ? data.subheader.content : null
    state.subheader.size = (data.subheader && data.subheader.size) ? data.subheader.size : ITS__UI__SUBHEADER__SIZE__3
    state.subheader.props = (data.subheader && data.subheader.props) ? data.subheader.props : {}

    state.content = data.content ? data.content : null
    state.openState = true
    state.closeActions = data.closeActions
    state.styles = data.styles ? Object.assign(state.styles, data.styles) : { padding: null }

    state.data = data.data
  },
  // Overlay | HIDE
  [VUEX_OVERLAY_HIDE]: state => {
    state.openState = false
  },

  /* ---------- OVERLAY HEADER */
  /* */
  // Overlay -> Header | SHOW
  [VUEX_OVERLAY_HEADER_SHOW]: state => {
    // state.header.content = payload.content ? payload.content : null
    state.header.openState = true
  },
  // Overlay -> Header | HIDE
  [VUEX_OVERLAY_HEADER_HIDE]: state => {
    // state.header.content = null
    state.header.openState = false
  },

  [VUEX_OVERLAY_HEADER_SET_TITLE]: (state, data) => {
    // state.header.content = null
    state.header.title = data
  },

  /* ---------- OVERLAY SUBHEADER */
  /* */
  // Overlay -> Subheader | SHOW
  [VUEX_OVERLAY_SUBHEADER_SHOW]: (state, data) => {
    state.subheader.content = data.content ? data.content : null
    state.subheader.size = data.size ? data.size : ITS__UI__SUBHEADER__SIZE__1
    state.subheader.openState = true
  },
  // Overlay -> Subheader | HIDE
  [VUEX_OVERLAY_SUBHEADER_HIDE]: state => {
    // state.subheader.content = null
    state.subheader.openState = false
  },
  // Overlay -> Subheader | SET SIZE
  [VUEX_OVERLAY_SUBHEADER_SET_SIZE]: (state, data) => {
    state.subheader.size = data
  },

  [VUEX_OVERLAY_UNLOAD_SUBHEADER_CONTENT]: state => {
    state.subheader.content = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
