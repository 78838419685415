// import axios from 'axios'
import api from '@/api'

import {
  VUEX_API_ANNOUNCEMENT_FETCH,
  VUEX_API_ANNOUNCEMENT_FETCH_SUCCESS,
  VUEX_API_ANNOUNCEMENT_FETCH_FAILURE,

  VUEX_API_ANNOUNCEMENT_UPDATE,
  VUEX_API_ANNOUNCEMENT_UPDATE_SUCCESS,
  VUEX_API_ANNOUNCEMENT_UPDATE_FAILURE,

  VUEX_API_ANNOUNCEMENT_DELETE,
  VUEX_API_ANNOUNCEMENT_DELETE_SUCCESS,
  VUEX_API_ANNOUNCEMENT_DELETE_FAILURE,

  VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART,
  VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_SUCCESS,
  VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_FAILURE,

  VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD,
  VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_SUCCESS,
  VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_FAILURE
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  fetchRequest: null,
  sendRequest: null,
  downloadRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_ANNOUNCEMENT_FETCH]: ({ commit, dispatch }) => {
    commit(VUEX_API_ANNOUNCEMENT_FETCH)
    return api.get('/charts?published=true').then(response => {
      commit(VUEX_API_ANNOUNCEMENT_FETCH_SUCCESS)
      return response
    }).catch(() => {
      commit(VUEX_API_ANNOUNCEMENT_FETCH_FAILURE)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: 'Error: Cannot fetch Announcements'
        }
      })
    })
  },

  [VUEX_API_ANNOUNCEMENT_UPDATE]: ({ commit, dispatch }, payload) => {
    commit(VUEX_API_ANNOUNCEMENT_UPDATE)
    return api.patch(`/charts/${payload._id}`, payload).then(response => {
      commit(VUEX_API_ANNOUNCEMENT_UPDATE_SUCCESS)
      return response
    }).catch(() => {
      commit(VUEX_API_ANNOUNCEMENT_UPDATE_FAILURE)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: 'Error: Cannot fetch Announcements'
        }
      })
    })
  },

  [VUEX_API_ANNOUNCEMENT_DELETE]: ({ commit, dispatch }, payload) => {
    return api.delete(`/charts/${payload._id}`).then(response => {
      commit(VUEX_API_ANNOUNCEMENT_DELETE_SUCCESS)
      return response
    }).catch(() => {
      commit(VUEX_API_ANNOUNCEMENT_DELETE_FAILURE)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: 'Error: Cannot fetch Announcements'
        }
      })
    })
  },

  [VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART]: ({ commit, dispatch }, payload) => {
    commit(VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART)
    return api.post('/assets/AdvertisingAsset/announcement', payload).then(response => {
      commit(VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_SUCCESS)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: 'Thank you. Your message has been sent.'
        }
      })
    }).catch(() => {
      commit(VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_FAILURE)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: 'Error: Cannot send message'
        }
      })
    })
  },

  [VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD]: ({ commit, dispatch }, payload) => {
    commit(VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD)
    return api.post('/assets/AdvertisingAsset/announcement/download/pdf', payload,
      {
        responseType: 'blob'
      }
    ).then(response => {
      commit(VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_SUCCESS)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: 'Thank you. Your message has been sent.'
        }
      })

      return response
    }).catch(err => {
      commit(VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_FAILURE)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: 'Error: Cannot send message'
        }
      })

      return err
    })
  }
}

const mutations = {
  [VUEX_API_ANNOUNCEMENT_FETCH]: state => {
    state.fetchRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_ANNOUNCEMENT_FETCH_SUCCESS]: state => {
    state.fetchRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_ANNOUNCEMENT_FETCH_FAILURE]: state => {
    state.fetchRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUEX_API_ANNOUNCEMENT_DELETE]: state => {
    state.fetchRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_ANNOUNCEMENT_DELETE_SUCCESS]: state => {
    state.fetchRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_ANNOUNCEMENT_DELETE_FAILURE]: state => {
    state.fetchRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART]: state => {
    state.sendRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_SUCCESS]: state => {
    state.sendRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_FAILURE]: state => {
    state.sendRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD]: state => {
    state.downloadRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_SUCCESS]: state => {
    state.downloadRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_FAILURE]: state => {
    state.downloadRequest = ITS__API__REQUEST_TYPE__FAILED
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
