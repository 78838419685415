// LIBRARIES
export const VUEX_LIBRARIES_FETCH = 'VUEX_LIBRARIES_FETCH'
export const VUEX_LIBRARIES_FETCH_SUCCESS = 'VUEX_LIBRARIES_FETCH_SUCCESS'
export const VUEX_LIBRARIES_FETCH_FAILURE = 'VUEX_LIBRARIES_FETCH_FAILURE'
export const VUEX_LIBRARIES_REFETCH = 'VUEX_LIBRARIES_REFETCH'
export const VUEX_LIBRARIES_PAGED_DATA_FETCH = 'VUEX_LIBRARIES_PAGED_DATA_FETCH'
export const VUEX_LIBRARIES_PRIMARY_FILE_NAME_SEARCH = 'VUEX_LIBRARIES_PRIMARY_FILE_NAME_SEARCH'
export const VUEX_LIBRARIES_SET_QUERY_PARAMS = 'VUEX_LIBRARIES_SET_QUERY_PARAMS'

export const VUEX_LIBRARIES_GRID_ROWDATA_LOADING_START = 'VUEX_LIBRARIES_GRID_ROWDATA_LOADING_START'
export const VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH = 'VUEX_LIBRARIES_GRID_ROWDATA_LOADING_FINISH'

export const VUEX_LIBRARIES_GRID_REQUEST_CLEAR = 'VUEX_LIBRARIES_GRID_REQUEST_CLEAR'

export const VUEX_LIBRARIES_GRID_ROWS_UPDATE = 'VUEX_LIBRARIES_GRID_ROWS_UPDATE'
export const VUEX_LIBRARIES_GRID_ROWS_STATUS_CHANGE = 'VUEX_LIBRARIES_GRID_ROWS_STATUS_CHANGE'
export const VUEX_LIBRARIES_GRID_ROWS_DELETE = 'VUEX_LIBRARIES_GRID_ROWS_DELETE'
export const VUEX_LIBRARIES_PRODUCTS_UPDATE = 'VUEX_LIBRARIES_PRODUCTS_UPDATE'
export const VUEX_LIBRARIES_FILE_DELETE = 'VUEX_LIBRARIES_FILE_DELETE'
export const VUEX_LIBRARIES_FILE_ADD = 'VUEX_LIBRARIES_FILE_ADD'
export const VUEX_LIBRARIES_FILE_MERGE = 'VUEX_LIBRARIES_FILE_MERGE'
export const VUEX_LIBRARIES_FILE_CHECK_FILENAME = 'VUEX_LIBRARIES_FILE_CHECK_FILENAME'
export const VUEX_LIBRARIES_GRID_CONFIRMATION_ROWS_STATUS_CHANGE = 'VUEX_LIBRARIES_GRID_CONFIRMATION_ROWS_STATUS_CHANGE'

export const VUEX_LIBRARIES_REKEY = 'VUEX_LIBRARIES_REKEY'
