import app from '@/main'

import _clone from 'lodash.clonedeep'

import {
  VUEX_GRID_ROWS_ADD,
  VUEX_GRID_ROWS_REMOVE,
  VUEX_GRID_REQUEST_SNAPSHOT,
  VUEX_GRID_IMPORT_SPREADSHEET_CLOSE,
  VUEX_GRID_LIST_SET_FILTER,

  VUEX_GRID_REKEY,
  VUEX_GRID_ROWDATA_LOADING_START,
  VUEX_GRID_ROWDATA_LOADING_FINISH,

  VUEX_GRID_CONFIRMATION_ROW_DELETE,
  VUEX_GRID_CONFIRMATION_ROW_DELETE_CLEAR,

  VUEX_GRID_UPDATE_START,
  VUEX_GRID_UPDATE_SUCCESS,
  VUEX_GRID_UPDATE_FAILURE,

  VUEX_GRID_SETTINGS_SET
} from '@/store/constants/ui/grid'

import {
  VUEX_ASSORTMENT_ALERTS_UPDATE
} from '@/store/constants/models/assortments'

const state = {
  // currently used in the list of Key Initiatives, but could be extended
  // it's for when you click the back button it remembers your last filter
  lookupFilterSavedState: '',

  // core shared features
  updatingInformation: {
    isUpdating: false,
    message: ''
  }, // used to show if any of these API calls are still updating
  agGridRowDataLoading: false,
  rekey: '', // if this value changes, the loaded aggrid component will do a hard-rekey

  // common togglers
  confirmationTogglerRowDelete: '', // used to continue row delete code after the confirmation dialogue is accepted

  gridSettings: {
    sort: {},
    filter: {}
  }
}

const getters = {}

const actions = {
  [VUEX_GRID_REKEY]: async ({ commit }) => {
    await commit(VUEX_GRID_REKEY)
  },

  [VUEX_GRID_ROWDATA_LOADING_START]: ({ commit }) => {
    commit(VUEX_GRID_ROWDATA_LOADING_START)
  },

  [VUEX_GRID_ROWDATA_LOADING_FINISH]: ({ commit }) => {
    commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
  },

  [VUEX_GRID_LIST_SET_FILTER]: ({ commit }, payload) => {
    commit(VUEX_GRID_LIST_SET_FILTER, payload)
  },

  // payload: { type:'products' & rows:[...]}
  [VUEX_GRID_ROWS_ADD]: ({ commit, dispatch }, payload) => {
    if (!payload?.rows?.length) return

    let data = _clone(payload.rows)
    if (payload.type === 'products') {
      data = _clone(payload.rows).map(item => {
        const lineStatus = (item.locations && item.locations.lineStatus) ? item.locations.lineStatus : null
        item['previousStatus'] = lineStatus
        return item
      })
    }

    app.config.globalProperties.emitter.emit('aggrid-event', {
      type: (payload.type === 'orders') ? 'rowsAddOrders' : 'rowsAdd',
      payload: data
    })
  },

  // payload: { type:'products' & rows:[...]}
  [VUEX_GRID_ROWS_REMOVE]: ({ commit, dispatch }, payload) => {
    if (payload.rows.length === 0) return

    app.config.globalProperties.emitter.emit('aggrid-event', {
      type: 'rowsDelete',
      payload: payload.rows
    })
  },

  [VUEX_GRID_REQUEST_SNAPSHOT]: ({ commit }) => {
    app.config.globalProperties.emitter.emit('aggrid-event', {
      type: 'snapshot'
    })
  },

  [VUEX_GRID_IMPORT_SPREADSHEET_CLOSE]: ({ commit }) => {
    app.config.globalProperties.emitter.emit('aggrid-event', {
      type: 'excelImporterClose'
    })
  },

  // action dialogue confirmations
  [VUEX_GRID_CONFIRMATION_ROW_DELETE]: ({ commit }, payload) => {
    commit(VUEX_GRID_CONFIRMATION_ROW_DELETE, payload)

    // Update FE state to clear any alerts for products that are no longer in the assortment
    commit(VUEX_ASSORTMENT_ALERTS_UPDATE, payload)
  },
  [VUEX_GRID_CONFIRMATION_ROW_DELETE_CLEAR]: ({ commit }) => {
    commit(VUEX_GRID_CONFIRMATION_ROW_DELETE_CLEAR)
  },

  // IS UPDATING STATES
  [VUEX_GRID_UPDATE_START]: ({ commit }) => {
    commit(VUEX_GRID_UPDATE_START)
  },
  [VUEX_GRID_UPDATE_SUCCESS]: ({ commit }) => {
    commit(VUEX_GRID_UPDATE_SUCCESS)
  },
  [VUEX_GRID_UPDATE_FAILURE]: ({ commit }) => {
    commit(VUEX_GRID_UPDATE_FAILURE)
  },

  [VUEX_GRID_SETTINGS_SET]: ({ commit }, payload) => {
    commit(VUEX_GRID_SETTINGS_SET, payload)
  }
}

const mutations = {
  [VUEX_GRID_REKEY]: state => {
    state.rekey = Math.random()
  },

  [VUEX_GRID_ROWDATA_LOADING_START]: state => {
    state.agGridRowData = []
    state.agGridRowDataLoading = true
  },

  [VUEX_GRID_ROWDATA_LOADING_FINISH]: state => {
    state.agGridRowDataLoading = false
  },

  [VUEX_GRID_LIST_SET_FILTER]: (state, data) => {
    state.lookupFilterSavedState = data.data
  },

  [VUEX_GRID_CONFIRMATION_ROW_DELETE]: (state, data) => {
    if (data.rowData) {
      state.confirmationTogglerRowDelete = data.rowData
    }
  },
  [VUEX_GRID_CONFIRMATION_ROW_DELETE_CLEAR]: state => {
    state.confirmationTogglerRowDelete = null
  },

  // IS UPDATING STATES
  [VUEX_GRID_UPDATE_START]: state => {
    state.updatingInformation = {
      isUpdating: true,
      message: 'Saving...'
    }
  },
  [VUEX_GRID_UPDATE_SUCCESS]: state => {
    state.updatingInformation = {
      isUpdating: false,
      message: 'All changes saved.'
    }
  },
  [VUEX_GRID_UPDATE_FAILURE]: state => {
    state.updatingInformation = {
      isUpdating: false,
      message: 'An error has occurred while saving.'
    }
  },

  [VUEX_GRID_SETTINGS_SET]: (state, { prop, manageType, data }) => {
    state.gridSettings[prop][manageType] = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
