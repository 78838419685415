import _cloneDeep from 'lodash.clonedeep'

import assetsModifiedDateModule from '@/components/_core/FilterBar/_config/modules/assetsModifiedDataModule.js'

let cloneModifiedDateModule = _cloneDeep(assetsModifiedDateModule)
Object.assign(cloneModifiedDateModule.filterGroups[0].filters[0], { key: 'files.modifiedDate__gtd' })

export default {
  modules: [
    {
      label: 'Keyword',
      filterGroups: [
        {
          hint: 'Searches within filename',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter Keyword'
              },
              key: 'name'
            }
          ]
        }
      ]
    },
    {
      label: 'Document Type',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'docType',
              data: 'properties.data.ProductDocument.properties.docType.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Division',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Division',
                filterable: true,
                multiselect: true
              },
              key: 'divisionId',
              data: 'properties.data.ProductDocument.properties.divisionId.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Gender',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'gender',
              data: 'properties.data.ProductDocument.properties.gender.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Region',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'region',
              data: 'properties.data.ProductDocument.properties.locations.options'
            }
          ]
        }
      ]
    },
    /* {
      label: 'Language',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'lang',
              data: 'properties.data.ProductDocument.properties.lang.optionsInUseWithDefault'
            }
          ]
        }
      ]
    }, */
    cloneModifiedDateModule
  ]
}
