import axios from 'axios'
import api from '@/api'

import {
  VUEX_API_REPORTING_FETCH,
  VUEX_API_REPORTING_SUCCESS,
  VUEX_API_REPORTING_FAILED,

  VUEX_API_REPORTING_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  reportingRequest: null,
  reportingCancelRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_REPORTING_FETCH]: async ({ dispatch, commit }, payload) => {
    if (!payload || !payload.reportRoute) return
    if (!payload.params) payload.params = {}

    await commit(VUEX_API_REPORTING_FETCH)

    if (state.reportingCancelRequest) state.reportingCancelRequest.cancel()
    await commit(VUEX_API_REPORTING_SET_CANCEL_TOKEN, 'reportingCancelRequest')

    return api.post(`assortments/admin/report/${payload.reportRoute}`, payload.fileData, {
      params: payload.params,
      cancelToken: state.reportingCancelRequest.token,
      responseType: 'blob'
    }).then(response => {
      commit(VUEX_API_REPORTING_SUCCESS)
      return {
        ...payload,
        status: ITS__FILE__PROCESSING__STATUS__SUCCESS,
        data: response
      }
    }).catch((err) => {
      commit(VUEX_API_REPORTING_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: err.message ? 'success' : 'error',
          message: err.message || (err.response && err.response.data.message)
        }
      })

      if (err.message === 'Your document has been canceled') {
        return {
          ...err,
          status: ITS__FILE__PROCESSING__STATUS__CANCELLED
        }
      } else {
        return {
          ...err,
          status: ITS__FILE__PROCESSING__STATUS__FAILED
        }
      }
    })
  }
}

const mutations = {
  // Documents
  [VUEX_API_REPORTING_FETCH]: state => {
    state.reportingRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_REPORTING_SUCCESS]: state => {
    state.reportingRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_REPORTING_FAILED]: state => {
    state.reportingRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_REPORTING_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
