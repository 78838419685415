import { createMachine } from '@xstate/fsm'

import detect from '@/utils/detect'
import Debug from 'logdown'
import api from '@/api'
import vars from '@/vars'
import visibility from '@/utils/visibility'

import {
  VUEX_ROUTING_ROUTE
} from '@/store/constants/routing'

import {
  VUEX_DIALOG_SHOW,
  VUEX_DIALOG_HIDE,
  VUEX_DIALOG_LOGGED_OUT_MESSAGE
} from '@/store/constants/ui/dialog'

import {
  VUEX_USER_SET,
  VUEX_USER_SET_AWS_CREDENTIALS,
  VUEX_USER_SET_AWS_TOKEN
} from '@/store/constants/user'

const d = new Debug('its:users:timeout')

// Reference: http://stackoverflow.com/questions/667555/detecting-idle-time-in-javascript-elegantly/10126042#10126042

// constants
// ---------

const CONTROLLER_INTERVAL_MS = 1000 // 1 sec
const PING_MS = 2 * 60 * 1000
const DEFAULT_WARNING_MS = 5 * 60 * 1000 // when to present timeout warning
const DEFAULT_TIMEOUT_MS = 60 * 60 * 1000

// state definition
// ----------------

// Login state determined by:
// * Response from user ping status
// * User login/logout direct actions
// * Inactivity timeout status (as determined by localStorage value shared between tabs)
// * Initial url on page load
// * State is shared between tabs using localStorage
// * These values are also shared in localStorage between tabs: lastUserActivity
const stateDescription = {
  id: 'timeoutState',
  initial: 'loggedOut',
  states: {
    loggedOut: {
      on: {
        login: 'loggedIn',
        logoutManual: 'loggedOut',
        logoutAutomatic: 'loggedOut',
        userActivity: 'loggedOut',
        pingGood: 'loggedIn',
        pingBad: 'loggedOut',
        inactivityTrigger: 'loggedOut',
        inactivityTimeout: 'loggedOut',
        '*': 'error'
      },
      fn: async function (previousState, action) {
        d.info('state handler:', action, 'from', previousState, '-> loggedOut')
      }
    },
    loggedIn: {
      on: {
        login: 'loggedIn',
        logoutManual: 'loggedOut',
        logoutAutomatic: 'loggedOut',
        userActivity: 'loggedIn',
        pingGood: 'loggedIn',
        pingBad: 'loggedOut',
        inactivityTrigger: 'inactivityWarning',
        inactivityTimeout: 'loggedOut',
        '*': 'error'
      },
      fn: async function (previousState, action) {
        d.info('state handler:', action, 'from', previousState, '-> loggedIn')
        hideDialog()
      }
    },
    inactivityWarning: {
      on: {
        login: 'loggedIn',
        logoutManual: 'loggedOut',
        logoutAutomatic: 'loggedOut',
        userActivity: 'loggedIn',
        pingGood: 'inactivityWarning',
        pingBad: 'loggedOut',
        inactivityTrigger: 'inactivityWarning',
        inactivityTimeout: 'loggedOut',
        '*': 'error'
      },
      fn: async function (previousState, action) {
        d.info('state handler:', action, 'from', previousState, '-> inactivityWarning')
      }
    },
    error: {
      on: {
        '*': 'error'
      },
      fn: async function (previousState, action) {
        d.error('ERROR state handler:', action, 'from', previousState, 'Reload the page to fix')
      }
    }
  }
}
const stateMachine = createMachine(stateDescription)

const actionFn = {
  login: async function (previousState, action, newState) {
    d.info('action handler:', action, 'from', previousState, '->', newState)
    const now = Date.now()
    saveLocalStorageState({
      lastPingTime: now,
      lastUserActivityTime: now,
      warningStartTime: 0
    })
  },
  logoutManual: async function (previousState, action, newState) {
    d.info('action handler:', action, 'from', previousState, '->', newState)
    const now = Date.now()
    saveLocalStorageState({
      lastUserActivityTime: now,
      warningStartTime: 0,
      lastPingTime: now
    })
    clearUserData()
    // do not show logged out dialog here
    redirectToDashboardCheck()
  },
  logoutAutomatic: async function (previousState, action, newState) {
    d.info('action handler:', action, 'from', previousState, '->', newState)
    saveLocalStorageState({
      warningStartTime: 0,
      lastPingTime: Date.now()
    })
    clearUserData()
    showLoggedOutDialog()
  },
  userActivity: async function (previousState, action, newState) {
    if (newState !== previousState) d.info('action handler:', action, 'from', previousState, '->', newState, '(new state)')
    saveLocalStorageState({
      lastUserActivityTime: Date.now(),
      warningStartTime: 0
    })
  },
  pingGood: async function (previousState, action, newState) {
    if (newState !== previousState) d.info('action handler:', action, 'from', previousState, '->', newState, '(new state)')
  },
  pingBad: async function (previousState, action, newState) {
    if (newState !== previousState) d.info('action handler:', action, 'from', previousState, '->', newState, '(new state)')
    showLoggedOutDialog()
  },
  inactivityTrigger: async function (previousState, action, newState) {
    if (newState !== previousState) {
      d.info('action handler:', action, 'from', previousState, '->', newState)
      saveLocalStorageState({ warningStartTime: Date.now() })
    }
    showWarningDialog()
  },
  inactivityTimeout: async function (previousState, action, newState) {
    // only trigger logout action in an active/visible tab
    if (newState !== previousState && !window.document.hidden) {
      d.info('action handler:', action, 'from', previousState, '->', newState)
      saveLocalStorageState({
        warningStartTime: 0,
        lastPingTime: Date.now()
      })
      showLoggedOutDialog()
      await logout(true)
    }
  },
  error: async function (previousState, action, newState) {
    d.info('action handler:', action, 'from', previousState, '->', newState)
    showErrorDialog()
  }
}

// variables
// ---------

const timeoutState = {
  timeoutState: 'loggedOut',
  lastUserActivityTime: Date.now(),
  lastPingTime: Date.now(),
  warningStartTime: 0
}

// timeoutMs from default or from server
let timeoutMs
// warningMs from default or from server
let warningMs
// need to set dynamically because of circular import issue
let context = null
// one interval every second, controls checking/updating things
let controllerInterval

// localStorage and state handlers
// -------------------------------

// initialize from context when hooked up by vuex
function setStateFromUpdatedContext () {
  const isLoggedIn = !!context?.state?.user
  const now = Date.now()

  const updatedState = {
    timeoutState: isLoggedIn ? 'loggedIn' : 'loggedOut'
  }

  if (isLoggedIn) {
    Object.assign(updatedState, {
      lastUserActivityTime: now,
      lastPingTime: now,
      warningStartTime: 0
    })
  }

  d.info(updatedState, 'Set state from context', isLoggedIn)

  saveLocalStorageState(updatedState)
}

// update in mem and localStorage state values
function saveLocalStorageState (passedState = {}) {
  const state = Object.assign(timeoutState, passedState)
  for (const key in state) {
    timeoutState[key] = state[key]
    localStorage.setItem(key, state[key]?.toString ? state[key].toString() : state[key])
    // d.info('localStorage set', key, state[key])
  }
}

async function transitionTimeoutState (action) {
  const previousState = timeoutState.timeoutState
  const result = stateMachine.transition(previousState, action)
  if (previousState !== result.value) d.info('State transition', previousState, action, '->', result.value)
  saveLocalStorageState({ timeoutState: result.value })
  if (actionFn[action]) await actionFn[action](previousState, action, timeoutState.timeoutState)
  const newStateFn = stateDescription.states[result.value].fn
  // call our new state function with action that go us here, if any, once per state change
  if (newStateFn && timeoutState.timeoutState !== previousState) await newStateFn(previousState, action)
}

// browser event handlers
// ----------------------

// set up browser event handlers
const activityEvents = ['scroll', 'click', 'keypress', 'touchstart', 'mousewheel', 'DOMMouseScroll', 'pointerdown']

// checks for support first
// allows remove
function setUserActivityListeners (remove) {
  d.log('setUserActivityListeners', remove ? 'unset' : 'set')
  activityEvents.forEach(function (eventName) {
    if (detect.isEventSupported(eventName)) {
      if (remove) {
        document.removeEventListener(eventName, onUserActivity, false)
        // d.log('Removed event listener', eventName)
      } else {
        document.addEventListener(eventName, onUserActivity, false)
        // d.log('Set event listener', eventName)
      }
    }
  })
}

// load from local storage when come back into visibility
async function handleVisibility (status) {
  if (status === 'visible') {
    d.info('Visibility handler')

    saveLocalStorageState({
      lastUserActivityTime: Date.now(),
      warningStartTime: 0
    })

    hideDialog('windowBlur')

    // trigger ping when we return to a tab to confirm login state
    // use no401Transition option for triggerPing so we can control handling here
    const pingSuccess = await triggerPing(true)
    // if ping is successful but we have no knowledge of logged in user in this tab
    // then force reload to get fixed login dialog state OR
    // if it fails and we show logged in here
    // probably better to handle this with vuex methods later, but not available yet
    if ((pingSuccess && !context?.state?.user) ||
        (!pingSuccess && context?.state?.user)) window.location.reload()
  }
}

async function onUserActivity () {
  // d.info('onUserActivity')
  await transitionTimeoutState('userActivity')
}

function initializeAllBrowserEventHandlers () {
  if (controllerInterval) clearInterval(controllerInterval)
  controllerInterval = setInterval(controllerIntervalFn, CONTROLLER_INTERVAL_MS)
  setUserActivityListeners()
  visibility(handleVisibility)
}

// MAIN TIME CONTROLLER
// check and handle our timers every second
async function controllerIntervalFn () {
  const now = Date.now()

  // only handle these timers when logged in
  if (timeoutState.timeoutState === 'loggedOut') return

  if (now - timeoutState.lastPingTime > PING_MS) {
    // only trigger pings when visible!
    if (!window.document.hidden) await triggerPing()
  }

  const inactiveTime = now - timeoutState.lastUserActivityTime
  const warningStartsMs = timeoutMs - warningMs
  // d.info('Checking inactiveTime', inactiveTime, warningStartsMs, timeoutMs)

  // set inactivity warning timer if needed
  if (inactiveTime > warningStartsMs) {
    await transitionTimeoutState('inactivityTrigger')
  }

  // trigger inactivityTimeout if needed
  if (inactiveTime > timeoutMs) {
    await transitionTimeoutState('inactivityTimeout')
  }
}

// api interactions
// ----------------

// returns true or false based on success or fail of ping
async function triggerPing (no401Transition) {
  // d.info(timeoutState, 'timeoutState')
  saveLocalStorageState({
    lastPingTime: Date.now()
  })

  try {
    await api.get('users/ping')
    await transitionTimeoutState('pingGood')
    return true
  } catch (err) {
    d.log(err, 'Ping fail', err.response?.status)
    if (err.response?.status !== 401) await transitionTimeoutState('error')
    else {
      if (!no401Transition) await transitionTimeoutState('pingBad')
    }
    return false
  }
}

async function logout (isAutomatic) {
  try {
    await api.get('users/logout')
  } catch (err) {
    d.log(err, 'Logout fail')
  }
  if (isAutomatic) await transitionTimeoutState('logoutAutomatic')
  else await transitionTimeoutState('logoutManual')
}

function redirectToDashboardCheck () {
  d.log('Redirect to dashboard check?', window.location.pathname, vars.UNAUTHENTICATED_PATHS)
  if (!vars.UNAUTHENTICATED_PATHS.some(p => window.location.pathname.indexOf(p) === 0)) {
    // eslint-disable-next-line
    context?.dispatch(VUEX_ROUTING_ROUTE, { name: ITS__ROUTING__DEFAULT__LOGGED_IN })
  }
}

// dialog handlers
// ---------------

function hideDialog (type) {
  // eslint-disable-next-line
  context?.dispatch(VUEX_DIALOG_HIDE, type)
}

function showWarningDialog () {
  // d.info('Show warning dialog')
  const now = Date.now()
  const timeToLogoutMs = timeoutMs - (now - timeoutState.lastUserActivityTime)
  let val; let timeLeftMsg = ''

  if (timeToLogoutMs >= 60000) {
    val = Math.round(timeToLogoutMs / 60000)
    timeLeftMsg = val + ' minute' + (val > 1 ? 's' : '')
  } else {
    val = Math.round(timeToLogoutMs / 1000)
    timeLeftMsg = val + ' second' + (val > 1 ? 's' : '')
  }

  // eslint-disable-next-line
  context?.dispatch(VUEX_DIALOG_SHOW, {
    content: '_core/Dialogs/Alerts/Dialog_ServerError.vue',
    type: 'windowBlur',
    title: 'Logging out in ' + timeLeftMsg,
    message: 'You are about to be logged out. Please click to stay logged in. Note that if you leave a download in progress it will not be interrupted.'
  }, { root: true })
}

function showLoggedOutDialog () {
  // eslint-disable-next-line
  context?.dispatch(VUEX_DIALOG_SHOW, {
    content: '_core/Dialogs/Alerts/Dialog_ServerError.vue',
    type: 'windowBlur',
    title: 'Logged Out',
    message: VUEX_DIALOG_LOGGED_OUT_MESSAGE
  }, { root: true })
}

function showErrorDialog () {
// eslint-disable-next-line
context?.dispatch(VUEX_DIALOG_SHOW, {
  content: '_core/Dialogs/Alerts/Dialog_ServerError.vue',
  type: 'windowBlur',
  title: 'Error',
  message: 'There was a login state error. Please reload your page.'
}, { root: true })
}

function clearUserData () {
  // eslint-disable-next-line
  context?.commit(VUEX_USER_SET_AWS_CREDENTIALS, null)
  // eslint-disable-next-line
  context?.commit(VUEX_USER_SET_AWS_TOKEN, null)
  // eslint-disable-next-line
  context?.commit(VUEX_USER_SET, null)
  window.data = null
}

// timeout controller
async function startup () {
  setTimeoutMs()
  saveLocalStorageState()
  initializeAllBrowserEventHandlers()
}

function setTimeoutMs () {
  warningMs = (context?.rootState?.options.userWarningMs || DEFAULT_WARNING_MS)
  d.info('Warning seconds set to', warningMs / 1000, 'seconds')
  timeoutMs = (context?.rootState?.options?.userTimeoutMs || DEFAULT_TIMEOUT_MS) - (30 * 1000) // allow a little fudge factor;
  d.info('Logout timeout set to ', timeoutMs / 60000, 'minutes')
}

// IMPORTANT! this is how this module knows about vuex store
// and must be set from vuex before anything else can happen
// need to do this to prevent circular dependencies
function setContext (contextRef) {
  context = contextRef
  setTimeoutMs()
}

export default {
  setContext,
  setStateFromUpdatedContext,
  logout
}

// startup on load
startup()
