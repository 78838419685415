// DOCUMENTS
export const VUEX_DOCUMENTS_FETCH_REQUEST = 'VUEX_DOCUMENTS_FETCH_REQUEST'
export const VUEX_DOCUMENTS_FETCH_SUCCESS = 'VUEX_DOCUMENTS_FETCH_SUCCESS'
export const VUEX_DOCUMENTS_FETCH_FAILURE = 'VUEX_DOCUMENTS_FETCH_FAILURE'
export const VUEX_DOCUMENT_CREATE_FILE_POP = 'VUEX_DOCUMENT_CREATE_FILE_POP'

// DOCUMENT
export const VUEX_DOCUMENT_FETCH_REQUEST = 'VUEX_DOCUMENT_FETCH_REQUEST'
export const VUEX_DOCUMENT_FETCH_SUCCESS = 'VUEX_DOCUMENT_FETCH_SUCCESS'
export const VUEX_DOCUMENT_FETCH_FAILURE = 'VUEX_DOCUMENT_FETCH_FAILURE'

export const VUEX_DOCUMENT_CREATE_REQUEST = 'VUEX_DOCUMENT_CREATE_REQUEST'
export const VUEX_DOCUMENT_CREATE_SUCCESS = 'VUEX_DOCUMENT_CREATE_SUCCESS'
export const VUEX_DOCUMENT_CREATE_FAILURE = 'VUEX_DOCUMENT_CREATE_FAILURE'

export const VUEX_DOCUMENT_SCHEDULED_REQUEST = 'VUEX_DOCUMENT_SCHEDULED_REQUEST'
export const VUEX_DOCUMENT_SCHEDULED_SUCCESS = 'VUEX_DOCUMENT_SCHEDULED_SUCCESS'
export const VUEX_DOCUMENT_SCHEDULED_FAILURE = 'VUEX_DOCUMENT_SCHEDULED_FAILURE'

export const VUEX_DOCUMENT_SCHEDULED_DELETE = 'VUEX_DOCUMENT_SCHEDULED_DELETE'
export const VUEX_DOCUMENT_SCHEDULED_DELETE_SUCCESS = 'VUEX_DOCUMENT_SCHEDULED_DELETE_SUCCESS'
export const VUEX_DOCUMENT_SCHEDULED_DELETE_FAILURE = 'VUEX_DOCUMENT_SCHEDULED_DELETE_FAILURE'

export const VUEX_DOCUMENT_EMAIL_FILE_REQUEST = 'VUEX_DOCUMENT_EMAIL_FILE_REQUEST'
export const VUEX_DOCUMENT_EMAIL_FILE_SUCCESS = 'VUEX_DOCUMENT_EMAIL_FILE_SUCCESS'
export const VUEX_DOCUMENT_EMAIL_FILE_FAILURE = 'VUEX_DOCUMENT_EMAIL_FILE_FAILURE'

export const VUEX_DOCUMENT_SET_DATA = 'VUEX_DOCUMENT_SET_DATA'
export const VUEX_DOCUMENT_CLEAR_DATA = 'VUEX_DOCUMENT_CLEAR_DATA'
export const VUEX_DOCUMENT_CLEAR_EXPORT_STATUS = 'VUEX_DOCUMENT_CLEAR_EXPORT_STATUS'
export const VUEX_DOCUMENT_ADD_TO_CREATE_QUEUE = 'VUEX_DOCUMENT_ADD_TO_CREATE_QUEUE'
export const VUEX_DOCUMENT_TRIGGER_FILE_COMPLETE_ACTIONS = 'VUEX_DOCUMENT_TRIGGER_FILE_COMPLETE_ACTIONS'

export const VUEX_SHARED_FILE_DOWNLOAD = 'VUEX_SHARED_FILE_DOWNLOAD'
export const VUEX_SHARED_FILE_DOWNLOAD_SUCCESS = 'VUEX_SHARED_FILE_DOWNLOAD_SUCCESS'
export const VUEX_SHARED_FILE_DOWNLOAD_FAILURE = 'VUEX_SHARED_FILE_DOWNLOAD_FAILURE'

export const VUEX_SCHEDULED_FILE_DOWNLOAD = 'VUEX_SCHEDULED_FILE_DOWNLOAD'
export const VUEX_SCHEDULED_FILE_DOWNLOAD_SUCCESS = 'VUEX_SCHEDULED_FILE_DOWNLOAD_SUCCESS'
export const VUEX_SCHEDULED_FILE_DOWNLOAD_FAILURE = 'VUEX_SCHEDULED_FILE_DOWNLOAD_FAILURE'
