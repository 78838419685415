import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PENDING,
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PUBLISHED,
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UNPUBLISHED,
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UPLOAD,
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_DELETED
} from '@/store/constants/routing/routingAdvertising'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { advertisingMeta } from '@/router/routeMeta'

export default {
  path: '/advertising/libraries/manage',
  // name: 'advertising--libraries--manage',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      permissions: {
        accessRequires: [
          {
            permission: feConstants.ITS__PERMISSION__ADVERTISING__ASSETS,
            roles: [
              feConstants.ITS__ROLE__EDITOR,
              feConstants.ITS__ROLE__SENIOR_EDITOR
            ]
          },
          {
            permission: feConstants.ITS__PERMISSION__ADVERTISING__LOGOS,
            roles: [
              feConstants.ITS__ROLE__EDITOR,
              feConstants.ITS__ROLE__SENIOR_EDITOR
            ]
          }
        ]
      },
      callback: () => { store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()) }
    }),
  children: [
    {
      path: '',
      name: 'advertising--libraries--manage--default',
      redirect: { name: 'advertising--libraries--assets--manage--pending' }
    },
    {
      path: 'assets/pending',
      name: 'advertising--libraries--assets--manage--pending',
      component: loadComponent('Advertising/Advertising_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PENDING, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET,
        optionalNavAppBarLabel: 'Manage Advertising Assets'
      }
    },
    {
      path: 'assets/published',
      name: 'advertising--libraries--assets--manage--published',
      component: loadComponent('Advertising/Advertising_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PUBLISHED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET,
        optionalNavAppBarLabel: 'Manage Advertising Assets'
      }
    },
    {
      path: 'assets/unpublished',
      name: 'advertising--libraries--assets--manage--unpublished',
      component: loadComponent('Advertising/Advertising_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UNPUBLISHED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET,
        optionalNavAppBarLabel: 'Manage Advertising Assets'
      }
    },
    {
      path: 'assets/deleted',
      name: 'advertising--libraries--assets--manage--deleted',
      component: loadComponent('Advertising/Advertising_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UPLOAD, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET,
        optionalNavAppBarLabel: 'Manage Advertising Assets'
      }
    },
    {
      path: 'assets/upload',
      name: 'advertising--libraries--assets--manage--upload',
      component: loadComponent('Advertising/Advertising_Upload.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_DELETED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET,
        optionalNavAppBarLabel: 'Manage Advertising Assets'
      }
    },
    {
      path: 'logos/pending',
      name: 'advertising--libraries--logos--manage--pending',
      component: loadComponent('Advertising/Advertising_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PENDING, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO,
        optionalNavAppBarLabel: 'Manage Advertising Logos'
      }
    },
    {
      path: 'logos/published',
      name: 'advertising--libraries--logos--manage--published',
      component: loadComponent('Advertising/Advertising_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PUBLISHED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO,
        optionalNavAppBarLabel: 'Manage Advertising Logos'
      }
    },
    {
      path: 'logos/unpublished',
      name: 'advertising--libraries--logos--manage--unpublished',
      component: loadComponent('Advertising/Advertising_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UNPUBLISHED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO,
        optionalNavAppBarLabel: 'Manage Advertising Logos'
      }
    },
    {
      path: 'logos/deleted',
      name: 'advertising--libraries--logos--manage--deleted',
      component: loadComponent('Advertising/Advertising_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UPLOAD, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO,
        optionalNavAppBarLabel: 'Manage Advertising Logos'
      }
    },
    {
      path: 'logos/upload',
      name: 'advertising--libraries--logos--manage--upload',
      component: loadComponent('Advertising/Advertising_Upload.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_DELETED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO,
        optionalNavAppBarLabel: 'Manage Advertising Logos'
      }
    }
  ],
  meta: advertisingMeta
}
