import {
  VUEX_FILEPROGRESS_SHOW,
  VUEX_FILEPROGRESS_SHOW_MINI,
  VUEX_FILEPROGRESS_HIDE,

  VUEX_FILEPROGRESS_OPEN,
  VUEX_FILEPROGRESS_CLOSE,
  VUEX_FILEPROGRESS_OPEN_STATE_TOGGLE
} from '@/store/constants/ui/fileProgress'

const state = {
  displayState: 'hidden', // visible | mini | hidden
  openState: false
}

const getters = {}

const actions = {
  [VUEX_FILEPROGRESS_SHOW]: ({ commit }) => {
    commit(VUEX_FILEPROGRESS_SHOW)
  },

  [VUEX_FILEPROGRESS_SHOW_MINI]: ({ commit }) => {
    commit(VUEX_FILEPROGRESS_SHOW_MINI)
  },

  [VUEX_FILEPROGRESS_HIDE]: ({ getters, commit }, delay) => {
    // let hasS3FilesProcessing = (getters.s3FUM_filesQueued().length > 0 && getters.s3FUM_filesUploading().length > 0)

    let closeDelay = delay || 0
    setTimeout(() => {
      commit(VUEX_FILEPROGRESS_HIDE)
      /* if (!hasS3FilesProcessing) {
        commit(VUEX_FILEPROGRESS_HIDE)
      } */
    }, closeDelay)
  },

  [VUEX_FILEPROGRESS_OPEN]: ({ commit }) => {
    commit(VUEX_FILEPROGRESS_OPEN)
  },

  [VUEX_FILEPROGRESS_CLOSE]: ({ commit }) => {
    commit(VUEX_FILEPROGRESS_CLOSE)
  },

  [VUEX_FILEPROGRESS_OPEN_STATE_TOGGLE]: ({ commit }) => {
    commit(VUEX_FILEPROGRESS_OPEN_STATE_TOGGLE)
  }
}

const mutations = {
  [VUEX_FILEPROGRESS_SHOW]: state => {
    state.displayState = 'visible'
  },

  [VUEX_FILEPROGRESS_SHOW_MINI]: state => {
    state.displayState = 'mini'
  },

  [VUEX_FILEPROGRESS_HIDE]: state => {
    state.displayState = 'hidden'
  },

  [VUEX_FILEPROGRESS_OPEN]: state => {
    state.openState = true
  },

  [VUEX_FILEPROGRESS_CLOSE]: state => {
    state.openState = false
  },

  [VUEX_FILEPROGRESS_OPEN_STATE_TOGGLE]: state => {
    state.openState = !state.openState
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
