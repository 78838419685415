export default {
  label: 'Due Date',
  allowMultiple: false,
  filterGroups: [
    {
      layout: {
        type: 'date-vertical'
      },
      filters: [
        {
          title: 'Within',
          component: {
            type: 'date-single-select'
          },
          payloadType: 'object',
          sendType: 'offset',
          key: 'dueDate',
          dateOptions: [
            {
              label: 'Next 15 Days',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -2
                },
                end: {
                  position: 'endOf',
                  time: 'day',
                  offset: 14
                }
              }
            },
            {
              label: 'Next 30 Days',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -2
                },
                end: {
                  position: 'endOf',
                  time: 'day',
                  offset: 29
                }
              }
            },
            {
              label: 'Next 60 Days',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -2
                },
                end: {
                  position: 'endOf',
                  time: 'day',
                  offset: 59
                }
              }
            },
            {
              label: 'Next 90 Days',
              dates: {
                begin: {
                  position: 'startOf',
                  time: 'day',
                  offset: -2
                },
                end: {
                  position: 'endOf',
                  time: 'day',
                  offset: 89
                }
              }
            }
          ]
        },
        {
          payloadType: 'object',
          component: {
            type: 'date-picker',
            allowSingleSelect: true
          },
          pickerType: 'any', // single, range, any
          title: 'or Between',
          key: 'dueDate',
          timeSpan: 'future'
        }
      ]
    }
  ]
}
