import productsAvailabilityModule from '@/components/_core/FilterBar/_config/modules/productsAvailabilityModule.js'

export default {
  modules: [
    {
      label: 'Style / Color',
      filterGroups: [
        {
          hint: 'i.e. SK80575, SK83253',
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter IDs separated by commas'
              },
              key: 'id'
            }
          ]
        }
      ]
    },
    {
      label: 'Name',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'text-input',
                placeholder: 'Enter full or partial names separated by commas'
              },
              key: 'name'
            }
          ]
        }
      ]
    },
    {
      label: 'Key Initiative Season',
      allowMultiple: false,
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'keyInitiative',
              placeholder: 'Key Initiative',
              // data: 'properties.Product.properties.keyInitiative.options'
              data: {
                getter: 'seasonSetsFlattened',
                filters: {
                  productType: 'POP'
                }
              }
            }
          ]
        }
      ]
    },
    {
      label: 'Type',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select',
                placeholder: 'Type'
              },
              key: 'popType',
              data: 'properties.Product.properties.popType.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Material',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'materials',
              data: 'properties.Product.properties.materials.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Category',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'popCategory',
              data: 'properties.Product.properties.popCategory.options',
              specialOptions: [
                {
                  label: 'ALL',
                  value: '___ALL___'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      label: 'Gender',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'popGender',
              data: 'properties.Product.properties.popGender.options'
            }
          ]
        }
      ]
    },
    {
      label: 'Usage',
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'select'
              },
              key: 'usage',
              data: 'properties.Product.properties.usage.options'
            }
          ]
        }
      ]
    },
    productsAvailabilityModule('POP'),
    {
      label: 'Price',
      allowMultiple: false,
      filterGroups: [
        {
          filters: [
            {
              component: {
                type: 'price-span',
                fields: [
                  {
                    label: 'From',
                    key: '$gte'
                  },
                  {
                    label: 'To',
                    key: '$lte'
                  }
                ]
              },
              dependencies: {
                product: [{ location: 'locations.code' }]
              },
              payloadType: 'object',
              multiQuery: {
                type: 'singleselect',
                options: [
                  {
                    label: 'Cost',
                    key: 'locations.wholesalePrice',
                    value: true
                  },
                  {
                    label: 'Sugg. Retail',
                    key: 'locations.suggestedRetail',
                    value: false
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ]
}
