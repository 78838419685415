import regex from '@/helpers/regex'

// ------------------------------------------------------------
// SENTENCE CASE:
// Capitalize the first letter in the word or sentence
//
// EXAMPLE: 'this is an example' -> 'This is an example'
//                                   ^
// ------------------------------------------------------------
const toSentenceCase = str => {
  if (!str) return ''

  str = regex.functions.replaceMultipleWhiteSpace(str)
  str = str.toString().trim().charAt(0).toUpperCase() + str.slice(1).toLowerCase()

  return str
}

// ------------------------------------------------------------
// PROPER CASE:
// Capitalize the first letter of every word in a sentence
//
// EXAMPLE: 'tHis is aN eXamPle' -> 'This Is An Example'
//                                   ^    ^  ^  ^
// ------------------------------------------------------------
const toProperCase = str => {
  if (!str) return ''

  str = regex.functions.replaceSpecialCharaters(str)
  str = regex.functions.replaceMultipleWhiteSpace(str)
  str = str.toString().trim().toLowerCase().split(' ')

  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

// ------------------------------------------------------------
// TITLE CASE:
// Capitalize the first letter of every word in a sentence
// except for specific non-capitalized articles
//
// EXAMPLE: 'tHis is aN eXamPle' -> 'This is an Example'
//                                   ^          ^
// ----------------------------------------------------------
const toTitleCase = str => {
  // TODO
  return null
}

// ------------------------------------------------------------
// PASCAL CASE:
// Capitalize the first letter of every word in a sentence and join together
//
// EXAMPLE: 'tHis is aN eXamPle' -> 'ThisIsAnExample'
//                                   ^   ^ ^ ^
// ------------------------------------------------------------
const toPascalCase = str => {
  if (!str) return ''

  str = regex.functions.replaceSpecialCharaters(str)
  str = str.toString().trim().toLowerCase().split(' ')

  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join('')
}

// ------------------------------------------------------------
// TO SLUG:
// Capitalize the first letter of every word in a sentence and join together
//
// EXAMPLE: '!tHis_ is aN eXamPle?' -> 'this-is-an-example'
//                                          ^  ^  ^
// ------------------------------------------------------------
const toSlug = (str, toLowerCase = true) => {
  if (toLowerCase) str = str.toLowerCase()

  str = regex.functions.replaceSpecialCharaters(str)
  str = regex.functions.replaceMultipleWhiteSpace(str)

  return str.toString().trim().replace(/ /g, '-')
}

export default {
  toSentenceCase, // Capitalize the first letter in the FIRST word or sentence
  toProperCase, // Capitalize the first letter of EVERY word in a sentence
  toTitleCase, // Capitalize the first letter of every word in a sentence except for specific non-capitalized articles
  toPascalCase, // Capitalize the first letter of EVERY word in a sentence and join together,
  toSlug // Removes all special charaters and replaces spaces with dashes '-'
}
