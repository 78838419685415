export default {
  /* eslint-disable */
  data: () => ({
    regEx_Email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  }),
  /* eslint-enable */

  methods: {
    $isValidEmailAddress (emailStr) {
      return this.regEx_Email.test(String(emailStr).toLowerCase())
    }
  }
}
