<template>
  <v-text-field
    class="mb-2"
    :append-icon="appendIcon"
    :clearable="clearable"
    :color="color"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :hint="hint"
    :label="label"
    :model-value="modelValue"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :prepend-inner-icon="prependInnerIcon"
    :readonly="readonly"
    :rules="rules"
    :type="type"
    @update:modelValue="update"
    :variant="variant"
  >
    <template v-if="appendIcon" v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'its-text-field',

  props: {
    appendIcon: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean
    },
    errorMessages: {
      type: Object
    },
    hideDetails: {
      type: Boolean
    },
    hint: {
      type: String
    },
    label: {
      type: String
    },
    modelValue: {
      type: String
    },
    placeholder: {
      type: String
    },
    prependIcon: {
      type: String
    },
    prependInnerIcon: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    rules: {
      type: Object
    },
    type: {
      type: String,
      default: 'text'
    },
    variant: {
      type: String,
      default: 'filled'
    }
  },

  computed: {
    value () {
      return this.modelValue
    }
  },

  emits: ['update:modelValue'],

  methods: {
    update (val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
