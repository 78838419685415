import _set from 'lodash.set'
import dotize from 'dotize'

import { stringify as qsStringify } from 'qs'
// import Debug from 'logdown'

// const d = new Debug('its:router:query')

const CLIENT_QUERY_PARAMS = ['filter', 'time']

/**
 *
 * This handles query strings like this:
 *
 * You can dot keys to make a single level of objects (not recursive)
 * You can separate values with comma to make it an array
 *
 * It's intended to work with node-restful and its filtering
 *
 * You can force something into array shape by adding a trailing , which will denote its supposed to be an array
 * And final empty item will be ignored
 *
 *
 *
 */

function parse (qStr) {
  if (!qStr) return {}

  let qObj = {}
  const parts = qStr.split(/\b&\b/g) // only split at correct params (& and not 'Linesheets & reports')

  parts.forEach(function (part) {
    const sides = part.split('=')
    // TODO: I think we can remove checks for `__` props
    const keyArr = (sides[0] === 'modifiedDate__ltd' || sides[0] === 'modifiedDate__gtd' || sides[0] === 'files.modifiedDate__gtd') ? [sides[0]] : sides[0].split('__')
    const key = keyArr[0]
    let data = (sides[0] !== '_sortOrder' || sides[0] !== 'sortOrder') ? sides[1].split(',') : sides[1]

    for (let i = 0; i < data.length; i++) {
      switch (data[i]) {
        case 'null':
          data[i] = null
          break
        case 'undefined':
          data[i] = undefined
          break
        case 'true':
          data[i] = true
          break
        case 'false':
          data[i] = false
          break
      }
    }

    _set(qObj, key, data.length > 1 ? data : data[0])
  })

  return qObj
}

function stringify (qObj) {
  const dotizedObj = dotize.convert(qObj)

  let queryKeys = Object.keys(dotizedObj)
  if (!queryKeys.length) return ''

  // let queryStr = `?${JsonURL.stringify(dotizedObj)}`
  let queryStr = '' // '?' was giving double question marks

  Object.keys(dotizedObj).forEach((k, i) => {
    let key = k
    let value = dotizedObj[key]

    // base case for empty array
    if (Array.isArray(value) && !value.length) return

    queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
  })

  return queryStr
}

// leaves null values
function cleanQuery (query) {
  let q = {}
  Object.keys(query).forEach(function (key) {
    const type = typeof query[key]

    if (type !== 'undefined') {
      if ((type === 'boolean') ||
        (type === 'string' && query[key]) ||
        type === 'number' ||
        (type === 'object' && query[key] === null)) q[key] = query[key]
      else if ((Array.isArray(query[key]) && query[key].length) ||
        (type === 'object' && Object.keys(query[key]).length)) q[key] = JSON.parse(JSON.stringify(query[key]))
    }
  })
  return q
}

// return only query items that should go to server api endpoints
// pass in cleanQuery result
// this also strips out nulls
function serverQuery (query) {
  let q = {}
  Object.keys(query).forEach(function (key) {
    if (query[key] === null) return
    if (CLIENT_QUERY_PARAMS.some(function (param) {
      return key.includes(param)
    })) return
    q[key] = query[key]
  })
  return q
}

// in string form
function serverQueryStr (query) {
  return stringify(serverQuery(query))
}

let encodeQueryParams = params => encodeURIComponent(JSON.stringify(params))

export default {
  parse,
  stringify,
  cleanQuery,
  serverQuery,
  serverQueryStr,
  qsStringify,
  encodeQueryParams
}
