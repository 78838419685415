import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import cloneDeep from 'lodash/cloneDeep'

let AssortmentManager = {
  totalCount: 0,
  readyCount: 0,
  loading: false,
  params: null,
  fetcher: function () {
    return {
      getRows (params) {
        // deselect all - paging breaks if cells are selected or open
        GridHelpers.deselectAll()
        AssortmentManager.loading = true
        AssortmentManager.params = params
        AssortmentManager.readyCount = 0
        AssortmentManager.serverLookupReadyCheck()
      } // getRows
    } // return
  }, // fetcher

  // first lopp through a readiness detector, since sometimes the site isn't loaded enough to check
  serverLookupReadyCheck () {
    let payload = cloneDeep(GridHelpers.mgThisArray[0].dynamicQuery)
    let chips = GridHelpers.mgThisArray[0].appliedFilterBarChips()
    let params = AssortmentManager.params

    // I have no way of knowing if there are A) no chips, or B) if the chips haven't loaded yet
    // so i check a few times, and if nothing comes it, show the no results
    if (AssortmentManager.readyCount > 5) {
      AssortmentManager.serverDoneLoading('empty', params)
    } else if (!payload || !chips || chips.length === 0) {
      setTimeout(() => {
        AssortmentManager.readyCount++
        AssortmentManager.serverLookupReadyCheck()
      }, 200)
    } else {
      if (AssortmentManager) {
        AssortmentManager.serverLookup()
      }
    }
  },
  serverLookup () {
    AssortmentManager.loading = true
    let perPage = GridHelpers.mgThisArray[0].cacheBlockSize
    let payload = cloneDeep(GridHelpers.mgThisArray[0].dynamicQuery)
    // let chips = GridHelpers.mgThisArray[0].appliedFilterBarChips()
    let params = AssortmentManager.params

    // get location and product type
    let locationId = ''
    let productType = ''
    let status = ''
    if (GridHelpers.mgThisArray[0].assortment) {
      if (GridHelpers.mgThisArray[0].assortment.locationId) {
        locationId = GridHelpers.mgThisArray[0].assortment.locationId
      }
      if (GridHelpers.mgThisArray[0].assortment.productType) {
        productType = GridHelpers.mgThisArray[0].assortment.productType
      }
      if (GridHelpers.mgThisArray[0].assortment.status) {
        status = GridHelpers.mgThisArray[0].assortment.status
      }
    }

    // get sort state, then add random at end
    let sortState = cloneDeep(GridHelpers.mgThisArray[0].gridApi.getColumnState())
    sortState.sort((a, b) => a.sortIndex - b.sortIndex)
    let columnsSorted = []
    if (sortState) {
      columnsSorted = sortState.filter(x => x['sort'] !== null)
    }
    let sortObj = {}
    if (columnsSorted.length > 0) {
      sortObj = DataMiddleware.parseAssortmentSort(columnsSorted, false, true)
    } else {
      sortObj = {
        title: 1
      }
    }
    sortObj['rand'] = 1 // append rand to everything

    // return total count
    let doTotalCount = (!params.request.startRow || AssortmentManager.totalCount === 0)

    // check for query
    let hasAnyProperties = false
    for (let prop in payload) {
      if (payload.hasOwnProperty(prop)) {
        hasAnyProperties = true
      }
    }

    // if no properties, show empty, otherwise fetch
    if (hasAnyProperties === false) {
      AssortmentManager.serverDoneLoading('altempty', params)
    } else {
      payload['productType'] = productType
      payload['locations.code'] = locationId
      payload['locations.lineStatus'] = status
      payload._options = {
        totalCount: doTotalCount,
        limit: perPage,
        skip: params.request.startRow,
        sort: cloneDeep(sortObj)
        /* agGrid: {
          endRow: params.request.endRow,
          startRow: params.request.startRow
        } */
      }

      // clean up bad stuff
      // delete payload._uiOptions

      payload._uiOptions = {
        frontendOptions: {
          agGrid: {
            endRow: params.request.endRow,
            startRow: params.request.startRow
          }
        }
      }

      // start lookup
      GridHelpers.mgThisArray[0].$store.dispatch('VUEX_GRID_ROWDATA_LOADING_START')
      GridHelpers.mgThisArray[0].$store.dispatch('VUEX_API_PRODUCTS_REQUEST_FETCH', payload).then(response => {
        if (params.parentNode?.beans?.context?.destroyed) {
          // check for destroyed -this issue has promising notes
          // https://github.com/ag-grid/ag-grid/issues/3334#issuecomment-584403287
        } else {
          // NOT DESTROYED
          if (response) {
            let resp = response.data[0] || response.data
            // set total count
            if (resp.totalCount) {
              AssortmentManager.totalCount = resp.totalCount
              GridHelpers.mgThisArray[0].$store.dispatch('VUEX_ASSORTMENT_UPDATE_DYNAMIC_PRODUCT_TOTAL_COUNT', AssortmentManager.totalCount)
            }

            // set rows
            let rows = resp.data
            rows = DataMiddleware.convertAssortmentDetailsToRowData(rows, GridHelpers.mgThisArray[0].assortment)
            if (rows.length > 0) {
              // did we hit the last row?
              let endRow = payload._uiOptions.frontendOptions.agGrid.endRow
              let startRow = payload._uiOptions.frontendOptions.agGrid.startRow

              let lastRow = -1
              if (endRow < AssortmentManager.totalCount) { // if more pages
                lastRow = AssortmentManager.totalCount
              } else if (endRow >= AssortmentManager.totalCount && startRow === 0) { // if first page is smaller that subsets
                lastRow = AssortmentManager.totalCount
              }
              AssortmentManager.serverDoneLoading('success', params, rows, lastRow)
            } else {
              AssortmentManager.serverDoneLoading('empty', params, rows)
            }
          } else {
            AssortmentManager.serverDoneLoading('empty', params)
          }
          // end lookup
          GridHelpers.mgThisArray[0].$store.dispatch('VUEX_GRID_ROWDATA_LOADING_FINISH')
        }// destroyed
      }) // lookup
    } // hasAnyProperties
  }, // serverlookup

  serverDoneLoading (type, params, rowData = [], rowCount = null) {
    AssortmentManager.loading = false
    if (type === 'empty') {
      AssortmentManager.totalCount = 0
      params.success({
        rowData: rowData,
        rowCount: null
      })
    } else if (type === 'altempty') {
      AssortmentManager.totalCount = 0
      params.successCallback([], null)
    } else if (type === 'success') {
      params.success({
        rowData: rowData,
        rowCount: rowCount
      })
    } else if (type === 'fail') {
      AssortmentManager.totalCount = 0
      params.fail()
    }
    GridHelpers.mgThisArray[0].showMasterGridTogger = Math.random()
  } // serverDoneLoading
}

export default AssortmentManager
