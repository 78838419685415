import _debounce from 'lodash.debounce'
import _merge from 'lodash.merge'
import _clone from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'

import router from '@/router'

import {
  VUEX_COLLECTIONS_FETCH,
  VUEX_COLLECTION_FETCH,

  VUEX_COLLECTION_LOOKUP,
  VUEX_COLLECTION_LOOKUP_QUERY_SET,
  VUEX_COLLECTION_CREATE,
  VUEX_COLLECTION_UPDATE,
  VUEX_COLLECTIONS_CLEAR_BATCHED_REQUESTS,

  VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS_PROMPT,
  VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS,
  VUEX_COLLECTION_PRODUCT_REMOVE_PRODUCTS,
  VUEX_COLLECTION_PRODUCTS_BATCH_UPDATE,

  VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT,
  VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT_REQUEST
} from '@/store/constants/models/collections'

import {
  VUEX_ASSORTMENT_GRID_ROWS_ADD
} from '@/store/constants/models/assortments'

import {
  VUEX_API_COLLECTION_LOOKUP,
  VUEX_API_COLLECTION_CREATE,
  VUEX_API_COLLECTION_UPDATE,

  VUEX_API_PRODUCTS_REQUEST_FETCH
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const UPDATE_DELAY = 1000

const state = {
  collectionLookupQuery: {}, // query used to lookup collection | used for updating collection data
  collections: [], // holds collection data | comes in as an array

  batchRequests: [], // the request data to be sent to the backend to update the COLLECTION
  batchProductAddRequests: [] // the request data used for batch adding product to the ASSORTMENT
}

const getters = {
  // Gets collection in state by ID.
  // Currently only 1 exists and defaults to index 0
  collectionByIndex: state => (idx = 0) => {
    return state.collections[idx]
  },

  // TODO: DERECATE
  // Returns number of products in collection
  collectionProductsLength: state => (idx = 0) => {
    return state.collections[idx]?.products?.length || 0
  },

  // TODO: DERECATE
  // Returns number of products in collection with property selected:true
  selectedCollectionProductsLength: state => (idx = 0) => {
    const selectedItems = state.collections[idx]?.products?.filter(product => product.selected)
    return selectedItems?.length || 0
  },

  // Returns all products in collection that match style(optional), color(optional), and properties(optional)
  // Example: collectionItemsMatch('100025W', 'NVY', { selected:true })
  collectionItemsMatch: (state, getters) => (style = null, color = null, properties = {}) => {
    const collection = getters.collectionByIndex()
    if (!collection?.products) return []

    let matches = collection?.products

    // IF: style arg passed, narrow down to matching style
    if (style) matches = matches.filter(p => p.style === style)
    // IF: no matches exist: return empty array
    if (!matches?.length) return []

    // IF: color arg passed, narrow down to matching style and color
    if (color) matches = matches.filter(p => p.color === color)
    // IF: no matches exist: return empty array
    if (!matches?.length) return []

    // Filter results with matching requirments, ie selected:true
    const reqKeys = Object.keys(properties)
    // IF: no properties needed: return current matches
    if (!reqKeys.length) return matches

    // Loop through properties and narrow by value
    reqKeys.forEach(k => {
      matches = matches.filter(p => p[k] === properties[k])
    })

    // return requirement matches or empty array
    return matches || []
  }
}

const actions = {
  // ----------------------------------------------
  // ----------------------------------------------
  /* Collections Actions */
  // ----------------------------------------------
  // ----------------------------------------------

  // FETCH ALL: Collections : NOT CURRENTLY USED
  [VUEX_COLLECTIONS_FETCH]: ({ commit, dispatch }) => {
  },

  // FETCH: a user Collection by ID : NOT CURRENTLY USED
  [VUEX_COLLECTION_FETCH]: ({ commit, dispatch }, payload) => {
  },

  // LOOK UP: a user Collection
  [VUEX_COLLECTION_LOOKUP]: async ({ rootState, commit, dispatch }) => {
    // Set base request structure
    let requestObj = {
      collectionType: null,
      data: {
        name: 'Default',
        linkageType: null,
        linkageModel: 'Assortment'
      }
    }

    const internalAssortment = rootState.assortmentsInternal.query
    const assortment = rootState.assortments.assortment

    // TODO: use meta instead of route name -> collectionType?
    // Define structure based on route
    switch (router.currentRoute.value.name) {
      case 'product--libraries--assortments-internal--international-wholesale':
      case 'product--libraries--assortments-internal--international-retail':
      case 'product--libraries--assortments-internal--domestic-wholesale':
      case 'product--libraries--assortments-internal--domestic-retail':
      case 'product--libraries--assortments-internal--detail':
      case 'product--libraries--assortments-internal--divider':
        requestObj.collectionType = router.currentRoute.value.meta.collectionType
        requestObj.data.linkageType = 'linkageObject'
        requestObj.data.linkageObject = {
          channel: internalAssortment._uiOptions.assortmentsQuery.channel,
          method: internalAssortment._uiOptions.assortmentsQuery.method,
          orgType: internalAssortment._uiOptions.assortmentsQuery.orgType,
          productType: internalAssortment.productType,
          region: internalAssortment._uiOptions.assortmentsQuery.region,
          season: internalAssortment._uiOptions.assortmentsQuery.season,
          year: internalAssortment._uiOptions.assortmentsQuery.year
        }
        break

        /* case 'assortment-internal-details--manage' :
        requestObj.collectionType = router.currentRoute.value.meta.collectionType
        requestObj.data.linkageType = 'linkageObject'
        requestObj.data.linkageObject = {
          season: internalAssortment.season,
          year: internalAssortment.year,
          region: internalAssortment.region
        }
        break */

      case 'assortment-details--manage':
      case 'assortment-showroom':
      case 'assortment-showroom--detail':
      case 'assortment-showroom--divider':
        requestObj.collectionType = router.currentRoute.value.meta.collectionType
        requestObj.data.linkageType = 'linkageId'
        requestObj.data.linkageId = assortment._id
        break

      default :
        console.error('Vuex | models:collections | VUEX_COLLECTION_LOOKUP | route not configured > ', router.currentRoute.value.name)
        break
    }

    // IF: collection type or linkage type doesn't exist: bail
    if (!requestObj.collectionType || !requestObj.data.linkageType) {
      console.error('Vuex | models:collections | VUEX_COLLECTION_LOOKUP | missing "collectionType" or "linkageType"', requestObj)
      return
    }

    if (_isEqual(requestObj.data, state.collectionLookupQuery.data)) return

    // Look up on route entry. Save query to create/update later
    await commit(VUEX_COLLECTION_LOOKUP_QUERY_SET, requestObj)

    // Init lookup on backend
    await dispatch(VUEX_API_COLLECTION_LOOKUP, requestObj).then(response => {
      if (response?.data) commit(VUEX_COLLECTION_LOOKUP, response.data)
    })
  },

  // CREATE: a user Collection
  [VUEX_COLLECTION_CREATE]: async ({ state, commit, dispatch }, payload) => {
    await dispatch(VUEX_API_COLLECTION_CREATE, state.collectionLookupQuery).then(async response => {
      await commit(VUEX_API_COLLECTION_CREATE, response.data)
    })
  },

  // UPDATE: a user collection
  [VUEX_COLLECTION_UPDATE]: _debounce(async ({ state, getters, commit, dispatch }) => {
    // Create Collection if state.collections._id is missing
    const clonedBatchedRequests = _clone(state.batchRequests)
    await commit(VUEX_COLLECTIONS_CLEAR_BATCHED_REQUESTS)

    // *************************************************************
    // *************************************************************
    // NOTE:
    // This block looks for `updateProducts` props and loops through
    // the array to nest data inside `properties` object.
    // THIS IS NOT IDEAL. Can we send a flattened obj to the BE?

    // UPDATE: Brendan is amenable to changing this, but we're punting for now.
    // UPDATE 2: Removed
    /* const requestData = clonedBatchedRequests.map(batch => {
      // IF: This batch (array el) has `updatedProducts` property: restructure objects
      if (batch.hasOwnProperty('updateProducts')) {
        return {
          updateProducts: batch.updateProducts.map(product => ({
            style: product.style,
            color: product.color,
            properties: {
              ...product
            }
          }))
        }
      } else {
        return batch
      }
    }) */

    // *************************************************************
    // *************************************************************
    // Init update Collection on backend
    await dispatch(VUEX_API_COLLECTION_UPDATE, {
      collectionType: state.collectionLookupQuery.collectionType,
      collectionId: getters.collectionByIndex()._id,
      data: clonedBatchedRequests
    }).then(response => {
      commit(VUEX_COLLECTION_UPDATE)
    })
  }, UPDATE_DELAY),

  // ----------------------------------------------
  // ----------------------------------------------
  /* Modify Collection Products */
  // ----------------------------------------------
  // ----------------------------------------------

  [VUEX_COLLECTION_PRODUCT_REMOVE_PRODUCTS]: ({ dispatch }, payload) => {
    const products = payload.map(product => ({
      style: product.style,
      color: product.color,
      actionType: 'deleteProducts'
    }))

    dispatch(VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS_PROMPT, products)
  },

  // Update product property: selected
  [VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS_PROMPT]: ({ rootState, dispatch, commit }, payload) => {
    // GLOBAL ACTION PROMPT: Pass messages & dispatch action
    // first ask them if they want to do this.  This doesn't apply for Key Initiatives, since KIs already have it
    // also, don't do this in "browse" mode, since those are already added to assortment

    // grab some key values to determine whether to add product to assortment
    let orgType = rootState.assortments.assortment?.orgType ? rootState.assortments.assortment.orgType : rootState.assortmentsInternal?.query?._uiOptions?.assortmentsQuery?.orgType

    const isInternalAssortment = (orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL)
    const tabValue = router.currentRoute.value.query?._uiOptions?.frontendOptions?.controlBarTab
    const addProductToAssortment = (!isInternalAssortment && tabValue === 'search')
    const isSelected = (Array.isArray(payload)) ? payload[0].selected : payload.selected

    // set modified dispatch payload
    let dispatchPayload = {
      payload: payload,
      isInternalAssortment: isInternalAssortment,
      tabValue: tabValue,
      addProductToAssortment: addProductToAssortment
    }

    // now prompt if isSelected and adding to assortment
    if (isSelected && addProductToAssortment) {
      let actionObj = {
        checkChangedFields: false,
        title: 'Add to Collection?',
        description: 'In order to add to selections, please note this will also add the product to the assortment.',
        dispatchAction: VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS,
        dispatchActionObject: dispatchPayload
      }
      dispatch('VUEX_GLOBAL_ACTION_PROMPT_SHOW', { actionObj: actionObj })
    } else {
      dispatch(VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS, dispatchPayload)
    }
  },
  [VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS]: ({ rootState, dispatch, commit }, payload) => {
    const addProductToAssortment = payload.addProductToAssortment
    payload = payload.payload

    // IF: payload type is no an object or array: log error | bail
    if (typeof payload !== 'object') {
      console.error(VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS, 'payload.products need to be an [Array, Object]')
      return
    }

    // IF: payload is not an Array (is Object): wrap payload in array
    if (!Array.isArray(payload)) payload = [payload] || []

    const productsLookup = rootState.assortments.assortmentProductsLookup

    // Construct batched object payload
    let batchedObj = {}
    payload.forEach(product => {
      // Find index of product in state
      const pIdx = state.collections[0]?.products.findIndex(p => `${product.style}_${product.color}` === `${p.style}_${p.color}`)
      // If product exist in list: 'updateProduct' else 'addProducts'
      const actionType = product.actionType || ((product.actionType || pIdx > -1) ? 'updateProducts' : 'addProducts')

      // if this area requires adding to assortment, check
      if (addProductToAssortment) {
        if (actionType === 'addProducts' && !productsLookup[`${product.style}-${product.color}`]) {
          dispatch(VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT, {
            style: product.style,
            color: product.color
          })
        }
      }

      // IF: actionType is 'deleteProducts' but item doesn't exist in collection, bail
      if (actionType === 'deleteProducts' && pIdx === -1) return

      if (product.actionType) delete product.actionType

      // Add items in batched obj
      // If action type doesn't exist, create it
      if (!batchedObj[actionType]) batchedObj[actionType] = []
      // Add product object to batch payload based on action type
      batchedObj[actionType].push(product)
    })

    // Init batch update
    if (!Object.keys(batchedObj).length) return
    dispatch(VUEX_COLLECTION_PRODUCTS_BATCH_UPDATE, batchedObj)
  },

  // BATCH: Products update
  [VUEX_COLLECTION_PRODUCTS_BATCH_UPDATE]: async ({ getters, commit, dispatch }, payload) => {
    if (!getters.collectionByIndex()?._id) await dispatch(VUEX_COLLECTION_CREATE)
    await commit(VUEX_COLLECTION_PRODUCTS_BATCH_UPDATE, payload)
    dispatch(VUEX_COLLECTION_UPDATE)
  },

  [VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT]: async ({ commit, dispatch }, payload) => {
    await commit(VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT, payload)
    dispatch(VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT_REQUEST)
  },

  [VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT_REQUEST]: _debounce(async ({ rootState, state, dispatch, commit }) => {
    const assortment = rootState.assortments.assortment
    const clonedBatchedRequests = _clone(state.batchProductAddRequests)
    await commit(VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT_REQUEST)

    const payload = {
      '$or': clonedBatchedRequests,
      'locations.code': assortment.locationId,
      // 'locations.lineStatus': 'A',
      productType: 'Footwear',
      _options: {
        preserveNullAndEmptyArrays: false,
        totalCount: true,
        sort: { style: 1 },
        includeEcomData: true
        // skipHumanFormat: true,
        // productDataFormat: { withPricing: true }
      }
    }

    dispatch(VUEX_API_PRODUCTS_REQUEST_FETCH, payload).then(response => {
      const products = response.data[0].data

      if (products.length) {
        dispatch(VUEX_ASSORTMENT_GRID_ROWS_ADD, products)

        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'success',
            message: `${products.length} Item${products.length > 1 ? 's' : ''} added to assortment`
          }
        })
      }
    })
  }, UPDATE_DELAY)
}

const mutations = {
  [VUEX_COLLECTIONS_FETCH]: (state, data) => {
    // Fetch all Collections : NOT CURRENTLY USED
  },

  [VUEX_COLLECTION_FETCH]: (state, data) => {
    // Fetch a user Collection by ID : NOT CURRENTLY USED
  },

  [VUEX_COLLECTION_LOOKUP_QUERY_SET]: (state, data) => {
    state.collectionLookupQuery = data
  },

  [VUEX_COLLECTION_LOOKUP]: (state, data) => {
    state.collections = data
  },

  [VUEX_API_COLLECTION_CREATE]: (state, data) => {
    state.collections.push(data)
  },

  [VUEX_COLLECTION_CREATE]: (state, data) => {
    // Create a user Collection
  },

  [VUEX_COLLECTION_UPDATE]: (state, data) => {
    // Update a user Collection by ID
    // state.batchRequests = []
  },

  [VUEX_COLLECTION_PRODUCTS_BATCH_UPDATE]: (state, data) => {
    if (!state.collections[0]) state.collections[0] = { products: [] }

    // Update State
    Object.keys(data).forEach(key => {
      switch (key) {
        case 'addProducts' :
          // state.collection[0].products.push(...data[key])
          state.collections[0].products.push(...data[key])
          break
        case 'deleteProducts' :
          data[key].forEach(product => {
            const pIdx = state.collections[0].products.findIndex(p => `${product.style}_${product.color}` === `${p.style}_${p.color}`)
            state.collections[0].products.splice(pIdx, 1)
          })
          break
        case 'updateProducts' :
          data[key].forEach(product => {
            const pIdx = state.collections[0].products.findIndex(p => `${product.style}_${product.color}` === `${p.style}_${p.color}`)
            _merge(state.collections[0].products[pIdx], product)
          })
          break
      }
    })

    // Update request data
    state.batchRequests.push(data)
  },

  [VUEX_COLLECTIONS_CLEAR_BATCHED_REQUESTS]: state => {
    state.batchRequests = []
  },

  [VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT]: (state, data) => {
    state.batchProductAddRequests.push(data)
  },

  [VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT_REQUEST]: state => {
    state.batchProductAddRequests = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
