import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'
import router from '@/router'
import Debug from 'logdown'
import cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash.orderby'
const d = new Debug('its:molecules:description')
// import cloneDeep from 'lodash/cloneDeep'
let SampleInventory = {
  totalCount: 0,
  params: null,
  fetcher: function () {
    return {
      getRows (params) {
        // deselect all - paging breaks if cells are selected or open
        GridHelpers.deselectAll()

        SampleInventory.params = params
        SampleInventory.serverLookupReadyCheck()
      } // getRows
    } // return
  }, // fetcher

  /// / first loop through a readiness detector, since sometimes the site isn't loaded enough to check
  serverLookupReadyCheck () {
    if (router.currentRoute?.value?.meta?.modules?.toolbar?.title) {
      GridHelpers.mgThisArray[0].showMasterGridTogger = Math.random()
      if (SampleInventory) {
        SampleInventory.serverLookup()
      }
    } else {
      d.log('ServerSideDatasourceSampleInventory serverLookupReadyCheck')
    }
  },

  serverLookup () {
    let perPage = GridHelpers.mgThisArray[0].cacheBlockSize
    let params = SampleInventory.params

    // get sort state, then add random at end
    let sortState = cloneDeep(GridHelpers.mgThisArray[0].gridApi.getColumnState())
    let columnsSorted = []
    if (sortState) {
      columnsSorted = sortState.filter(x => x['sort'] !== null)
    }
    let sortObj = {}
    if (columnsSorted.length > 0) {
      columnsSorted = _orderBy(columnsSorted, ['sortIndex'], ['asc'])
      for (let i = 0; i < columnsSorted.length; i++) {
        let obj = columnsSorted[i]
        let dir = (obj.sort === 'asc') ? 1 : -1

        let colId = obj.colId
        sortObj[colId] = dir
      }
    } else {
      sortObj = {
        // name: 1
      }
    }

    // return total count - always do it because the count can change if we delete rows or move their status
    // let doTotalCount = (params.request.startRow === 0 || ServerSideDatasourceSampleInventory.totalCount === 0)
    let doTotalCount = true

    // MAIN FETCH
    let payload = {}
    /*
    payload._select = {
      nameTokens: 0,
      'files.filenameTokens': 0,
      'files.content': 0
    }
     */

    // payload._manage = true
    payload._options = {
      totalCount: doTotalCount, // if you set this, it makes response zero
      limit: perPage,
      skip: params.request.startRow,
      sort: sortObj,
      agGrid: {
        endRow: params.request.endRow,
        startRow: params.request.startRow
      }
    }

    // kill sort if no object
    if (Object.keys(sortObj).length === 0) {
      delete payload._options.sort
    }

    // ADD FILTERS
    let filters = ColumnHelpers.getAllFilters()
    for (let property in filters.activeFilters) {
      // tweak property for back end
      let propertyBackend = property

      // set property value
      if (filters.activeFilters.hasOwnProperty(property)) {
        let values = ''
        if (Array.isArray(filters.activeFilters[property])) {
          values = Object.values(filters.activeFilters[property])
          let index = values.indexOf('')
          if (index !== -1) {
            values[index] = 'null'
          }
        } else {
          values = filters.activeFilters[property]
        }
        payload[propertyBackend] = values
      }
    }

    // clean up bad stuff
    delete payload._uiOptions

    // add subtype
    payload.subtype = GridHelpers.mgThisArray[0].subtype

    // start lookup
    GridHelpers.mgThisArray[0].$store.dispatch('VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_START')
    GridHelpers.mgThisArray[0].$store.dispatch('VUEX_SAMPLE_INVENTORY_FETCH', payload).then(response => {
      if (params.parentNode?.beans?.context?.destroyed) {
        // check for destroyed -this issue has promising notes
        // https://github.com/ag-grid/ag-grid/issues/3334#issuecomment-584403287
      } else {
        // NOT DESTROYED
        if (response) {
          let resp = response[0] || response

          // set total count
          // if total count is there, grab it once
          // otherwise, the data is no longer nested, so change resp to be response
          if (resp.totalCount) {
            SampleInventory.totalCount = resp.totalCount
          } else {
            // resp = response
            SampleInventory.totalCount = 0
          }

          // set rows
          let rows = resp.data
          rows = DataMiddleware.convertSampleInventoryToRowData(rows)
          if (rows.length > 0) {
            // did we hit the last row?
            let endRow = payload._options.agGrid.endRow
            let startRow = payload._options.agGrid.startRow

            let lastRow = -1
            if (endRow < SampleInventory.totalCount) { // if more pages
              lastRow = SampleInventory.totalCount
            } else if (endRow >= SampleInventory.totalCount && startRow === 0) { // if first page is smaller that subsets
              lastRow = SampleInventory.totalCount
            }

            GridHelpers.mgThisArray[0].showMasterGridTogger = Math.random()
            params.success({
              rowData: rows,
              rowCount: lastRow
            })
          } else {
            SampleInventory.totalCount = 0
            GridHelpers.mgThisArray[0].showMasterGridTogger = Math.random()
            params.success({
              rowData: rows,
              rowCount: 0
            })
          }
        } else {
          SampleInventory.totalCount = 0
          GridHelpers.mgThisArray[0].showMasterGridTogger = Math.random()
          params.fail()
        }

        // end lookup
        GridHelpers.mgThisArray[0].$store.dispatch('VUEX_SAMPLE_INVENTORY_GRID_ROWDATA_LOADING_FINISH')
      } // DESTROYED
    }) // lookup
  } // gettrows2
}

export default SampleInventory
