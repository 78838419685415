import _get from 'lodash.get'
import _merge from 'lodash.merge'

import {
  requestOptionsData,
  VUEX_REQUEST_OPTIONS_INIT
} from '@/store/constants/config/requestOptions'

const state = {}
const getters = {}

const actions = {
  [VUEX_REQUEST_OPTIONS_INIT]: ({ rootState, commit }) => {
    commit(VUEX_REQUEST_OPTIONS_INIT, {
      state: rootState,
      data: requestOptionsData
    })
  }
}

const mutations = {
  VUEX_REQUEST_OPTIONS_INIT: (_, data) => {
    let rootState = data.state

    /*
     * Request options from requestOptionsData get automatically
     * injected into the state defined as the object key.
     */
    Object.keys(data.data).forEach(key => {
      _merge(_get(rootState, key), data.data[key])
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
