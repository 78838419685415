import api from '@/api'

import {
  VUEX_API_PACKAGE_CREATE,
  VUE_API_PACKAGE_EMAIL_CREATE,
  VUE_API_PACKAGE_EMAIL_DIVISION,
  VUE_API_PACKAGE_EMAIL_SPREADSHEET,
  VUE_API_PACKAGE_DOWNLOAD_SPREADSHEET,
  VUE_API_PACKAGE_DOWNLOAD_DIVISION,
  VUE_API_PACKAGE_DOWNLOAD_ASSORTMENT
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {

}

const getters = {}

const actions = {
  [VUEX_API_PACKAGE_CREATE]: async ({ dispatch, commit }, payload) => {
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = `${process.env.VUE_APP_API_BASE_URL}/packages/download`

    if (payload.filename) {
      const inputFilename = document.createElement('input')
      inputFilename.name = 'filename'
      inputFilename.value = payload.filename
      form.appendChild(inputFilename)
    }

    payload.files.forEach((file, index) => {
      if (file.type === 's3') {
        const inputKey = document.createElement('input')
        const inputBucket = document.createElement('input')
        const inputType = document.createElement('input')
        const inputS3Type = document.createElement('input')

        inputKey.name = `files[${index}][key]`
        inputKey.value = file.key
        inputBucket.name = `files[${index}][bucket]`
        inputBucket.value = file.bucket
        inputType.name = `files[${index}][type]`
        inputType.value = file.type
        inputS3Type.name = `files[${index}][s3Type]`
        inputS3Type.value = file.s3Type

        form.appendChild(inputKey)
        form.appendChild(inputBucket)
        form.appendChild(inputType)
        form.appendChild(inputS3Type)
      }

      if (file.type === 'photo') {
        const inputType = document.createElement('input')
        const inputStyle = document.createElement('input')
        const inputColor = document.createElement('input')
        const inputSmall = document.createElement('input')
        const inputHeroOnly = document.createElement('input')
        const inputProductType = document.createElement('input')

        inputType.name = `files[${index}][type]`
        inputType.value = file.type
        inputStyle.name = `files[${index}][style]`
        inputStyle.value = file.style
        inputColor.name = `files[${index}][color]`
        inputColor.value = file.color
        inputSmall.name = `files[${index}][small]`
        inputSmall.value = file.small
        inputHeroOnly.name = `files[${index}][heroOnly]`
        inputHeroOnly.value = file.heroOnly
        inputProductType.name = `files[${index}][productType]`
        inputProductType.value = file.productType

        form.appendChild(inputType)
        form.appendChild(inputStyle)
        form.appendChild(inputColor)
        form.appendChild(inputSmall)
        form.appendChild(inputHeroOnly)
        form.appendChild(inputProductType)
      }
    })

    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  },

  [VUE_API_PACKAGE_EMAIL_CREATE]: async ({ dispatch }, payload) => {
    return api.post(`/packages/mail`, payload, {
      params: {}
    }).then(response => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: 'Thank you. Your message has been sent.'
        }
      })
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    })
  },

  // Note: We may want to remove these methods and pass the specific mail path
  // into the generic package create method given that they are identical otherwise.

  [VUE_API_PACKAGE_EMAIL_DIVISION]: async ({ dispatch }, payload) => {
    return api.post(`/packages/mail/division`, payload, {
      params: {}
    }).then(response => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: 'Thank you. Your message has been sent.'
        }
      })
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    })
  },

  [VUE_API_PACKAGE_EMAIL_SPREADSHEET]: async ({ dispatch }, payload) => {
    return api.post(`/packages/mail/spreadsheet`, payload, {
      params: {}
    }).then(response => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: 'Thank you. Your message has been sent.'
        }
      })
    }).catch(err => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    })
  },

  [VUE_API_PACKAGE_DOWNLOAD_SPREADSHEET]: async ({ dispatch }, payload) => {
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = `${process.env.VUE_APP_API_BASE_URL}/packages/download/spreadsheet`

    if (payload.filename) {
      const inputFilename = document.createElement('input')
      inputFilename.name = 'filename'
      inputFilename.value = payload.filename
      form.appendChild(inputFilename)
    }

    const inputSmall = document.createElement('input')
    const inputHeroOnly = document.createElement('input')

    inputSmall.name = `[small]`
    inputSmall.value = payload.small
    inputHeroOnly.name = `[heroOnly]`
    inputHeroOnly.value = payload.heroOnly

    form.appendChild(inputSmall)
    form.appendChild(inputHeroOnly)

    payload.rows.forEach((file, index) => {
      const inputStyle = document.createElement('input')
      const inputColor = document.createElement('input')

      inputStyle.name = `rows[${index}][style]`
      inputStyle.value = file.style
      inputColor.name = `rows[${index}][color]`
      inputColor.value = file.color

      form.appendChild(inputStyle)
      form.appendChild(inputColor)
    })

    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  },

  [VUE_API_PACKAGE_DOWNLOAD_DIVISION]: async ({ dispatch }, payload) => {
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = `${process.env.VUE_APP_API_BASE_URL}/packages/download/division`

    if (payload.filename) {
      const inputFilename = document.createElement('input')
      inputFilename.name = 'filename'
      inputFilename.value = payload.filename
      form.appendChild(inputFilename)
    }

    const inputSmall = document.createElement('input')
    const inputHeroOnly = document.createElement('input')
    const inputDivision = document.createElement('input')

    inputSmall.name = `[small]`
    inputSmall.value = payload.small
    inputHeroOnly.name = `[heroOnly]`
    inputHeroOnly.value = payload.heroOnly
    inputDivision.name = `[division]`
    inputDivision.value = payload.division

    form.appendChild(inputSmall)
    form.appendChild(inputHeroOnly)
    form.appendChild(inputDivision)

    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  },

  [VUE_API_PACKAGE_DOWNLOAD_ASSORTMENT]: async ({ dispatch }, payload) => {
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = `${process.env.VUE_APP_API_BASE_URL}/packages/download/assortment`

    // filename
    if (payload.filename) {
      const inputFilename = document.createElement('input')
      inputFilename.name = 'filename'
      inputFilename.value = payload.filename
      form.appendChild(inputFilename)
    }

    // KIND
    let tmpInput = document.createElement('input')
    tmpInput.name = 'kind'
    tmpInput.value = 'AssortmentDocument'
    form.appendChild(tmpInput)

    // documentType
    tmpInput = document.createElement('input')
    tmpInput.name = 'documentType[type]'
    tmpInput.value = 'Zip'
    form.appendChild(tmpInput)

    tmpInput = document.createElement('input')
    tmpInput.name = 'documentType[subType]'
    tmpInput.value = 'Standard'
    form.appendChild(tmpInput)

    // assortmentsQuery
    /* tmpInput = document.createElement('input')
    tmpInput.name = 'assortmentsQuery[published]'
    tmpInput.value = true
    form.appendChild(tmpInput) */

    tmpInput = document.createElement('input')
    tmpInput.name = 'assortmentsQuery[orgType]'
    tmpInput.value = payload.orgType
    form.appendChild(tmpInput)

    tmpInput = document.createElement('input')
    tmpInput.name = 'assortmentsQuery[channel]'
    tmpInput.value = payload.channel
    form.appendChild(tmpInput)

    tmpInput = document.createElement('input')
    tmpInput.name = 'assortmentsQuery[season]'
    tmpInput.value = payload.season
    form.appendChild(tmpInput)

    tmpInput = document.createElement('input')
    tmpInput.name = 'assortmentsQuery[year]'
    tmpInput.value = payload.year
    form.appendChild(tmpInput)

    tmpInput = document.createElement('input')
    tmpInput.name = 'assortmentsQuery[region]'
    tmpInput.value = payload.region
    form.appendChild(tmpInput)

    if (payload.state) {
      tmpInput = document.createElement('input')
      tmpInput.name = 'assortmentsQuery[state]'
      tmpInput.value = payload.state
      form.appendChild(tmpInput)
    }

    // settings
    tmpInput = document.createElement('input')
    tmpInput.name = 'settings[heroOnly]'
    tmpInput.value = true
    form.appendChild(tmpInput)

    tmpInput = document.createElement('input')
    tmpInput.name = 'settings[small]'
    tmpInput.value = !payload.printResolution
    form.appendChild(tmpInput)

    if (payload.collectionsQueryId) {
      // collectionsQuery filtering
      tmpInput = document.createElement('input')
      tmpInput.name = 'settings[productFilteringQuery][_options][collectionsQuery][_id]'
      tmpInput.value = payload.collectionsQueryId
      form.appendChild(tmpInput)
    } else {
      // division filtering

      payload.titles.forEach((item, index) => {
        tmpInput = document.createElement('input')
        tmpInput.name = `assortmentsQuery[$or][${index}][title]`
        tmpInput.value = item.title
        form.appendChild(tmpInput)

        tmpInput = document.createElement('input')
        tmpInput.name = `assortmentsQuery[$or][${index}][gender]`
        tmpInput.value = item.gender
        form.appendChild(tmpInput)
      })
    }

    const inputSmall = document.createElement('input')

    form.appendChild(inputSmall)

    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  }
}

const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations
}
