import store from '@/store/store'
import checkUserPermissions from '@/mixins/checkUserPermissions'

export default (to, from, next, options) => {
  // Deconstruct options
  const { authenticate, permissions, callback } = options
  // Deconstruct meta data
  const { authenticate: metaAuthenticate } = to?.meta

  /* ------- AUTHENTICATION ------- */
  // Is auth needed: set base on _to_ route meta.bypassAuth or auth from options
  // Authenticate from meta obj takes precedence
  const toRouteAuth = typeof metaAuthenticate === 'boolean'
  ? metaAuthenticate : authenticate

  // Check permission/role for routes
  let hasPermsAccess = true
  if (permissions) {
    hasPermsAccess = checkUserPermissions(permissions, store).hasPerm
  }

  // If needs authentication and user not logged in
  if ((toRouteAuth && !store.state.user.user) || !hasPermsAccess) {
    // Route to logged out default route
    store.dispatch('VUEX_ROUTING_ROUTE', { name: ITS__ROUTING__DEFAULT__LOGGED_IN })
    return
  }

  /* ------------------------------ */

  // DO MORE HERE

  /* ------- CALLBACK || NEXT ------- */
  // Finally trigger callback or next function
  if (callback) callback(next)
  else next()
  /* ------------------------------ */
}
