const groupProductsByProperty = (products, property, additionalProps = {}) => {
  if (!property) return products

  let groups = []
  let groupsMem = {}
  let idx = 0

  products.forEach(p => {
    const propValue = p[property]

    const productData = {
      ...p,
      ...additionalProps, // add additions props to data
      isDropped: !p.createdDate
    }

    if (!groupsMem.hasOwnProperty(propValue)) {
      groupsMem[propValue] = idx
      groups.push([productData])
      ++idx
    } else {
      groups[groupsMem[propValue]].push(productData)
    }
  })

  return groups
}

const locationToRegion = location => {
  if (!location) return 'International'

  switch (location) {
    case 'EU' :
      return 'Europe'

    default :
      return 'International'
  }
}

const regionToLocation = region => {
  if (!region) return 'US'

  switch (region) {
    case 'Europe' :
      return 'EU'

    default :
      return 'US'
  }
}

export {
  groupProductsByProperty,
  locationToRegion,
  regionToLocation
}
