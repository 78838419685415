export const VUEX_COLLECTIONS_FETCH = 'VUEX_COLLECTIONS_FETCH'
export const VUEX_COLLECTION_FETCH = 'VUEX_COLLECTION_FETCH'

export const VUEX_COLLECTION_LOOKUP = 'VUEX_COLLECTION_LOOKUP'
export const VUEX_COLLECTION_LOOKUP_QUERY_SET = 'VUEX_COLLECTION_LOOKUP_QUERY_SET'
export const VUEX_COLLECTION_CREATE = 'VUEX_COLLECTION_CREATE'
export const VUEX_COLLECTION_UPDATE = 'VUEX_COLLECTION_UPDATE'
export const VUEX_COLLECTIONS_CLEAR_BATCHED_REQUESTS = 'VUEX_COLLECTIONS_CLEAR_BATCHED_REQUESTS'

export const VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS_PROMPT = 'VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS_PROMPT'
export const VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS = 'VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS'
export const VUEX_COLLECTION_PRODUCT_REMOVE_PRODUCTS = 'VUEX_COLLECTION_PRODUCT_REMOVE_PRODUCTS'
export const VUEX_COLLECTION_PRODUCTS_BATCH_UPDATE = 'VUEX_COLLECTION_PRODUCTS_BATCH_UPDATE'

export const VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT = 'VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT'
export const VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT_REQUEST = 'VUEX_COLLECTION_PRODUCT_ADD_TO_ASSORTMENT_REQUEST'
