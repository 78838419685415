// SALES Libraries
export const VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP = 'VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP'
export const VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP_LANDING = 'VUEX_ROUTING_ENTER_SALES_LIBRARIES_STEP_LANDING'

// SALES Libraries Manage
export const VUEX_ROUTING_ENTER_SALES_MANAGE_PENDING = 'VUEX_ROUTING_ENTER_SALES_MANAGE_PENDING'
export const VUEX_ROUTING_ENTER_SALES_MANAGE_PUBLISHED = 'VUEX_ROUTING_ENTER_SALES_MANAGE_PUBLISHED'
export const VUEX_ROUTING_ENTER_SALES_MANAGE_UNPUBLISHED = 'VUEX_ROUTING_ENTER_SALES_MANAGE_UNPUBLISHED'
export const VUEX_ROUTING_ENTER_SALES_MANAGE_UPLOAD = 'VUEX_ROUTING_ENTER_SALES_MANAGE_UPLOAD'
export const VUEX_ROUTING_ENTER_SALES_MANAGE_DELETED = 'VUEX_ROUTING_ENTER_SALES_MANAGE_DELETED'
