import _debounce from 'lodash.debounce'
import _get from 'lodash.get'
import _cloneDeep from 'lodash.clonedeep'

import dotize from 'dotize'

import queryCtrl from '@/utils/query'
import chipDataBuilder from '@/components/_core/FilterBar/helpers/chipDataBuilder'

import store from '@/store/store'
import router from '@/router'

import { getUserPOPColors } from '@/helpers/pop_colors'
import feConstants from '@/store/constants/config/fe_constants'

import {
  VUEX_FILTERBAR_QUERYUPDATE_QUERY_CLEAR,
  VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY,
  VUEX_FILTERBAR_QUERY_CONSTRUCT_CHIPS_FROM_QUERY,
  VUEX_FILTERBAR_QUERYUPDATE_SHARE_LINK_SAVE_ANNOUNCEMENT,
  VUEX_FILTERBAR_QUERYUPDATE_SHARE_LINK
} from '@/store/constants/ui/filterBar/filterBar_QueryUpdate'

import {
  VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET
} from '@/store/constants/ui/filterBar/filterBar_ItemsDisplay'

import {
  VUEX_FILTERBAR_SELECTOR_ADD_PROP
} from '@/store/constants/ui/filterBar/filterBar_Selectors'

import {
  VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY,
  VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL
} from '@/store/constants/ui/filterBar'

import {
  VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART,
  VUEX_API_MAIL_SEND
} from '@/store/constants/api'

import {
  VUEX_PHOTOS_FETCH
} from '@/store/constants/models/photos'

import {
  VUEX_ASSETS_FETCH
} from '@/store/constants/models/assets'

import {
  VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH
} from '@/store/constants/models/assortments/assortmentProducts'

import {
  VUEX_ROUTING_ROUTE_UPDATE
} from '@/store/constants/routing'

let editorObj = {
  accessRequires: [
    {
      permission: feConstants.ITS__PERMISSION__PRODUCT__INTERNAL_ASSORTMENTS,
      roles: [ feConstants.ITS__ROLE__USER, feConstants.ITS__ROLE__ADMIN_EDITOR ],
      infoKinds: [
        {
          InternalAssortmentsData: {
            subRoles: {
              region: [ feConstants.ITS__REGION__ALL ],
              channel: [ feConstants.ITS__ASSORTMENTS__CHANNEL_TYPE__ALL ],
              productType: [ feConstants.ITS__PRODUCT_TYPE__ALL ],
              role: [ feConstants.ITS__ROLE__EDITOR, feConstants.ITS__ROLE__SENIOR_EDITOR ]
            }
          }
        }
      ]
    }
  ]
}

const state = {
  query: {}
}

const getters = {}

const actions = {
  [VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY]: _debounce(async ({ state, dispatch, commit }) => {
    await commit(VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY)

    setTimeout(async () => {
      await dispatch(VUEX_ROUTING_ROUTE_UPDATE, null)
      await dispatch(VUEX_ROUTING_ROUTE_UPDATE, state.query)

      switch (router.currentRoute.value.name) {
        case 'product--libraries--products' :
          dispatch(VUEX_PHOTOS_FETCH, state.query)
          break

        case 'advertising--libraries--assets':
        case 'advertising--libraries--logos':
        case 'global-retail--libraries--assets':
        case 'global-retail--libraries--resources':
        case 'product--libraries--documents':
          dispatch(VUEX_ASSETS_FETCH, state.query)
          break

        case 'product--libraries--assortments-internal--international-wholesale':
        case 'product--libraries--assortments-internal--domestic-wholesale':
        case 'product--libraries--assortments-internal--international-retail':
        case 'product--libraries--assortments-internal--domestic-retail':
        case 'assortment-showroom':
          dispatch(VUEX_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH, state.query)
          break

        default :
          /* console.error(`Vuex:
          ui > filterbar > filterBar_QueryUpdate.js |
          VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY |
          route not configured: "${router.currentRoute.value.name}"`) */
      }
    }, 500)
  }, 200),

  [VUEX_FILTERBAR_QUERY_CONSTRUCT_CHIPS_FROM_QUERY]: async ({ dispatch, commit }, payload) => {
    await dispatch(VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL)

    // Get FilterBar selectors data from query
    let selectorState = store.state.filterBarSelectors.selectorProps
    let selectorKeys = Object.keys(selectorState)

    // Loop over selector data and add to selectors where applicable
    if (selectorKeys.length) {
      selectorKeys.forEach(selectorKey => {
        if (_get(payload, selectorKey)) commit(VUEX_FILTERBAR_SELECTOR_ADD_PROP, { [selectorKey]: _get(payload, selectorKey) })
      })
    }

    const dotized = dotize.convert(payload) || {}
    Object.entries(dotized).forEach(async ([key, value]) => {
      const chipData = chipDataBuilder(key, value, dotized)

      let itemsDisplayState = store.state.filterBarItemsDisplay
      let itemsDisplayKeys = Object.keys(itemsDisplayState)

      if (itemsDisplayKeys.length) {
        itemsDisplayKeys.forEach(displayKey => {
          if (key === displayKey || key === `_${displayKey}`) dispatch(VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET, value)
        })
      }

      await dispatch(VUEX_FILTERBAR_PARAMETER_ADD_FROM_QUERY, chipData)
    })
  },

  [VUEX_FILTERBAR_QUERYUPDATE_SHARE_LINK_SAVE_ANNOUNCEMENT]: ({ dispatch }, payload) => {
    let path = router.currentRoute.value.fullPath

    dispatch(VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART, {
      ...payload,
      query: state.query,
      queryPath: path
    })
  },

  [VUEX_FILTERBAR_QUERYUPDATE_SHARE_LINK]: ({ dispatch }, payload) => {
    let link = router.currentRoute.value.fullPath

    dispatch(VUEX_API_MAIL_SEND, {
      recipients: payload.recipients,
      bccRecipients: payload.bccRecipients,
      subject: 'Its the S - A search has been shared with you',
      body: payload.message,
      textLink: 'Click here to see the search results.',
      path: link
    })
  },

  [VUEX_FILTERBAR_PARAMETERS_REMOVE_ALL]: ({ commit }) => {
    commit(VUEX_FILTERBAR_QUERYUPDATE_QUERY_CLEAR)
  }
}

const mutations = {
  [VUEX_FILTERBAR_QUERYUPDATE_QUERY_CLEAR]: state => {
    state.query = {}
  },

  [VUEX_FILTERBAR_QUERYUPDATE_UPDATE_QUERY]: state => {
    /* const isEditor = app.checkUserPermissions({
      accessRequires: [
        {
          permission: ITS__PERMISSION__PRODUCT__INTERNAL_ASSORTMENTS,
          roles: [ ITS__ROLE__USER, ITS__ROLE__ADMIN_EDITOR ],
          infoKinds: [
            {
              InternalAssortmentsData: {
                subRoles: { role: [ ITS__ROLE__EDITOR, ITS__ROLE__SENIOR_EDITOR ] }
              }
            }
          ]
        }
      ]
    }, app).hasPerm */

    let routeName = router.currentRoute.value.name
    let query = {}

    store.state.filterBar.modules.forEach(m => {
      m.filterGroups.forEach(fg => {
        fg.filters.forEach(f => {
          f.parameters.forEach(p => {
            let param = typeof p.value === 'string' ? p.value.replace('&', '%26') : p.value // replace chips values with ampersands with URI encoded value
            let encodedParam = encodeURIComponent(param) // encode chip values (used for ; in Features) // was encodeURIComponent(decodeURIComponent(param))
            let evalParam = p.key === 'features' ? encodedParam : param // only use encoded param for 'features' key

            if (f.payloadType && f.payloadType === 'object') {
              Object.assign(query, { [p.key]: evalParam })
            } else if (f.payloadType && f.payloadType === 'string') {
              query[p.key] = evalParam
            } else {
              if (!query[p.key]) query[p.key] = []
              query[p.key].push(evalParam)
            }
          })
        })
      })
    })

    // Bail if no chips applied
    if (Object.keys(query).length === 0) {
      state.query = null
      return
    }

    // Apply sortOrder from sortOrder module
    // Object.assign(query, { _sortOrder: store.state.filterBarItemsDisplay.sortOrder })

    // Add `encodedOptions` per route
    // Some route do not need them
    switch (routeName) {
      // Global Retail
      case 'global-retail--libraries--assets' :
      case 'global-retail--libraries--resources' :
        Object.assign(query, {
          '_select': queryCtrl.encodeQueryParams({ nameTokens: 0, 'files.filenameTokens': 0, 'files.content': 0 }),
          'status': 'Published',
          'libraryType': ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
          'librarySubType': ITS__LIBRARIES__SUB_TYPE__VISUAL_ASSET,
          '_sortOrder': store.state.filterBarItemsDisplay.sortOrder
        })

        if (routeName === 'global-retail--libraries--assets') {
          Object.assign(query, { 'librarySubType': ITS__LIBRARIES__SUB_TYPE__VISUAL_ASSET })
        } else if (routeName === 'global-retail--libraries--resources') {
          Object.assign(query, { 'librarySubType': ITS__LIBRARIES__SUB_TYPE__RESOURCE })
        }
        break

      // Product | documents
      case 'product--libraries--documents' :
        Object.assign(query, {
          '_select': queryCtrl.encodeQueryParams({ nameTokens: 0, 'files.filenameTokens': 0, 'files.content': 0 }),
          'status': 'Published',
          'libraryType': ITS__LIBRARIES__LIBRARY_TYPE__PRODUCT__DOCUMENT,
          '_sortOrder': store.state.filterBarItemsDisplay.sortOrder
        })

        break

      // Advertising | Assets
      case 'advertising--libraries--assets' :
        Object.assign(query, {
          '_select': queryCtrl.encodeQueryParams({ nameTokens: 0, 'files.filenameTokens': 0, 'files.content': 0 }),
          'libraryType': ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__ASSET,
          'status': 'Published',
          '_sortOrder': store.state.filterBarItemsDisplay.sortOrder
        })

        break

      // Advertising | Logos
      case 'advertising--libraries--logos' :
        Object.assign(query, {
          '_select': queryCtrl.encodeQueryParams({ nameTokens: 0, 'files.filenameTokens': 0, 'files.content': 0 }),
          'libraryType': ITS__LIBRARIES__LIBRARY_TYPE__ADVERTISING__LOGO,
          'status': 'Published',
          '_sortOrder': store.state.filterBarItemsDisplay.sortOrder
        })

        break

      case 'product--libraries--products' :
        // Add FilterBar `selectorProps` from filterBar_Selectors module
        Object.assign(query, store.state.filterBarSelectors.selectorProps)
        // Apply sortOrder from sortOrder module
        Object.assign(query, { _sortOrder: store.state.filterBarItemsDisplay.sortOrder })

        // Add popColors to options
        if (store.state.filterBarSelectors.selectorProps?.productType === ITS__PRODUCT_TYPE__POP) {
          const location = query['locations.code']
          const status = query['locations.lineStatus']

          let popColors = getUserPOPColors(location, status)
          Object.assign(query, { '_options': { popColors: popColors } })
        }
        break

      case 'product--libraries--assortments-internal--international-wholesale' :
      case 'product--libraries--assortments-internal--domestic-wholesale' :
      case 'product--libraries--assortments-internal--international-retail' :
      case 'product--libraries--assortments-internal--domestic-retail' :
        let internalAssortmentData = JSON.parse(JSON.stringify(_cloneDeep(store.getters.getFullinternalAssortmentsQuery()), (key, value) => {
          return (value === null ? undefined : value)
        }))

        if (internalAssortmentData._uiOptions.assortmentsQuery.title) delete internalAssortmentData._uiOptions.assortmentsQuery.title
        if (internalAssortmentData._uiOptions.assortmentsQuery.gender) delete internalAssortmentData._uiOptions.assortmentsQuery.gender

        // ------------------------------------------------
        // User dependent 'state'
        const queryChannel = internalAssortmentData._uiOptions.assortmentsQuery.channel
        const queryRegion = internalAssortmentData._uiOptions.assortmentsQuery.region
        const queryProductType = internalAssortmentData._uiOptions.assortmentsQuery.productType

        if (queryChannel) editorObj.accessRequires[0].infoKinds[0].InternalAssortmentsData.subRoles.channel.push(queryChannel)
        if (queryRegion) editorObj.accessRequires[0].infoKinds[0].InternalAssortmentsData.subRoles.region.push(queryRegion)
        if (queryProductType) editorObj.accessRequires[0].infoKinds[0].InternalAssortmentsData.subRoles.productType.push(queryProductType)

        internalAssortmentData._uiOptions.assortmentsQuery.state = ITS__ASSORTMENTS__STATE__PUBLISHED

        /* if (!app._instance.ctx.checkUserPermissions(editorObj, app._instance.ctx).hasPerm || store.state.categoryBrowser.actions.showUnpublished) {
        internalAssortmentData._uiOptions.assortmentsQuery.state = ITS__ASSORTMENTS__STATE__PUBLISHED
        } */
        // ------------------------------------------------

        internalAssortmentData._uiOptions.sort = store.state.filterBarItemsDisplay.kiSort
        internalAssortmentData._options.styleLevel = { colorSort: { rand: 1 } }

        Object.assign(query, internalAssortmentData)
        break

      case 'assortment-showroom' :
        let assortment = store.state.assortments.assortment
        let assortmentShowroomOptionsData = _cloneDeep(store.getters.getAssortmentShowroomOptions('search'))
        assortmentShowroomOptionsData._uiOptions.sort = store.state.filterBarItemsDisplay.kiSort

        let data = {
          'locations.code': assortment.locationId,
          'locations.lineStatus': assortment.status,
          'productType': assortment.productType,
          ...assortmentShowroomOptionsData
        }

        Object.assign(query, data)
        break

      // No options needed
      default :
        //
        break
    }

    state.query = query
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
