import axios from 'axios'
import Oboe from 'oboe'

import api from '@/api'
import queryCtrl from '@/utils/query'

import {
  VUEX_API_ASSETS_FETCH,
  VUEX_API_ASSETS_FETCH_STREAMING,

  VUEX_API_ASSETS_FETCH_FAILED,
  VUEX_API_ASSETS_FETCH_SUCCESS,

  VUEX_API_ASSETS_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

let oboeInstance
const BUFFER_SIZE = 20
const ACTIVE_BUFFER_SIZE = 20

const state = {
  assetsRequest: null,
  assetsCancelRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_ASSETS_FETCH]: async ({ commit, dispatch }, payload) => {
    if (state.assetsCancelRequest) state.assetsCancelRequest.cancel()
    if (!Object.keys(payload).length) return // payload = {}

    await commit(VUEX_API_ASSETS_SET_CANCEL_TOKEN, 'assetsCancelRequest')
    await commit(VUEX_API_ASSETS_FETCH)

    // return api request
    return api.get(`/assets${'?' + queryCtrl.serverQueryStr(payload)}`, {
      cancelToken: state.assetsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSETS_FETCH_SUCCESS)
      return response
    }).catch(err => {
      if (axios.isCancel(err)) {
        // Handle cancel request
      } else {
        commit(VUEX_API_ASSETS_FETCH_FAILED)

        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/assets' - ${err.response && err.response.data.message}`
          }
        })

        return err
      }
    })
  },

  [VUEX_API_ASSETS_FETCH_STREAMING]: async ({ state, dispatch, commit }, payload) => {
    if (oboeInstance) {
      oboeInstance.abort()
      oboeInstance = null
    }

    let itemCount = 0
    let itemRows = 0
    let totalItems = 0
    let buffer = []

    let queryStr = queryCtrl.qsStringify(payload.query, { arrayFormat: 'indices' })

    Oboe({
      method: 'Get',
      url: process.env.VUE_APP_API_BASE_URL + 'assets?' + queryStr,
      withCredentials: true
    }).on('start', function () {
      payload.callback({ oboe: this })
    }).node('totalCount', function (totalCount) {
      payload.callback({ totalCount: totalCount })
    }).node('row', function (row) {
      if (state.assetsRequest !== ITS__API__REQUEST_TYPE__PENDING) {
        this.abort()
        return
      }

      const item = Object.freeze(row)
      itemCount++

      if (itemRows > ACTIVE_BUFFER_SIZE) {
        buffer.push(item)

        if (buffer.length === BUFFER_SIZE || itemCount === itemRows) {
          payload.callback({ items: buffer })
          buffer.push(item)
        }
      } else {
        payload.callback({ items: [item] })
      }

      return Oboe.drop
    }).on('done', function () {
      if (itemCount === totalItems) {
        commit(VUEX_API_ASSETS_FETCH_SUCCESS)
      }
    }).on('fail', function () {
      commit(VUEX_API_ASSETS_FETCH_FAILED)
    })
  }
}

const mutations = {
  [VUEX_API_ASSETS_FETCH]: state => {
    state.assetsRequest = ITS__API__REQUEST_TYPE__PENDING
  },

  [VUEX_API_ASSETS_FETCH_SUCCESS]: state => {
    state.assetsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },

  [VUEX_API_ASSETS_FETCH_FAILED]: state => {
    state.assetsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_ASSETS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
