import axios from 'axios'
import api from '@/api'

import ApiValidator from '@/classes/ApiValidator'

import {
  VUEX_API_ASSORTMENTS_REQUEST_FETCH,
  VUEX_API_ASSORTMENTS_REQUEST_SUCCESS,
  VUEX_API_ASSORTMENTS_REQUEST_FAILED,

  VUEX_API_ASSORTMENT_REQUEST_FETCH,
  VUEX_API_ASSORTMENT_REQUEST_SUCCESS,
  VUEX_API_ASSORTMENT_REQUEST_FAILED,

  VUEX_API_ASSORTMENT_CREATE,
  VUEX_API_ASSORTMENT_UPDATE,
  VUEX_API_ASSORTMENT_UPDATE_BATCH,
  VUEX_API_ASSORTMENT_UPDATE_MERGE,
  VUEX_API_ASSORTMENT_DUPLICATE,
  VUEX_API_ASSORTMENT_DELETE,

  VUEX_API_ASSORTMENT_CREATE_FROM_KI_EXPORT,

  VUEX_API_ASSORTMENT_PRODUCTS_FETCH,
  VUEX_API_ASSORTMENT_PRODUCTS_UPDATE,
  VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS,

  VUEX_API_ASSORTMENT_PRODUCTS_RENAME,

  VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD,
  VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD_CANCEL,

  VUEX_API_ASSORTMENTS_INTERNAL_TABLE_OF_CONTENTS_FETCH,

  VUEX_API_ASSORTMENTS_INTERNAL_REQUEST_FETCH,
  // VUEX_API_INTERNAL_ASSORTMENTS_REQUEST_SUCCESS,
  // VUEX_API_INTERNAL_ASSORTMENTS_REQUEST_FAILED,

  VUEX_API_ASSORTMENT_INTERNAL_REQUEST_FETCH,
  VUEX_API_ASSORTMENT_INTERNAL_LOCKER,
  VUEX_API_ASSORTMENT_INTERNAL_PRODUCTS_REQUEST_FETCH,

  VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN

} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

import router from '@/router'

const state = {
  // Assortments
  assortmentsRequest: null,
  assortmentsCancelRequest: null,
  // Assortment
  assortmentRequest: null,
  assortmentCancelRequest: null,
  assortmentDownloadPackageCancelRequest: null,
  assortmentProductUpdate: false
}

const CACHED_TOC = {}

const getters = {}

const actions = {
  // ***************************************
  // Internal Assortments
  // ***************************************

  // Fetch all Assortments
  [VUEX_API_ASSORTMENTS_REQUEST_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    if (!payload.params) payload.params = {}

    await commit(VUEX_API_ASSORTMENTS_REQUEST_FETCH)

    if (state.assortmentsCancelRequest) state.assortmentsCancelRequest.cancel()
    await commit(VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN, 'assortmentsCancelRequest')

    // compose options payload
    return api.get(`/assortments`, {
      params: payload.params,
      cancelToken: state.assortmentsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSORTMENTS_REQUEST_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ASSORTMENTS_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/assortments' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Fetch a single Assortment
  [VUEX_API_ASSORTMENT_REQUEST_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    if (!payload.params) payload.params = {}

    const newPayload = {
      _options: payload._options
    }

    await commit(VUEX_API_ASSORTMENT_REQUEST_FETCH)

    if (state.assortmentsCancelRequest) state.assortmentsCancelRequest.cancel()
    await commit(VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN, 'assortmentCancelRequest')

    return api.get(`/assortments/${payload.id}`, {
      params: newPayload,
      cancelToken: state.assortmentCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSORTMENT_REQUEST_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ASSORTMENT_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Create an Assortment from KI Export
  [VUEX_API_ASSORTMENT_CREATE_FROM_KI_EXPORT]: async ({ commit, dispatch }, payload) => {
    const apiValidator = new ApiValidator('assortment')
    const validatedPayload = apiValidator.validate(payload.data)

    if (!payload) return
    if (!payload.params) payload.params = {}

    let res = null
    await api.post(`/internalAssortments/products/query/createAssortment`, validatedPayload, {
      params: { ...payload.params }
    }).then(response => {
      res = response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `Cannot create an assortment - ${err.response && err.response.data.message}`
        }
      })
    })
    return res
  },

  // Create an Assortment
  [VUEX_API_ASSORTMENT_CREATE]: ({ dispatch }, payload) => {
    if (!payload) return
    if (!payload.params) payload.params = {}

    const apiValidator = new ApiValidator('assortment')
    const validatedPayload = apiValidator.validate(payload.data)

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'internalAssortments' : 'assortments'

    return api.post(`/${pathSlug}`, validatedPayload, {
      params: { ...payload.params }
    }).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `Cannot create an assortment - ${err.response && err.response.data.message}`
        }
      })
    })
  },

  // Update an Assortment
  [VUEX_API_ASSORTMENT_UPDATE]: ({ dispatch }, payload) => {
    const apiValidator = new ApiValidator('assortmentUpdate')
    const validatedPayload = apiValidator.validate(payload.properties)

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'internalAssortments' : 'assortments'

    return api.patch(`/${pathSlug}/${payload.id}`, validatedPayload, {
      params: {}
    }).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
    })
  },

  [VUEX_API_ASSORTMENT_UPDATE_BATCH]: ({ dispatch }, payload) => {
    // Validate payload
    const apiValidator = new ApiValidator('assortmentProductsBatch')
    const validatedPayload = apiValidator.validate(payload.properties)

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'internalAssortments' : 'assortments'

    return api.patch(`/${pathSlug}/batch/${payload.id}`, validatedPayload, {
      params: {}
    }).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
    })
  },

  // TODO: Is this used?
  [VUEX_API_ASSORTMENT_UPDATE_MERGE]: ({ commit, dispatch }, payload) => {
    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'internalAssortments' : 'assortments'

    return api.patch(`/${pathSlug}/${payload.id}/merge`, payload.properties, {
      params: {}
    }).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
    })
  },

  // Duplicate an Assortment
  [VUEX_API_ASSORTMENT_DUPLICATE]: ({ commit, dispatch }, payload) => {
    // Validate payload
    const apiValidator = new ApiValidator('assortmentClone')
    const validatedPayload = apiValidator.validate(payload)

    const url = `/assortments/cloneMany`
    return api.post(url, validatedPayload)
      .then(response => {
        return {
          ...response,
          status: 'success'
        }
      }).catch(err => {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: err.response.data.message || `Cannot update '/api/assortments/clone/${payload.id}'`
          }
        })

        return {
          ...err,
          status: 'error'
        }
      })
  },

  // Delete an Assortment
  [VUEX_API_ASSORTMENT_DELETE]: async ({ commit, dispatch }, payload) => {
    if (!payload) return
    if (!payload.params) payload.params = {}

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'internalAssortments' : 'assortments'

    return api.delete(`/${pathSlug}/${payload.id}`, {
      params: payload.params
    }).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
    })
  },

  // TODO: is this used?
  [VUEX_API_ASSORTMENT_PRODUCTS_FETCH]: ({ commit, dispatch }, payload) => {
    const apiValidator = new ApiValidator('assortmentProductsFetch')
    const validatedPayload = apiValidator.validate(payload)

    commit(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS, false)

    return api.post(`/assortments/products/query`, validatedPayload).then(response => {
      commit(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS, true)
      return response
    }).catch((err) => {
      commit(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS, false)

      let dispatchObject = {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error'
        }
      }

      // for user lock error, add a longer timeout and give them additional refresh text
      let msgAddition = ``
      if (err.response?.data?.errorType === 'userLock') {
        dispatchObject.timeout = 5000
        msgAddition = `Your changes were not saved.  The underlying data might be different than how you see it. Click <a href=''>here</a> to reload the page.`
      }

      // set message with addition
      dispatchObject.data.message = `${err.response?.data?.message}.${msgAddition}`

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, dispatchObject)
    })
  },

  // Update Assortment Product(s)
  [VUEX_API_ASSORTMENT_PRODUCTS_UPDATE]: ({ commit, dispatch }, payload) => {
    // Validate payload
    // TODO - 2/23/2024 - temporary comment out until we can fix this properly for ticket ITSFD-2487-ki-europe-sample-and-rush-sam
    // const apiValidator = new ApiValidator('assortmentProductsBatch')
    // const validatedPayload = apiValidator.validate(payload.data)
    const validatedPayload = payload.data

    commit(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS, false)

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'internalAssortments' : 'assortments'

    return api.patch(`/${pathSlug}/batch/${payload.id}`, validatedPayload).then(response => {
      commit(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS, true)
      return response
    }).catch((err) => {
      commit(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS, false)

      let dispatchObject = {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error'
        }
      }

      // for user lock error, add a longer timeout and give them additional refresh text
      let msgAddition = ``
      if (err.response?.data?.errorType === 'userLock') {
        dispatchObject.timeout = 5000
        msgAddition = `Your changes were not saved.  The underlying data might be different than how you see it. Click <a href=''>here</a> to reload the page.`
      }

      // set message with addition
      dispatchObject.data.message = `${err.response?.data?.message}.${msgAddition}`

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, dispatchObject)
    })
  },

  // TODO: is this used?
  [VUEX_API_ASSORTMENT_PRODUCTS_RENAME]: async ({ dispatch }, payload) => {
    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'internalAssortments' : 'assortments'

    return api.patch(`/${pathSlug}/batch/${payload.id}`, payload.data).then(response => {
      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
      return err.response
    })
  },

  [VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD]: async ({ commit, dispatch }, payload) => {
    if (state.assortmentDownloadPackageCancelRequest) state.assortmentDownloadPackageCancelRequest.cancel()
    await commit(VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN, 'assortmentDownloadPackageCancelRequest')

    let pathSlug = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) ? 'internalAssortments' : 'assortments'

    return api.get(`/${pathSlug}/zip/images/${payload.request_data.small}/${payload.request_data.heroOnly}/${payload.id}`, {
      responseType: 'arraybuffer',
      cancelToken: state.assortmentDownloadPackageCancelRequest.token
    }).then(response => {
      return {
        ...payload,
        status: ITS__FILE__PROCESSING__STATUS__SUCCESS,
        data: response
      }
    }).catch(err => {
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: err.message ? 'success' : 'error',
            message: err.message || (err.response && err.response.data.message)
          }
        })

        if (err.message === 'Your ZIP download has been cancelled') {
          return {
            ...payload,
            status: ITS__FILE__PROCESSING__STATUS__CANCELLED,
            error: err
          }
        } else {
          return {
            ...payload,
            status: ITS__FILE__PROCESSING__STATUS__FAILED,
            error: err
          }
        }
      }
    })
  },

  // ***************************************
  // Internal Assortments
  // ***************************************
  [VUEX_API_ASSORTMENTS_INTERNAL_TABLE_OF_CONTENTS_FETCH]: async ({ dispatch }, payload) => {
    const key = JSON.stringify(payload.data).toString('base64')
    const keyExists = key in CACHED_TOC

    if (keyExists && !payload.forceRequest) return CACHED_TOC[key]

    return dispatch(VUEX_API_ASSORTMENTS_INTERNAL_REQUEST_FETCH, payload.data).then(response => {
      if (!keyExists) CACHED_TOC[key] = response
      return response
    })
  },

  [VUEX_API_ASSORTMENTS_INTERNAL_REQUEST_FETCH]: async ({ commit, dispatch }, payload) => {
    if (payload.params) delete payload.params

    const apiValidator = new ApiValidator('internalAssortmentTOCFetch')
    const validatedPayload = apiValidator.validate(payload)

    await commit(VUEX_API_ASSORTMENTS_REQUEST_FETCH)

    if (state.assortmentsCancelRequest) state.assortmentsCancelRequest.cancel()
    await commit(VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN, 'assortmentsCancelRequest')

    // compose options payload
    // used to do /internalAssortments/query, now using
    return api.post(`/internalAssortments/discover`, validatedPayload, {
    // return api.post(`/internalAssortments/query`, payload, {
      // params: payload.params,
      cancelToken: state.assortmentsCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSORTMENTS_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      if (!axios.isCancel(err)) {
        commit(VUEX_API_ASSORTMENTS_REQUEST_FAILED)

        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/api/internalAssortments' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Fetch a single Assortment
  [VUEX_API_ASSORTMENT_INTERNAL_REQUEST_FETCH]: async ({ commit, dispatch }, payload) => {
    const newPayload = {
      _options: payload._options
    }
    await commit(VUEX_API_ASSORTMENT_REQUEST_FETCH)

    if (state.assortmentsCancelRequest) state.assortmentsCancelRequest.cancel()
    await commit(VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN, 'assortmentCancelRequest')

    return api.get(`/internalAssortments/${payload.id}`, {
      params: newPayload,
      cancelToken: state.assortmentCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSORTMENT_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      if (!axios.isCancel(err)) {
        commit(VUEX_API_ASSORTMENT_REQUEST_FAILED)
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // Fetch Internal Assortment products
  [VUEX_API_ASSORTMENT_INTERNAL_PRODUCTS_REQUEST_FETCH]: async ({ commit }, payload) => {
    await commit(VUEX_API_ASSORTMENT_REQUEST_FETCH)

    if (state.assortmentsCancelRequest) state.assortmentsCancelRequest.cancel()
    await commit(VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN, 'assortmentCancelRequest')

    return api.post(`/internalAssortments/products/query`, payload, {
      cancelToken: state.assortmentCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ASSORTMENT_REQUEST_SUCCESS)
      return response
    }).catch(err => {
      commit(VUEX_API_ASSORTMENT_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        /* dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        }) */
      }
    })
  },

  // lock: type is request or release
  [VUEX_API_ASSORTMENT_INTERNAL_LOCKER]: async ({ dispatch }, payload) => {
    if (!payload.id || (payload.type !== 'request' && payload.type !== 'release')) {
      return
    }
    return api.post(`/internalAssortments/edit/lock/${payload.id}/${payload.type}`, {}).then(response => {
      return response
    }).catch(() => {
      /*
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `${err.response && err.response.data.message}`
        }
      })
       */
    })
  },

  [VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD_CANCEL]: () => {
    state.assortmentDownloadPackageCancelRequest.cancel('Your ZIP download has been cancelled')
  }
}

const mutations = {
  // Assortments
  [VUEX_API_ASSORTMENTS_REQUEST_FETCH]: state => {
    state.assortmentsRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_ASSORTMENTS_REQUEST_SUCCESS]: state => {
    state.assortmentsRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_ASSORTMENTS_REQUEST_FAILED]: state => {
    state.assortmentsRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Assortment
  [VUEX_API_ASSORTMENT_REQUEST_FETCH]: state => {
    state.assortmentRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_ASSORTMENT_REQUEST_SUCCESS]: state => {
    state.assortmentRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_ASSORTMENT_REQUEST_FAILED]: state => {
    state.assortmentRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS]: (state, data) => {
    state.assortmentProductUpdate = data
  },

  // Set Cancelation token
  [VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
