import { loadComponent } from '@/helpers/asyncLoaders'

export const routes = {
  path: 'spring23',
  name: 'spring23--main',
  meta: {
    conferenceClass: 'spring23'
  },
  component: loadComponent('GlobalConference/Spring23/Main.vue'),
  redirect: { name: 'spring23--welcome' },
  children: [
    {
      path: 'welcome',
      name: 'spring23--welcome',
      component: loadComponent('GlobalConference/Spring23/Welcome.vue')
    },
    {
      path: 'advertising',
      name: 'spring23--advertising',
      component: loadComponent('GlobalConference/Spring23/Advertising.vue')
    },
    {
      path: 'categories',
      name: 'spring23--categories',
      component: loadComponent('GlobalConference/Spring23/Categories.vue')
    },
    {
      path: 'retail',
      name: 'spring23--retail',
      component: loadComponent('GlobalConference/Spring23/Retail.vue')
    },
    {
      path: 'marketing',
      name: 'spring23--marketing',
      component: loadComponent('GlobalConference/Spring23/Marketing.vue')
    },
    {
      path: 'dtc-catalog',
      name: 'spring23--dtc-catalog',
      component: loadComponent('GlobalConference/Spring23/DTC_Catalog.vue')
    }
  ]
}
