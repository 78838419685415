<template>
  <v-autocomplete
    :clearable="clearable"
    :color="color"
    :disabled="disabled"
    :hide-no-data="hideNoData"
    :items="items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :label="label"
    :model-value="modelValue"
    :multiple="multiple"
    :return-object="returnObject"
    :rules="rules"
    @update:modelValue="update"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    appendIcon: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean
    },
    hideNoData: {
      type: Boolean
    },
    items: {
      type: Object
    },
    itemTitle: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    label: {
      type: String
    },
    modelValue: {
      type: [String, Object]
    },
    multiple: {
      type: Boolean
    },
    returnObject: {
      type: Boolean
    },
    rules: {
      type: Object
    }
  },

  computed: {
    value () {
      return this.modelValue
    }
  },

  emits: ['update:modelValue'],

  methods: {
    update (val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
