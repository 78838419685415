import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_SALES_MANAGE_PENDING,
  VUEX_ROUTING_ENTER_SALES_MANAGE_PUBLISHED,
  VUEX_ROUTING_ENTER_SALES_MANAGE_UNPUBLISHED,
  VUEX_ROUTING_ENTER_SALES_MANAGE_UPLOAD,
  VUEX_ROUTING_ENTER_SALES_MANAGE_DELETED
} from '@/store/constants/routing/routingSales'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { salesMeta } from '@/router/routeMeta'

export default {
  path: '/sales/libraries/manage',
  // name: 'sales--libraries--manage',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      callback: () => { store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()) }
    }),
  children: [
    {
      path: '',
      name: 'sales--libraries--manage--default',
      redirect: { name: 'sales--libraries--assets--manage--pending' }
    },
    {
      path: 'step/pending',
      name: 'sales--libraries--step--manage--pending',
      component: loadComponent('Sales/Sales_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_SALES_MANAGE_PENDING, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__SALES__ASSET,
        optionalNavAppBarLabel: 'Manage Sales Assets'
      }
    },
    {
      path: 'step/published',
      name: 'sales--libraries--step--manage--published',
      component: loadComponent('Sales/Sales_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_SALES_MANAGE_PUBLISHED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__SALES__ASSET,
        optionalNavAppBarLabel: 'Manage Sales Assets'
      }
    },
    {
      path: 'step/unpublished',
      name: 'sales--libraries--step--manage--unpublished',
      component: loadComponent('Sales/Sales_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_SALES_MANAGE_UNPUBLISHED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__SALES__ASSET,
        optionalNavAppBarLabel: 'Manage Sales Assets'
      }
    },
    {
      path: 'step/deleted',
      name: 'sales--libraries--step--manage--deleted',
      component: loadComponent('Sales/Sales_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_SALES_MANAGE_UPLOAD, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__STEP__ASSET,
        optionalNavAppBarLabel: 'Manage Sales Assets'
      }
    },
    {
      path: 'step/upload',
      name: 'sales--libraries--step--manage--upload',
      component: loadComponent('Sales/Sales_Upload.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_SALES_MANAGE_DELETED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__STEP__ASSET,
        optionalNavAppBarLabel: 'Manage Sales Assets'
      }
    }
  ],
  meta: salesMeta
}
