import {
  VUEX_APP_INIT,
  VUEX_APP_INITIALIZED
} from '@/store/constants/app'

import {
  VUEX_LOCALSTORAGE_INIT
} from '@/store/constants/localStorage'

import {
  VUEX_CONSTANTS_INIT
} from '@/store/constants/config/constants'

import {
  VUEX_REQUEST_OPTIONS_INIT
} from '@/store/constants/config/requestOptions'

const state = {
  initializing: false,
  initialized: false
}

const getters = {}

const actions = {
  [VUEX_APP_INIT]: async ({ dispatch, commit }) => {
    commit(VUEX_APP_INIT)

    await dispatch(VUEX_CONSTANTS_INIT)
    await dispatch(VUEX_REQUEST_OPTIONS_INIT)
    await dispatch(VUEX_LOCALSTORAGE_INIT)
    await dispatch(VUEX_REQUEST_OPTIONS_INIT)

    dispatch(VUEX_APP_INITIALIZED)
  },

  [VUEX_APP_INITIALIZED]: ({ commit }) => {
    commit(VUEX_APP_INITIALIZED)
  }
}

const mutations = {
  [VUEX_APP_INIT]: state => {
    state.initializing = true
  },

  [VUEX_APP_INITIALIZED]: state => {
    state.initializing = false
    state.initialized = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
