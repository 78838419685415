import {
  VUEX_ITEM_DISPLAY_FETCH_DATA,
  VUEX_ITEM_DISPLAY_SET_ITEM,
  VUEX_ITEM_DISPLAY_UPDATE_QUERY
} from '@/store/constants/ui/itemDisplay'

import queryCtrl from '@/utils/query'

import {
  VUEX_ASSETS_FETCH
} from '@/store/constants/models/assets'

import {
  VUEX_API_USER_FLAGS_UPDATE_SEEN_STATUS
} from '@/store/constants/api'

import feConstants from '@/store/constants/config/fe_constants'

const state = {
  item: null,
  query: {
    'lang': 'English',
    'status': 'Published',
    'libraryType': feConstants.ITS__LIBRARIES__LIBRARY_TYPE__STEP__ASSET,
    '_options': queryCtrl.encodeQueryParams({
      'sort': {
        'assetType': 1,
        'divisionId': 1,
        'gender': 1,
        'name': 1
      },
      'includeUserFlag': 'Seen',
      'grouping': ['assetType', 'gender'],
      'project': {
        'beginDate': 0,
        'expireDate': 0,
        'nameTokens': 0,
        'nameAlternatesTokens': 0,
        'libraryType': 0,
        'tag': 0,
        '__v': 0,
        'lang': 0,
        // 'season': 0,
        // 'year': 0,
        'status': 0,
        'assetType': 0,
        'divisionId': 0,
        'gender': 0,
        'createdDate': 0,
        'modifiedDate': 0
      }
    })
  }
}

const getters = {

}

const actions = {
  [VUEX_ITEM_DISPLAY_FETCH_DATA]: async ({ rootGetters, rootState, dispatch, commit, state }, payload) => {
    dispatch(VUEX_ASSETS_FETCH, state.query)
  },
  [VUEX_ITEM_DISPLAY_UPDATE_QUERY]: async ({ rootGetters, rootState, dispatch, commit, state }, payload) => {
    // Update lang prop on query and then re-fetch data
    await commit(VUEX_ITEM_DISPLAY_UPDATE_QUERY, payload)
    dispatch(VUEX_ITEM_DISPLAY_FETCH_DATA)
  },
  [VUEX_ITEM_DISPLAY_SET_ITEM]: async ({ rootGetters, rootState, dispatch, commit, state }, item) => {
    await commit(VUEX_ITEM_DISPLAY_SET_ITEM, item)

    if (item && !item.userFlag) {
      // If this is first time loading asset, update seen status
      dispatch(VUEX_API_USER_FLAGS_UPDATE_SEEN_STATUS, item._id)
    }
  }
}

const mutations = {

  [VUEX_ITEM_DISPLAY_SET_ITEM]: (state, item) => {
    state.item = item
  },

  [VUEX_ITEM_DISPLAY_UPDATE_QUERY]: (state, data) => {
    state.query[data.key] = data.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
