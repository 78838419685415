import { emitter } from '@/main'
import {
  // DOCUMENT
  VUEX_DOCUMENT_CREATE_REQUEST,
  VUEX_DOCUMENT_CREATE_SUCCESS,
  VUEX_DOCUMENT_CREATE_FAILURE,
  VUEX_DOCUMENT_CREATE_FILE_POP,

  VUEX_DOCUMENT_SCHEDULED_REQUEST,
  VUEX_DOCUMENT_SCHEDULED_SUCCESS,
  VUEX_DOCUMENT_SCHEDULED_FAILURE,

  VUEX_DOCUMENT_SCHEDULED_DELETE,
  VUEX_DOCUMENT_SCHEDULED_DELETE_SUCCESS,
  VUEX_DOCUMENT_SCHEDULED_DELETE_FAILURE,

  VUEX_DOCUMENT_EMAIL_FILE_REQUEST,
  VUEX_DOCUMENT_EMAIL_FILE_SUCCESS,
  VUEX_DOCUMENT_EMAIL_FILE_FAILURE,

  VUEX_DOCUMENTS_FETCH_REQUEST,
  VUEX_DOCUMENTS_FETCH_SUCCESS,
  VUEX_DOCUMENTS_FETCH_FAILURE,

  VUEX_SHARED_FILE_DOWNLOAD,
  VUEX_SHARED_FILE_DOWNLOAD_SUCCESS,
  VUEX_SHARED_FILE_DOWNLOAD_FAILURE,

  VUEX_SCHEDULED_FILE_DOWNLOAD,
  VUEX_SCHEDULED_FILE_DOWNLOAD_SUCCESS,
  VUEX_SCHEDULED_FILE_DOWNLOAD_FAILURE,

  VUEX_DOCUMENT_SET_DATA,
  VUEX_DOCUMENT_CLEAR_DATA,
  VUEX_DOCUMENT_CLEAR_EXPORT_STATUS,
  VUEX_DOCUMENT_ADD_TO_CREATE_QUEUE,
  VUEX_DOCUMENT_TRIGGER_FILE_COMPLETE_ACTIONS
} from '@/store/constants/models/documents'

import {
  VUEX_API_DOCUMENTS_FETCH,
  VUEX_API_DOCUMENT_CREATE,
  VUEX_API_DOCUMENT_SCHEDULED_CREATE,
  VUEX_API_DOCUMENT_SCHEDULED_DELETE,
  VUE_API_DOCUMENT_EMAIL_FILE_CREATE,
  VUEX_API_SHARED_FILE_DOWNLOAD,
  VUEX_API_SCHEDULED_FILE_DOWNLOAD
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  documents: [],
  createDocumentPayload: {
    kind: 'AssortmentDocument',
    documentType: {},
    productType: '',
    settings: {},
    assortment: '',
    filename: ''
  },

  createDocumentsQueue: [],
  createDocumentLoading: false,
  scheduledLoading: false,
  fileLoading: false,
  fileData: null
}

const getters = {
  documentsQueued (state) {
    return status => state.createDocumentsQueue.filter(item => {
      return status ? item.status === status : item.status
    })
  }
}

const actions = {
  [VUEX_DOCUMENT_SET_DATA]: async ({ commit }, payload) => {
    await commit(VUEX_DOCUMENT_SET_DATA, payload)
  },
  [VUEX_DOCUMENT_CLEAR_DATA]: async ({ commit }) => {
    await commit(VUEX_DOCUMENT_CLEAR_DATA)
  },

  [VUEX_DOCUMENT_CLEAR_EXPORT_STATUS]: async ({ commit }, payload) => {
    await commit(VUEX_DOCUMENT_CLEAR_EXPORT_STATUS, payload)
  },

  /* DOCUMENT LIST */
  [VUEX_DOCUMENTS_FETCH_REQUEST]: async ({ dispatch, commit }, payload) => {
    await dispatch(VUEX_API_DOCUMENTS_FETCH, payload).then(response => {
      commit(VUEX_DOCUMENTS_FETCH_SUCCESS, response.data)
      return response
    }).catch(error => {
      commit(VUEX_DOCUMENTS_FETCH_FAILURE, error)
    })
  },

  /* DOCUMENT CREATE */
  [VUEX_DOCUMENT_CREATE_REQUEST]: async ({ state, dispatch, commit }, payload) => {
    await commit(VUEX_DOCUMENT_CREATE_REQUEST)
    let data = {
      ...payload,
      ...state.createDocumentPayload,
      status: ITS__FILE__PROCESSING__STATUS__STARTED
    }
    await commit(VUEX_DOCUMENT_ADD_TO_CREATE_QUEUE, data)
    await dispatch(VUEX_API_DOCUMENT_CREATE, data).then(response => {
      switch (response.action) {
        case 'completed' :
          commit(VUEX_DOCUMENT_CREATE_SUCCESS, response)
          dispatch(VUEX_DOCUMENT_TRIGGER_FILE_COMPLETE_ACTIONS, response)
          break
        case 'cancelled' :
          commit(VUEX_DOCUMENT_CREATE_FAILURE, response)
          break
        case 'failed' :
          commit(VUEX_DOCUMENT_CREATE_FAILURE, response)
          break
      }

      setTimeout(() => {
        commit(VUEX_DOCUMENT_CREATE_FILE_POP, data)
      }, ITS__UI__PROGRESS__DELAY__FILE_POP)
    }).catch(error => {
      commit(VUEX_DOCUMENT_CREATE_FAILURE, error)
    })
  },

  [VUEX_DOCUMENT_TRIGGER_FILE_COMPLETE_ACTIONS]: () => {
    // Temporarily hardcoding this, will us "id" to look up object
    let data = state.createDocumentsQueue[0]
    emitter.emit(`file-download-handler--${data.action.toLowerCase()}`, data)
  },

  [VUEX_DOCUMENT_SCHEDULED_REQUEST]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_DOCUMENT_SCHEDULED_REQUEST)

    await dispatch(VUEX_API_DOCUMENT_SCHEDULED_CREATE, payload.model).then(response => {
      commit(VUEX_DOCUMENT_SCHEDULED_SUCCESS, response)
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: `Scheduled ${payload.edit ? 'Updated' : 'Successfully'}`
        }
      })
      return response
    }).catch(error => {
      commit(VUEX_DOCUMENT_SCHEDULED_FAILURE, error)
    })
  },

  [VUEX_DOCUMENT_SCHEDULED_DELETE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_DOCUMENT_SCHEDULED_DELETE)

    await dispatch(VUEX_API_DOCUMENT_SCHEDULED_DELETE, payload).then(response => {
      commit(VUEX_DOCUMENT_SCHEDULED_DELETE_SUCCESS, response)
      if (payload.alert) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'success',
            message: `Scheduled Deleted`
          }
        })
      }
      return response
    }).catch(error => {
      commit(VUEX_DOCUMENT_SCHEDULED_DELETE_FAILURE, error)
    })
  },

  [VUEX_DOCUMENT_EMAIL_FILE_REQUEST]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_DOCUMENT_EMAIL_FILE_REQUEST)

    await dispatch(VUE_API_DOCUMENT_EMAIL_FILE_CREATE, payload).then(response => {
      commit(VUEX_DOCUMENT_EMAIL_FILE_SUCCESS, response)
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: `Shared Successfully`
        }
      })
      return response
    }).catch(error => {
      commit(VUEX_DOCUMENT_SCHEDULED_FAILURE, error)
    })
  },

  /* FILES DOWNLOADS */
  [VUEX_SHARED_FILE_DOWNLOAD]: async ({ dispatch, commit }, payload) => {
    await dispatch(VUEX_API_SHARED_FILE_DOWNLOAD, payload).then(response => {
      commit(VUEX_SHARED_FILE_DOWNLOAD_SUCCESS, response)
    }).catch(err => {
      commit(VUEX_SHARED_FILE_DOWNLOAD_FAILURE, err)
    })
  },

  [VUEX_SCHEDULED_FILE_DOWNLOAD]: async ({ dispatch, commit }, payload) => {
    await dispatch(VUEX_API_SCHEDULED_FILE_DOWNLOAD, payload).then(response => {
      commit(VUEX_SCHEDULED_FILE_DOWNLOAD_SUCCESS, response)
    }).catch(err => {
      commit(VUEX_SCHEDULED_FILE_DOWNLOAD_FAILURE, err)
    })
  }
}

const mutations = {
  [VUEX_DOCUMENT_SET_DATA]: (state, data) => {
    if (data.kind) {
      state.createDocumentPayload.kind = data.kind
    }

    if (data.filename) {
      state.createDocumentPayload.filename = data.filename
      state.createDocumentPayload.displayName = data.filename.replace(/_/g, ' ')
    }

    if (data.documentSettings) {
      // reset settings - settings get changed only on new
      state.createDocumentPayload.settings = data.documentSettings
    }

    if (data.documentExportSettings) {
      let settings = { ...state.createDocumentPayload.settings, ...data.documentExportSettings }
      state.createDocumentPayload.settings = settings
    }

    if (data.documentType) {
      state.createDocumentPayload.documentType = data.documentType
    }

    if (data.productType) {
      state.createDocumentPayload.productType = data.productType
    }

    if (data.assortment) {
      state.createDocumentPayload.assortment = data.assortment
    }

    if (data.user) {
      state.createDocumentPayload.user = data.user
    }

    if (data.createDocumentPreviewOnly) {
      state.createDocumentPreviewOnly = data.createDocumentPreviewOnly
    }
  },

  [VUEX_DOCUMENT_CLEAR_DATA]: (state) => {
    state.createDocumentPayload.settings = {}
  },

  [VUEX_DOCUMENT_CLEAR_EXPORT_STATUS]: (state) => {
    if (state.createDocumentPayload?.settings?.exportStatus) {
      delete state.createDocumentPayload.settings.exportStatus
    }
  },

  [VUEX_DOCUMENT_ADD_TO_CREATE_QUEUE]: (state, data) => {
    // Temporarily limit queue to single object until we modify api
    // to handle multiple concurrent requests
    state.createDocumentsQueue = []
    state.createDocumentsQueue.push({
      ...data,
      addedToQueue: Date.now()
    })
  },

  [VUEX_DOCUMENTS_FETCH_SUCCESS]: (state, data) => {
    state.documents = data
  },

  [VUEX_DOCUMENTS_FETCH_FAILURE]: (state, data) => {
    console.error('VUEX_DOCUMENTS_FETCH_FAILURE', data)
  },

  [VUEX_DOCUMENT_CREATE_FILE_POP]: (state, data) => {
    let index = state.createDocumentsQueue.indexOf(data.id)
    state.createDocumentsQueue.splice(index, 1)
  },

  /* DOCUMENT CREATE */
  [VUEX_DOCUMENT_CREATE_REQUEST]: state => {
    state.createDocumentLoading = true
  },
  [VUEX_DOCUMENT_CREATE_SUCCESS]: (state, data) => {
    // Temporarily just updating the first index, bc there is only one for now
    state.createDocumentsQueue[0].data = data.data
    state.createDocumentsQueue[0].status = ITS__FILE__PROCESSING__STATUS__SUCCESS
    state.createDocumentLoading = false
    // TODO: Look up create document object in queue and set status to 'completed', or just remove object
  },
  [VUEX_DOCUMENT_CREATE_FAILURE]: state => {
    state.createDocumentLoading = false
    state.createDocumentsQueue[0].status = ITS__FILE__PROCESSING__STATUS__FAILED
    // TODO: Look up create document object in queue and set status to 'failed', or just remove object
  },

  [VUEX_DOCUMENT_SCHEDULED_REQUEST]: state => {
    state.scheduledLoading = true
  },
  [VUEX_DOCUMENT_SCHEDULED_SUCCESS]: state => {
    state.scheduledLoading = false
  },
  [VUEX_DOCUMENT_SCHEDULED_FAILURE]: (state, data) => {
    state.scheduledLoading = false
    console.error('VUEX_DOCUMENT_SCHEDULED_FAILURE', data)
  },

  [VUEX_DOCUMENT_SCHEDULED_DELETE]: state => {
    state.scheduledLoading = true
  },
  [VUEX_DOCUMENT_SCHEDULED_DELETE_SUCCESS]: (state, data) => {
    state.scheduledLoading = false
  },
  [VUEX_DOCUMENT_SCHEDULED_DELETE_FAILURE]: state => {
    state.scheduledLoading = false
  },

  [VUEX_DOCUMENT_EMAIL_FILE_REQUEST]: state => {
    state.fileLoading = true
  },
  [VUEX_DOCUMENT_EMAIL_FILE_SUCCESS]: state => {
    state.fileLoading = false
  },
  [VUEX_DOCUMENT_EMAIL_FILE_FAILURE]: (state, data) => {
    state.fileLoading = false
    console.error('VUEX_DOCUMENT_PDF_FAILURE', data)
  },

  [VUEX_SHARED_FILE_DOWNLOAD_SUCCESS]: (state, data) => {
    state.fileData = data.data
  },

  [VUEX_SHARED_FILE_DOWNLOAD_FAILURE]: state => {
    state.fileData = null
    console.error('VUEX_SHARED_FILE_DOWNLOAD_FAILURE')
  },

  [VUEX_SCHEDULED_FILE_DOWNLOAD_SUCCESS]: (state, data) => {
    state.fileData = data.data
  },

  [VUEX_SCHEDULED_FILE_DOWNLOAD_FAILURE]: state => {
    state.fileData = null
    console.error('VUEX_SCHEDULED_FILE_DOWNLOAD_FAILURE')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
