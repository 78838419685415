// ASSORTMENTS
export const VUEX_ASSORTMENTS_FETCH = 'VUEX_ASSORTMENTS_FETCH_REQUEST'
export const VUEX_ASSORTMENTS_REFETCH = 'VUEX_ASSORTMENTS_REFETCH'
export const VUEX_ASSORTMENTS_FETCH_SUCCESS = 'VUEX_ASSORTMENTS_FETCH_SUCCESS'
export const VUEX_ASSORTMENTS_FETCH_FAILURE = 'VUEX_ASSORTMENTS_FETCH_FAILURE'

export const VUEX_ASSORTMENTS_ALL_FETCH = 'VUEX_ASSORTMENTS_ALL_FETCH'
export const VUEX_ASSORTMENTS_ALL_FETCH_SUCCESS = 'VUEX_ASSORTMENTS_ALL_FETCH_SUCCESS'
export const VUEX_ASSORTMENTS_ALL_FETCH_FAILURE = 'VUEX_ASSORTMENTS_ALL_FETCH_FAILURE'

export const VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH = 'VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH'
export const VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_SUCCESS = 'VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_SUCCESS'
export const VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_FAILURE = 'VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_FAILURE'

// ASSORTMENT
export const VUEX_ASSORTMENT_FETCH = 'VUEX_ASSORTMENT_FETCH_REQUEST'
export const VUEX_ASSORTMENT_REFETCH = 'VUEX_ASSORTMENT_REFETCH_REQUEST'
export const VUEX_ASSORTMENT_FETCH_SUCCESS = 'VUEX_ASSORTMENT_FETCH_SUCCESS'
export const VUEX_ASSORTMENT_FETCH_FAILURE = 'VUEX_ASSORTMENT_FETCH_FAILURE'

export const VUEX_ASSORTMENT_CREATE = 'VUEX_ASSORTMENT_CREATE'
export const VUEX_ASSORTMENT_CREATE_SUCCESS = 'VUEX_ASSORTMENT_CREATE_SUCCESS'

export const VUEX_ASSORTMENT_UPDATE = 'VUEX_ASSORTMENT_UPDATE'
export const VUEX_ASSORTMENT_UPDATE_SUCCESS = 'VUEX_ASSORTMENT_UPDATE_SUCCESS'
export const VUEX_ASSORTMENT_UPDATE_FAILURE = 'VUEX_ASSORTMENT_UPDATE_FAILURE'

export const VUEX_ASSORTMENT_UPDATE_QUERY = 'VUEX_ASSORTMENT_UPDATE_QUERY'

export const VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT = 'VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT'
export const VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_REQUEST = 'VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_REQUEST'
export const VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_CLEAR_BATCHED_REQUEST = 'VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_CLEAR_BATCHED_REQUEST'

export const VUEX_ASSORTMENT_UPDATE_DYNAMIC_PRODUCT_TOTAL_COUNT = 'VUEX_ASSORTMENT_UPDATE_DYNAMIC_PRODUCT_TOTAL_COUNT'

export const VUEX_ASSORTMENT_DUPLICATE = 'VUEX_ASSORTMENT_DUPLICATE'
export const VUEX_ASSORTMENT_DUPLICATE_SUCCESS = 'VUEX_ASSORTMENT_DUPLICATE_SUCCESS'
export const VUEX_ASSORTMENT_DUPLICATE_FAILURE = 'VUEX_ASSORTMENT_DUPLICATE_FAILURE'

// export const VUEX_ASSORTMENT_PRODUCTS_ADD = 'VUEX_ASSORTMENT_PRODUCTS_ADD'
export const VUEX_ASSORTMENT_PRODUCTS_UPDATE = 'VUEX_ASSORTMENT_PRODUCTS_UPDATE'
export const VUEX_ASSORTMENT_PRODUCTS_STATUS_UPDATE = 'VUEX_ASSORTMENT_PRODUCTS_STATUS_UPDATE'
export const VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE = 'VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE'

export const VUEX_ASSORTMENT_PRODUCTS_RENAME = 'VUEX_ASSORTMENT_PRODUCTS_RENAME'

export const VUEX_ASSORTMENT_TREEDATA_UPDATE = 'VUEX_ASSORTMENT_TREEDATA_UPDATE'

export const VUEX_ASSORTMENT_SET_POP_COLORS = 'VUEX_ASSORTMENT_SET_POP_COLORS'

export const VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH = 'VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH'
export const VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_ADD = 'VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_ADD'
export const VUEX_ASSORTMENT_CATEGORIES_FETCH = 'VUEX_ASSORTMENT_CATEGORIES_FETCH'
export const VUEX_ASSORTMENT_CATEGORIES_ADD = 'VUEX_ASSORTMENT_CATEGORIES_ADD'

export const VUEX_ASSORTMENT_ALERTS_UPDATE = 'VUEX_ASSORTMENT_ALERTS_UPDATE'

// AG GRID LOADERS
export const VUEX_ASSORTMENT_GRID_ROWS_ADD = 'VUEX_ASSORTMENT_GRID_ROWS_ADD'
export const VUEX_ASSORTMENT_GRID_ROWS_DELETE = 'VUEX_ASSORTMENT_GRID_ROWS_DELETE'
export const VUEX_ASSORTMENT_GRID_ROWS_UPDATE = 'VUEX_ASSORTMENT_GRID_ROWS_UPDATE'
export const VUEX_ASSORTMENT_GRID_ROWS_UPDATE_SORT = 'VUEX_ASSORTMENT_GRID_ROWS_UPDATE_SORT'
export const VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE = 'VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE'
export const VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE = 'VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE'
export const VUEX_ASSORTMENT_GRID_REQUEST_CLEAR = 'VUEX_ASSORTMENT_GRID_REQUEST_CLEAR'
export const VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE = 'VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE'

export const VUEX_ASSORTMENT_GRID_TREEROWS_ADD = 'VUEX_ASSORTMENT_GRID_TREEROWS_ADD'
export const VUEX_ASSORTMENT_GRID_TREEROWS_DELETE = 'VUEX_ASSORTMENT_GRID_TREEROWS_DELETE'
export const VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE = 'VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE'

export const VUEX_ASSORTMENT_GRID_SET_SNAPSHOT = 'VUEX_ASSORTMENT_GRID_SET_SNAPSHOT'

export const VUEX_GRID_VALIDATE_PRODUCTS_FETCH = 'VUEX_GRID_VALIDATE_PRODUCTS_FETCH'
export const VUEX_GRID_VALIDATE_PRODUCTS_SUCCESS = 'VUEX_GRID_VALIDATE_PRODUCTS_SUCCESS'
export const VUEX_GRID_VALIDATE_PRODUCTS_FAILURE = 'VUEX_GRID_VALIDATE_PRODUCTS_FAILURE'

export const VUEX_GRID_REKEY = 'VUEX_GRID_REKEY'
export const VUEX_GRID_REKEY_EXCEL_UPLOADER = 'VUEX_GRID_REKEY_EXCEL_UPLOADER'

export const VUEX_GRID_CONFIRMATION_RESET_PRICES = 'VUEX_GRID_CONFIRMATION_RESET_PRICES'
export const VUEX_GRID_CONFIRMATION_CHANGE_INTERNAL_STATUS = 'VUEX_GRID_CONFIRMATION_CHANGE_INTERNAL_STATUS'
export const VUEX_GRID_CONFIRMATION_LABEL_DELETE = 'VUEX_GRID_CONFIRMATION_LABEL_DELETE'
export const VUEX_GRID_CONFIRMATION_SCHEDULE_DELETE = 'VUEX_GRID_CONFIRMATION_SCHEDULE_DELETE'
export const VUEX_GRID_CONFIRMATION_ARCHIVE_SELECTED = 'VUEX_GRID_CONFIRMATION_ARCHIVE_SELECTED'
export const VUEX_GRID_CONFIRMATION_ADD_TO_ASSORTMENT = 'VUEX_GRID_CONFIRMATION_ADD_TO_ASSORTMENT'

export const VUEX_GRID_ROWDATA_LOADING_START = 'VUEX_GRID_ROWDATA_LOADING_START'
export const VUEX_GRID_ROWDATA_LOADING_FINISH = 'VUEX_GRID_ROWDATA_LOADING_FINISH'
