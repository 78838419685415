import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_LIBRARIES_PENDING,
  VUEX_ROUTING_ENTER_LIBRARIES_PUBLISHED,
  VUEX_ROUTING_ENTER_LIBRARIES_UNPUBLISHED,
  VUEX_ROUTING_ENTER_LIBRARIES_UPLOAD,
  VUEX_ROUTING_ENTER_LIBRARIES_DELETED
} from '@/store/constants/routing/routingGlobalRetail'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import feConstants from '@/store/constants/config/fe_constants'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { globalRetailMeta } from '@/router/routeMeta'

export default {
  path: '/global-retail/libraries/manage',
  // name: 'global-retail--libraries--manage',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      permissions: {
        accessRequires: [
          {
            permission: feConstants.ITS__PERMISSION__GLOBALRETAIL__ASSETS,
            roles: [
              feConstants.ITS__ROLE__EDITOR,
              feConstants.ITS__ROLE__SENIOR_EDITOR
            ]
          },
          {
            permission: feConstants.ITS__PERMISSION__GLOBALRETAIL__RESOURCES,
            roles: [
              feConstants.ITS__ROLE__EDITOR,
              feConstants.ITS__ROLE__SENIOR_EDITOR
            ]
          }
        ]
      },
      callback: () => { store.dispatch(VUEX_OPTIONS_FETCH, 'assets').then(() => next()) }
    }),
  children: [
    {
      path: '',
      name: 'global-retail--libraries--manage--default',
      redirect: { name: 'global-retail--libraries--manage--pending' }
    },
    {
      path: 'pending',
      name: 'global-retail--libraries--manage--pending',
      component: loadComponent('GlobalRetail/GlobalRetail_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_LIBRARIES_PENDING, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        optionalNavAppBarLabel: 'Manage Global Retail Assets & Resources'
      }
    },
    {
      path: 'published',
      name: 'global-retail--libraries--manage--published',
      component: loadComponent('GlobalRetail/GlobalRetail_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_LIBRARIES_PUBLISHED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        optionalNavAppBarLabel: 'Manage Global Retail Assets & Resources'
      }
    },
    {
      path: 'unpublished',
      name: 'global-retail--libraries--manage--unpublished',
      component: loadComponent('GlobalRetail/GlobalRetail_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_LIBRARIES_UNPUBLISHED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        optionalNavAppBarLabel: 'Manage Global Retail Assets & Resources'
      }
    },
    {
      path: 'deleted',
      name: 'global-retail--libraries--manage--deleted',
      component: loadComponent('GlobalRetail/GlobalRetail_Manage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_LIBRARIES_DELETED, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        optionalNavAppBarLabel: 'Manage Global Retail Assets & Resources'
      }
    },
    {
      path: 'upload',
      name: 'global-retail--libraries--manage--upload',
      component: loadComponent('GlobalRetail/GlobalRetail_Upload.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_LIBRARIES_UPLOAD, { to: to, from: from }).then(() => next())
      },
      meta: {
        manageType: feConstants.ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET,
        libraryType: feConstants.ITS__LIBRARIES__LIBRARY_TYPE__GLOBAL_RETAIL__ASSET,
        optionalNavAppBarLabel: 'Manage Global Retail Assets & Resources'
      }
    }
  ],
  meta: globalRetailMeta
}
