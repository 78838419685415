import {
  VUEX_SAMPLE_INVENTORY_TRACKING_FETCH,
  VUEX_SAMPLE_INVENTORY_TRACKING_CREATE
} from '@/store/constants/models/samples'

import {
  VUEX_API_SAMPLE_INVENTORY_TRACKING_FETCH,
  VUEX_API_SAMPLE_INVENTORY_TRACKING_CREATE
} from '@/store/constants/api'
import router from '@/router'

const state = {
  trackedInventory: null,
  newTracking: null
}

const getters = {}

const actions = {
  [VUEX_SAMPLE_INVENTORY_TRACKING_FETCH]: async ({ commit, dispatch }, payload) => {
    const manageType = router.currentRoute.value.meta.manageType
    payload.manageType = manageType
    commit(VUEX_SAMPLE_INVENTORY_TRACKING_FETCH, [])
    const res = await dispatch(VUEX_API_SAMPLE_INVENTORY_TRACKING_FETCH, payload).then(response => {
      commit(VUEX_SAMPLE_INVENTORY_TRACKING_FETCH, response.data)
      return response.data
    })
    return res
  },

  [VUEX_SAMPLE_INVENTORY_TRACKING_CREATE]: async ({ commit, dispatch }, payload) => {
    const manageType = router.currentRoute.value.meta.manageType
    payload.manageType = manageType
    const response = await dispatch(VUEX_API_SAMPLE_INVENTORY_TRACKING_CREATE, payload)
    commit(VUEX_SAMPLE_INVENTORY_TRACKING_CREATE, response.data)
  }
}

const mutations = {
  [VUEX_SAMPLE_INVENTORY_TRACKING_FETCH]: (state, data) => {
    state.trackedInventory = data
  },

  [VUEX_SAMPLE_INVENTORY_TRACKING_CREATE]: (state, data) => {
    state.newTracking = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
