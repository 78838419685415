import {
  VUEX_USER_HAS_ACCESS
} from '@/store/constants/user'

const bypassPerms = process.env.VUE_APP_BYPASS_PERMS === 'true' || false

export default (permObj, store) => {
  if (!store) console.error('checkUserPermission: Vuex store needs to be supplied')

  // Returned data array
  let infoKindData = []
  let rawPermissionsData = []
  let isEditor = false
  let isSeniorEditor = false

  // ENV var for globally bypassing perms that use this function : VUE_APP_BYPASS_PERMS
  if (bypassPerms) return { hasPerm: true, infoKindData, message: 'Perms bypassed. Check ENV var `VUE_APP_BYPASS_PERMS`' }

  // Set context. App context needs to be passed in if used in store
  // let appContext = context || this

  // Permission object needs to be wrapped in an `accessRequires` prop. Grant access TRUE if it doesn't exist
  if (!permObj.hasOwnProperty('accessRequires')) return { hasPerm: true, infoKindData, message: 'Missing `accessRequires` prop - ACCESS GRANTED' }

  let permAndRole = permObj.accessRequires.some(req => {
    let perm = store.getters[VUEX_USER_HAS_ACCESS]({ permission: req?.permission, roles: req?.roles || null })

    // IF: user doesn't have base permission (domain) or role: bail
    if (!perm) return null
    if (!isEditor) isEditor = [ ITS__ROLE__EDITOR ].includes(perm.permission.role)
    if (!isSeniorEditor) isSeniorEditor = [ ITS__ROLE__SENIOR_EDITOR ].includes(perm.permission.role)

    rawPermissionsData.push(perm)

    /* ********************************************************* */
    /*
      * Checks data against `permission.infoKinds`
      *
      * accessRequires: [{                                             <--- Must match at least one accessRequires obj
          permission: ITS__PERMISSION__PRODUCT__INTERNAL_ASSORTMENTS,  <--- Must have permission [domain]                       <required>
          roles: [ 'user' ],                                           <--- Must have atleast one matching 'role' when defined  (optional)
          infoKinds: [                                                 <--- Must match ALL infoKinds                            (optional)
            {
              InternalAssortmentsData: {                               <--- Permission must have 'kind' when defined            (optional)
                subRoles: {                                            <--- Nested props under 'kind' (InternalAssortmentsData)
                  channel: ['Wholesale']
                  region: [ 'All', 'Europe' ],                             will need to match at least one value from ever prop
                  productType: [ 'All', 'Footwear' ],                      (i.e, 'subRoles' MUST exist and one matching value from 'region', 'productType' and 'role')
                  role: [ 'All', 'seniorEditor', 'viewer' ]           --->
                }
              }
            }
          ]
        }]
      *
      */

    // Default to true. Doesn't need infoKinds
    let allInfoKinds = true
    // IF: 'infoKinds' object exists
    if (req.infoKinds) {
      // Loop over all infoKinds in accessRequires object
      // --> MUST MATCH ALL (narrowing) <-- //
      allInfoKinds = req.infoKinds.every(kindArr => {
        // Access kind (example: 'InternalAssortmentsData')
        const asscessKind = Object.keys(kindArr)[0]
        // Permission object matching asscessKind ('InternalAssortmentsData') value
        const permInfoObj = perm.info.find(i => i.kind === asscessKind) || {}

        // IF: Object is null or has no properties: bail
        if (!Object.keys(permInfoObj).length) return false
        infoKindData.push(permInfoObj)

        // Loop over all nested access objects (example: 'subRoles')
        // --> MUST MATCH ALL (narrowing) <-- //
        const matchedKinds = Object.keys(kindArr[asscessKind]).every(kindKey => {
          // Nested access object
          const accessPropObj = kindArr[asscessKind][kindKey]
          // Nested permission object
          const permPropObj = Array.isArray(accessPropObj) ? accessPropObj : permInfoObj[kindKey]
            // Must match at least one perm subrole data (example: region: 'Europe' or 'All' )
            // --> MUST MATCH AT LEAST ONE (non-narrowing) <-- //
            return permPropObj.some(perm => {
              if (Array.isArray(accessPropObj)) {
                // handles non-nested kind prop
                return permInfoObj[kindKey].includes(perm)
              } else {
                // Every item in 'subRoles' should include one of the values in array
                return Object.keys(accessPropObj).every(propKey => accessPropObj[propKey].includes(perm[propKey]))
              }
            })
          })

        return matchedKinds
      })
    }

    return perm && allInfoKinds
  })

  // return permAndRole ? returnPermInfo : false

  /* console.info({
    hasPerm: !!permAndRole,
    editorRoles: {
      isEditor,
      isSeniorEditor
    },
    rawPermissionsData,
    infoKindData
  }) */

  return {
    hasPerm: !!permAndRole,
    editorRoles: {
      isEditor,
      isSeniorEditor
    },
    rawPermissionsData,
    infoKindData
  }
}
