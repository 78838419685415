import {
  VUEX_DRAWER_SHOW,
  VUEX_DRAWER_HIDE
} from '@/store/constants/ui/drawer'

const state = {
  openState: false,
  content: null,
  rekey: null, // optionally force rekey this instead of just checking a deep watch on content
  props: {
    width: 400,
    styles: {}
  }
}

const getters = {}

const actions = {
  [VUEX_DRAWER_SHOW]: ({ commit }, payload) => {
    commit(VUEX_DRAWER_SHOW, payload)
  },

  [VUEX_DRAWER_HIDE]: ({ commit }) => {
    commit(VUEX_DRAWER_HIDE)
  }
}

const mutations = {
  [VUEX_DRAWER_SHOW]: (state, data) => {
    if (!data) return

    state.props.styles = {} // reset styles
    if (data?.props) Object.assign(state.props, data.props)
    if (data?.content) state.content = data.content
    if (data?.rekey) state.rekey = data.rekey

    state.openState = true
  },

  [VUEX_DRAWER_HIDE]: state => {
    state.openState = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
