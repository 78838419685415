import { validation } from 'skch_its_be_fe_shared'

const { generateJoiSchema: generateAssortmentSchema } = validation.assortment
const { generateAssortmentCloneSchema } = validation.assortment.api.assortmentClone
const { generateProductsBatchScema } = validation.assortment.api.assortmentProductsBatch
const { generateProductSearchSchema } = validation.assortment.api.productSearch
const { generateAssortmentGroupSortBatchSchema } = validation.assortment.api.assortmentGroupSortBatch
const { generateCollectionQuerySchema } = validation.collection.collectionsQuery
const { generateCollectionUpdate } = validation.assortment.api.collectionUpdate
const { generateDocumentCreateSchema } = validation.assortment.api.documentCreate
const { generateDocumentShareSchema } = validation.assortment.api.documentShare
const { generateInternalAssortmentTOCFetchSchema } = validation.assortment.api.internalAssortmentTOCFetch
const { generateProductQuerySchema } = validation.product.productsQuery
const { generateSampleInventoryBatchSchema, generateSampleInventoryDeleteSchema, generateSampleInventoryValidateSchema } = validation.sampleInventory.sampleInventory

const generateConfig = { variant: 'browser' }

export default {
  assortment: generateAssortmentSchema(generateConfig),
  assortmentClone: generateAssortmentCloneSchema(generateConfig),
  assortmentUpdate: generateAssortmentSchema({ ...generateConfig, operation: 'patch' }),
  assortmentProductsBatch: generateProductsBatchScema(generateConfig),
  assortmentProductsFetch: generateProductSearchSchema(generateConfig),
  assortmentGroupSortBatch: generateAssortmentGroupSortBatchSchema(generateConfig),
  collectionLookup: generateCollectionQuerySchema(generateConfig),
  collectionUpdate: generateCollectionUpdate(generateConfig),
  documentCreate: generateDocumentCreateSchema(generateConfig),
  documentShare: generateDocumentShareSchema(generateConfig),
  internalAssortmentTOCFetch: generateInternalAssortmentTOCFetchSchema(generateConfig),
  productSearch: generateProductQuerySchema(generateConfig),

  sampleInventoryBatchSchema: generateSampleInventoryBatchSchema(generateConfig),
  sampleInventoryDeleteSchema: generateSampleInventoryDeleteSchema(generateConfig),
  sampleInventoryValidateSchema: generateSampleInventoryValidateSchema(generateConfig)
  // dynamicOptions
}
