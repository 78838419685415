// import _clone from 'lodash.clonedeep'

export default {
  transformValue: (key, value) => {
    switch (key) {
      case 'keyInitiative' : return `${value.channel}_${value.season}_${value.year}_${value.region}_${value.productType}`
      default : return value
    }
  },

  transformLabel: (key, label) => {
    switch (key) {
      case 'keyInitiative' : return label.split('_').slice(1).join(' ')
      default : return label
    }
  }
}
