import {
  VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS,
  VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS_LANDING,
  VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS,
  VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS_LANDING,

  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PENDING,
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PUBLISHED,
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UNPUBLISHED,
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UPLOAD,
  VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_DELETED
} from '@/store/constants/routing/routingAdvertising'

import {
  VUEX_ANNOUNCEMENTS_FETCH
} from '@/store/constants/models/announcements'

import {
  VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET
} from '@/store/constants/ui/filterBar/filterBar_ItemsDisplay'

import {
  VUEX_ASSETS_CLEAR
} from '@/store/constants/models/assets'

import {
  VUEX_SUBHEADER_SHOW,
  VUEX_SUBHEADER_HIDE,
  VUEX_SUBHEADER_SET_SIZE
} from '@/store/constants/ui/subheader'

import {
  VUEX_CONTENT_CONTAINER_SET_STYLES
} from '@/store/constants/ui/contentContainer'

import {
  VUEX_LIBRARIES_SET_QUERY_PARAMS
} from '@/store/constants/models/libraries'

import advertisingAssetsConfig from '@/components/_core/FilterBar/_config/dataSets/advertising/assets'
import advertisingLogosConfig from '@/components/_core/FilterBar/_config/dataSets/advertising/logos'

// ------------------ Dynamic Options Config ----------------
import dynamicOptions from '@/components/_core/FilterBar/_config/dynamicOptions'

const actions = {
  /***************************/
  // Advertising Routes
  /***************************/
  [VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS]: async ({ dispatch }) => {
    await dispatch(VUEX_ASSETS_CLEAR)

    await dispatch(VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET, '-modifiedDate')

    dispatch(VUEX_SUBHEADER_SHOW, {
      content: '_core/FilterBar/FilterBar.vue',
      props: {
        dataSet: advertisingAssetsConfig,
        constructFromQuery: true,
        dynamicOptionsData: dynamicOptions.advertising.assets,
        actions: [
          'share'
        ]
      }
    })
  },

  [VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_ASSETS_LANDING]: async ({ dispatch }) => {
    dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    dispatch(VUEX_SUBHEADER_HIDE, { content: null })
    dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__NONE
    })

    await dispatch(VUEX_ANNOUNCEMENTS_FETCH)
  },

  [VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS]: async ({ dispatch }) => {
    await dispatch(VUEX_ASSETS_CLEAR)

    await dispatch(VUEX_FILTERBAR_ITEMSDISPLAY_SORTORDER_SET, '-modifiedDate')

    dispatch(VUEX_SUBHEADER_SHOW, {
      content: '_core/FilterBar/FilterBar.vue',
      props: {
        dataSet: advertisingLogosConfig,
        constructFromQuery: true,
        dynamicOptionsData: dynamicOptions.advertising.logos,
        actions: [
          'share'
        ]
      }
    })
  },

  [VUEX_ROUTING_ENTER_ADVERTISING_LIBRARIES_LOGOS_LANDING]: async ({ dispatch }) => {
    dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    dispatch(VUEX_SUBHEADER_HIDE, { content: null })
    dispatch(VUEX_CONTENT_CONTAINER_SET_STYLES, {
      padding: ITS__UI__CONTENT_CONTAINER__PADDING__NONE
    })

    await dispatch(VUEX_ANNOUNCEMENTS_FETCH)
  },
  /***************************/
  /***************************/

  /***************************/
  // Advertising Manage Routes
  /***************************/
  [VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PENDING]: async ({ dispatch, commit }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Pending'
      }
    })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_PUBLISHED]: async ({ dispatch, commit }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Published'
      }
    })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UNPUBLISHED]: async ({ dispatch, commit }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Unpublished'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_DELETED]: async ({ dispatch, commit }) => {
    await dispatch(VUEX_LIBRARIES_SET_QUERY_PARAMS, {
      params: {
        status: 'Deleted'
      }
    })
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  },

  [VUEX_ROUTING_ENTER_ADVERTISING_MANAGE_UPLOAD]: ({ dispatch, commit }) => {
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })
  }
}

const mutations = {}

export default {
  actions,
  mutations
}
