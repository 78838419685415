import {
  VUEX_CONTENT_CONTAINER_SET_PROPS,
  VUEX_CONTENT_CONTAINER_SET_STYLES
} from '@/store/constants/ui/contentContainer'

const state = {
  props: {},
  styles: {}
}

const getters = {}

const actions = {
  [VUEX_CONTENT_CONTAINER_SET_PROPS]: ({ commit }, payload) => {
    commit(VUEX_CONTENT_CONTAINER_SET_PROPS, payload)
  },

  [VUEX_CONTENT_CONTAINER_SET_STYLES]: ({ commit }, payload) => {
    commit(VUEX_CONTENT_CONTAINER_SET_STYLES, payload)
  }
}

const mutations = {
  [VUEX_CONTENT_CONTAINER_SET_PROPS]: (state, data) => {
    Object.keys(data).forEach(key => {
      state.props[key] = data[key]
    })
  },

  [VUEX_CONTENT_CONTAINER_SET_STYLES]: (state, data) => {
    Object.keys(data).forEach(key => {
      state.styles[key] = data[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
