import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'

import {
  VUEX_ROUTING_ENTER_DASHBOARD
} from '@/store/constants/routing/routingDashboard'

export default {
  path: '/dashboard',
  // name: 'dashboard',
  component: loadView('app/Headless.vue'),
  children: [
    // {
    //   path: '',
    //   name: 'dashboard--default',
    //   redirect: { name: 'dashboard--its' }
    // },
    {
      path: '',
      name: 'dashboard--its',
      component: loadComponent('Dashboard/Dashboard_Its.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_DASHBOARD, { to: to, from: from }).then(() => next())
      }
    }
  ]
}
