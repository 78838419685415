// import Vue from 'vue'
// import api from '@/api'

// NOTE! These constants are exposed on window so don't need to be explicitly imported!
// NOTE! If you add more, make sure to add them to eslintrc.js so the linter doesn't complain

import feConstants from '@/store/constants/config/fe_constants'

import {
  VUEX_CONSTANTS_INIT,
  VUEX_CONSTANTS_INITIALIZED,
  VUEX_CONSTANTS_SET_ALL
} from '@/store/constants/config/constants'

/**
 * Constants module state
 * @const
 */
const state = {
  initialized: false
}

/**
 * Constants module getters
 * @const
 */
const getters = {
  constantsAreLoaded: (state) => () => {
    return state.initialized
  },

  getConst: (state) => (constToFind) => {
    return state[constToFind]
  }
}

/**
 * Constants module action definitions
 * @const
 */
const actions = {
  [VUEX_CONSTANTS_INIT]: async ({ dispatch, commit }) => {
    commit(VUEX_CONSTANTS_INIT)

    // TODO: Move inside GET request
    await commit(VUEX_CONSTANTS_SET_ALL, feConstants)

    commit(VUEX_CONSTANTS_INITIALIZED)
  }
}

/**
 * Constants module mutation definitions
 * @const
 */
const mutations = {
  [VUEX_CONSTANTS_INIT]: () => {},

  [VUEX_CONSTANTS_INITIALIZED]: (state) => {
    state.initialized = true
  },

  [VUEX_CONSTANTS_SET_ALL]: (state, data) => {
    Object.keys(data).forEach(function (constant, index) {
      state[constant] = data[constant]
      // Vue.set(state, constant, data[constant])

      /* jslint browser: true */
      window[constant] = data[constant]
    })
  }
}

/**
 * The Constants module of our Vuex store.
 * @module store/modules/constants
 */
export default {
  state,
  getters,
  actions,
  mutations
}
