import { loadView, loadComponent } from '@/helpers/asyncLoaders'

export default {
  path: '/file',
  // name: 'files-download',
  component: loadView('app/Unauthenticated.vue'),
  children: [
    {
      path: 'shared/:fileType/document/:documentId/:accessCode/:filename',
      component: loadComponent('FilesDownload/SharedDownload.vue')
    },
    {
      path: 'scheduled/:fileType/document/:documentId/:timeStamp/:filename',
      component: loadComponent('FilesDownload/ScheduledDownload.vue')
    }
  ]
}
