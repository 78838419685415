// Global Retail
export const VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS = 'VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS'
export const VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS_LANDING = 'VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_ASSETS_LANDING'
export const VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES = 'VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES'
export const VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES_LANDING = 'VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_RESOURCES_LANDING'
export const VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_MANAGE = 'VUEX_ROUTING_ENTER_GLOBAL_RETAIL_LIBRARIES_MANAGE'

// Repositories Manage
export const VUEX_ROUTING_ENTER_LIBRARIES_PENDING = 'VUEX_ROUTING_ENTER_LIBRARIES_PENDING'
export const VUEX_ROUTING_ENTER_LIBRARIES_PUBLISHED = 'VUEX_ROUTING_ENTER_LIBRARIES_PUBLISHED'
export const VUEX_ROUTING_ENTER_LIBRARIES_UNPUBLISHED = 'VUEX_ROUTING_ENTER_LIBRARIES_UNPUBLISHED'
export const VUEX_ROUTING_ENTER_LIBRARIES_DELETED = 'VUEX_ROUTING_ENTER_LIBRARIES_DELETED'
export const VUEX_ROUTING_ENTER_LIBRARIES_UPLOAD = 'VUEX_ROUTING_ENTER_LIBRARIES_UPLOAD'
