import _clone from 'lodash.clonedeep'

import {
  VUEX_ASSIGNMENTS_FETCH_COORDINATORS,
  VUEX_ASSIGNMENTS_CREATE,
  VUEX_ASSIGNMENTS_PATCH_UPDATE,
  VUEX_ASSIGNMENTS_DELETE
} from '@/store/constants/models/assignments'

import {
  VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS,
  VUEX_API_ASSIGNMENTS_REQUEST_CREATE_COORDINATOR,
  VUEX_API_ASSIGNMENTS_REQUEST_UPDATE_COORDINATOR,
  VUEX_API_ASSIGNMENTS_REQUEST_DELETE_ASSIGNMENT
} from '@/store/constants/api'

const state = {
  assignments: []
}

const getters = {}

const actions = {
  [VUEX_ASSIGNMENTS_FETCH_COORDINATORS]: async ({ commit, dispatch }, payload) => {
    dispatch(VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS, payload).then(response => {
      commit(VUEX_ASSIGNMENTS_FETCH_COORDINATORS, response)
    })
  },

  [VUEX_ASSIGNMENTS_CREATE]: async ({ commit, dispatch }, payload) => {
   return dispatch(VUEX_API_ASSIGNMENTS_REQUEST_CREATE_COORDINATOR, payload).then(response => {
      // commit(VUEX_ASSIGNMENTS_CREATE, response)
      return response
    })
  },

  [VUEX_ASSIGNMENTS_PATCH_UPDATE]: async ({ commit, dispatch }, payload) => {
    return dispatch(VUEX_API_ASSIGNMENTS_REQUEST_UPDATE_COORDINATOR, payload).then(response => {
      // commit(VUEX_ASSIGNMENTS_PATCH_UPDATE, response)
      return payload
    })
  },

  [VUEX_ASSIGNMENTS_DELETE]: async ({ commit, dispatch }, payload) => {
    return dispatch(VUEX_API_ASSIGNMENTS_REQUEST_DELETE_ASSIGNMENT, payload).then(() => {
      // commit(VUEX_ASSIGNMENTS_DELETE, payload)
      return payload
    })
  }
}

const mutations = {
  [VUEX_ASSIGNMENTS_FETCH_COORDINATORS]: (state, data) => {
    state.assignments = data
  },

  [VUEX_ASSIGNMENTS_CREATE]: (state, data) => {
    state.assignments.push(data)
  },

  [VUEX_ASSIGNMENTS_PATCH_UPDATE]: (state, data) => {
    let assignments = _clone(state.assignments)
    const idx = assignments.findIndex(assignment => assignment._id === data._id)

    assignments[idx] = data
    state.assignments = assignments
  },

  [VUEX_ASSIGNMENTS_DELETE]: (state, data) => {
    let assignments = _clone(state.assignments)
    const idx = assignments.findIndex(assignment => assignment._id === data.id)

    assignments.splice(idx, 1)
    state.assignments = assignments
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
