import _unionBy from 'lodash.unionby'
import _merge from 'lodash.merge'

import { getReadableBytes } from '@/utils/bytes'

import {
  VUEX_LIBRARIES_COLLECTION_ADD_TO_COLLECTION,
  VUEX_LIBRARIES_COLLECTION_BULK_ADD_TO_COLLECTION,
  VUEX_LIBRARIES_COLLECTION_REMOVE_FROM_COLLECTION,
  VUEX_LIBRARIES_COLLECTION_CLEAR_COLLECTION,

  VUEX_LIBRARIES_COLLECTION_INIT_FROM_LOCAL_STORAGE,
  VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE,

  VUEX_LIBRARIES_COLLECTION_TOGGLE_INCLUDE_WORKING_FILES,
  VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_WORKING_FILES_ONLY,
  VUEX_LIBRARIES_COLLECTION_SET_VIEW_TYPE,
  // TODO: Consider moving to different store
  VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_NEW_STYLES_ONLY

} from '@/store/constants/ui/librariesCollection'

import {
  VUEX_LOCALSTORAGE_DATA_SET,
  VUEX_LOCALSTORAGE_SET_STATE
} from '@/store/constants/localStorage'

const state = {
  collections: {
    globalRetail: [],
    product: [],
    productDocument: [],
    advertisingLogo: [],
    advertisingAsset: []
  },
  includeWorkingFiles: false,
  showWorkingFilesOnly: false,
  viewType: 'grid',
  showNewStylesOnly: false
}

const getters = {
  collectionTotalBytes: (state) => (collectionTypeKey) => {
    let totalBytes = 0
    state.collections[collectionTypeKey].forEach((card) => {
      card.files.forEach((file) => {
        if (!state.includeWorkingFiles && file.usageType === ITS__FILE__LIBRARIES__USAGE_TYPE__WORKING_FILE) {
          return
        }
        if (file.fileSize) {
          totalBytes += file.fileSize
        }
      })
    })

    return totalBytes
  },

  estimatedCollectionSize: (state, getters) => (collectionTypeKey) => {
    return getReadableBytes(getters.collectionTotalBytes(collectionTypeKey))
  },

  isCollectionTooLarge: (state, getters) => (collectionTypeKey) => {
    // 20GB collection limit
    const TWENTY_GB = 21474836480
    return getters.collectionTotalBytes(collectionTypeKey) >= TWENTY_GB
  }
}

const actions = {
  [VUEX_LIBRARIES_COLLECTION_ADD_TO_COLLECTION]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_LIBRARIES_COLLECTION_ADD_TO_COLLECTION, payload)
    dispatch(VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE)
  },
  [VUEX_LIBRARIES_COLLECTION_REMOVE_FROM_COLLECTION]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_LIBRARIES_COLLECTION_REMOVE_FROM_COLLECTION, payload)
    dispatch(VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE)
  },
  [VUEX_LIBRARIES_COLLECTION_BULK_ADD_TO_COLLECTION]: ({ dispatch, commit }, payload) => {
    commit(VUEX_LIBRARIES_COLLECTION_BULK_ADD_TO_COLLECTION, payload)
    dispatch(VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE)
  },
  [VUEX_LIBRARIES_COLLECTION_CLEAR_COLLECTION]: ({ dispatch, commit }, payload) => {
    commit(VUEX_LIBRARIES_COLLECTION_CLEAR_COLLECTION, payload)
    dispatch(VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE)
  },

  [VUEX_LOCALSTORAGE_SET_STATE]: ({ commit }, payload) => {
    commit(VUEX_LIBRARIES_COLLECTION_INIT_FROM_LOCAL_STORAGE, payload)
  },
  [VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE]: ({ state, dispatch }) => {
    dispatch(VUEX_LOCALSTORAGE_DATA_SET, { path: 'libraries.librariesCollection', value: state })
  },

  [VUEX_LIBRARIES_COLLECTION_TOGGLE_INCLUDE_WORKING_FILES]: async ({ dispatch, commit }) => {
    await commit(VUEX_LIBRARIES_COLLECTION_TOGGLE_INCLUDE_WORKING_FILES)
    dispatch(VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE)
  },

  [VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_WORKING_FILES_ONLY]: async ({ dispatch, commit }) => {
    await commit(VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_WORKING_FILES_ONLY)
    dispatch(VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE)
  },

  [VUEX_LIBRARIES_COLLECTION_SET_VIEW_TYPE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_LIBRARIES_COLLECTION_SET_VIEW_TYPE, payload)
    dispatch(VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE)
  },
  // TODO: Consider moving this to separate store file
  [VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_NEW_STYLES_ONLY]: async ({ dispatch, commit }) => {
    await commit(VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_NEW_STYLES_ONLY)
    dispatch(VUEX_LIBRARIES_COLLECTION_UPDATE_LOCAL_STORAGE)
  }
}

const mutations = {
  [VUEX_LIBRARIES_COLLECTION_ADD_TO_COLLECTION]: (state, data) => {
    state.collections[data.collectionTypeKey].push(data.data)
  },
  [VUEX_LIBRARIES_COLLECTION_BULK_ADD_TO_COLLECTION]: (state, data) => {
    state.collections[data.collectionTypeKey] = _unionBy(state.collections[data.collectionTypeKey], data.data, '_id')
  },
  [VUEX_LIBRARIES_COLLECTION_REMOVE_FROM_COLLECTION]: (state, data) => {
    state.collections[data.collectionTypeKey] = state.collections[data.collectionTypeKey].filter(item => item._id !== data.data._id)
  },
  [VUEX_LIBRARIES_COLLECTION_CLEAR_COLLECTION]: (state, data) => {
    state.collections[data.collectionTypeKey] = []
  },

  [VUEX_LIBRARIES_COLLECTION_INIT_FROM_LOCAL_STORAGE]: (state, data) => {
    if (data.libraries && data.libraries.librariesCollection) {
      state.includeWorkingFiles = data.libraries.librariesCollection.includeWorkingFiles
      state.showWorkingFilesOnly = data.libraries.librariesCollection.showWorkingFilesOnly
      state.viewType = data.libraries.librariesCollection.viewType
      // Adding this check to make sure collections initializes correctly for users with
      // legacy collections. If we continue to change to form of this we may introduct versioning here
      if (data.libraries.librariesCollection.collections) {
        _merge(state.collections, data.libraries.librariesCollection.collections)
      }
      // TODO: Confirm we need this in local storage, and if it should be moved to different store
      state.showNewStylesOnly = data.libraries.librariesCollection.showNewStylesOnly
    }
  },

  [VUEX_LIBRARIES_COLLECTION_TOGGLE_INCLUDE_WORKING_FILES]: state => {
    state.includeWorkingFiles = !state.includeWorkingFiles
  },

  [VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_WORKING_FILES_ONLY]: state => {
    state.showWorkingFilesOnly = !state.showWorkingFilesOnly
  },
  [VUEX_LIBRARIES_COLLECTION_SET_VIEW_TYPE]: (state, data) => {
    state.viewType = data
  },
  // TODO: Consider adding to different store file
  [VUEX_LIBRARIES_COLLECTION_TOGGLE_SHOW_NEW_STYLES_ONLY]: state => {
    state.showNewStylesOnly = !state.showNewStylesOnly
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
