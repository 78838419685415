import _merge from 'lodash.merge'
import _cloneDeep from 'lodash.clonedeep'
import _debounce from 'lodash.debounce'
import _forEach from 'lodash.foreach'

import store from '@/store/store'
import router from '@/router'

import {
  VUEX_ORDERS_UPDATE_QUERY,

  VUEX_ORDERS_LIST_FETCH,
  VUEX_ORDERS_LIST_FETCH_SUCCESS,
  VUEX_ORDERS_LIST_FETCH_FAILURE,
  VUEX_ORDERS_LIST_GRID_ROWS_DELETE,

  VUEX_ORDERS_DETAIL_CREATE,

  VUEX_ORDERS_DETAIL_REFETCH,
  VUEX_ORDERS_DETAIL_FETCH,
  VUEX_ORDERS_DETAIL_FETCH_SUCCESS,
  VUEX_ORDERS_DETAIL_FETCH_FAILURE,

  VUEX_ORDERS_PRODUCTS_LOOKUP_UPDATE,
  VUEX_ORDERS_GRID_SET_SNAPSHOT,

  VUEX_ORDERS_DETAIL_GRID_ROWS_ADD,
  VUEX_ORDERS_DETAIL_GRID_ROWS_ADD_COMPLETE,
  VUEX_ORDERS_DETAIL_GRID_ROWS_UPDATE,
  VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE,

  VUEX_ORDERS_DETAIL_MARK_COMPLETE,
  VUEX_ORDERS_DETAIL_MARK_COMPLETE_CONFIRMED,
  VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE,

  VUEX_ORDERS_DETAIL_BATCH_UPDATE,
  VUEX_ORDERS_DETAIL_BATCH_UPDATE2,
  VUEX_ORDERS_DETAIL_ADD_COMMENT,
  VUEX_ORDERS_DETAIL_GRID_REQUEST_CLEAR,

  VUEX_ORDERS_SET_SELECTED_COORDINATOR,
  VUEX_ORDERS_CLEAR_SELECTED_COORDINATOR,

  VUEX_ORDERS_SET_GRID_SETTINGS,
  VUEX_ORDERS_UPDATE_GRID_SETTINGS,
  VUEX_ORDERS_CONFIRMATION_LEAVE_PAGE,
  VUEX_ORDERS_DROP_PRODUCTS
} from '@/store/constants/models/orders'

import {
  VUEX_API_ORDER_FETCH,
  VUEX_API_ORDER_CREATE,
  VUEX_API_ORDER_DELETE,
  VUEX_API_ORDERS_REQUEST_FETCH,
  VUEX_API_ORDER_BATCH_UPDATE,
  VUEX_API_ORDER_ADD_COMMENT,
  VUEX_API_ORDERS_PRODUCTS_DELETE,
  VUEX_API_ORDERS_DROP_PRODUCTS
} from '@/store/constants/api'

import {
  VUEX_GRID_REKEY,
  VUEX_GRID_ROWDATA_LOADING_START,
  VUEX_GRID_ROWDATA_LOADING_FINISH,
  VUEX_GRID_UPDATE_START,
  VUEX_GRID_UPDATE_SUCCESS,
  VUEX_GRID_UPDATE_FAILURE,
  VUEX_GRID_REQUEST_SNAPSHOT
} from '@/store/constants/ui/grid'

import {
  VUEX_NOTIFICATIONS_FETCH
} from '@/store/constants/models/notifications'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import longWaitBlocker from '@/helpers/longWaitBlocker'
import checkUserPermissions from '@/mixins/checkUserPermissions'
import requestHelper from './requestHelper'

function calculateOrderEditable (order) {
  const status = order?.status
  let ret = false
  if (status === 'DRAFT') {
    ret = true
  }
  if (order.type === ITS__ORDERS__ORDER_TYPE__SAMPLE) {
    const sc = store.getters.isSampleCoordinator()
    if (sc.hasPerm) {
      if (status === 'SUBMITTED' || status === 'IN PROGRESS') {
        ret = true
      }
    }
  }
  if (order.type === ITS__ORDERS__ORDER_TYPE__PROMO) {
    const approver = store.getters.isApprover()
    const seniorApprover = store.getters.isSeniorApprover()
    if (seniorApprover.hasPerm) {
      if (order.orderApprovalStatus === 'REQUIRES APPROVER' || order.orderApprovalStatus === 'REQUIRES SENIOR') {
        ret = true
      }
    } else if (approver.hasPerm) {
      if (order.orderApprovalStatus === 'REQUIRES APPROVER') {
        ret = true
      }
    }
  }
  return ret
}

const state = {
  // processing
  updatingInformation: {}, // used to show if any of these API calls are still updating

  // API related
  agGridRequestData: {
    addProducts: [],
    updateProducts: [],
    statusChangeProducts: [],
    deleteProducts: []
  },

  order: [],
  orders: [],
  query: {},

  agGridRowData: [],
  agGridPatchIdentifier: {},
  // selectedCoordinator: null,
  orderProductsLookup: {},
  orderRowsAddedToggler: '',

  orderDetailRefetchParams: '', // the parameters used to fetch this order deteail - sometimes need to trigger a refetch

  // indicators while updating: mark as complete, submit new sample
  markCompleteStatus: '',
  submitNewSampleStatus: '',
  markCompleteConfirmedToggler: '',

  // shared calculated fields
  orderIsEditable: false,

  selectedCoordinator: null,

  confirmationLeavePageToggler: null
}

const getters = {
  selectedSampleCoordinatorCodeOrders: (state, rootGetters, rootState) => {
    const allowCoordinatorChange = router.currentRoute.value.meta.allowCoordinatorChange
    const userCode = rootState.user.user.userCode
    const selectedCoordinatorCode = state.selectedCoordinator?.coordinatorCode
    const isSeniorSampleCoordinator = rootGetters.isSeniorSampleCoordinator().hasPerm
    const isSampleCoordinator = rootGetters.isSampleCoordinator().hasPerm

    return (/* (selectedCoordinatorCode && selectedCoordinatorCode !== userCode) && */ (isSeniorSampleCoordinator || isSampleCoordinator) && allowCoordinatorChange)
      ? { data: { _options: { viewAsCoordinatorCode: selectedCoordinatorCode || userCode } } }
      : null
  }
}

const actions = {
  [VUEX_ORDERS_DROP_PRODUCTS]: async ({ state, dispatch, commit }, payload) => {
    // start
    commit(VUEX_GRID_UPDATE_START)
    if (payload.rowData) {
      let dropProducts = []
      for (const obj of payload.rowData) {
        dropProducts.push(
          {
            identifier: obj.identifier
          }
        )
      }
      let finalPayload = [
        {
          dropProducts: dropProducts
        },
        {
          submit: true
        }
      ]
      const response = await dispatch(VUEX_API_ORDERS_DROP_PRODUCTS, {
        data: finalPayload,
        id: state.order._id,
        orderType: state.order.purpose,
        orderSubtype: state.order.type
      })
      if (response?.data?.message === 'Success') {
        ///
        commit(VUEX_GRID_UPDATE_SUCCESS)
      } else {
        commit(VUEX_GRID_UPDATE_FAILURE)
      }
      await commit(VUEX_ORDERS_DETAIL_GRID_REQUEST_CLEAR)
    } else {
      commit(VUEX_GRID_UPDATE_FAILURE)
      console.error('VUEX_ORDERS_LIST_GRID_ROWS_DELETE fail')
    }
  },
  [VUEX_ORDERS_LIST_GRID_ROWS_DELETE]: async ({ state, dispatch, commit }, payload) => {
    // start
    commit(VUEX_GRID_UPDATE_START)
    if (payload.rows) {
      let numberSuccess = 0
      for (const obj of payload.rows) {
        const response = await dispatch(VUEX_API_ORDER_DELETE, {
          id: obj._id,
          orderType: 'SampleOrder', // all orderSubtype - PROMO or SAMPLE - say SampleOrder here
          orderSubtype: router.currentRoute.value.meta.orderSubtype
        })
        if (response?.data?.message === 'Success') {
          numberSuccess++
        } else {
          console.error('VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE | err', response)
        }
      } // for
      if (numberSuccess === payload.rows.length) {
        commit(VUEX_GRID_UPDATE_SUCCESS)
      } else {
        commit(VUEX_GRID_UPDATE_FAILURE)
      }
      await commit(VUEX_ORDERS_DETAIL_GRID_REQUEST_CLEAR)
    } else {
      commit(VUEX_GRID_UPDATE_FAILURE)
      console.error('VUEX_ORDERS_LIST_GRID_ROWS_DELETE fail')
    }
  },

  [VUEX_ORDERS_LIST_FETCH]: async ({ state, rootState, getters, dispatch, commit }, payload) => {
    const manageType = payload?.manageType || router.currentRoute.value.meta.manageType
    const orderSubtype = payload?.orderSubtype || router.currentRoute.value.meta.orderSubtype
    const userCode = rootState.user.user.userCode
    const role = checkUserPermissions({ accessRequires: [{ permission: ITS__PERMISSION__ORDERS__PROMO_ORDERS }] }, store).rawPermissionsData[0]?.permission?.role || null

    let data = payload /* || state.query */
    data = _merge(data, {
      orderType: 'SampleOrder',
      orderSubtype,
      data: requestHelper.getRequestObj({ manageType, role, userCode })
    }, getters.selectedSampleCoordinatorCodeOrders)

    commit(VUEX_ORDERS_UPDATE_QUERY, data)

    await dispatch(VUEX_GRID_ROWDATA_LOADING_START)
    return dispatch(VUEX_API_ORDERS_REQUEST_FETCH, data).then(response => {
      commit(VUEX_ORDERS_LIST_FETCH_SUCCESS, response.data)
      dispatch(VUEX_GRID_ROWDATA_LOADING_FINISH)

      dispatch(VUEX_GRID_REKEY)
      return response.data
    }).catch(error => {
      commit(VUEX_ORDERS_LIST_FETCH_FAILURE, error)
      dispatch(VUEX_GRID_ROWDATA_LOADING_FINISH)
      return null
    })
  },

  [VUEX_ORDERS_SET_SELECTED_COORDINATOR]: ({ state, commit, dispatch }, { coordinator, request }) => {
    commit(VUEX_ORDERS_SET_SELECTED_COORDINATOR, coordinator)

    if (!request) return

    dispatch(VUEX_ORDERS_LIST_FETCH, { payload: state.query })

    dispatch(VUEX_NOTIFICATIONS_FETCH, { notificationType: 'SampleOrderNotification', data: { orderType: 'SAMPLE' } })
  },

  [VUEX_ORDERS_CLEAR_SELECTED_COORDINATOR]: ({ commit }) => {
    commit(VUEX_ORDERS_CLEAR_SELECTED_COORDINATOR)
  },

  [VUEX_ORDERS_DETAIL_REFETCH]: async ({ dispatch, commit, state }) => {
    await commit(VUEX_ORDERS_DETAIL_REFETCH)
    let payload = state.orderDetailRefetchParams
    payload.refetch = true
    await dispatch(VUEX_ORDERS_DETAIL_FETCH, payload)
  },

  [VUEX_ORDERS_DETAIL_FETCH]: async ({ dispatch, commit, rootState }, payload) => {
    await commit(VUEX_ORDERS_DETAIL_REFETCH)
    await commit(VUEX_ORDERS_DETAIL_FETCH, payload)
    await dispatch(VUEX_GRID_ROWDATA_LOADING_START)
    await dispatch(VUEX_API_ORDER_FETCH, payload).then(async response => {
      await commit(VUEX_ORDERS_DETAIL_FETCH_SUCCESS, { response, rootState })
      await commit(VUEX_ORDERS_PRODUCTS_LOOKUP_UPDATE, response.data[0])
      dispatch(VUEX_GRID_ROWDATA_LOADING_FINISH)
    }).catch(async error => {
      await commit(VUEX_ORDERS_DETAIL_FETCH_FAILURE, error)
      await dispatch(VUEX_GRID_ROWDATA_LOADING_FINISH)
    })

    // now rekey when done
    dispatch(VUEX_GRID_REKEY)
  },

  [VUEX_ORDERS_DETAIL_CREATE]: ({ dispatch, commit }, payload) => {
    return dispatch(VUEX_API_ORDER_CREATE, payload).then(response => {
      commit(VUEX_ORDERS_DETAIL_CREATE, response.data)
      return response.data
    })
  },

  [VUEX_ORDERS_DETAIL_GRID_ROWS_ADD]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ORDERS_DETAIL_GRID_ROWS_ADD, payload)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.addProducts
    let finalPayload = {
      frontEnd: {
        type: 'add'
      },
      addProducts: rows
    }
    await commit(VUEX_GRID_UPDATE_START) // to get the status message faster because of debounce
    dispatch(VUEX_ORDERS_DETAIL_BATCH_UPDATE, finalPayload)
  },

  [VUEX_ORDERS_DETAIL_GRID_ROWS_UPDATE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ORDERS_DETAIL_GRID_ROWS_UPDATE, payload)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.updateProducts
    let finalPayload = {
      frontEnd: {
        type: 'update'
      },
      updateProducts: rows
    }
    await commit(VUEX_GRID_UPDATE_START) // to get the status message faster because of debounce
    dispatch(VUEX_ORDERS_DETAIL_BATCH_UPDATE, finalPayload)
  },
  [VUEX_ORDERS_DETAIL_BATCH_UPDATE]: _debounce(async ({ state, dispatch, commit }, payload) => {
    await commit(VUEX_GRID_UPDATE_START)
    if (payload.updateProducts || payload.addProducts || payload.submit || payload.status) {
      let finalPayloadData = []
      // kill all frontEnd passed keys
      if (payload.updateProducts) {
        let update = []
        _forEach(payload.updateProducts, function (obj) {
          let tobj = {
            identifier: obj._id,
            properties: obj.patch
          }
          update.push(tobj)
        })
        let temp = {
          updateProducts: update
        }
        finalPayloadData.push(temp)
      }

      let add = []
      if (payload.addProducts) {
        _forEach(payload.addProducts, function (obj) {
          add.push(obj)
        })
        let temp = {
          addProducts: add
        }
        finalPayloadData.push(temp)
      }

      if (payload.submit || payload.status) {
        finalPayloadData = payload
      }

      try {
        let res = await dispatch(VUEX_ORDERS_DETAIL_BATCH_UPDATE2, finalPayloadData)
        longWaitBlocker.hide()

        commit(VUEX_GRID_UPDATE_SUCCESS)
        await dispatch(VUEX_GRID_REQUEST_SNAPSHOT)
        await commit(VUEX_ORDERS_PRODUCTS_LOOKUP_UPDATE, state.order)
        await commit(VUEX_ORDERS_DETAIL_GRID_REQUEST_CLEAR)

        if (payload.frontEnd.type === 'add') {
          // dispatch(VUEX_GRID_REKEY)
          if (add.length === res.data.length) {
            let newpayload = {
              request: add,
              response: res.data
            }

            await commit(VUEX_ORDERS_DETAIL_GRID_ROWS_ADD_COMPLETE, newpayload)
            // message added
            let toastMessage = `${res.data.length} Items Added`
            dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
              component: '_core/Toast/Toast_Message.vue',
              timeout: 400,
              data: {
                type: 'success',
                message: toastMessage
              }
            })
          } else {
            // message added
            let toastMessage = `Error.  Request count doesn't match response count.`
            dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
              component: '_core/Toast/Toast_Message.vue',
              timeout: 2000,
              data: {
                type: 'error',
                message: toastMessage
              }
            })
          }
        }
        return true
      } catch (err) {
        commit(VUEX_GRID_UPDATE_FAILURE)
        longWaitBlocker.hide()
        return err
      }
    } else {
      commit(VUEX_GRID_UPDATE_FAILURE)
      longWaitBlocker.hide()
      console.error('VUEX_ORDERS_DETAIL_BATCH_UPDATE fail')
      return false
    }
  }, 1500),

  [VUEX_ORDERS_DETAIL_BATCH_UPDATE2]: async ({ state, dispatch, commit }, payload) => {
    const response = await dispatch(VUEX_API_ORDER_BATCH_UPDATE, {
      data: payload,
      id: state.order._id,
      orderType: state.order.purpose,
      orderSubtype: state.order.type
    })

    return response
  },

  [VUEX_ORDERS_DETAIL_ADD_COMMENT]: async ({ state, dispatch }, payload) => {
    const response = await dispatch(VUEX_API_ORDER_ADD_COMMENT, {
      data: payload,
      id: state.order._id,
      orderType: state.order.purpose,
      orderSubtype: state.order.type
    })
    return response
  },

  [VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE]: _debounce(async ({ state, dispatch, commit }, payload) => {
    // get rows
    await commit(VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE, payload.rows)
    let data = _cloneDeep(state.agGridRequestData)
    let rows = data.deleteProducts
    longWaitBlocker.show()

    // start
    commit(VUEX_GRID_UPDATE_START)
    if (rows) {
      // kill all frontEnd passed keys
      let finalPayloadData = []
      let deleteArr = []
      // let i = 1
      _forEach(rows, function (obj) {
        let finalObj = {
          'identifier': obj._id
        }
        deleteArr.push(finalObj)
      })
      let temp = {
        deleteProducts: deleteArr
      }
      finalPayloadData.push(temp)
      dispatch(VUEX_API_ORDERS_PRODUCTS_DELETE, {
        data: finalPayloadData,
        id: state.order._id,
        orderType: state.order.purpose,
        orderSubtype: state.order.type
      }).then(async response => {
        commit(VUEX_GRID_UPDATE_SUCCESS)
        await commit(VUEX_ORDERS_DETAIL_GRID_REQUEST_CLEAR)
        longWaitBlocker.hide()
        // GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })
      }).catch(err => {
        commit(VUEX_GRID_UPDATE_FAILURE)
        longWaitBlocker.hide()
        // GridHelpers.mgThisArray[0].gridApi.refreshServerSide({ purge: true })

        console.error('VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE | err', err)
        return err
      })
    } else {
      commit(VUEX_GRID_UPDATE_FAILURE)
      longWaitBlocker.hide()

      console.error('VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE fail')
    }
  }, 200),

  [VUEX_ORDERS_DETAIL_MARK_COMPLETE]: async ({ dispatch, commit }) => {
    await commit(VUEX_ORDERS_DETAIL_MARK_COMPLETE, 'submitting')
    let finalPayload = [{
      markCompleted: true
    }]
    await dispatch(VUEX_ORDERS_DETAIL_BATCH_UPDATE2, finalPayload).then(async response => {
      const payload = (response.status === 200) ? 'success' : 'error'
      commit(VUEX_ORDERS_DETAIL_MARK_COMPLETE, payload)
      if (payload === 'success') {
        dispatch(VUEX_ORDERS_DETAIL_REFETCH)

        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          timeout: 2000,
          data: {
            type: 'success',
            message: `Success`
          }
        })
      }
    }).catch(err => {
      commit(VUEX_ORDERS_DETAIL_MARK_COMPLETE, 'error')
      console.error('VUEX_ORDERS_DETAIL_MARK_COMPLETE | err', err)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        timeout: 2000,
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    })
  },

  [VUEX_ORDERS_DETAIL_MARK_COMPLETE_CONFIRMED]: async ({ commit }) => {
    await commit(VUEX_ORDERS_DETAIL_MARK_COMPLETE_CONFIRMED)
  },

  [VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE]: async ({ dispatch, commit }) => {
    await commit(VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE, 'submitting')
    let finalPayload = [{
      submit: true
    }]
    try {
      const response = await dispatch(VUEX_ORDERS_DETAIL_BATCH_UPDATE2, finalPayload)
      const payload = (response.status === 200) ? 'success' : 'error'
      commit(VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE, payload)
      if (payload === 'success') {
        dispatch(VUEX_ORDERS_DETAIL_REFETCH)

        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          timeout: 2000,
          data: {
            type: 'success',
            message: `Success`
          }
        })
      }
    } catch (err) {
      console.error('VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE | err', err)
      commit(VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE, 'error')
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        timeout: 2000,
        data: {
          type: 'error',
          message: err || err.response.data.message || err.response
        }
      })
    }
  },

  [VUEX_ORDERS_GRID_SET_SNAPSHOT]: ({ commit }, payload) => {
    commit(VUEX_ORDERS_GRID_SET_SNAPSHOT, payload)
  },

  [VUEX_ORDERS_SET_GRID_SETTINGS]: ({ commit }, payload) => {
    commit(VUEX_ORDERS_SET_GRID_SETTINGS, payload)
  },

  [VUEX_ORDERS_UPDATE_GRID_SETTINGS]: ({ commit }, payload) => {
    commit(VUEX_ORDERS_UPDATE_GRID_SETTINGS, payload)
  },

  [VUEX_ORDERS_CONFIRMATION_LEAVE_PAGE]: ({ commit }, payload) => {
    commit(VUEX_ORDERS_CONFIRMATION_LEAVE_PAGE, payload)
  }
}

const mutations = {
  [VUEX_ORDERS_DETAIL_REFETCH]: (state, data) => {
    // reset some states
    // don't clear on REFETCH, only clear on first fetch - because it looks cleaner because some aspects in the grid rely on state.order.properties...
    // see below: if (payload.refetch) {...
    // state.order = []

    state.orderIsEditable = calculateOrderEditable(state.order)
    state.markCompleteStatus = ''
    state.submitNewSampleStatus = ''
  },
  [VUEX_ORDERS_DETAIL_FETCH]: (state, payload) => {
    state.orderDetailRefetchParams = payload
    if (payload.refetch) {
      state.order = []
    }
  },

  [VUEX_ORDERS_UPDATE_QUERY]: (state, data) => {
    state.query = _merge(state.query, data)
  },

  [VUEX_ORDERS_DETAIL_CREATE]: (state, data) => {
    state.orders.push(data)
    // state.orderProductsLookup = {}
  },

  [VUEX_ORDERS_LIST_FETCH_SUCCESS]: (state, data) => {
    // nested data fetch
    // data = Array.isArray(data) ? data[0] : data
    if (data) {
      // data = Array.isArray(data.data) ? data.data : data
      state.agGridRowData = DataMiddleware.convertOrdersToRowData(data)
      state.orders = data
    } else {
      state.orders = []
    }
  },

  [VUEX_ORDERS_DETAIL_FETCH_SUCCESS]: (state, { response, rootState }) => {
    let data = response.data
    data = Array.isArray(data) ? data[0] : data
    if (data) {
      state.agGridRowData = DataMiddleware.convertOrderToRowData(data.products, data, rootState.user.user)
      state.order = data
    } else {
      state.order = []
    }
    state.orderIsEditable = calculateOrderEditable(state.order)
  },

  [VUEX_ORDERS_GRID_SET_SNAPSHOT]: (state, data) => {
    // state.agGridSnapshot = _cloneDeep(data)
    state.order.products = []
    state.order.products = _cloneDeep(data)
  },

  [VUEX_ORDERS_PRODUCTS_LOOKUP_UPDATE]: (state, data) => {
    state.orderProductsLookup = {}

    if (data.products?.length > 0) {
      data.products.forEach(p => {
        state.orderProductsLookup[`${p.style}-${p.color}`] = true
      })
    }
  },

  [VUEX_ORDERS_DETAIL_FETCH_FAILURE]: state => {
    state.order = {}
    state.orderIsEditable = calculateOrderEditable(state.order)
  },

  [VUEX_ORDERS_LIST_FETCH_FAILURE]: state => {
    state.orders = []
    state.orderIsEditable = calculateOrderEditable(state.order)
  },

  [VUEX_ORDERS_DETAIL_GRID_ROWS_ADD]: (state, data) => {
    state.agGridRequestData.addProducts = state.agGridRequestData.addProducts.concat(data)
  },

  [VUEX_ORDERS_DETAIL_GRID_ROWS_UPDATE]: (state, data) => {
    data.map(item => {
      state.agGridRequestData.updateProducts.push(item)
    })
  },
  [VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE]: (state, data) => {
    data.map(item => {
      state.agGridRequestData.deleteProducts.push(item)
    })
  },

  [VUEX_ORDERS_DETAIL_GRID_REQUEST_CLEAR]: state => {
    Object.keys(state.agGridRequestData).forEach(item => {
      if (item !== 'patch') state.agGridRequestData[item] = []
    })
    state.agGridPatchIdentifier = {}
  },

  [VUEX_ORDERS_DETAIL_GRID_ROWS_ADD_COMPLETE]: (state, data) => {
    state.agGridPatchIdentifier = data
    // state.order = data
    state.orderRowsAddedToggler = Math.random()
  },

  [VUEX_ORDERS_DETAIL_MARK_COMPLETE]: (state, data) => {
    state.markCompleteStatus = data
  },

  [VUEX_ORDERS_DETAIL_MARK_COMPLETE_CONFIRMED]: state => {
    state.markCompleteConfirmedToggler = Math.random()
  },

  [VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE]: (state, data) => {
    state.submitNewSampleStatus = data
  },

  [VUEX_ORDERS_SET_SELECTED_COORDINATOR]: (state, data) => {
    state.selectedCoordinator = data
  },

  [VUEX_ORDERS_CLEAR_SELECTED_COORDINATOR]: state => {
    state.selectedCoordinator = null
  },

  [VUEX_ORDERS_SET_GRID_SETTINGS]: (state, data) => {
    state.gridSettings = data
  },

  [VUEX_ORDERS_UPDATE_GRID_SETTINGS]: (state, data) => {
    const newSettings = Object.assign(state.gridSettings, data)
    state.gridSettings = newSettings
  },

  [VUEX_ORDERS_CONFIRMATION_LEAVE_PAGE]: (state, data) => {
    state.confirmationLeavePageToggler = data
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
