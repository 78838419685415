export const VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY = 'VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_SEARCH_QUERY'
export const VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_UPDATE = 'VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_UPDATE'
export const VUEX_FILTERBAR_PRODUCTSEARCH_SET_FETCH_PENDING = 'VUEX_FILTERBAR_PRODUCTSEARCH_SET_FETCH_PENDING'
export const VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_ADD_QUERY = 'VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_ADD_QUERY'
export const VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_FETCH = 'VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCTS_FETCH'
export const VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_PENDING_ROWS = 'VUEX_FILTERBAR_PRODUCTSEARCH_UPDATE_PENDING_ROWS'
export const VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS = 'VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS'
export const VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS_CLEAR = 'VUEX_FILTERBAR_PRODUCTSEARCH_GRID_UPDATE_ROWS_CLEAR'
export const VUEX_FILTERBAR_PRODUCTSEARCH_CLEAR_ADD_QUERY = 'VUEX_FILTERBAR_PRODUCTSEARCH_CLEAR_ADD_QUERY'
export const VUEX_FILTERBAR_PRODUCTSEARCH_QUERY_CONSTRUC = 'VUEX_FILTERBAR_PRODUCTSEARCH_QUERY_CONSTRUC'
export const VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCT_QUERY_CLEAR = 'VUEX_FILTERBAR_PRODUCTSEARCH_PRODUCT_QUERY_CLEAR'
export const VUEX_FILTERBAR_PRODUCTSEARCH_QUERY_CONSTRUCT = 'VUEX_FILTERBAR_PRODUCTSEARCH_QUERY_CONSTRUCT'
