import { loadComponent } from '@/helpers/asyncLoaders'
import store from '@/store/store'

import {
  VUEX_USER_HAS_CONFERENCE_ASIAN,
  VUEX_USER_HAS_CONFERENCE_DOMESTIC,
  VUEX_USER_HAS_CONFERENCE_INTERNATIONAL
} from '@/store/constants/user'

const hasPerms = () => {
  return store.getters[VUEX_USER_HAS_CONFERENCE_ASIAN] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_DOMESTIC] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_INTERNATIONAL]
}

export const routes = {
  path: 'fall23',
  name: 'fall23--main',
  meta: {
    conferenceClass: 'fall23'
  },
  component: loadComponent('GlobalConference/Fall23/Main.vue'),
  redirect: { name: 'fall23--categories' },
  children: [
    {
      path: 'welcome',
      name: 'fall23--welcome',
      component: loadComponent('GlobalConference/Fall23/Pages/Welcome.vue')
    },
    {
      path: '',
      name: 'fall23--dashboard',
      component: loadComponent('GlobalConference/Fall23/Pages/Dashboard.vue'),
      beforeEnter: (to, from, next) => {
        if (hasPerms()) {
          next()
        } else {
          next('/')
        }
      },
      children: [
        {
          path: '',
          name: 'fall23--dashboard-default',
          redirect: { name: 'fall23--categories' }
        },
        {
          path: 'categories',
          name: 'fall23--categories',
          component: loadComponent('GlobalConference/Fall23/Pages/Categories.vue'),
          meta: {
            eventCategory: 'fall23',
            title: 'Product Categories',
            navigation: [
              'Womens',
              'Mens',
              'Kids',
              'Unisex'
            ]
          }
        },
        {
          path: 'advertising',
          name: 'fall23--ads',
          component: loadComponent('GlobalConference/Fall23/Pages/Advertising.vue'),
          meta: {
            eventCategory: 'fall23',
            title: 'Advertising',
            navigation: [
              'Womens',
              'Mens',
              'Kids',
              'Unisex',
              'Radio',
              'Work',
              'International'
            ]
          }
        },
        {
          path: 'marketing',
          name: 'fall23--marketing',
          component: loadComponent('GlobalConference/Fall23/Pages/Marketing.vue'),
          meta: {
            eventCategory: 'fall23',
            title: 'Marketing'
          }
        },
        {
          path: 'catalogs',
          name: 'fall23--catalogs',
          component: loadComponent('GlobalConference/Fall23/Pages/Catalogs.vue'),
          meta: {
            eventCategory: 'fall23',
            title: 'DTC Catalogs'
          }
        },
        {
          path: 'retail',
          name: 'fall23--retail',
          component: loadComponent('GlobalConference/Fall23/Pages/Retail.vue'),
          meta: {
            eventCategory: 'fall23',
            title: 'Retail Openings'
          }
        }
      ]
    }
  ]
}
