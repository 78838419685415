import _clone from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'

import router from '@/router'

import {
  VUEX_ROUTING_INIT,

  VUEX_ROUTING_ROUTE,
  VUEX_ROUTING_ROUTE_UPDATE,
  VEUX_ROUTING_ROUTE_CLEAR_QUERY,
  VUEX_ROUTING_ROUTE_PREVIOUS,
  VUEX_ROUTING_ROUTE_SET_CURRENT,

  VUEX_ROUTING_HISTORY_TREE_UPDATE,
  VUEX_ROUTING_HISTORY_ROUTE_BACK
} from '@/store/constants/routing'

import {
  VUEX_DIALOG_SHOW,
  VUEX_DIALOG_LOGGED_OUT_MESSAGE
} from '@/store/constants/ui/dialog'

const state = {
  currentRoute: {},
  previousRoute: {},
  routeHistory: []
}
const actions = {
  [VUEX_ROUTING_INIT]: ({ state, dispatch }) => {
    // Determine if redirect
    const origPathname = window.location.pathname
    const redirect = window.data?.redirect ||
                     origPathname.includes('/set-password') ? origPathname : null ||
                     origPathname === '/logged-out/' ? '/dashboard/' : null

    if (redirect) {
      let route = { path: redirect }
      dispatch(VUEX_ROUTING_ROUTE, route)
    }

    // Determine and set current route
    const currentRoute = router.currentRoute || { name: ITS__ROUTING__DEFAULT__LOGGED_IN }

    dispatch(VUEX_ROUTING_ROUTE_PREVIOUS, {
      name: currentRoute.name,
      params: currentRoute.params || {},
      query: currentRoute.query || {}
    })

    // if origPathname === '/logges-out`
    if (origPathname === `/${ITS__ROUTING__DEFAULT__LOGGED_OUT}`) {
      dispatch(VUEX_DIALOG_SHOW, {
        content: '_core/Dialogs/Alerts/Dialog_ServerError.vue',
        title: 'Logged Out',
        message: VUEX_DIALOG_LOGGED_OUT_MESSAGE
      })
    }

    // window.onpopstate function || Back button handler
    window.onpopstate = event => {
      // dispatch(VUEX_ROUTING_ROUTE, document.location)
    }
  },

  [VUEX_ROUTING_ROUTE]: ({ state, dispatch }, payload) => {
    const route = _clone(payload)
    if (!route.query) route.query = {}

    dispatch(VUEX_ROUTING_ROUTE_PREVIOUS, route)

    if (payload) {
      let cleanedRoute = _clone(route)
      if (delete cleanedRoute.query?._options) delete cleanedRoute.query._options
      router.push(cleanedRoute)
    }
  },

  [VUEX_ROUTING_ROUTE_UPDATE]: ({ state, dispatch }, payload) => {
    const route = _clone(state.previousRoute) || {}
    if (!route.query) route.query = {}

    if (payload) {
      const parts = _clone(payload)
      Object.keys(parts).forEach(key => {
        if ((parts[key] === null || parts[key] === '') && route.query[key]) delete route.query[key]
        else route.query[key] = parts[key]
      })
    } else route.query = {}

    if (_isEqual(route, state.previousRoute)) return

    dispatch(VUEX_ROUTING_ROUTE_PREVIOUS, route)

    if (payload) {
      let cleanedRoute = _clone(route)
      if (delete cleanedRoute.query?._options) delete cleanedRoute.query._options
      router.push({ query: cleanedRoute.query })
        .catch(_ => {}) // Supresses Navigation duplicated errors
    } else {
      router.push({ query: null }).catch(_ => {}) // Supresses Navigation duplicated errors
    }
  },

  [VEUX_ROUTING_ROUTE_CLEAR_QUERY]: ({ state, commit }) => {
    let route = _clone(state.previousRoute)
    delete route.query

    router.push(route)
    commit(VEUX_ROUTING_ROUTE_CLEAR_QUERY)
  },

  [VUEX_ROUTING_ROUTE_PREVIOUS]: ({ commit }, payload) => {
    commit(VUEX_ROUTING_ROUTE_PREVIOUS, payload)
  },

  [VUEX_ROUTING_ROUTE_SET_CURRENT]: ({ commit }, payload) => {
    commit(VUEX_ROUTING_ROUTE_SET_CURRENT, payload)
  },

  /***********************************************************************/
  // Native routing actions
  /***********************************************************************/
  [VUEX_ROUTING_HISTORY_TREE_UPDATE]: ({ state, commit }, payload) => {
    if (state.routeHistory[0] !== payload) {
      // VUE 3 Performance: Remove unused keys
      const { name, matched } = payload
      commit(VUEX_ROUTING_HISTORY_TREE_UPDATE, { name, matched })
    }
  },

  [VUEX_ROUTING_HISTORY_ROUTE_BACK]: async ({ state, commit }, payload) => {
    await commit(VUEX_ROUTING_HISTORY_ROUTE_BACK)

    const prop = payload.property || 'path'
    const type = payload.type || 'exact'
    return state.routeHistory.find(route => type === 'exact' ? route[prop] === payload.match : route[prop].includes(payload.match))
  }
}

const mutations = {
  [VEUX_ROUTING_ROUTE_CLEAR_QUERY]: state => {
    if (state.previousRoute.query) state.previousRoute.query = {}
  },

  [VUEX_ROUTING_ROUTE_PREVIOUS]: (state, data) => {
    state.previousRoute = data
  },

  [VUEX_ROUTING_ROUTE_SET_CURRENT]: (state, data) => {
    state.currentRoute = data
  },

  [VUEX_ROUTING_HISTORY_TREE_UPDATE]: (state, data) => {
    state.routeHistory.unshift(data)
  },

  [VUEX_ROUTING_HISTORY_ROUTE_BACK]: state => {
    state.routeHistory.shift()
  }
}

export default {
  state,
  actions,
  mutations
}
