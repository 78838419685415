import enhancedAxios from '@/api'
import qs from '@/utils/qs'
import {
  VUEX_PRODUCTS,
  // VUEX_PRODUCT_GET,
  VUEX_PRODUCT_INDEX,
  VUEX_PRODUCT_INDEX_SUCCESS,
  VUEX_PRODUCT_INDEX_FAILURE
} from '@/store/constants/ui/productSearch'

// TODO: load from client side
const endpoint = 'products'

const state = {
  products: []
}

const getters = {}

const actions = {
  // use cached frozen get
  [VUEX_PRODUCT_INDEX]: async ({ dispatch }, payload) => {
    await enhancedAxios.cachedFrozenGet(`${endpoint}${qs(payload)}`)
      .then((products) => dispatch(VUEX_PRODUCT_INDEX_SUCCESS, products))
      .catch((err) => dispatch(VUEX_PRODUCT_INDEX_FAILURE, err))
  },
  [VUEX_PRODUCT_INDEX_SUCCESS]: ({ commit }, products) => {
    commit(VUEX_PRODUCTS, products)
  },
  [VUEX_PRODUCT_INDEX_FAILURE]: ({ dispatch }) => {
    // TODO: handler error here
  }
}

const mutations = {
  [VUEX_PRODUCTS]: (state, products) => {
    state.products = products
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
